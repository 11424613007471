/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import connectionToServer from '../../../../assets/Connection-to-server.gif';

import chargeWaitStyles from './charge-wait.styles';

export const ChargeWait: FC = () => {
    const intl = useIntl();
    return (
        <>
            <h4 css={chargeWaitStyles.containerTitle}>
                {intl.formatMessage({ id: 'paymentSuccessWait' })}
            </h4>
            <p css={chargeWaitStyles.description}>
                {intl.formatMessage({ id: 'paymentSuccessWaitDescription' })}
            </p>
            <div css={chargeWaitStyles.infoImageContainer}>
                <div css={chargeWaitStyles.infoImageContent}>
                    <img
                        css={chargeWaitStyles.infoImage}
                        src={connectionToServer}
                        alt="Connecting to server"
                    />
                </div>
            </div>
        </>
    );
};
