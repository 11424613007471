import { useDispatch } from 'react-redux';

import { changeGeoLocation, clearGeoLocation } from '../store/geo-location/geo-location.slice';
import { ILocation } from '../api/types';

export const useGeoLocation = (): {
    setGeoLocation: (location: ILocation) => void;
    clearLocation: () => void;
} => {
    const dispatch = useDispatch();

    const setGeoLocation = (location: ILocation) => {
        dispatch(changeGeoLocation(location));
    };
    const clearLocation = () => {
        dispatch(clearGeoLocation());
    };

    return {
        setGeoLocation,
        clearLocation,
    };
};
