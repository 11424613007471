/** @jsxImportSource @emotion/react */
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useReadLocalStorage } from 'usehooks-ts';

import { useStations } from '../../../../hooks/stations.hook';
import { selectGeoLocation } from '../../../../store/geo-location/geo-location.selector';
import {
    selectStations,
    selectStationsParamsSearch,
} from '../../../../store/stations/stations-list.selector';
import { IFilterStations } from '../../../../api/station/types';
import mainStyles from '../../main.styles';
import { StationItem } from '../../components/station/station-item.component';
import { ITabComponent } from '../../../../components/tabs/tabs.component';

export const RecentTab: FC<ITabComponent> = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const ids = useReadLocalStorage<string[]>('recentStations') || [];
    const { fetchStationsList, clearStations } = useStations();

    const search = useSelector(selectStationsParamsSearch);
    const { entry: location } = useSelector(selectGeoLocation);
    const { entry: stations, loading, reload, error } = useSelector(selectStations);

    const handleClick = (id: string) => {
        navigate(`/` + id);
    };

    const loadStations = (filter: IFilterStations) => {
        fetchStationsList({ ...filter, search }).then();
    };

    const renderStations = () => {
        const { rows } = stations;

        if (loading && reload) {
            return intl.formatMessage({ id: 'loading' });
        }

        if (error?.message) {
            return error.message;
        }

        if (!rows.length) {
            return intl.formatMessage({ id: 'noActiveStations' });
        }

        return (
            <div css={mainStyles.list}>
                {rows.map(station => (
                    <StationItem
                        key={station._id}
                        station={station}
                        onClick={() => handleClick(station._id)}
                    />
                ))}
            </div>
        );
    };

    useEffect(() => {
        if (Object.keys(location).length && location.lng !== null && location.lat !== null) {
            loadStations({
                lng: location.lng,
                lat: location.lat,
                reload: true,
                cursor: 0,
                ids: ids ? ids : [],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, search]);

    useEffect(() => {
        return (): void => {
            clearStations().then();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div css={mainStyles.container}>
            {renderStations()}
            <div>{loading && !reload && intl.formatMessage({ id: 'loading' })}</div>
        </div>
    );
};
