import React, { FC } from 'react';

import { IIconType } from './types';

export const IconApplePay: FC<IIconType> = ({ size = 48 }) => {
    return (
        <svg width="80" height={size} viewBox="0 0 80 48" fill="none">
            <rect width="80" height="48" rx="4" fill="white" />
            <rect
                x="0.5"
                y="0.5"
                width="79"
                height="47"
                rx="3.5"
                stroke="black"
                strokeOpacity="0.12"
            />
            <path
                d="M20.5279 18.7459C21.6978 18.8471 22.8667 18.1629 23.6039 17.2996C24.3162 16.4114 24.7992 15.2186 24.6718 14C23.6298 14.0516 22.3579 14.6857 21.6217 15.5749C20.9607 16.336 20.3757 17.5784 20.5279 18.7459Z"
                fill="black"
            />
            <path
                d="M27.7114 20.5934C26.6102 18.8977 24.8898 18.7065 24.2931 18.6587C23.3583 18.6006 22.5086 18.9491 21.8211 19.2312C21.3784 19.4128 21.003 19.5668 20.7155 19.5668C20.3984 19.5668 20.0088 19.4065 19.5705 19.2261C18.9934 18.9887 18.332 18.7166 17.641 18.7299C16.0588 18.7538 14.5908 19.6853 13.7876 21.167C12.136 24.1312 13.3519 28.5279 14.958 30.9416C15.7379 32.136 16.678 33.4508 17.9164 33.404C18.4587 33.3818 18.8483 33.2107 19.2517 33.0334C19.7188 32.8283 20.2045 32.6149 20.9675 32.6149C21.6952 32.6149 22.1586 32.8216 22.6039 33.0203C23.0284 33.2097 23.4364 33.3917 24.0411 33.3801C25.3254 33.3562 26.1287 32.1852 26.9085 30.9894C27.7531 29.7044 28.1268 28.4612 28.1857 28.2652C28.1892 28.2536 28.1916 28.2457 28.1929 28.2416C28.1914 28.2401 28.1803 28.2348 28.1609 28.2255C27.8707 28.0864 25.7141 27.0527 25.6931 24.298C25.6711 21.9843 27.3926 20.8112 27.6756 20.6184C27.6936 20.6061 27.7058 20.5978 27.7114 20.5934Z"
                fill="black"
            />
            <path
                d="M46.3472 21.4936C46.3472 22.7564 45.9336 23.7241 45.1065 24.3969C44.2793 25.0696 43.102 25.406 41.5746 25.406H40.3173V29.9305H38.3404V17.8376H41.8227C43.3336 17.8376 44.4641 18.1464 45.214 18.764C45.9695 19.3816 46.3472 20.2915 46.3472 21.4936ZM40.3173 23.7434H41.3678C42.3824 23.7434 43.1268 23.567 43.6011 23.2141C44.0753 22.8611 44.3124 22.3097 44.3124 21.5598C44.3124 20.865 44.1001 20.3466 43.6755 20.0047C43.2509 19.6629 42.5892 19.4919 41.6904 19.4919H40.3173V23.7434Z"
                fill="black"
            />
            <path
                d="M54.2795 29.9305L53.8907 28.6567H53.8246C53.3834 29.2136 52.9395 29.5941 52.4929 29.7981C52.0462 29.9966 51.4727 30.0959 50.7724 30.0959C49.8736 30.0959 49.1705 29.8533 48.6632 29.368C48.1614 28.8827 47.9105 28.1962 47.9105 27.3084C47.9105 26.3655 48.2606 25.6541 48.961 25.1744C49.6613 24.6946 50.7283 24.4327 52.162 24.3886L53.7418 24.339V23.851C53.7418 23.2664 53.604 22.8308 53.3283 22.5441C53.0581 22.2518 52.6362 22.1057 52.0627 22.1057C51.594 22.1057 51.1446 22.1746 50.7145 22.3125C50.2844 22.4503 49.8708 22.613 49.4738 22.8005L48.8452 21.4109C49.3414 21.1517 49.8846 20.956 50.4746 20.8236C51.0647 20.6858 51.6216 20.6168 52.1455 20.6168C53.309 20.6168 54.1857 20.8705 54.7758 21.3778C55.3713 21.8851 55.6691 22.6819 55.6691 23.7682V29.9305H54.2795ZM51.3845 28.607C52.0903 28.607 52.6555 28.4113 53.0801 28.0198C53.5102 27.6227 53.7253 27.0685 53.7253 26.3572V25.5631L52.5508 25.6128C51.6354 25.6459 50.9682 25.8003 50.5491 26.076C50.1355 26.3462 49.9287 26.7625 49.9287 27.325C49.9287 27.733 50.05 28.0501 50.2927 28.2762C50.5353 28.4967 50.8992 28.607 51.3845 28.607Z"
                fill="black"
            />
            <path
                d="M56.9925 20.7823H59.11L60.9711 25.9684C61.2523 26.7074 61.4398 27.4022 61.5335 28.0528H61.5997C61.6493 27.7496 61.7403 27.3829 61.8727 26.9527C62.005 26.5171 62.7053 24.4603 63.9736 20.7823H66.0745L62.1622 31.1464C61.4508 33.0488 60.2652 34 58.6054 34C58.1753 34 57.7562 33.9531 57.3482 33.8594V32.3209C57.6404 32.3871 57.9741 32.4202 58.349 32.4202C59.2865 32.4202 59.9454 31.877 60.3259 30.7907L60.665 29.9305L56.9925 20.7823Z"
                fill="black"
            />
        </svg>
    );
};
