import { createAction, createSlice } from '@reduxjs/toolkit';

import { IApiError, IRowsAndTotal } from '../../api/types';
import { IStation } from '../../api/station/types';
import { getStations } from '../../api/station/station.api';

export interface IStationsListState {
    entry: IRowsAndTotal<IStation>;
    loading: boolean;
    reload: boolean;
    cursor: number;
    error: IApiError;
}

const initialState: IStationsListState = {
    entry: {
        rows: [],
        total: 0,
    },
    loading: true,
    reload: true,
    cursor: 0,
    error: <IApiError>{},
};

const stationsListSlice = createSlice({
    name: 'stationsList',
    initialState,
    reducers: {
        actionClearStations: (state): void => {
            state.entry = initialState.entry;
            state.error = initialState.error;
            state.loading = true;
            state.reload = true;
            state.cursor = 0;
        },
    },
    extraReducers: builder => {
        builder.addCase(getStations.pending, (state, { meta }) => {
            state.loading = true;
            state.reload = meta.arg.reload;
            state.error = initialState.error;
        });
        builder.addCase(getStations.fulfilled, (state, { meta, payload }) => {
            state.entry.rows = meta.arg.reload
                ? payload.rows
                : state.entry.rows.concat(payload.rows);
            state.entry.total = payload.total;
            state.loading = false;
            state.reload = meta.arg.reload;
            state.cursor = payload.cursor || 0;
            state.error = initialState.error;
        });
        builder.addCase(getStations.rejected, (state, { payload }) => {
            state.entry = initialState.entry;
            state.error = <IApiError>payload;
            state.loading = false;
        });
    },
});

export const setStationsParamsSearch = createAction<string>('setStationsParamsSearch');

const stationsListParamsSlice = createSlice({
    name: 'stationsListParams',
    initialState: { search: '' },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(setStationsParamsSearch, (state, { payload }) => {
            state.search = payload;
        });
    },
});

export const { actionClearStations } = stationsListSlice.actions;
export const stationsListReducer = stationsListSlice.reducer;

export const stationsListParamsReducer = stationsListParamsSlice.reducer;
