import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { get } from '../api-config';

import { ITokenPayData } from './types';

export const getPaymentTokenInfo = createAsyncThunk(
    'pay/fetchPaymentTokenInfo',
    async (paymentToken: string, { signal, rejectWithValue }) => {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });

        try {
            return await get<ITokenPayData, unknown>(
                `/payment-token-info`,
                { paymentToken },
                source.token
            );
        } catch ({ response: { data } }) {
            return rejectWithValue(data);
        }
    }
);
