import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { PrivacyUkView } from './ladergy/privacy/uk/privacy-uk.view';
import { OfferEnView } from './adele/offer/en/offer-en.view';
import { OfferKkView } from './adele/offer/kk/offer-kk.view';
import { OfferRuView } from './adele/offer/ru/offer-ru.view';

export const OfferView: FC = () => {
    const { app, lang } = useParams();

    if (app === 'adele-cloud') {
        if (lang === 'ru') {
            return <OfferRuView />;
        }

        if (lang === 'kk') {
            return <OfferKkView />;
        }

        if (lang === 'en') {
            return <OfferEnView />;
        }
    }

    if (app === 'ladergy') {
        if (lang === 'uk') {
            return <PrivacyUkView />;
        }
    }

    return null;
};
