/** @jsxImportSource @emotion/react */
import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { EStationStatus, IStation, IStationConnector } from '../../../../api/station/types';
import { ISetUpState } from '../../set-up.view';
import { IconConnectorSchuko } from '../../../../assets/icons/icon-connector-schuko';
import { IconConnectorType1 } from '../../../../assets/icons/icon-connector-type1';
import { IconConnectorType2Plug } from '../../../../assets/icons/icon-connector-type2-plug';
import { IconConnectorType2Socket } from '../../../../assets/icons/icon-connector-type2-socket';
import { IconConnectorChademo } from '../../../../assets/icons/icon-connector-chademo';
import { IconConnectorCcsType1 } from '../../../../assets/icons/icon-connector-ccs-type1';
import { IconConnectorCcsType2 } from '../../../../assets/icons/icon-connector-ccs-type2';
import { IconConnectorGbtAc } from '../../../../assets/icons/icon-connector-gbt-ac';
import { IconConnectorGbtDc } from '../../../../assets/icons/icon-connector-gbt-dc';

import setConnectorStyles from './set-connector.styles';

interface IConnectorsTypes {
    [k: string]: {
        name: string;
        icon: ReactNode;
    };
}
const connectorsTypes: IConnectorsTypes = {
    schuko: {
        name: 'Schuko',
        icon: <IconConnectorSchuko size={46} />,
    },
    type1: {
        name: 'Type 1',
        icon: <IconConnectorType1 size={46} />,
    },
    'type2-plug': {
        name: 'Type 2',
        icon: <IconConnectorType2Plug size={46} />,
    },
    'type2-socket': {
        name: 'Type 2',
        icon: <IconConnectorType2Socket size={46} />,
    },
    chademo: {
        name: 'Chademo',
        icon: <IconConnectorChademo size={46} />,
    },
    'ccs-type1': {
        name: 'CCS',
        icon: <IconConnectorCcsType1 size={46} />,
    },
    'ccs-type2': {
        name: 'CCS',
        icon: <IconConnectorCcsType2 size={46} />,
    },
    'gbt-ac': {
        name: 'GB/T AC',
        icon: <IconConnectorGbtAc size={46} />,
    },
    'gbt-dc': {
        name: 'GB/T DC',
        icon: <IconConnectorGbtDc size={46} />,
    },
    default: {
        name: '',
        icon: null,
    },
};

type PropTypes = {
    station: IStation;
    onSetSetUpStep: (state: ISetUpState) => void;
};

export const SetConnector: FC<PropTypes> = ({ station, onSetSetUpStep }) => {
    const intl = useIntl();
    const availableConnectors = station.connectors.filter(
        connector =>
            connector.status === EStationStatus.AVAILABLE ||
            connector.status === EStationStatus.PREPARING
    );

    const unAvailableConnectors = station.connectors.filter(
        connector =>
            !(
                connector.status === EStationStatus.AVAILABLE ||
                connector.status === EStationStatus.PREPARING
            )
    );

    const renderConnector = (
        connector: IStationConnector,
        onSetSetUpStep: (state: ISetUpState) => void,
        isAvailable = true
    ) => {
        const typeData = connectorsTypes[connector.type] || connectorsTypes.default;
        return (
            <div
                css={setConnectorStyles.connector}
                key={connector.id}
                onClick={() => isAvailable && onSetSetUpStep({ connector })}>
                <div
                    css={[
                        setConnectorStyles.connectorId,
                        !isAvailable
                            ? setConnectorStyles.connectorIdBusy
                            : connector.status === EStationStatus.PREPARING
                            ? setConnectorStyles.connectorIdPreparing
                            : {},
                    ]}>
                    {connector.id < 10 ? 0 : ''}
                    {connector.id}
                </div>

                <div css={setConnectorStyles.connectorInfoContainer}>
                    {'typeData.power' && (
                        <div css={[setConnectorStyles.connectorInfo, { marginBottom: '8px' }]}>
                            <div css={setConnectorStyles.connectorInfoHead}>
                                {intl.formatMessage({ id: 'station.maxPower' })}
                            </div>
                            <div css={setConnectorStyles.connectorInfoHead}>
                                {connector.maxPower} {intl.formatMessage({ id: 'unit.kW' })}
                            </div>
                        </div>
                    )}

                    <div css={setConnectorStyles.connectorInfo}>
                        <div css={setConnectorStyles.connectorInfoLabel}>
                            {intl.formatMessage({ id: 'connector.info.per' })}{' '}
                            {intl.formatMessage({ id: 'unit.kWh' })}
                        </div>
                        <div css={setConnectorStyles.connectorInfoValue}>
                            {connector.priceKW} {station.business.currency.code}
                        </div>
                    </div>
                    <div css={setConnectorStyles.connectorInfo}>
                        <div css={setConnectorStyles.connectorInfoLabel}>
                            {intl.formatMessage({ id: 'connector.rent' })}
                        </div>
                        <div css={setConnectorStyles.connectorInfoValue}>
                            {connector.rentPriceKW} {station.business.currency.code}
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '4px',
                    }}>
                    <div css={setConnectorStyles.connectorIcon}>{typeData.icon}</div>
                    <div style={{ fontSize: '12px' }}>{typeData.name}</div>
                </div>
            </div>
        );
    };

    return (
        <>
            {!availableConnectors.length ? (
                <>
                    <h4 css={setConnectorStyles.containerTitle}>
                        {intl.formatMessage({ id: 'noAvailablePortsTitle' })}
                    </h4>
                    <p css={setConnectorStyles.description}>
                        {intl.formatMessage({ id: 'noAvailablePorts' })}
                    </p>
                </>
            ) : (
                <h4 css={setConnectorStyles.containerTitle}>
                    {intl.formatMessage({ id: 'setConnector' })}
                </h4>
            )}
            <div css={setConnectorStyles.content}>
                {availableConnectors.map(connector => renderConnector(connector, onSetSetUpStep))}
            </div>

            {unAvailableConnectors.length > 0 && (
                <div css={{ marginTop: '45px' }}>
                    <h4 css={setConnectorStyles.containerTitle}>
                        {intl.formatMessage({ id: 'busy' })}
                    </h4>
                    <div css={setConnectorStyles.content}>
                        {unAvailableConnectors.map(connector =>
                            renderConnector(connector, onSetSetUpStep, false)
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
