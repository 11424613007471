/** @jsxImportSource @emotion/react */
import React from 'react';
import { useIntl } from 'react-intl';

import { AppTheme } from '../../app.theme';

import deleteAccountStyles from './delete-account.styles';

export const DeleteAccountView = () => {
    const { appName } = AppTheme();
    const intl = useIntl();

    return (
        <div css={deleteAccountStyles.container}>
            <div
                css={deleteAccountStyles.title}
                dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: 'deleteAccount.title' }, { 0: appName }),
                }}
            />
            <div
                css={deleteAccountStyles.text}
                dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: 'deleteAccount.description' }),
                }}
            />
            <ul css={deleteAccountStyles.steps}>
                <li
                    dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: 'deleteAccount.option1' }),
                    }}
                />
                <li
                    dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: 'deleteAccount.option2' }),
                    }}
                />
            </ul>
            <div
                css={deleteAccountStyles.text}
                dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: 'deleteAccount.note' }),
                }}
            />
            <div css={deleteAccountStyles.contactInfo}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: 'contactInfo.title' }),
                    }}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: 'contactInfo.description' }),
                    }}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: 'contactInfo.email' }),
                    }}
                />
            </div>
        </div>
    );
};
