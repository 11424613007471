import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IApiError, ICharge } from '../../api/types';
import { getGuestById } from '../../api/guest/guest.api';

export interface IChargeState {
    entry: ICharge;
    error: IApiError;
    loading: boolean;
}

const initialState: IChargeState = {
    entry: <ICharge>{},
    error: <IApiError>{},
    loading: true,
};

const chargeSlice = createSlice({
    name: 'charge',
    initialState,
    reducers: {
        setCharge: (state, data: PayloadAction<ICharge>): void => {
            state.entry = data.payload;
            state.error = initialState.error;
        },
        setError: (state, data: PayloadAction<string>): void => {
            state.entry = initialState.entry;
            state.error = { message: data.payload };
        },
    },
    extraReducers: builder => {
        // GET GUEST
        builder.addCase(getGuestById.pending, state => {
            state.loading = true;
            state.error = initialState.error;
        });
        builder.addCase(getGuestById.fulfilled, (state, { payload }) => {
            state.entry = payload.charge || <ICharge>{};
            state.loading = false;
            state.error = initialState.error;
        });
        builder.addCase(getGuestById.rejected, (state, { payload }) => {
            state.entry = initialState.entry;
            state.error = <IApiError>payload;
            state.loading = false;
        });
    },
});

export const { setCharge, setError } = chargeSlice.actions;
export const chargeReducer = chargeSlice.reducer;
