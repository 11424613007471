/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import chargeFailStyles from './charge-fail.styles';

export const ChargeFail: FC = () => {
    const intl = useIntl();
    return (
        <div css={chargeFailStyles.container}>
            <div css={chargeFailStyles.info}>
                <h4 css={chargeFailStyles.infoTitle}>
                    {intl.formatMessage({ id: 'chargeFailed' })}
                </h4>
                <p>{intl.formatMessage({ id: 'chargeFailedDetails' })}</p>
            </div>
        </div>
    );
};
