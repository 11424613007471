import { createSlice } from '@reduxjs/toolkit';

import { IApiError } from '../../api/types';
import { ICreateCardTransactionResponse } from '../../api/card/types';
import { postCreateCardTransaction } from '../../api/card/card.api';

export interface ICardTransactionState {
    entry: ICreateCardTransactionResponse;
    loading: boolean;
    error: IApiError;
    created: boolean;
    deleted: boolean;
}

const initialState: ICardTransactionState = {
    entry: <ICreateCardTransactionResponse>{},
    loading: true,
    error: <IApiError>{},
    created: false,
    deleted: false,
};

const cardTransactionSlice = createSlice({
    name: 'cardTransaction',
    initialState,
    reducers: {
        actionClearCardTransaction: (state): void => {
            state.entry = initialState.entry;
            state.error = initialState.error;
            state.loading = true;
            state.created = false;
            state.deleted = false;
        },
    },
    extraReducers: builder => {
        // CREATE CARD TRANSACTION
        builder.addCase(postCreateCardTransaction.pending, state => {
            state.loading = true;
            state.error = initialState.error;
            state.created = false;
            state.deleted = false;
        });
        builder.addCase(postCreateCardTransaction.fulfilled, (state, { payload }) => {
            state.entry = payload;
            state.loading = false;
            state.error = initialState.error;
            state.created = true;
            state.deleted = false;
        });
        builder.addCase(postCreateCardTransaction.rejected, (state, { payload }) => {
            state.entry = initialState.entry;
            state.error = <IApiError>payload;
            state.loading = false;
            state.created = false;
            state.deleted = false;
        });
    },
});

export const { actionClearCardTransaction } = cardTransactionSlice.actions;
export const cardTransactionReducer = cardTransactionSlice.reducer;
