/** @jsxImportSource @emotion/react */
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import conditionsStyles from './conditions.styles';

const AVAILABLE_COUNTRIES = ['ua', 'kz'];

type PropTypes = {
    color?: 'white' | 'black';
    country?: string;
};

export const Conditions: FC<PropTypes> = ({ color = 'black', country }) => {
    const intl = useIntl();

    const [offerLink, setOfferLink] = useState<string | undefined>(undefined);
    const [privacyLink, setPrivacyLink] = useState<string | undefined>(undefined);

    const renderConditionsByCountry = (country: string | undefined) => {
        switch (country?.toLowerCase()) {
            case 'kz':
                return (
                    <>
                        <div css={conditionsStyles.termsPartner}>
                            {intl.formatMessage({ id: 'conditions.title' })}
                        </div>
                        <div css={conditionsStyles.termsCompany}>
                            ТОО «Adele Energy Qazaqstan», БИН: 220440045257
                        </div>
                        <div css={conditionsStyles.termsCompany}>
                            {intl.formatMessage({ id: 'conditions.address' })}
                        </div>
                        <div css={conditionsStyles.termsEmail}>info@adele.energy</div>
                    </>
                );
            case 'ua':
                return (
                    <>
                        <div css={conditionsStyles.termsCompany}>
                            ФОП Сталь Ірина Вікторівна м. Дніпро, Вулиця Полігонна 11, квартира 2
                        </div>
                        <div css={conditionsStyles.termsEmail}>hello@ladergy.com</div>
                    </>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        const loadPdf = async () => {
            if (country) {
                try {
                    const offerModule = await import(
                        `../../../../assets/docs/${country.toLowerCase()}/offer.pdf`
                    );
                    setOfferLink(offerModule.default);
                } catch (err) {
                    console.error('Error loading offer PDF:', err);
                }

                try {
                    const privacyModule = await import(
                        `../../../../assets/docs/${country.toLowerCase()}/privacy.pdf`
                    );
                    setPrivacyLink(privacyModule.default);
                } catch (err) {
                    console.error('Error loading privacy PDF:', err);
                }
            }
        };

        loadPdf();
    }, [country]);

    if (!country || AVAILABLE_COUNTRIES.indexOf(country.toLowerCase()) === -1) {
        return null;
    }

    return (
        <div css={conditionsStyles.terms} style={{ color }}>
            {renderConditionsByCountry(country)}
            <div>
                <a
                    css={conditionsStyles.termsLink}
                    style={{ color }}
                    href={offerLink}
                    rel="noopener noreferrer"
                    target="_blank">
                    {intl.formatMessage({ id: 'footer.offerConditions' })}
                </a>{' '}
                <a
                    css={conditionsStyles.termsLink}
                    style={{ color }}
                    href={privacyLink}
                    rel="noopener noreferrer"
                    target="_blank">
                    {intl.formatMessage({ id: 'footer.privacyPolicy' })}
                </a>
            </div>
        </div>
    );
};
