/** @jsxImportSource @emotion/react */
import React, { FC, useEffect } from 'react';

import useScript from '../../../../hooks/script.hook';
import { REACT_APP_IOKA_WIDGET_LIB } from '../../../app.config';

import iokaStyles from './ioka-widget.styles';

declare let IokaWidget: any;

type PropTypes = {
    orderId: string;
    orderAccessToken: string;
    onSuccess: (id: string) => void;
};

export const IokaPaymentWidget: FC<PropTypes> = ({ orderId, orderAccessToken, onSuccess }) => {
    const status = useScript(`${REACT_APP_IOKA_WIDGET_LIB}?t=` + Math.random(), {
        removeOnUnmount: true,
    });

    useEffect(() => {
        if (status === 'ready') {
            const IokaWidgetItem = new IokaWidget({
                orderId,
                orderAccessToken,
                elementId: 'ioka-widget',
                options: {
                    onError: (err: any) => {
                        console.log('on error', err);
                    },
                    onSuccess: (res: any) => {
                        onSuccess(res.external_id);
                    },
                },
            });
            IokaWidgetItem.initPayment();
        }
    }, [status]);

    return <div id={'ioka-widget'} key={Math.random() + 1} css={iokaStyles.container}></div>;
};
