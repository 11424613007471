import { useDispatch } from 'react-redux';

import {
    actionClearStations,
    setStationsParamsSearch,
} from '../store/stations/stations-list.slice';
import { getStationById, getStations } from '../api/station/station.api';
import { actionClearStation } from '../store/station/station.slice';
import { IFilterStations } from '../api/station/types';

export const useStations = (): {
    fetchStationById: (stationId: string) => Promise<void>;
    fetchStationsList: (filter: IFilterStations) => Promise<void>;
    clearStations: () => Promise<void>;
    clearStation: () => Promise<void>;
    setStationsFilterSearch: (value: string) => Promise<void>;
} => {
    const dispatch = useDispatch();

    const fetchStationsList = async (filter: IFilterStations): Promise<void> => {
        await dispatch(getStations(filter));
    };

    const fetchStationById = async (stationId: string): Promise<void> => {
        await dispatch(getStationById(stationId));
    };

    const clearStation = async (): Promise<void> => {
        await dispatch(actionClearStation());
    };

    const clearStations = async (): Promise<void> => {
        await dispatch(actionClearStations());
    };

    const setStationsFilterSearch = async (value: string): Promise<void> => {
        await dispatch(setStationsParamsSearch(value));
    };

    return {
        fetchStationById,
        fetchStationsList,
        clearStations,
        clearStation,
        setStationsFilterSearch,
    };
};
