import { PathLike } from 'fs';

import axios, { AxiosInstance, AxiosRequestConfig, CancelToken } from 'axios';
import qs from 'qs';

import { REACT_APP_API_URL } from '../app/app.config';

const ACCESS_TOKEN_NAME = 'token';

export const axiosInstance = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        Accept: 'application/json',
        'Accept-Language': 'en',
        'Content-Type': 'application/json',
    },
    paramsSerializer: (params: PathLike) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

const prepareAuthHeaders = async <R>(
    axiosInstance: AxiosInstance,
    config: AxiosRequestConfig
): Promise<R> => {
    if (!axiosInstance.defaults.headers.common.Authorization) {
        axiosInstance.interceptors.request.use(function (config) {
            const token = localStorage.getItem(ACCESS_TOKEN_NAME);
            Object.assign(config.headers || {}, { Authorization: token ? `Bearer ${token}` : '' });

            return config;
        });
    }

    return axiosInstance.request(config).then(response => response.data);
};

export const get = <T, B>(url: string, data?: B, cancelToken?: CancelToken): Promise<T> =>
    prepareAuthHeaders(axiosInstance, {
        url,
        method: 'get',
        params: data,
        cancelToken: cancelToken,
    });

export const post = <T, B>(url: string, data?: B, cancelToken?: CancelToken): Promise<T> =>
    prepareAuthHeaders(axiosInstance, { url, method: 'post', data, cancelToken: cancelToken });

export const put = <T, B>(url: string, data: B, cancelToken?: CancelToken): Promise<T> =>
    prepareAuthHeaders(axiosInstance, { url, method: 'put', data, cancelToken: cancelToken });

export const del = <T, B>(url: string, data?: B, cancelToken?: CancelToken): Promise<T> =>
    prepareAuthHeaders(axiosInstance, { url, method: 'delete', data, cancelToken: cancelToken });
