import { css } from '@emotion/react';

const langListStyle = {
    buttonLang: css({
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: '15px',
        lineHeight: '16px',
        borderRadius: '3px',
        border: 'none',
        padding: '5px 11px',
        textTransform: 'uppercase',
        outline: 'none',
        cursor: 'pointer',
    }),
    buttonLangWhite: css({
        background: '#ffffff',
        color: '#101010',
    }),
    buttonLangBlack: css({
        background: '#101010',
        color: '#ffffff',
    }),
    langList: css({
        textAlign: 'center',
        transition: '0.3s',
        overflow: 'hidden',
        position: 'absolute',
        zIndex: 99,
        backgroundColor: '#101010',
        left: 0,
        right: 0,
        marginTop: '-1px',
    }),
    langListItem: css({
        fontSize: '1.5rem',
        fontWeight: 300,
        color: '#ffffff',
        padding: '1.5rem 0 0.5rem',
    }),
    langListItemActive: css({
        fontWeight: 'bold',
    }),
};

export default langListStyle;
