import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { PrivacyRuView } from './adele/privacy/ru/privacy-ru.view';
import { PrivacyKkView } from './adele/privacy/kk/privacy-kk.view';
import { PrivacyEnView } from './adele/privacy/en/privacy-en.view';
import { PrivacyUkView } from './ladergy/privacy/uk/privacy-uk.view';
import { LadergyPrivacyEnView } from './ladergy/privacy/en/privacy-en.view';

export const PrivacyView: FC = () => {
    const { app, lang } = useParams();

    if (app === 'adele-cloud') {
        if (lang === 'ru') {
            return <PrivacyRuView />;
        }

        if (lang === 'kk') {
            return <PrivacyKkView />;
        }

        if (lang === 'en') {
            return <PrivacyEnView />;
        }
    }

    if (app === 'ladergy') {
        if (lang === 'uk') {
            return <PrivacyUkView />;
        }

        if (lang === 'en') {
            return <LadergyPrivacyEnView />;
        }
    }

    return null;
};
