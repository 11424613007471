import { css } from '@emotion/react';

const chargePanelStyles = {
    infoBlock: css({
        position: 'relative',
        width: '50%',
        padding: '1rem 0.75rem',
        boxSizing: 'border-box',
    }),
    infoBlockTitle: css({
        fontWeight: 700,
        fontSize: '10px',
        lineHeight: '10px',
        color: '#000000',
        opacity: 0.5,
    }),
    infoBlockValue: css({
        display: 'flex',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '24px',
        color: '#101010',
        alignItems: 'flex-end',
    }),
    infoBlockValueSymbol: css({
        fontWeight: 700,
        fontSize: '10px',
        lineHeight: '18px',
        color: '#101010',
        paddingLeft: '5px',
    }),
    row: css({
        display: 'flex',
        flexWrap: 'wrap',
    }),
    businessInfo: css({
        padding: '2rem 1rem',
        width: '100%',
        textAlign: 'center',
    }),
};

export default chargePanelStyles;
