import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { get, post } from '../api-config';
import { REACT_APP_API_URL } from '../../app/app.config';

import { ICreateGuestRequest, IGuest } from './types';

export const postCreateGuest = createAsyncThunk(
    'guest/createGuest',
    async (createGuestData: ICreateGuestRequest, { signal, rejectWithValue }) => {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });

        try {
            return await post<IGuest, ICreateGuestRequest>(`/`, createGuestData, source.token);
        } catch ({ response: { data } }) {
            return rejectWithValue(data);
        }
    }
);

export const getGuestById = createAsyncThunk(
    'guest/fetchById',
    async (id: string, { signal, rejectWithValue }) => {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });

        try {
            return await get<IGuest, unknown>(`/${id}`, null, source.token);
        } catch ({ response: { data } }) {
            return rejectWithValue(data);
        }
    }
);

export const stopCharge = (id: string) => {
    return get<IGuest, unknown>(`/${id}/stop`);
};

export const getInvoiceLink = (id: string) => {
    return `${REACT_APP_API_URL}/${id}/invoice`;
};
