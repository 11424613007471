import { css } from '@emotion/react';

const setEnergyStyles = {
    container: css({
        position: 'relative',
    }),
    columnWrapper: css({
        display: 'flex',
        flexWrap: 'wrap',
        height: '160px',
        marginTop: '25px',
        marginBottom: '50px',
    }),
    column: css({
        display: 'flex',
        flexGrow: 1,
        flexBasis: 0,
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '20px',
    }),
    infoBlock: css({
        display: 'flex',
        flexBasis: 0,
        flexDirection: 'column',
    }),
    infoBlockTitle: css({
        fontWeight: 700,
        fontSize: '10px',
        lineHeight: '10px',
        color: '#000000',
        opacity: 0.5,
    }),
    infoBlockValue: css({
        display: 'flex',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '24px',
        color: '#101010',
        alignItems: 'flex-end',
    }),
    infoBlockValueSymbol: css({
        fontWeight: 700,
        fontSize: '10px',
        lineHeight: '18px',
        color: '#101010',
        paddingLeft: '5px',
    }),
    battery: css({
        width: '82px',
        height: '160px',
        border: '2px solid #000000',
        borderRadius: '4px',
        position: 'relative',
        flexDirection: 'column-reverse',
        display: 'flex',

        ':before': {
            content: '""',
            position: 'absolute',
            boxShadow: '0px 3px 3px rgb(0 0 0 / 16%)',
            borderRadius: '10px 10px 5px 5px',
            width: '26px',
            height: '6px',
            backgroundColor: '#101010',
            top: '-10px',
            left: 0,
            right: 0,
            margin: '0 auto',
        },
    }),
    batteryEnergy: css({
        background: 'linear-gradient(90deg, #FCE03E 0%, #FDF1AB 100%)',
    }),
    batteryEnergyValue: css({
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: '30px',
        display: 'flex',
        justifyContent: 'center',
    }),
    inputEnergy: css({
        background: 'transparent',
        textAlign: 'center',
        fontWeight: 700,
        fontSize: '25px',
        lineHeight: '32px',
        color: '#000000',
        width: '50px',
        border: 'none',
        ':focus': {
            outline: 'none !important',
            border: '2px solid black',
            borderRadius: '4px',
        },
        '::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '-moz-appearance': 'textfield',
    }),
    batteryEnergyValueLabel: css({
        fontWeight: 700,
        fontSize: '25px',
        lineHeight: '32px',
        color: '#000000',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
    }),
    batteryEnergyValueSymbol: css({
        fontWeight: 700,
        fontSize: '10px',
        lineHeight: '10px',
        color: '#000000',
        textAlign: 'center',
    }),
    actionWrapper: css({
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        marginBottom: '30px',
    }),
    maxEnergy: css({
        color: '#FFFFFF',
        fontSize: '15px',
        lineHeight: '16px',
        letterSpacing: '-0.12px',
        border: 'solid 1px #000000',
        backgroundColor: '#000000',
        padding: '12px 4px',
        boxShadow: 'none',
        borderRadius: '9px',
    }),
};

export default setEnergyStyles;
