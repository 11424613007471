import { css } from '@emotion/react';

const chargeFailStyles = {
    container: css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'calc(100% - 4rem)',
        color: '#101010',
        height: '100%',
    }),
    info: css({
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem',
    }),
    infoTitle: css({
        fontSize: '1.5rem',
        fontWeight: 800,
        textAlign: 'left',
        color: '#101010',
        margin: 0,
    }),
    infoDescription: css({
        color: '#101010',
        fontSize: '2rem',
        margin: 0,
    }),
};

export default chargeFailStyles;
