import { css } from '@emotion/react';

const setConnectorStyles = {
    containerTitle: css({
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '19px',
        lineHeight: '23px',
        color: '#000000',
        margin: '15px 15px',
    }),
    description: css({
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '20px',
        color: '#000000',
    }),
    content: css({
        display: 'flex',
        flexDirection: 'column',
        gap: '13px',
    }),
    connector: css({
        boxShadow: ' 0px 3px 20px rgba(0, 0, 0, 0.301961)',
        background: '#FFFFFF',
        borderRadius: '8px',
        display: 'flex',
        color: '#101010',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: '13px',
    }),
    connectorId: css({
        width: '65px',
        height: '76px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
        backgroundColor: '#10CE00',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '31px',
        textAlign: 'center',
        color: '#FFFFFF',
    }),
    connectorIdBusy: css({
        backgroundColor: '#8A8A8A',
    }),
    connectorIdPreparing: css({
        backgroundColor: 'rgba(255, 95, 48, 1)',
    }),
    connectorInfoContainer: css({
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'space-between',
        flex: 1,
    }),
    connectorInfo: css({
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 20px 0 13px',
    }),
    connectorInfoLabel: css({
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '15px',
        color: '#000000',
        opacity: 0.8,
    }),
    connectorInfoHead: css({
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '22px',
        color: '#000000',
        marginBottom: '8px',
    }),
    connectorInfoValue: css({
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '15px',
        color: '#000000',
        opacity: 0.8,
    }),
    connectorIcon: css({
        width: '46px',
        height: '46px',
    }),
    connectorSVG: css({
        background: 'radial-gradient(white 0, white 50%, transparent 60%)',
        color: '#000000',
        fontSize: '46px',
    }),
};

export default setConnectorStyles;
