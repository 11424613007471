/** @jsxImportSource @emotion/react */
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { useStations } from '../../hooks/stations.hook';
import { selectStation } from '../../store/station/station.selector';
import { EStationStatus, IStationConnector } from '../../api/station/types';
import { useGuest } from '../../hooks/guest.hook';
import { selectGuest } from '../../store/guest/guest.selector';

import { StationUnavailable } from './components/station-unavailable/station-unavailable.component';
import { SetConnector } from './components/set-connector/set-connector.component';
import { SetEnergy } from './components/set-energy/set-energy.component';
import { SetPhone } from './components/set-phone/set-phone.component';
import { StationIsCharging } from './components/station-is-charging/station-is-charging.component';
import setUpStyles from './set-up.styles';

export type SetUpStep = 'connector' | 'energy' | 'phone';

export interface ISetUpState {
    energy?: number;
    step?: SetUpStep;
    connector?: IStationConnector;
    phone?: string;
}

export const SetUp: FC = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { stationId, connectorId } = useParams();

    const { createGuest, clearGuest } = useGuest();
    const { fetchStationById } = useStations();
    const { entry: guest, created, error } = useSelector(selectGuest);
    const {
        entry: station,
        loading: stationLoading,
        error: stationError,
    } = useSelector(selectStation);
    const [setUpState, setSetUpState] = useState<ISetUpState>({
        step: 'connector',
    });

    const stationIsUnavailable = () => {
        if (!station?._id && !stationError?.message) return false;
        return (
            stationError?.message ||
            !station.online ||
            !station.qrEnabled ||
            station.status !== EStationStatus.AVAILABLE
        );
    };

    const set = ({ connector, energy, phone }: ISetUpState) => {
        setSetUpState(prevState => {
            return {
                ...prevState,
                ...(connector ? { connector, step: 'energy' } : null),
                ...(energy ? { energy, step: 'phone' } : null),
                ...(phone ? { phone } : null),
            };
        });
        if (phone && stationId && setUpState?.connector?.id && setUpState?.energy) {
            createGuest({
                phone,
                stationId,
                energy: setUpState?.energy * 1000,
                connectorId: setUpState?.connector?.id,
                language: localStorage.getItem('language') || 'en',
            }).then();
        }
    };

    const handleChangeStep = (step: SetUpStep) => {
        setSetUpState(prevState => {
            if (step === 'connector') {
                history.replaceState(null, '', `/${station._id}`);
            }
            return {
                ...prevState,
                step,
            };
        });
    };

    const renderPage = () => {
        if (stationLoading) {
            return intl.formatMessage({ id: 'loading' });
        }

        if (stationIsUnavailable()) {
            return <StationUnavailable />;
        }

        return (
            <>
                {setUpState?.step === 'connector' && (
                    <SetConnector station={station} onSetSetUpStep={set} />
                )}
                {setUpState?.step === 'energy' &&
                    (setUpState?.connector?.charge ? (
                        <StationIsCharging />
                    ) : (
                        <SetEnergy
                            connectorId={setUpState?.connector?.id}
                            priceKW={setUpState?.connector?.priceKW || 0}
                            rentPriceKW={setUpState?.connector?.rentPriceKW || 0}
                            currency={station.business.currency.code}
                            onSetSetUpStep={set}
                            changeStep={handleChangeStep}
                        />
                    ))}
                {setUpState?.step === 'phone' && (
                    <SetPhone
                        phone={setUpState.phone}
                        onSetSetUpStep={set}
                        changeStep={handleChangeStep}
                    />
                )}
                {error?.message ? <div css={setUpStyles.errorMessage}>{error?.message}</div> : null}
                {/*<ChargeParams*/}
                {/*    connector={setUpState?.connector}*/}
                {/*    energy={setUpState?.energy}*/}
                {/*    priceKW={station.priceKW}*/}
                {/*    currency={station.business.currency.code}*/}
                {/*    changeStep={handleChangeStep}*/}
                {/*    step={setUpState?.step}*/}
                {/*/>*/}
            </>
        );
    };

    useEffect(() => {
        if (station._id && connectorId) {
            const connector = station.connectors.find(
                connector => connector.id === Number(connectorId)
            );

            if (connector) {
                set({ connector });
            } else {
                history.replaceState(null, '', `/${station._id}`);
            }
        }
    }, [station, connectorId]);

    useEffect(() => {
        if (stationId) {
            fetchStationById(stationId).then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stationId]);

    useEffect(() => {
        if (guest?._id && created) {
            navigate(`/pay/` + guest._id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guest, created]);

    useEffect(() => {
        return () => {
            clearGuest().then();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{renderPage()}</>;
};
