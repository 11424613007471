/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import stationIsChargingStyles from './station-is-charging.styles';

export const StationIsCharging: FC = () => {
    const intl = useIntl();
    return (
        <>
            <h4 css={stationIsChargingStyles.title}>
                {intl.formatMessage({ id: 'stationIsCharging' })}
            </h4>

            <p css={stationIsChargingStyles.description}>
                {intl.formatMessage({ id: 'stationIsChargingInfo' })}
            </p>
        </>
    );
};
