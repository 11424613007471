/** @jsxImportSource @emotion/react */
import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useGuest } from '../../hooks/guest.hook';
import { selectGuest } from '../../store/guest/guest.selector';
import { useCharge } from '../../hooks/charge.hook';
import { selectCharge } from '../../store/charge/charge.selector';
import { useStations } from '../../hooks/stations.hook';

import { ChargeProcess } from './steps/charge-process/charge-process.component';
import { ChargeFinished } from './steps/charge-finished/charge-finished.component';
import { ChargeFail } from './steps/charge-fail/charge-fail.component';
import { ChargeWait } from './steps/charge-wait/charge-wait.component';

export const Charge: FC = () => {
    const { connect, disconnect } = useCharge();
    const { guestId } = useParams();
    const { fetchGuestById } = useGuest();
    const { fetchStationById } = useStations();
    const { entry: guest, loading, error } = useSelector(selectGuest);
    const { entry: charge, error: chargeError } = useSelector(selectCharge);

    const renderContent = () => {
        if (!guest._id && loading) {
            return 'loading';
        }

        if (error?.message) {
            return error.message;
        }

        const hasCharge = Object.keys(charge).length;

        if (
            (!hasCharge &&
                (guest.paymentStatus === 'reversed' ||
                    guest.paymentStatus === 'failure' ||
                    guest.paymentStatus === 'UNPAID')) ||
            chargeError.message == 'fail'
        ) {
            return <ChargeFail />;
        }
        if (hasCharge && charge.status !== 1) {
            return <ChargeProcess guest={guest} />;
        }
        if (hasCharge && charge.status === 1) {
            return <ChargeFinished guest={guest} />;
        }
        return <ChargeWait />;
    };

    useEffect(() => {
        if (!guest._id && guestId && (!error.message || !chargeError.message)) {
            fetchGuestById(guestId).then(() => {
                connect(guestId);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guest, loading, guestId, error, chargeError]);

    useEffect(() => {
        if (guest._id) {
            fetchStationById(guest.station._id).then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guest]);

    useEffect(() => {
        return () => {
            disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{renderContent()}</>;
};
