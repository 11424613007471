import { css } from '@emotion/react';

const appMarketsButtonsStyles = css`
    .app-markets-buttons {
        display: flex;
        max-width: 400px;
        flex-direction: row;
        justify-content: space-between;
    }
`;

export default appMarketsButtonsStyles;
