import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { get } from '../api-config';
import { IRowsAndTotal } from '../types';

import { IFilterStations, IStation } from './types';

export const getStationById = createAsyncThunk(
    'station/fetchById',
    async (id: string, { signal, rejectWithValue }) => {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });

        try {
            return await get<IStation, unknown>(`/station/${id}`, null, source.token);
        } catch ({ response: { data } }) {
            return rejectWithValue(data);
        }
    }
);

export const getStations = createAsyncThunk(
    'station/fetchStations',
    async (filter: IFilterStations, { signal, rejectWithValue }) => {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });

        try {
            return await get<IRowsAndTotal<IStation>, unknown>(`/stations`, filter, source.token);
        } catch ({ response: { data } }) {
            return rejectWithValue(data);
        }
    }
);
