import { IBusinessCurrency } from '../types';

export interface IFilterStations {
    lng: number;
    lat: number;
    cursor?: number;
    search?: string;
    reload: boolean;
    ids?: string[];
}

export interface IStation {
    qrEnabled: boolean;
    priceKW: number;
    rentPriceKW: number;
    online: boolean;
    status: EStationStatus;
    _id: string;
    identity: string;
    city: string;
    address: string;
    business: IBusiness;
    model: string;
    distance: number;
    connectors: IStationConnector[];
}

export interface IBusiness {
    _id: string;
    address: string;
    city: string;
    description: string;
    name: string;
    phones: string[];
    site: string;
    email: string;
    workHours: string;
    country: string;
    abbreviation: string;
    currency: IBusinessCurrency;
}

export interface IStationConnector {
    errorCode: string;
    id: number;
    power: number;
    status: EStationStatus;
    charge: string;
    type: string;
    maxPower: number;
    priceKW: number;
    rentPriceKW: number;
    _id: string;
}

export enum EStationStatus {
    AVAILABLE = 'Available',
    PREPARING = 'Preparing',
    CHARGING = 'Charging',
    SUSPENDED_EV = 'SuspendedEV',
    SUSPENDED_EVSE = 'SuspendedEVSE',
    FINISHING = 'Finishing',
    RESERVED = 'Reserved',
    UNAVAILABLE = 'Unavailable',
    FAULTED = 'Faulted',
}
