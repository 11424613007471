/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';

import tabsStyles from './tabs.styles';

export interface ITabComponent {
    tabIndex?: number;
}

export interface ITab {
    label: string;
    index: number;
    Component: FC<ITabComponent>;
}

type TabsProps = {
    tabs: ITab[];
    selectedTab: number;
    onClick: (index: number) => void;
    className?: string;
};

export const Tabs: FC<TabsProps> = ({ tabs = [], selectedTab = 0, onClick }) => {
    const Panel = tabs && tabs.find(tab => tab.index === selectedTab);

    return (
        <div css={tabsStyles.container}>
            <div css={tabsStyles.tabList}>
                {tabs.map(tab => (
                    <button
                        css={[
                            tabsStyles.button,
                            selectedTab === tab.index ? tabsStyles.buttonActive : '',
                        ]}
                        onClick={() => onClick(tab.index)}
                        key={tab.index}
                        tabIndex={selectedTab === tab.index ? 0 : -1}
                        id={`btn-${tab.index}`}>
                        {tab.label}
                    </button>
                ))}
            </div>
            <div
                css={tabsStyles.tabPanel}
                aria-labelledby={`btn-${selectedTab}`}
                id={`tabpanel-${selectedTab}`}>
                {Panel && <Panel.Component tabIndex={selectedTab} />}
            </div>
        </div>
    );
};
