import React, { FC } from 'react';

import { IIconType } from './types';

export const IconVisa: FC<IIconType> = ({ size = 48 }) => {
    return (
        <svg width="80" height={size} viewBox="0 0 80 48" fill="none">
            <rect width="80" height="48" rx="4" fill="white" />
            <path
                d="M33.8727 16.2825L27.365 31.758H23.1193L19.9168 19.4089C19.7224 18.6482 19.5544 18.3695 18.9623 18.0493C17.9969 17.5271 16.4027 17.0376 15 16.7333L15.0966 16.2825H21.9312C22.8021 16.2825 23.5853 16.8605 23.7831 17.8607L25.4744 26.8145L29.6544 16.2815L33.8727 16.2825ZM50.5084 26.7054C50.5255 22.6209 44.8419 22.3962 44.8809 20.5714C44.8931 20.0161 45.4246 19.4259 46.5847 19.275C47.1602 19.1999 48.7459 19.1424 50.5457 19.9676L51.2512 16.6859C50.2847 16.3365 49.0413 16 47.4942 16C43.5241 16 40.7302 18.1035 40.7066 21.1156C40.6807 23.3434 42.7001 24.5866 44.2226 25.3267C45.7881 26.0851 46.3131 26.572 46.3074 27.2503C46.296 28.2884 45.0588 28.7469 43.9023 28.7648C41.883 28.7957 40.7121 28.2203 39.778 27.7873L39.05 31.1776C39.9885 31.607 41.7207 31.9814 43.5169 32C47.7367 32 50.4968 29.9226 50.5097 26.7054H50.5084ZM60.9932 31.758H64.7079L61.4636 16.2825H58.0353C57.6692 16.2794 57.3105 16.386 57.0059 16.5884C56.7012 16.7908 56.4646 17.0798 56.3267 17.4179L50.2992 31.758H54.5168L55.3538 29.4465H60.507L60.9932 31.758ZM56.5115 26.2747L58.6256 20.464L59.8424 26.2747H56.5115ZM39.6115 16.2825L36.2902 31.758H32.2738L35.5964 16.2825H39.6115Z"
                fill="#1434CB"
            />
            <rect
                x="0.5"
                y="0.5"
                width="79"
                height="47"
                rx="3.5"
                stroke="black"
                strokeOpacity="0.12"
            />
        </svg>
    );
};
