import React, { FC } from 'react';

import styles from './privacy-uk.module.css';

export const PrivacyUkView: FC = () => {
    return (
        <div className={styles.c4 + ' ' + styles.c6}>
            <p className={styles.c11}>
                <span className={styles.c2}>
                    ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ ДЛЯ МОБІЛЬНОГО ДОДАТКУ <br />
                    LADERGY
                </span>
            </p>
            <p className={styles.c11 + ' ' + styles.c3}>
                <span className={styles.c2}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Для мобільного додатка Ladergy інтереси користувачів над усе. Цей принцип лежить
                    в основі всіх прийнятих нами рішень, у тому числі при зборі та обробці Ваших
                    персональних даних.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Ми постаралися зробити Політику конфіденційності якомога зрозумілішою та ясною.
                    Наша мета полягає в тому, щоб Ви користувач Ladergy — завжди відчували себе
                    впевнено, коли мова заходить про конфіденційності.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Збереження Вашої довіри також є одним із пріоритетів Ladergy. Для захисту
                    конфіденційності Ваших персональних даних використовуються вказані нижче
                    принципи.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c1}>
                    <br /> 
                </span>
                <span className={styles.c2}>1.</span>
                <span className={styles.c1}> </span>
                <span className={styles.c2 + ' ' + styles.c4}>ВИЗНАЧЕННЯ ПОНЯТЬ</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>«</span>
                <span className={styles.c2}>Закон</span>
                <span className={styles.c1}>
                    » означає Державний закон України «Про персональні дані та їх захист» з усіма
                    змінами та доповненнями, а також інші законодавчі акти України.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>«</span>
                <span className={styles.c2}>Мобільний додаток</span>
                <span className={styles.c1}>
                    » це програмне забезпечення (з усіма існуючими доповненнями та покращеннями),
                    призначене для роботи на смартфонах, планшетах, годинниках та інших мобільних
                    пристроях, та розроблене для конкретної платформи ( iOS , Android , Windows
                    Phone тощо). Для цілей цієї Політики під Мобільним додатком мається на увазі
                    наступне програмне забезпечення: Ladergy.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>«</span>
                <span className={styles.c2}>Персональні дані</span>
                <span className={styles.c1}>
                    » означає сукупність особистих даних та/або неперсоніфікованої інформації про
                    Користувача, яку він надає самому Правовласнику та/або автоматично збирається
                    Правовласником та/або третіми особами.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>«</span>
                <span className={styles.c2}>Політика</span>
                <span className={styles.c1}>
                    » означає цю Політику конфіденційності мобільного додатка (з усіма існуючими
                    доповненнями та змінами).
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>«</span>
                <span className={styles.c2}>Користувач</span>
                <span className={styles.c1}>
                    » означає юридичну або фізичну особу, яка завантажила Мобільний додаток на
                    смартфон, планшет, годинник або будь-який інший мобільний пристрій та/або
                    здійснила активацію такого Мобільного додатка на одному із зазначених пристроїв.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>«</span>
                <span className={styles.c2}>Угода користувача</span>
                <span className={styles.c1}>
                    » означає угоду, що укладається між Правовласником та Користувачем щодо порядку,
                    правил та особливостей використання Користувачем Мобільного додатка.{' '}
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>«</span>
                <span className={styles.c2}>Правовласник</span>
                <span className={styles.c1}>
                    » означає таку особу, якій належать виключні права володіння Мобільним додатком
                </span>
            </p>
            <p className={styles.c3 + ' ' + styles.c12}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c1 + ' ' + styles.c4}>ФОП Сталь Ірина Вікторівна</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c1 + ' ' + styles.c4}>3324109961</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c1 + ' ' + styles.c4}>UA893052990000026003050029451</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c1 + ' ' + styles.c4}>АТ КБ «ПРИВАТБАНК»</span>
            </p>
            <p className={styles.c5 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>«</span>
                <span className={styles.c2}>Файли cookie</span>
                <span className={styles.c1}>
                    » означає невеликі файли, які надсилаються будь-яким мобільним додаткам або
                    сайтом, і розміщуються на смартфонах, планшетах, годинниках та інших мобільних
                    пристроях Користувача, для покращення роботи таких додатків або сайтів, а також
                    якості розміщеного в них контенту.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>2. ВІДНОСИНИ, НА ЯКІ ПОШИРЮЄТЬСЯ ПОЛІТИКА</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>Загальні положення</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Ця Політика використовується і застосовується виключно до Персональних даних,
                    отриманих від Користувача у зв&apos;язку з використанням Мобільного додатка.
                    Положення цієї Політики спрямовані на:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(1) </span>
                <span className={styles.c1}>
                    визначення видів та типів одержуваних Персональних даних, напрямів та цілей
                    використання (обробки) Персональних даних, а також джерел отримання таких
                    Персональних даних;{' '}
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(2) </span>
                <span className={styles.c1}>
                    визначення прав Користувача щодо захисту конфіденційності переданих їм
                    Персональних даних; і
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(3) </span>
                <span className={styles.c1}>
                    визначення осіб, відповідальних за обробку та зберігання персональних даних, а
                    також третіх осіб, яким такі дані розкриваються (повністю або частково).
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Правила цієї Політики не застосовуються у разі обробки третіми особами
                    Персональних даних, які добровільно надаються Користувачем.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    За допомогою установки та/або активації Мобільного додатка на смартфоні,
                    планшеті, годиннику або іншому мобільному пристрої Користувач погоджується з
                    умовами даної Політики та дає свою згоду Правовласнику на збирання, обробку,
                    утримання та зберігання Персональних даних у порядку та на умовах, передбачених
                    цією Політикою.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Якщо Користувач не погоджується з умовами Політики та/або окремими умовами
                    Політики йому не зрозумілі, у такому разі Користувач зобов&apos;язаний негайно
                    припинити використання Мобільного додатка.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>Права користувача захисту персональних даних</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    У зв&apos;язку з наданням Персональних даних Користувач автоматично отримує
                    наступні права:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(1) </span>
                <span className={styles.c1}>
                    отримувати дані щодо їх обробки (підстави та цілі такої обробки, застосовувані
                    способи обробки, відомості про осіб, які мають доступ до них або яким вони
                    можуть бути розкриті на підставі договору або Закону).
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(2) </span>
                <span className={styles.c1}>
                    отримувати дані про місцезнаходження та ідентифікаційні дані осіб, які
                    здійснюють обробку Персональних даних.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(3) </span>
                <span className={styles.c1}>
                    отримувати дані про термін зберігання персональних даних.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(4) </span>
                <span className={styles.c1}>
                    отримувати дані про здійснену або про передбачувану транскордонну передачу
                    Персональних даних.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(5) </span>
                <span className={styles.c1}>
                    отримувати інформацію про місцезнаходження та ідентифікаційні дані осіб, які
                    здійснюють зберігання Персональних даних.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(6) </span>
                <span className={styles.c1}>
                    оскаржити дії чи бездіяльність Правовласника до уповноваженого органу захисту
                    прав суб&apos;єктів персональних даних або в судовому порядку.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(7) </span>
                <span className={styles.c1}>
                    отримувати відшкодування збитків та/або компенсацію моральної шкоди в судовому
                    порядку внаслідок допущених Правовласником та/або третіми особами порушень прав
                    Користувача на охорону та захист його Персональних даних.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(8) </span>
                <span className={styles.c1}>
                    реалізовувати інші права у сфері захисту персональних даних, передбачені Законом
                    чи положеннями цієї Політики.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>3. ПЕРЕЛІК ПЕРСОНАЛЬНИХ ДАНИХ, ЩО ЗБИРАТЬСЯ</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>Неперсоніфікована інформація про користувачів</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    У зв&apos;язку з використанням Мобільного додатка Правовласник може автоматично
                    збирати та обробляти наступну неперсоніфіковану інформацію про Користувача:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(1) </span>
                <span className={styles.c1}>
                    інформацію про трафік, можливу кількість скоєних кліків, логи та інші дані.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(2) </span>
                <span className={styles.c1}>
                    інформацію про місцезнаходження Користувача (геолокація). Користувач може в
                    будь-який момент відключити геолокацію шляхом зміни налаштувань пристрою, з
                    якого здійснено вхід до Мобільного додатка. Геолокація використовується
                    Мобільним додатком тільки тоді, коли Користувач активно використовує таку
                    програму. При виході з мобільного додатка геолокація перестає функціонувати.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(3) </span>
                <span className={styles.c1}>
                    інформацію про пристрій (ідентифікаційний номер, мережу мобільного оператора), з
                    якого виконується вхід, операційна система, платформа, тип браузера та інша
                    інформація про браузер, IP-адресу.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>Особисті дані про користувачів</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Користувач надає правовласнику про себе такі особисті дані:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(1) </span>
                <span className={styles.c1}>повні прізвище, ім&apos;я та по батькові.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(2) </span>
                <span className={styles.c1}>дату народження та вік.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(3) </span>
                <span className={styles.c1}>адресу електронної пошти.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(4) </span>
                <span className={styles.c1}>номер мобільного телефону.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(5) </span>
                <span className={styles.c1}>стать Користувача.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(6) </span>
                <span className={styles.c1}>
                    дані, які містяться в особистому кабінеті (профілі) Користувача, всі внутрішні
                    листування Користувача (якщо є), а також інша активність особистого кабінету
                    (профілю) Користувача.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(7) </span>
                <span className={styles.c1}>
                    дані про скоєні Користувачем замовлення/купівлі та/або отримані/оплачені послуги
                    через Мобільний додаток.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(8) </span>
                <span className={styles.c1}>марка електромобіля.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(9) </span>
                <span className={styles.c1}>
                    дані та інформація, що отримується в результаті об&apos;єднання тих чи інших
                    персональних даних конкретного Користувача, а також дані та інформація, що
                    отримуються про Користувача, які отримуються від третіх осіб (партнерів,
                    маркетологів, дослідників).
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Користувач є єдиною відповідальною особою за повноту особистих (персональних)
                    даних, що надаються, і зобов&apos;язаний здійснювати своєчасну їх зміну
                    (оновлення, перевірку, коригування) на регулярній основі.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Правовласник виходить з того, що всі надані Користувачем особисті (персональні)
                    дані є достовірними, і що Користувач підтримує таку інформацію в актуальному
                    стані.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>Інформація про транзакції, що здійснюються</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Користувач через Мобільний додаток може здійснювати оплату за товари або послуги
                    за допомогою введення у спеціальне поле інформації про платіжну картку та
                    ідентифікаційні дані власника такої картки. Користувач може здійснювати оплату в
                    Мобільному додатку в такий спосіб:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>• за допомогою банківської картки.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>• за допомогою платіжної системи Apple Pay .</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>• за допомогою платіжної системи Google Pay .</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Збір та обробка даних про Користувача в даному випадку здійснюється виключно з
                    метою проведення оплати, недопущення шахрайства, а також дотримання інших вимог
                    Закону:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Користувач дає свою згоду на доступ та збір Правовласником та відповідною
                    платіжною системою або банківською установою, через яку/яка проводиться оплата,
                    до таких Персональних даних, а також погоджується з політикою конфіденційності
                    відповідної платіжної системи чи банківської установи.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>Використання файлів cookie</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Цей Мобільний додаток застосовує певні Файли cookie для збереження IP-адреси,
                    переваг Користувачів або типу використовуваного пристрою з метою{' '}
                </span>
                <span className={styles.c2}>(1) </span>
                <span className={styles.c1}>
                    ведення статистики відвідувань і трафіку сайту, і{' '}
                </span>
                <span className={styles.c2}>(2) </span>
                <span className={styles.c1}>
                    персоналізації даних, що виводяться на екран Користувача, і{' '}
                </span>
                <span className={styles.c2}>(3) </span>
                <span className={styles.c1}>
                    збереження даних, необхідних для ідентифікації Користувача, у тому числі при
                    доступі з різних пристроїв, та{' '}
                </span>
                <span className={styles.c2}>(4) </span>
                <span className={styles.c1}>
                    показу реклами відповідно до інтересів та уподобань Користувача.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Мобільний додаток може використовувати як власні Файли cookie, що належать
                    Правовласнику , так і Файли cookie третіх осіб .
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Мобільний додаток використовує такі файли cookie:</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(1) </span>
                <span className={styles.c1}>
                    Технічні (функціональні) Файли cookie, які потрібні для контролю трафіку та
                    передачі даних, для ідентифікації Користувачів та надання доступу Користувачеві
                    до контенту Мобільного додатка та без яких використання Мобільного додатка є
                    функціонально обмеженим, а також для запобігання наданню рекомендацій, що не
                    відповідають інтересам Користувача.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(2) </span>
                <span className={styles.c1}>
                    Статистичні Файли cookie, які потрібні для відстеження частоти відвідування
                    сайту Користувачами, для виявлення способів використання Користувачем Мобільного
                    додатка, а також для виявлення типу та виду контенту, який є популярним або
                    цікавим для Користувача.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(3) </span>
                <span className={styles.c1}>
                    Геолокаційні Файли cookie, які потрібні для визначення місця знаходження
                    Користувача для персоналізації контенту, що виводиться на екран його пристрою в
                    Мобільному додатку.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(4) </span>
                <span className={styles.c1}>
                    Файли cookie третіх осіб, які встановлюються третіми особами з дозволу
                    Користувача та призначені для проведення статистичних досліджень щодо поведінки
                    Користувача в мережі Інтернет та/або напрямки персоналізованих рекламних чи
                    маркетингових матеріалів Користувачеві та/або надання товарів чи послуг.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Користувач має право в будь-який момент відключити Файли cookie в Мобільному
                    додатку шляхом зміни певних налаштувань у своєму смартфоні, планшеті, годиннику
                    або іншому мобільному пристрої. Таке відключення може спричинити обмеження або
                    зміну доступу Користувача до функціональних можливостей Мобільного додатка
                    та/або контенту.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>4. ЦІЛІ ЗБОРУ І ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ</span>
            </p>
            <p className={styles.c3 + ' ' + styles.c5}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c2}>Визначення цілей обробки</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Збір та обробка Персональних даних здійснюється в таких цілях:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(1) </span>
                <span className={styles.c1}>
                    для аналізу поведінки Користувача, а також виявлення переваг Користувача до
                    певного виду контенту.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(2) </span>
                <span className={styles.c1}>
                    для оперативної та коректної роботи Мобільного додатка, покращення
                    функціонування роботи Мобільного додатка, покращення контенту Мобільного
                    додатка, покращення внутрішньої архітектури та функціональності Мобільного
                    додатка.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(3) </span>
                <span className={styles.c1}>для ідентифікації Користувача.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(4) </span>
                <span className={styles.c1}>
                    для направлення персоналізованих рекламних та маркетингових матеріалів на
                    вказану електронну адресу та/або мобільний телефон Користувача.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(5) </span>
                <span className={styles.c1}>на дотримання вимог Закону.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(6) </span>
                <span className={styles.c1}>
                    для відстеження замовлень/покупок, здійснених Користувачем через Мобільний
                    додаток.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(7) </span>
                <span className={styles.c1}>визначення місця знаходження Користувача.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(8) </span>
                <span className={styles.c1}>
                    для технічної підтримки Мобільного додатка, виявлення проблем у його роботі та
                    їх усунення.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(9) </span>
                <span className={styles.c1}>
                    підтримки зв&apos;язку з Користувачем (комунікація).
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(10) </span>
                <span className={styles.c1}>
                    для виконання інших зобов&apos;язань Правовласника, які постали перед
                    Користувачем.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(11) </span>
                <span className={styles.c1}>щодо статистичних досліджень.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(12) </span>
                <span className={styles.c1}>
                    для будь-яких інших цілей за умови отримання окремої згоди від Користувача.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Обробка Персональних даних здійснюється на основі принципів:{' '}
                </span>
                <span className={styles.c2}>(1) </span>
                <span className={styles.c1}>законності цілей та способів обробки; та </span>
                <span className={styles.c2}>(2) </span>
                <span className={styles.c1}>сумлінності; та </span>
                <span className={styles.c2}>(3) </span>
                <span className={styles.c1}>
                    відповідності цілей обробки Персональних даних цілям, заздалегідь визначеним та
                    заявленим при зборі таких Персональних даних; та{' '}
                </span>
                <span className={styles.c2}>(4) </span>
                <span className={styles.c1}>
                    відповідності обсягу та характеру оброблюваних персональних даних заявленим
                    цілям їх обробки.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>Умови обробки персональних даних</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Обробка Персональних даних проводиться у випадках:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(1) </span>
                <span className={styles.c1}>отримання згоди від Користувача; або</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(2) </span>
                <span className={styles.c1}>
                    досягнення Правовласником цілей, передбачених міжнародним договором чи Законом;
                    або
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(3) </span>
                <span className={styles.c1}>
                    надання Користувачем своїх Персональних даних необмеженому колу осіб; або
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(4) </span>
                <span className={styles.c1}>
                    виконання інших зобов&apos;язань Правовласника перед Користувачем, включаючи,
                    однак, не обмежуючись, надання певного контенту Користувачеві; або
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(5) </span>
                <span className={styles.c1}>
                    порятунку життя або здоров&apos;я Користувача, коли згоду на обробку його
                    Персональних даних не вдається отримати завчасно.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    У разі знеособлення Персональних даних, що не дозволяє прямо чи опосередковано
                    визначити Користувача, подальше використання та розкриття таких даних третім
                    особам допускається та щодо них більше не застосовуються правила цієї Політики.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Правовласник вживає всіх можливих заходів для захисту конфіденційності отриманих
                    персональних даних, за винятком випадків, коли Користувач зробив такі дані
                    загальнодоступними.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Обробка Персональних даних здійснюється з використанням засобів автоматизації та
                    без використання таких засобів автоматизації.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>5. НАПРЯМОК СКАРГ І ЗАПИТІВ ПРАВОВЛАСНИКУ</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>Вимога про припинення обробки персональних даних</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Кожен Користувач має право висловити своє заперечення Правовласнику проти
                    обробки та зберігання його Персональних даних. Таке заперечення можна висловити
                    так: запит має бути направлений на пошту Правовласника за адресою
                    hello@ladergy.com з темою листа «Припинення обробки персональних (особистих)
                    даних».
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>
                    Запит на отримання інформації про персональні дані
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Якщо у Користувача виникають питання, пов&apos;язані з порядком застосування або
                    використання цієї Політики, порядком та/або способом обробки Персональних даних,
                    Користувач може поставити таке запитання таким чином: питання має бути
                    направлене на пошту Правовласника за адресою hello@ladergy.com з темою листа
                    «Припинення обробки персональних (особистих) даних».
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>
                    Зміна (оновлення, доповнення, коригування) або видалення персональних даних
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Користувач має право в будь-який момент самостійно змінити або видалити свої
                    персональні дані, за винятком випадків, коли така зміна або видалення може
                    призвести:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(1) </span>
                <span className={styles.c1}>до порушення правил цієї Політики; або</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(2) </span>
                <span className={styles.c1}>до порушення Закону;</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>(3) </span>
                <span className={styles.c1}>
                    характер таких Персональних даних є доказом у будь-якому судовому процесі, що
                    виник між Правовласником та Користувачем. Для цього Користувачеві потрібно
                    видалити свій особистий обліковий запис (профіль) у Мобільному додатку.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Правовласник має право в будь-який момент видалити особистий обліковий
                    запис/профіль Користувача, а також усі Персональні дані про Користувача, якщо
                    він порушив умови цієї Політики та/або Угоди користувача.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>
                    6. ТЕРМІНИ І ПОРЯДОК ЗБЕРІГАННЯ ПЕРСОНАЛЬНИХ ДАНИХ
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c2}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Зберігання здійснюється третіми особами за дорученням правовласника. Користувач
                    дає свою згоду на зберігання його персональних даних третіми особами за
                    дорученням правовласника, за умови збереження такими третіми особами
                    конфіденційності отриманих персональних даних.
                </span>
            </p>
            <p className={styles.c5 + ' ' + styles.c8}>
                <span className={styles.c1}>
                    Зберігання здійснюється протягом усього терміну використання Користувачем цього
                    Мобільного додатка.
                </span>
            </p>
            <p className={styles.c5 + ' ' + styles.c8}>
                <span className={styles.c1}>
                    Правовласник зобов&apos;язується негайно після припинення використання
                    Користувачем Мобільного додатка знищити або знеособити його Персональні дані.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>7. ДОСТУП НЕПОВНОЛІТНИХ ДО МОБІЛЬНОГО ДОДАТКУ</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c2}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Використання Мобільного додатка призначене для осіб віком від 18 років, які
                    отримують до нього доступ лише за умови надання попередньої згоди на обробку їх
                    Персональних даних. Правовласник перевіряє вік Користувача таким чином:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    користувачеві знадобиться підтвердити ознайомлення з політикою конфіденційності
                    у спеціальному вікні. Якщо Користувач є неповнолітньою особою, він повинен
                    негайно припинити використання цього Мобільного додатка.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>8. ПОРЯДОК ЗАХИСТУ ПЕРСОНАЛЬНИХ ДАНИХ</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Захист конфіденційності Персональних даних є першорядним та важливим завданням
                    для Правовласника. Правовласник дотримується всіх необхідних міжнародних
                    стандартів, правил та рекомендацій щодо захисту персональних даних.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Правовласник впровадив низку технічних та організаційних методів, спрямованих на
                    захист персональних даних від розголошення або несанкціонованого доступу до них
                    третіх осіб.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>9. Заключні положення</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>Доступність тексту політики для ознайомлення</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Користувачі можуть ознайомитися з умовами цієї Політики за таким посиланням:{' '}
                </span>
                <span className={styles.c4 + ' ' + styles.c10}>
                    https://guest.ladergy.com/privacy/ladergy/uk
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>Зміна та доповнення політики</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Ця Політика може бути змінена час від часу. Правовласник не несе жодної
                    відповідальності перед Користувачем за зміну умов цієї Політики без дозволу
                    та/або згоди Користувача.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Користувач сам зобов&apos;язується на регулярній основі перевіряти положення
                    цієї Політики щодо її можливої зміни або доповнення. Проте, у разі істотної
                    зміни умов цієї Політики, Правовласник зобов&apos;язується повідомити
                    Користувачів у такий спосіб: автоматичне розсилання всім користувачам на
                    електронну адресу, вказану при реєстрації профілю в мобільному додатку.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    <br />
                </span>
                <span className={styles.c2}>Ризик розголошення</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Незалежно від заходів, що вживаються Правовласником захисту конфіденційності
                    одержуваних персональних даних, Користувач цим вважається належним чином
                    ознайомлений з тим, що будь-яка передача Персональних даних в мережі Інтернет не
                    може бути гарантовано безпечною, а тому Користувач здійснює таку передачу на
                    власний ризик.
                </span>
            </p>
        </div>
    );
};
