import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import { appReducer } from './app/app.slice';
import { stationsListParamsReducer, stationsListReducer } from './stations/stations-list.slice';
import { stationReducer } from './station/station.slice';
import { guestReducer } from './guest/guest.slice';
import { supportReducer } from './support/support.slice';
import { chargeReducer } from './charge/charge.slice';
import { geoLocationReducer } from './geo-location/geo-location.slice';
import { cardTransactionReducer } from './card-transaction/card-transaction.slice';
import { paymentTokenReducer } from './token-pay/token-pay.slice';

const rootReducer = combineReducers({
    appReducer,
    station: stationReducer,
    stations: stationsListReducer,
    stationsListParams: stationsListParamsReducer,
    guest: guestReducer,
    support: supportReducer,
    charge: chargeReducer,
    geoLocation: geoLocationReducer,
    cardTransaction: cardTransactionReducer,
    paymentToken: paymentTokenReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const reducers = rootReducer;

export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
});
