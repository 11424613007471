import React, { FC } from 'react';

import { IIconType } from './types';

export const IconSearch: FC<IIconType> = ({ size = 24 }) => {
    return (
        <svg width={size} height={size} fill="none" viewBox="0 0 24 24">
            <path
                d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 15L21 21"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
