import { css } from '@emotion/react';

const stationFooterStyle = {
    container: css({
        color: '#000000',
        padding: '15px 25px 0',
        display: 'flex',
        flexDirection: 'column',
        gap: '9px',
        backgroundColor: '#F6F8FA',
    }),
    title: css({
        margin: '0 0 15px 5px',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '19px',
        lineHeight: '23px',
        textTransform: 'capitalize',
    }),
    appButtons: css({
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
    }),
    appButtonsDescription: css({
        fontSize: '10px',
        fontWeight: 700,
        lineHeight: '10px',
        color: '#000',
        opacity: 0.5,
    }),
    content: css({
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    }),
    infoBlock: css({
        display: 'flex',
        gap: 12,
    }),
    infoBlockIcon: css({
        marginTop: '5px',
    }),
    infoBlockTitle: css({
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '15px',
        opacity: 0.5,
    }),
    infoBlockValue: css({
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        whiteSpace: 'pre-line',
        textDecoration: 'none',
        color: '#000000',
    }),
    link: css({
        textDecoration: 'none',
        color: '#000000',
    }),
};

export default stationFooterStyle;
