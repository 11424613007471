import { useDispatch } from 'react-redux';

import { ICreateCardTransactionRequest } from '../api/card/types';
import { actionClearCardTransaction } from '../store/card-transaction/card-transaction.slice';
import { postCreateCardTransaction } from '../api/card/card.api';

export const useCardTransaction = (): {
    createCardTransaction: (createCardTransaction: ICreateCardTransactionRequest) => Promise<void>;
    clearCardTransaction: () => Promise<void>;
} => {
    const dispatch = useDispatch();

    const createCardTransaction = async (
        createCardTransaction: ICreateCardTransactionRequest
    ): Promise<void> => {
        await dispatch(postCreateCardTransaction(createCardTransaction));
    };

    const clearCardTransaction = async (): Promise<void> => {
        await dispatch(actionClearCardTransaction());
    };

    return {
        createCardTransaction,
        clearCardTransaction,
    };
};
