import ru from './ru.json';
import en from './en.json';
import ua from './ua.json';

export default {
    ru,
    en,
    ua,
    // eslint-disable-next-line
} as Record<string, any>;
