import { css } from '@emotion/react';

const notificationStyles = {
    container: css({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '15px 10px 15px 12px',
        gap: '5px',
        background: '#303030',
        borderRadius: '8px',
    }),
    text: css({
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '15px',
        color: '#F6F8FA',
    }),
};

export default notificationStyles;
