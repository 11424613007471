import React, { FC } from 'react';

import styles from './offer-en.module.css';

export const OfferEnView: FC = () => {
    return (
        <div className={styles.c17}>
            <p className={styles.c35}>
                <span className={styles.c10 + ' ' + styles.c4}>
                    The Offer Agreement (User Agreement)
                </span>
            </p>
            <p className={styles.c35}>
                <span className={styles.c10 + ' ' + styles.c4}>
                    for the provision of electric vehicle charging services
                </span>
            </p>
            <p className={styles.c24}>
                <span className={styles.c0}>revision No. 1 of January 10, 2023.</span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    This Offer Agreement is addrelectric charging station for electric vehiclesed to
                    Users, which is official and public in accordance with Article 395 of the Civil
                    Code of the Republic of Kazakhstan. The Offer Agreement for the provision of
                    electric vehicle charging services (hereinafter referred to as the &quot;Offer
                    Agreement&quot;) establishes the terms of use of the web application (mobile
                    application) (hereinafter referred to as the &quot;Application&quot;)
                    &quot;Adele Cloud&quot; and the service &quot;Ladergy.com &quot; for charging an
                    electric vehicle and concluded on the terms set forth below between a capable
                    and legally capable person (User) using a web application (mobile application),
                    the copyright holder of which is the Private Company Adele Energy Group ltd. and
                    the owner of the charging infrastructure facility, (hereinafter referred to as
                    the &quot;Contractor&quot;) jointly referred to as the Parties, and separately
                    as the Party.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c10 + ' ' + styles.c4}></span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c10 + ' ' + styles.c4}>1. DEFINITION OF TERMS</span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    The following terms and definitions are used in this offer:
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.1. </span>
                <span className={styles.c4}>Charging service</span>
                <span className={styles.c0}>
                    &nbsp;&ndash; services for charging electric vehicles by order of the User,
                    provided by the Contractor in the manner described in this Offer Agreement.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.2. </span>
                <span className={styles.c4}>Website</span>
                <span className={styles.c0}>
                    &nbsp;&ndash; the official website of the PRIVATE COMPANY &quot;Adele Energy
                    Group ltd.&quot;, available at the following link on the Internet:
                    https://guest.ladergy.com /. All rights in relation to the Site belong to the
                    PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot;.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.3. </span>
                <span className={styles.c4}>The Seller</span>
                <span className={styles.c13}>&nbsp;is the </span>
                <span className={styles.c13 + ' ' + styles.c25}>private</span>
                <span className={styles.c0}>
                    &nbsp;&quot;Adele Energy Group ltd.&quot;, registered at the addrelectric
                    charging station for electric vehicles: Republic of Kazakhstan, Astana city,
                    Yesil district, Mangilik El Avenue, building 55/22, and its affiliated legal
                    entities selling Goods and/or Services through the Website.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.4. </span>
                <span className={styles.c4}>Contractor</span>
                <span className={styles.c0}>
                    &nbsp;&ndash; a legal entity or individual entrepreneur registered in accordance
                    with the procedure provided for by the legislation of the Republic of
                    Kazakhstan, managing the charging infrastructure facility, and being the direct
                    contractor of the Electric vehicle Charging Service. The Contractor instructed
                    the PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; on behalf and at the
                    expense of the Contractor to search for and attract potential Users and conclude
                    contracts with Users for the provision of services for charging electric
                    vehicles.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.5. </span>
                <span className={styles.c4}>The User</span>
                <span className={styles.c0}>
                    &nbsp;is a capable individual or an employee of a Corporate Client who has
                    concluded this Offer Agreement in the prescribed manner.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.6. </span>
                <span className={styles.c4}>Corporate Client</span>
                <span className={styles.c0}>
                    &nbsp;is a legal entity or individual entrepreneur registered in accordance with
                    the procedure provided for by the legislation of the Republic of Kazakhstan, who
                    has concluded this Offer Agreement. The Corporate Client enters into this Offer
                    Agreement for the purpose of using the Application and the Service by his
                    employees.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.7. </span>
                <span className={styles.c4}>Support Service</span>
                <span className={styles.c0}>
                    &nbsp;- the internal service and specialists of the PRIVATE COMPANY &quot;Adele
                    Energy Group ltd.&quot;, or third parties engaged by the PRIVATE COMPANY
                    &quot;Adele Energy Group ltd.&quot;, advising the User on the use of the
                    Application and Service in accordance with the procedure provided for in this
                    Offer Agreement, as well as performing other actions provided for in this Offer
                    Agreement.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.8. </span>
                <span className={styles.c4}>The aggregator</span>
                <span className={styles.c0}>
                    &nbsp;of electronic mailings is a legal entity or individual entrepreneur
                    registered in accordance with the procedure provided for by the legislation of
                    the Republic of Kazakhstan, who has concluded a corresponding agreement with the
                    PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; on sending melectric
                    charging station for electric vehiclesages on the procedure for providing
                    services or advertising melectric charging station for electric vehiclesages to
                    Users of the Service.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.9. </span>
                <span className={styles.c4}>Application</span>
                <span className={styles.c0}>
                    &nbsp;- a computer program and for mobile devices &quot;Adele Cloud&quot;
                    (including all updates and improvements) intended for use by Users for the
                    purposes of placing Applications in the manner provided for in this Offer
                    Agreement.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.10. </span>
                <span className={styles.c4}>Service</span>
                <span className={styles.c0}>
                    &nbsp;- a software package named &quot;Adele Cloud&quot; and performing the
                    functions of receiving, procelectric charging station for electric vehiclesing,
                    storing, forming, changing and transmitting Applications, sending information
                    about the posted Application to the Contractor, entering, storing, changing,
                    deleting, procelectric charging station for electric vehiclesing and forming
                    consolidated information about charging infrastructure facilities, providing
                    consulting, technological and informational interaction between users,
                    generating and uploading statistical information (number of applications, cost
                    of applications, application statuses, etc.), displaying the status of charging
                    infrastructure objects in real time.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.11. </span>
                <span className={styles.c4}>Application</span>
                <span className={styles.c0}>
                    &nbsp;- information about the potential demand of the User for Electric Vehicle
                    Charging Services posted by the User in the Application in accordance with the
                    procedure provided for in this Offer Agreement.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.12. </span>
                <span className={styles.c4}>Account</span>
                <span className={styles.c0}>
                    &nbsp;is a personal section of the User in the Application, created in
                    accordance with the procedure provided for in the Offer Agreement, containing
                    information about the User, about the User&#39;s use of the Service, as well as
                    other information provided by the functionality of the Application.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.13. </span>
                <span className={styles.c29}>E</span>
                <span className={styles.c2}>lectric charging station for electric vehicles</span>
                <span className={styles.c25 + ' ' + styles.c36}>&nbsp;</span>
                <span className={styles.c0}>
                    is an electric charging station designed to provide services for charging
                    electric vehicles.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.14. </span>
                <span className={styles.c4}>Offer</span>
                <span className={styles.c0}>
                    &nbsp;&mdash; this user agreement, that is, the Seller&#39;s public offer
                    addrelectric charging station for electric vehiclesed to an unlimited number of
                    individuals and legal entities on the conclusion of a contract for the purchase
                    and sale of goods and / or services.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.15. </span>
                <span className={styles.c4}>Acceptance</span>
                <span className={styles.c0}>
                    &nbsp;&mdash; full and unconditional acceptance by the User on his own behalf,
                    as well as on behalf of those who will use his account, of the terms of this
                    Offer by registering as a user on the Site.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.16. </span>
                <span className={styles.c4}>Holding</span>
                <span className={styles.c0}>
                    &nbsp;is a payment operation in which the amount of payment paid by the User
                    when applying in the Application is reserved (held) in the account to which the
                    User&#39;s bank card is issued at the first stage, and at the second stage,
                    after confirmation of the Order by the parties and recalculation of the amount,
                    is debited from the User&#39;s account, and the difference, if any, is returned
                    to the User.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.17. </span>
                <span className={styles.c4}>Actual cost</span>
                <span className={styles.c0}>
                    &nbsp;&mdash; the final cost of the Order calculated based on the final weight
                    and price of the goods at the time of the start of delivery to the User.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.18. </span>
                <span className={styles.c4}>Personal data</span>
                <span className={styles.c0}>
                    &nbsp;- means a set of personal data and/or non-personalized information about
                    the User provided by him to the Copyright Holder and/or automatically collected
                    by the Copyright Holder and/or third parties.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>1.19. </span>
                <span className={styles.c4}>Cookies</span>
                <span className={styles.c0}>
                    &nbsp;- means small files sent to any mobile (web) applications or site, and
                    placed on smartphones, tablets, watches and other mobile devices of the User, to
                    improve the operation of such applications or sites, as well as the quality of
                    the content posted in them.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c0}></span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c10 + ' ' + styles.c4}>2. GENERAL PROVISIONS</span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    2.1. In accordance with this Agreement, the User through the Application places
                    an Application for a Service for charging an electric vehicle on the selected
                    ELECTRIC CHARGING STATION FOR ELECTRIC VEHICLES of the Contractor.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    2.2. The Contractor provides a Service for charging the User&#39;s electric
                    vehicle on the selected ELECTRIC CHARGING STATION FOR ELECTRIC VEHICLES.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    2.3. The User pays for the Service of charging an electric vehicle by using the
                    functionality of the Application described in this agreement.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c0}></span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c10 + ' ' + styles.c4}>
                    3. APPLICATION FUNCTIONALITY AND SERVICE DESCRIPTION
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    3.1. By using the Application, the User gets accelectric charging station for
                    electric vehicles to the Service. The Service is an information service and
                    offers the User the opportunity to post information (place an Application) about
                    the potential demand of the User for electric vehicle charging Services, as well
                    as the opportunity to get acquainted with information about the offers of the
                    Contractor providing electric vehicle charging Services and to search for such
                    offers according to the parameters specified by the User.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    3.2. The list of Performers providing Electric Vehicle Charging Services is
                    provided in Appendix No. 1 to this Offer Agreement.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    3.3. In various situations (including, but not limited to, the region of the
                    User&#39;s location, technical malfunctions, distribution of functions within
                    the framework of testing to a limited number of Users) All or some of the
                    functions of the Application may be unavailable or restricted for use by the
                    User. The Functions are considered inaccelectric charging station for electric
                    vehiclesible (restricted) to the User if such a User cannot use them through the
                    use of the Application. The use of any technical and software methods to
                    circumvent these restrictions is prohibited. The provisions of this Offer
                    Agreement governing the functions of the Application that are inaccelectric
                    charging station for electric vehiclesible (limited) to the User do not apply
                    until such functions become available to the User through the use of the
                    Application.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    3.4. If the User does not have the opportunity to use the Application or
                    accelectric charging station for electric vehicles the Service in any other way
                    specified in clause 3.1 of the Offer Agreement (including, but not limited to,
                    if there is no accelectric charging station for electric vehicles to the
                    Internet, if there are problems using the Application), the User can contact the
                    Support Service for help using the Service. The provisions of this Offer
                    Agreement apply to the User&#39;s requests to the Support Service for the
                    purposes of filing an Application to the extent applicable. All the User&#39;s
                    conversations with the Support Service specialists, as well as with the
                    Contractor, can be recorded in order to control quality and improve service.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    3.5. Through the use of the Application, the User can obtain information about
                    the Performers providing Services for charging electric vehicles. The PRIVATE
                    COMPANY &quot;Adele Energy Group ltd.&quot; is not obliged to verify the
                    completenelectric charging station for electric vehicles and reliability of the
                    information provided, and the PRIVATE COMPANY &quot;Adele Energy Group
                    ltd.&quot; does not change the information provided by the Contractor. The list
                    of Performers is available on the Website. Getting more detailed information by
                    the User about
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    The Contractor and the Services they offer for charging electric vehicles can be
                    carried out by the User by independently contacting the Contractor.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    3.6. As part of the use of the Service, the User may be offered to use the
                    services of third parties that are partners of the PRIVATE COMPANY &quot;Adele
                    Energy Group ltd.&quot; (hereinafter referred to as &quot;Third Party
                    Services&quot;). The use of such third-party Services is carried out on the
                    basis of the conclusion between the User and the partner of the PRIVATE COMPANY
                    &quot;Adele Energy Group ltd.&quot; of the relevant user agreements. If such
                    services or part of them are provided through the Adele Cloud Application, the
                    User gets acquainted with the agreement on joining the user agreement of the
                    third-party Service in the Application and is obliged to accept its terms before
                    using the services of the partner of the AdeleEnergy Group ltd. PRIVATE COMPANY.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    3.7. To inform the User about the procedure and progrelectric charging station
                    for electric vehicles of the provision of services, the PRIVATE COMPANY
                    &quot;Adele Energy Group ltd.&quot; has the right to involve Aggregators of
                    electronic mailings.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c10 + ' ' + styles.c4}></span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c10 + ' ' + styles.c4}>
                    4. THE USER&#39;S USE OF THE APPLICATION AND THE PROCEDURE FOR JOINING THIS
                    AGREEMENT
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    4.1. For the purposes of using the Application, the User is obliged to indicate
                    his mobile phone number in the Application. The specified data must be complete,
                    accurate and reliable.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    4.2. After specifying the mobile phone number, the User receives an SMS
                    melectric charging station for electric vehiclesage with a link to track and
                    manage the charging procelectric charging station for electric vehicles.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    4.3. After confirming the ownership of the specified mobile phone number, the
                    User is considered to have registered in the Application, and a User Account is
                    formed in the Application with the assignment of a unique identification number
                    to the Account.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    4.4. The User understands and agrees that all actions performed using the
                    User&#39;s Account are considered to be committed by the User. The User is
                    obliged to ensure the confidentiality of his registration data and restrict
                    accelectric charging station for electric vehicles to the User&#39;s personal
                    mobile device for third parties. The User independently bears the risk of
                    adverse consequences caused by violation of the confidentiality of the
                    User&#39;s registration data and/or granting third parties accelectric charging
                    station for electric vehicles to the User&#39;s personal mobile device.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    4.5. Acceptance of this Offer Agreement is carried out by actions in the
                    Appendix, as a result of which the charging of an electric vehicle begins.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    4.6. Acceptance of this Offer Agreement means the User&#39;s consent to the
                    procelectric charging station for electric vehiclesing of personal data,
                    according to Appendix No. 2 to this Offer Agreement.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    4.7. The Account can be deleted in the following cases:
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    4.7.1. At the discretion of the Contractor, if the User has submitted an
                    application for Account deletion, the Contractor may refuse to delete the
                    Account without explaining the reasons. If the Contractor refused to delete the
                    Account, he has the right to carry out technical restriction of accelectric
                    charging station for electric vehicles to the User&#39;s personal data by saving
                    the Account and the User&#39;s personal data on the Contractor&#39;s servers;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    4.7.2. At the discretion of the Contractor in relation to the User using
                    exclusively activated data and commands.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    4.7.3. In case of repeated or significant violation of the terms of this
                    Agreement by the User, the recognition of a violation as significant is carried
                    out by the Contractor independently on the basis of an asselectric charging
                    station for electric vehiclesment of the specific circumstances of the
                    violation. Material violations, including, may include actions prohibited by
                    Section 11 of this Agreement or violations that are not eliminated by the User
                    in time after receiving the relevant requirement from the Contractor, etc.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    4.7.4. The Contractor has the right not to explain the reasons for deleting the
                    Account.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c0}></span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c10 + ' ' + styles.c4}>
                    5. THE PROCEDURE FOR THE PROVISION OF SERVICES BY THE CONTRACTOR
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    5.1. Charging services, Applications for which were placed by the User through
                    the use of the Application or in any other way provided for by this Offer
                    Agreement, are directly provided by the Contractor. The terms and conditions of
                    the Contractor&#39;s Charging Services specified in this Offer Agreement are
                    brought to the attention of the User by the Contractor&#39;s order.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    5.2. PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; is not the owner of
                    charging stations for the provision of charging services, through the Service
                    enters into a contract with the User for Charging Services on behalf of the
                    Contractor. PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; is the owner of
                    the aggregator of information about charging services.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    5.3. The User&#39;s posting of an Application does not constitute acceptance by
                    the PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; to fulfill the
                    User&#39;s order for the provision of Charging Services. Acceptance of the
                    User&#39;s order for the provision of Charging Services in accordance with the
                    terms of the Application is carried out by the Contractor and is considered to
                    have taken place at the moment when information about the acceptance by the
                    Contractor is brought to the attention of the Contractor through the Application
                    interface.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    5.4. PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; does not guarantee to
                    the User that the Application posted by the User will be accepted for execution
                    by the Contractor. PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; does not
                    guarantee that the Application accepted by the Contractor will be executed by
                    the Contractor and is not responsible for failure to provide Charging Services
                    for Applications accepted by the Contractor.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    5.5. The cost of charging Services is set by the Contractor, but cannot exceed
                    the tariffs specified in the Appendix, with the exception of the cases specified
                    in this Offer Agreement. The cost of charging Services is determined based on
                    the tariff chosen by the Contractor. The cost of charging Services may be
                    changed in the cases specified in this Offer Agreement.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    5.6. If the User refuses to provide Charging Services after the start of the
                    booking selectric charging station for electric vehiclesion in accordance with
                    the terms of the Application (which the User is informed about through the
                    functionality of the Application or SMS melectric charging station for electric
                    vehiclesage), as well as in other cases specified on the Application, PRIVATE
                    COMPANY &quot;Adele Energy Group ltd.&quot;, acting on behalf of the Contractor,
                    charges such User the expenses actually incurred by the Contractor, which can be
                    calculated as the minimum cost of the Charging Service specified in the Appendix
                    at the selected tariff or in any other way specified on the Website, which is
                    not the User&#39;s fee for refusing to perform the contract.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    5.7. PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot;, by order of the
                    Contractor, informs the User of the rules for the provision of Charging Services
                    by the Contractor.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>6. PAYMENT FOR CHARGING SERVICES BY USERS</span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    6.1. For the purposes of the User&#39;s ability to use a non&ndash;cash payment
                    method, the User is obliged to indicate his bank card in the manner provided for
                    by this Offer Agreement using the functionality of the Application (hereinafter
                    referred to as the &quot;Linked Bank Card&quot;).
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    6.2. The creation of a Linked bank card is carried out by the User through the
                    service of an authorized payment acceptance operator, whose page opens in the
                    Application. The User undertakes to specify the following data using the
                    functionality of the Application: 1) bank card number; 2) expiration date; 3)
                    security code (CVC/CVV).
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    6.3. After specifying the bank card data on the account of the Linked bank card,
                    a reservation (holding) is carried out in the amount of the amount specified in
                    the Application, and the difference, if any, is returned to the User. If the
                    bank card data is complete and reliable and the use of this card is technically
                    possible, the bank card specified by the User is considered under this Offer
                    Agreement as a Linked bank card and can be used for a non-cash payment method.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    6.4. PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; does not have
                    accelectric charging station for electric vehicles to the data of the Linked
                    bank card specified by the User in the service of the authorized payment
                    acceptance operator and is not responsible for the safety and confidentiality of
                    the transmitted data when making a payment by non-cash payment method. Payment
                    by non-cash payment method is carried out by the User with the participation of
                    an authorized payment acceptance operator, or an electronic money operator, or
                    other participants in settlements, information technology interaction, and is
                    governed by the rules of international payment systems, banks (including the
                    issuing bank of the Linked bank Card) and other participants in settlements.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    6.5. When specifying their bank card details, as specified in clause 6.2. of the
                    Offer Agreement and further use of the Linked Card, the User confirms and
                    guarantees that they provide reliable and complete information about the bank
                    card issued in their name; compliance with the rules of international payment
                    systems and the requirements of the issuing bank that issued the bank card,
                    including the number in relation to the procedure for conducting non-cash
                    payments.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    6.6. The User understands and agrees that all actions performed within the
                    framework of using the Application using the mobile phone number confirmed by
                    him, including payment by non-cash payment method using a Linked bank card, are
                    considered committed by the User.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    6.7. If the User does not agree with the fact and/or the amount of payment by
                    non-cash payment method and on other issues related to the use of the Linked
                    Card within the framework of using the Service, the User has the right to
                    contact the Support Service within 14 (fourteen) calendar days from the date of
                    the non-cash payment method. If, based on the results of verification of such an
                    appeal, the PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; decides to
                    refund the amount of payment by non-cash payment method in whole or in part, the
                    specified refund is made to the bank account of the Linked bank card from which
                    the payment was made by non-cash payment method. The procedure for the refund of
                    funds is carried out with the participation of an authorized payment acceptance
                    operator or an electronic money operator, or other participants in settlements,
                    information technology interaction, and is governed by the rules of
                    international payment systems, banks (including the issuing bank of the Linked
                    bank Card) and other participants in settlements. After the expiration
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    14 (fourteen) calendar days from the date of making a non-cash payment method of
                    applying for the User&#39;s disagreement with the fact and/or the amount of
                    payment by non-cash payment method and on other issues related to the use of the
                    Linked Card within the framework of using the Service are not accepted.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    6.8. PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; reserves the right at
                    any time to require the User to confirm the data specified by him when
                    establishing a Linked bank card, and to request, in this regard, supporting
                    documents (in particular, identity documents), the failure of which may be
                    equated to the provision of false information and cause restriction of the
                    User&#39;s accelectric charging station for electric vehicles to the use of the
                    Application.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    6.9. By order of the Contractor, the PRIVATE COMPANY &quot;Adele Energy Group
                    ltd.&quot; informs Users about the payment procedure for charging Services
                    provided by the Contractor. Payment for Charging Services under this Offer
                    Agreement can be made by cashlelectric charging station for electric vehicles
                    method by using a Linked bank card or by using a payment application (Apple Pay,
                    Google Pay and others, if available), or using the &quot;KaspiPay&quot; service
                    (if available) in accordance with the terms of the payment application provider
                    (hereinafter referred to as &quot;Cashlelectric charging station for electric
                    vehicles payment method&quot;). If the User pays for the Charging Services
                    provided by the Contractor by a non-cash payment method, the PRIVATE COMPANY
                    &quot;Adele Energy Group ltd.&quot; acts on behalf of, on behalf of and at the
                    expense of the relevant Contractor with the involvement of an authorized payment
                    acceptance operator, or an electronic money operator, or other participants in
                    settlements, information technology interaction, and is the recipient of the
                    payment as an agent of the Performer.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    6.10. At the same time, the User takes into account that the method of payment
                    by cashlelectric charging station for electric vehicles method using the
                    &quot;KaspiPay&quot; service is possible only with the User&#39;s accelectric
                    charging station for electric vehicles to the application &quot;Kaspi.kz 6.11.
                    PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; does not guarantee the
                    absence of errors and failures in the non-cash payment method.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    6.12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Refund of funds
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    6.12.1. When making an online payment by means of payment cards, a refund in
                    cash is not allowed. The refund procedure is governed by the rules of
                    international payment systems:
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    &bull; The consumer has the right to refuse the goods at any time before its
                    transfer, after the transfer of the goods, the refusal must be issued within 14
                    days;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    &bull; The consumer does not have the right to refuse a product of proper
                    quality having individually defined properties if the specified product can be
                    used exclusively by the person acquiring it;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    &bull; If the goods are rejected by the consumer, the seller must return to him
                    the amount of money paid by the consumer, no later than ten days from the date
                    of the consumer&#39;s submission of the corresponding claim.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    6.12.2. The refund will be made to the bank card within 15 working days from the
                    date of receipt of the &quot;Application for refund&quot; by the Contractor,
                    provided that the User confirms the non-delivery of goods and services by the
                    Contractor.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    6.12.3. In order to refund funds to a bank card, it is necelectric charging
                    station for electric vehiclesary to fill out an &quot;Application for refund of
                    funds&quot;, which is sent by the company to the email addrelectric charging
                    station for electric vehicles upon request, and send it along with a copy of the
                    identity document attached to the addrelectric charging station for electric
                    vehicles info@adele.energy
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    6.12.4. In case of a positive response in favor of the User, the refund amount
                    will be equal to the purchase amount. The term for consideration of the
                    Application and refund of funds begins to be calculated from the moment the
                    Contractor receives the Application and is calculated in working days, excluding
                    holidays / weekends.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c10 + ' ' + styles.c4}></span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c10 + ' ' + styles.c4}>
                    7. RIGHTS AND OBLIGATIONS OF THE PRIVATE COMPANY &quot;Adele Energy Group
                    ltd.&quot;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    7.1. The PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; has the right:
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    7.1.1. At any time unilaterally restrict, expand, change the content of the
                    Application and Service, as well as change and modify the Application and
                    Service without prior notice to the User;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    7.1.2. In order to collect statistical data and identify the User, install and
                    store information about the User&#39;s IP addrelectric charging station for
                    electric vehicleses, use technical information files (cookies) placed on the
                    User&#39;s personal mobile device or personal computer;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    7.1.3. During the User&#39;s use of the Service, warn, notify, inform about the
                    User&#39;s non-compliance with the terms of this Offer Agreement;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    7.1.4. Send technical and informational melectric charging station for electric
                    vehiclesages to the User regarding the procelectric charging station for
                    electric vehicles of using the Application and the Service and/or the provision
                    of Charging Services By the Contractor (the latter is carried out by order of
                    the Contractor) through the functionality of the Application and by SMS
                    melectric charging station for electric vehiclesages to the phone number
                    specified by the User during registration and e-mail melectric charging station
                    for electric vehiclesages;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    7.1.5. Independently or with the involvement of third parties to contact the
                    User by the provided mobile phone number or email addrelectric charging station
                    for electric vehicles for the purposes of conducting research for the purpose of
                    improving the quality of the Application and Service. At the same time, the User
                    can refuse to participate in the research by contacting the Support Service;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    7.1.6. In case of violation by the User of the terms of this Offer Agreement,
                    limit the functionality of the Application and the Service in whole or in part;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    7.1.7. In case of non-payment of Services for one reporting period by the
                    Corporate Client of the PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot;,
                    limit the functionality of the Application and Service for the Corporate Client
                    in whole or in part;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    7.1.8. In case of suspicion of unfair use of electric vehicle charging services
                    by the User, to clarify the circumstances, delay the amount of Suspicious
                    Applications for up to 2 (two) reporting periods.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    7.2. PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; is obliged to:
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    7.2.1. Subject to the conditions and restrictions set forth in this Offer
                    Agreement, to enable the User to use the Application and the Service;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    7.2.2. Grant the rights to use the Application and the Service as provided for
                    in this Offer Agreement;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    7.2.3. Provide support to the User in terms of using the Application and the
                    Service, payment for Charging Services and in terms of using Charging Services
                    (the latter is carried out by order of the Contractor) through the Support
                    Service, through the functionality of the Application or otherwise, while the
                    User must apply for such support no later than 14 days from the date of the
                    Application, for which the User requests support;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    7.2.4. Bring to the User information about the Performer acting as the performer
                    of the Charging Service.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c0}></span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c10 + ' ' + styles.c4}>
                    8. RIGHTS AND OBLIGATIONS OF THE USER
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>8.1. The User has the right to:</span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    8.1.1. Use the Application and the Service in the ways specified in this Offer
                    Agreement, subject to the restrictions established by this Offer Agreement;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    8.1.2. Contact the Support Service for the purpose of obtaining information and
                    advice on the use of the Application and the Service, payment for charging
                    Services, and regarding the use of Charging Services (the latter is carried out
                    by order of the Contractor)
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>8.2. The User undertakes:</span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    8.2.1. Comply with the terms of this Offer Agreement without any restrictions
                    and exceptions;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    8.2.2. Use the Application and the Service exclusively in the ways specified in
                    this Offer Agreement;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    8.2.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Not to violate in any way
                    the intellectual property rights of the PRIVATE COMPANY &quot;Adele Energy Group
                    ltd.&quot; in relation to the Application and Service and/or any components of
                    the Application and Service, in particular, the User has no right to distribute,
                    make public, modify, modify, decompile or disassemble the Application and
                    Service, copy, broadcast, distribute, publish, and otherwise distribute and
                    reproduce the materials (text, graphic, audio-video) contained in the
                    Application without the written consent of the PRIVATE COMPANY &quot;Adele
                    Energy Group ltd.&quot;;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    8.2.4. Independently take appropriate measures to ensure the security of his
                    Account and prevent unauthorized use by third parties of the Account;
                    independently take appropriate measures to ensure the security and
                    confidentiality of the User&#39;s bank cards;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    8.2.5. Not to carry out deliberate actions aimed at creating excelectric
                    charging station for electric vehiclesive load on the servers hosting the
                    Application and Services;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    8.2.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Not to carry out any
                    actions not aimed at the normal use of the Application and the Service in
                    accordance with its functionality, including, but not limited to, not to place
                    Applications in the absence of a real potential desire to use Charging Services,
                    not to change the payment method for charging Services in order to avoid paying
                    for charging Services, not to carry out fraudulent actions aimed at to receive
                    discounts and other benefits, including, but not limited to, provided by the
                    Loyalty Program for Users, joining with the Contractor, acting as the direct
                    executor of the charging Service, in collusion for the purpose of obtaining
                    unreasonable benefits;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    8.2.7. Carefully treats the equipment of the charging infrastructure, exclude
                    cases of its damage and/or contamination;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    8.2.8. Vacate the parking space at the end of the charging selectric charging
                    station for electric vehiclesion;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    8.2.9. Fulfill other obligations stipulated by this Offer Agreement.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c0}></span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>9. RIGHTS AND OBLIGATIONS OF THE CONTRACTOR</span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>9.1. The Contractor has the right to:</span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    9.1.1. To set tariffs for electric vehicle charging services for each ELECTRIC
                    CHARGING STATION FOR ELECTRIC VEHICLES.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    9.1.2. At any time unilaterally carry out the commissioning / decommissioning of
                    the ELECTRIC CHARGING STATION FOR ELECTRIC VEHICLES.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    9.1.3. Transfer the right to demand payment that has not been received from the
                    User for the Services provided by charging to third parties with notification of
                    the User about the transfer of the creditor&#39;s rights to another person.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>9.2. The Contractor undertakes:</span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    9.2.1. To ensure, together with the connection, the correct operation of the
                    ELECTRIC CHARGING STATION FOR ELECTRIC VEHICLES and the maximum technical
                    readinelectric charging station for electric vehicles of all connected to the
                    Service.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    9.2.2. To ensure, together with the PRIVATE COMPANY &quot;Adele Energy Group
                    ltd.&quot; timely informing Users about changes in tariffs for electric vehicle
                    charging services.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    9.2.3. To ensure, together with the PRIVATE COMPANY &quot;Adele Energy Group
                    ltd.&quot; timely informing Users about changes in the composition of EHS
                    available for the provision of electric vehicle charging services.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    9.2.4. Take all necelectric charging station for electric vehiclesary measures
                    to provide services on the ELECTRIC CHARGING STATION FOR ELECTRIC VEHICLES to
                    Users, taking into account the approved threshold value of the indicator of
                    downtime / unavailability of the ELECTRIC CHARGING STATION FOR ELECTRIC VEHICLES
                    for the provision of services for charging electric vehicles.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    9.2.5. Perform at their own expense work on capital and current repairs
                    (including in case of technological violations), operation and maintenance of
                    the ELECTRIC CHARGING STATION FOR ELECTRIC VEHICLES.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    9.2.6. Bear all expenses for the maintenance and operability of the ELECTRIC
                    CHARGING STATION FOR ELECTRIC VEHICLES (including paying for electricity and
                    wirelelectric charging station for electric vehicles communication services).
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    9.2.7. Maintain the EHS in full working order and in proper condition, comply
                    with the requirements of fire safety, occupational safety and health, industrial
                    PRIVATE COMPANY &quot;Adele Energy Group Ltd.&quot; sanitation, environmental
                    and environmental legislation, rules of technical operation of power plants and
                    networks of the Republic of Kazakhstan, as well as use the EHS in accordance
                    with other regulatory and legal acts of the Republic of Kazakhstan.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    9.2.8. To guarantee the functioning of round-the-clock support for Users of the
                    second level, namely, to procelectric charging station for electric vehicles
                    requests received from the PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot;
                    from the first level of User support related to the technical serviceability and
                    operability of the ELECTRIC CHARGING STATION FOR ELECTRIC VEHICLES, etc.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    9.2.9. Be fully liable for any damage caused to third parties (Users) or their
                    property as a result of the provision of electric vehicle charging services.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c0}></span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c10 + ' ' + styles.c4}>10. LIABILITY</span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    10.1.1. The Application, the Service and the rights to use them in accordance
                    with this Offer Agreement are provided to the User in the &quot;as is&quot;
                    state.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    10.1.2. PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; does not guarantee
                    that: 1) The Application and Service will meet the subjective expectations and
                    requirements of the User; 2) the use of the Application and Service will proceed
                    continuously, quickly, without technical failures, reliably and without errors;
                    3) the results of the User&#39;s use of the Application and Service will be
                    error-free; 4) The Application and Service will be available and can be used
                    around the clock, to what extent-that particular moment of time or during any
                    period of time. PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; is not
                    responsible for temporary interruptions, technical problems and termination of
                    the Application and Service on the User&#39;s personal mobile device.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    10.1.3. PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; is not responsible
                    for the occurrence of losses, direct or indirect damage incurred by the User in
                    connection with the use and / or inability to use the Application and Service.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    10.1.4. PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; is not responsible
                    for the harm caused to the User and the persons in respect of whom the
                    Application was placed by the User during the provision of Charging Services.
                    The Contractor is responsible for the damage caused to the User and the persons
                    in respect of whom the Application was placed by the User in the procelectric
                    charging station for electric vehicles of providing Charging Services in
                    accordance with the provisions of applicable legislation.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    10.1.5. PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; is not responsible
                    for the content and/or relevance of the information provided by the Contractor,
                    including information about the Contractor. PRIVATE COMPANY &quot;Adele Energy
                    Group ltd.&quot; is not responsible for financial and any other transactions
                    performed by the User and the Contractor, as well as for any consequences of the
                    User&#39;s purchase of Charging Services provided by the Contractor.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    10.1.6. PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; is not responsible
                    for the User&#39;s lack of Internet accelectric charging station for electric
                    vehicles, for the quality of Internet service providers, for the quality of
                    telephone services with which the User has concluded agreements on the provision
                    of communication services.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    10.1.7. The User is responsible for violation of intellectual property rights
                    and other rights of the PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; in
                    accordance with applicable law.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    10.1.8. PRIVATE COMPANY &quot;Adele Energy Group ltd.&quot; is not responsible
                    for damage and losses caused to the User when using the Services of third
                    parties. The liability of the partner of the PRIVATE COMPANY &quot;Adele Energy
                    Group ltd.&quot; providing the Service of a third party is determined on the
                    basis of the agreement concluded by the User and the partner of the PRIVATE
                    COMPANY &quot;Adele Energy Group ltd.&quot; and the current legislation.
                </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c10 + ' ' + styles.c4}></span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c10 + ' ' + styles.c4}>11. FINAL PROVISIONS</span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    11.1.1. This Offer Agreement is valid from the moment the User accepts the
                    offer, as provided for in this Offer Agreement, and is valid until the User pays
                    in full for the Charging Service.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    11.1.2. This Offer Agreement, as well as applicable documents, may be amended by
                    the Contractor without any prior notice. Any changes to the Offer Agreement and
                    applicable documents come into force on the day following the date of
                    publication of the Offer Agreement and applicable documents in the amended
                    version. The User undertakes to independently check the Offer Agreement and
                    applicable documents for changes. The User&#39;s failure to take actions to
                    familiarize himself with the Offer Agreement and applicable documents and/or the
                    amended version of the Offer Agreement and applicable documents cannot serve as
                    a basis for the User&#39;s failure to fulfill his obligations and the User&#39;s
                    failure to comply with the restrictions established by the Offer Agreement and
                    applicable documents. The User independently bears the risk of adverse
                    consequences caused by not familiarizing the User with the amended version of
                    the Offer Agreement and applicable documents.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    11.1.3. The Offer Agreement is regulated and interpreted in accordance with the
                    legislation of the Republic of Kazakhstan. Issues not regulated by the Offer
                    Agreement are subject to resolution in accordance with the legislation of the
                    Republic of Kazakhstan.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    11.1.4. In case of any disputes or disagreements related to the performance of
                    the Offer Agreement, the Parties will make every effort to resolve them through
                    negotiations. If disputes are not resolved through negotiations, disputes are
                    subject to resolution in accordance with the procedure established by the
                    legislation of the Republic of Kazakhstan.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    11.1.5. Attached to this Agreement and is an integral part thereof:
                </span>
            </p>
            <div className={styles.c8}>
                <div className={styles.c0}>Appendix No. 1</div>
                <div className={styles.c0}>
                    to the Offer Agreement (user agreement) for the provision of electric vehicle
                    charging services
                </div>
            </div>
            <p className={styles.c12}>
                <span className={styles.c0}></span>
            </p>
            <a></a>
            <h1 className={styles.c23}>
                <span className={styles.c10 + ' ' + styles.c4}>
                    List of partners providing electric vehicle charging services
                </span>
            </h1>
            <table className={styles.c16}>
                <tbody>
                    <tr className={styles.c27}>
                        <td className={styles.c11} colSpan={1} rowSpan={1}>
                            <p className={styles.c33}>
                                <span className={styles.c0}>&#8470;</span>
                            </p>
                        </td>
                        <td className={styles.c26} colSpan={1} rowSpan={1}>
                            <p className={styles.c3}>
                                <span className={styles.c0}>Name of the partner and its data</span>
                            </p>
                        </td>
                        <td className={styles.c15} colSpan={1} rowSpan={1}>
                            <p className={styles.c1}>
                                <span className={styles.c0}>
                                    Location of the Performer and contact details
                                </span>
                            </p>
                        </td>
                        <td className={styles.c22} colSpan={1} rowSpan={1}>
                            <p className={styles.c40}>
                                <span className={styles.c0}>
                                    The Contractor&#39;s website addrelectric charging station for
                                    electric vehicleses
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr className={styles.c28}>
                        <td className={styles.c11} colSpan={1} rowSpan={1}>
                            <p className={styles.c18}>
                                <span className={styles.c4 + ' ' + styles.c10}></span>
                            </p>
                            <p className={styles.c21}>
                                <span className={styles.c0}>1</span>
                            </p>
                        </td>
                        <td className={styles.c26} colSpan={1} rowSpan={1}>
                            <p className={styles.c32}>
                                <span className={styles.c0}>
                                    LLP &laquo;Adele Energy Qazaqstan&raquo; &nbsp;BIN: 220440045257
                                </span>
                            </p>
                        </td>
                        <td className={styles.c15} colSpan={1} rowSpan={1}>
                            <p className={styles.c31}>
                                <span className={styles.c0}>
                                    Republic of Kazakhstan, Astana city, Yesil district, Mangilik El
                                    Avenue, 55/22, block C 3.5, office 314 aeq@adele.energy
                                </span>
                            </p>
                            <p className={styles.c19}>
                                <span>+7 777 00 11350</span>
                            </p>
                        </td>
                        <td className={styles.c22} colSpan={1} rowSpan={1}>
                            <p className={styles.c6}>
                                <span className={styles.c0}>https://adele.energy</span>
                            </p>
                        </td>
                    </tr>
                    <tr className={styles.c41}>
                        <td className={styles.c11} colSpan={1} rowSpan={1}>
                            <p className={styles.c18}>
                                <span className={styles.c10 + ' ' + styles.c4}></span>
                            </p>
                            <p className={styles.c21}>
                                <span className={styles.c0}>2</span>
                            </p>
                        </td>
                        <td className={styles.c26} colSpan={1} rowSpan={1}>
                            <p className={styles.c6}>
                                <span className={styles.c0}>
                                    LLP
                                    &quot;&#1054;&#1087;&#1077;&#1088;&#1072;&#1090;&#1086;&#1088;
                                    &#1069;&#1047;&#1057;&quot;
                                </span>
                            </p>
                        </td>
                        <td className={styles.c15} colSpan={1} rowSpan={1}>
                            <p className={styles.c34}>
                                <span className={styles.c0}>
                                    Republic of Kazakhstan, Astana city, Yesil district, Kabanbai
                                    Batyr Avenue, 11/5, Office 704, 705. info@oezs.kz
                                </span>
                            </p>
                            <p className={styles.c14}>
                                <span>+7 (702) 520-03-51</span>
                            </p>
                        </td>
                        <td className={styles.c22} colSpan={1} rowSpan={1}>
                            <p className={styles.c6}>
                                <span className={styles.c0}>https://oezs.kz/ru/</span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className={styles.c20 + ' ' + styles.c30 + ' ' + styles.c37}>
                <span className={styles.c0}></span>
            </p>
            <div className={styles.c8}>
                <div className={styles.c0}>Appendix No. 2</div>
                <div className={styles.c0}>
                    to the Offer Agreement (user agreement) for the provision of electric vehicle
                    charging services
                </div>
            </div>
            <p className={styles.c20 + ' ' + styles.c30 + ' ' + styles.c37}>
                <span className={styles.c0}></span>
            </p>
            <p className={styles.c35}>
                <span className={styles.c10 + ' ' + styles.c4}>
                    Consent to the collection, procelectric charging station for electric
                    vehiclesing, storage and transfer of personal data
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    In accordance with the Law of the Republic &quot;On Personal Data and their
                    Protection&quot; (hereinafter &ndash; the Law), when accepting the Contract of
                    the Offer of services for charging an electric vehicle, I give my consent to the
                    Private Company &quot;Adele Energy Group ltd.&quot; (hereinafter - Contractor),
                    for the collection and procelectric charging station for electric vehiclesing of
                    personal data, recording, systematization, accumulation, storage, clarification
                    (updating, modification), extraction, use, depersonalization, blocking, deletion
                    and destruction, including automated, of their personal data in a specialized
                    electronic database about my surname, first name, patronymic, date and place of
                    birth, addrelectric charging station for electric vehicles, as well as other
                    personal information that can be used when the Contractor provides services for
                    charging an electric vehicle, and for the purpose of participating in
                    surveys/questionnaires, conducted by the Contractor to study and research the
                    opinion of customers about the quality of service and services of the
                    Contractor, subject to the guarantee of non-disclosure of this information to
                    third parties, with the exception of persons on behalf and on behalf of whom the
                    Contractor enters into a contract with Users for the provision of services for
                    charging electric vehicles.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    In accordance with subparagraph 2) of Article 1 of the Law, personal data refers
                    to information related to a certain or determined on the basis of personal data
                    subject (an individual to whom personal data relates), recorded on electronic,
                    paper and (or) other material media.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    I agree to provide me with information by sending mail to my home addrelectric
                    charging station for electric vehicles, by e-mail, telephone calls, SMS
                    melectric charging station for electric vehiclesages.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    This consent is valid from the moment of acceptance of the Contract of the Offer
                    of services for charging an electric vehicle and five years after the
                    termination of these services. After the expiration of the specified period, the
                    validity of this application is considered to be extended for every next 5 years
                    if the Contractor does not have information about its withdrawal.
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    This consent may be revoked by submitting a written statement to the Contractor.
                    The offer agreement has been agreed:
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>Executor:</span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c10 + ' ' + styles.c4}>General manager</span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c0}>
                    Private company &quot;Adele Energy Group ltd.&quot;
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c13}>___________/______/</span>
            </p>
            <p className={styles.c39}>
                <span className={styles.c0}>&nbsp;&#1084;.&#1087;.</span>
            </p>
            <p className={styles.c24 + ' ' + styles.c30}>
                <span className={styles.c0}></span>
            </p>
        </div>
    );
};
