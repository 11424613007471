import { css } from '@emotion/react';

const menuStyles = {
    menuButton: css({
        alignItems: 'center',
        display: 'flex',
    }),
    menuButtonWhite: css({
        color: 'white',
    }),
    menuButtonBlack: css({
        color: 'black',
    }),
    menuModal: css({
        display: 'flex',
        position: 'fixed',
        zIndex: 1050,
        backgroundColor: '#000000',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }),
    menuModalWrapper: css({
        flexGrow: 1,
        padding: '20px',
        maxWidth: '600px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        ul: css({
            fontWeight: 'bold',
            padding: '0.5rem',
            listStyleType: 'none',
            textAlign: 'right',
            li: css({
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '20px',
                lineHeight: '24px',
                textShadow: '0px 3px 3px rgba(0, 0, 0, 0.160784)',
                marginBottom: '13px',
                a: css({
                    color: ' #FFFFFF',
                    textDecoration: 'none',
                }),
            }),
        }),
    }),
    menuFooter: css({
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
    }),
    flag: css({
        fontSize: '20px',
    }),
    telegram: css({
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '25px',
        textDecoration: 'none',
        letterSpacing: '0.019em',
        color: '#FFFFFF',
        textShadow: '0px 3px 3px rgba(0, 0, 0, 0.160784)',
    }),
};

export default menuStyles;
