import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { get } from '../api-config';

import { ISupport } from './types';

export const getSupport = createAsyncThunk(
    'support/fetch',
    async (_, { signal, rejectWithValue }) => {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });

        try {
            return await get<ISupport, unknown>(`/support`, null, source.token);
        } catch ({ response: { data } }) {
            return rejectWithValue(data);
        }
    }
);
