import React, { FC } from 'react';

import styles from './privacy-en.module.css';

export const PrivacyEnView: FC = () => {
    return (
        <div className={styles.c7}>
            <p className={styles.c2}>
                <span className={styles.c4}>PRIVACY POLICY FOR THE MOBILE APP ADELE CLOUD</span>
            </p>
            <p className={styles.c2 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    For the Adele Cloud mobile application, the interests of users are above all.
                    This principle underlies all decisions we make, including when collecting and
                    processing your personal data.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    We have tried to make the Privacy Policy as clear and understandable as
                    possible. Our goal is to make you &mdash; an Adele Cloud user &mdash; always
                    feel confident when it comes to privacy.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Maintaining your trust is also one of the priorities of Adele Cloud. The
                    following principles are used to protect the confidentiality of your personal
                    data.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c5}>1. DEFINITION OF CONCEPTS</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>&quot;Law&quot;</span>
                <span className={styles.c1}>
                    &nbsp;means the State Law of the Republic of Kazakhstan &quot;On Personal Data
                    and their Protection&quot; with all amendments and additions, as well as other
                    legislative acts of the Republic of Kazakhstan.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>&quot;Mobile Application&quot;</span>
                <span className={styles.c1}>
                    &nbsp;is software (with all existing additions and improvements) designed to
                    work on smartphones, tablets, watches and other mobile devices, and developed
                    for a specific platform (iOS, Android, Windows Phone, etc.). For the purposes of
                    this Policy, a Mobile Application means the following software: Adele Cloud.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>&quot;Personal Data&quot;</span>
                <span className={styles.c1}>
                    &nbsp;means a set of personal data and/or non-personalized information about the
                    User provided by him to the Copyright Holder and/or automatically collected by
                    the Copyright Holder and/or third parties.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>&quot;Policy&quot;</span>
                <span className={styles.c1}>
                    &nbsp;means the present Privacy Policy of the mobile application (with all
                    existing additions and changes).
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>&quot;User&quot;</span>
                <span className={styles.c1}>
                    &nbsp;means a legal entity or an individual who has downloaded a Mobile
                    Application to a smartphone, tablet, watch or any other mobile device and/or has
                    activated such a Mobile Application on one of these devices.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>&quot;User Agreement&quot;</span>
                <span className={styles.c1}>
                    &nbsp;means an agreement concluded between the Copyright Holder and the User
                    regarding the procedure, rules and features of the User&#39;s use of the Mobile
                    Application. The User joins such an agreement and has no right to make and/or
                    require any changes or additions to it.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>&quot;Copyright Holder&quot;</span>
                <span className={styles.c1}>
                    &nbsp;means the following person who owns the exclusive rights of ownership of
                    the Mobile Application
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3} id="h.gjdgxs">
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Adele Energy Group Ltd.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>BIN: 210240900314</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>KZ8796503F0010225426 BIC IRTYKZKA</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Branch of ForteBank JSC in Astana</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Bank BIN 990740000683 </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Legal address:</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Republic of Kazakhstan, Astana city, Yesil, Mangilik El Avenue, building 55/22,
                    postal code Z05T3F5{' '}
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Actual address:</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Republic of Kazakhstan, Astana city, Yesil, avenue Mangilik El, 55/22, block
                    C3.5, office 314
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>postal code 010020</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Tel.: +7 777 0011350</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>ruslan.dyussenov@adele.energy </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>General manager</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Dyusenov Ruslan Telmanovich</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>&quot;Cookies&quot;</span>
                <span className={styles.c1}>
                    &nbsp;means small files sent to any mobile applications or website and placed on
                    the User&#39;s smartphones, tablets, watches and other mobile devices to improve
                    the operation of such applications or websites, as well as the quality of the
                    content posted in them.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>2. RELATIONS COVERED BY THE POLICY</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>General provisions</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    This Policy is used and applies exclusively to Personal Data received from the
                    User in connection with the use of the Mobile Application. The provisions of
                    this Policy are aimed at:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (1) determination of the types and types of Personal Data received, directions
                    and purposes of use (processing) Personal Data, as well as the sources of
                    obtaining such Personal Data; and
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (2) determining the User&#39;s rights with respect to protecting the
                    confidentiality of the Personal Data transmitted to them; and
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (3) identification of persons responsible for the processing and storage of
                    Personal Data, as well as third parties to whom such data is disclosed (in whole
                    or in part).
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The rules of this Policy do not apply in the case of processing by third parties
                    of Personal Data that is voluntarily provided by the User.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    By installing and/or activating a Mobile Application on a smartphone, tablet,
                    watch or other mobile device, the User agrees to the terms of this Policy and
                    gives his consent to the Copyright Holder to collect, process, retain and store
                    Personal Data in accordance with the procedure and conditions provided for in
                    this Policy.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    If the User does not agree with the terms of the Policy and /or individual terms
                    of the Policy are not clear to him, in this case the User is obliged to
                    immediately stop using the Mobile Application.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>User&#39;s rights to protect personal data</span>
            </p>
            <p className={styles.c6 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    In connection with the provision of Personal Data, the User automatically
                    receives the following rights:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (1) to receive data concerning their processing (the grounds and purposes of
                    such processing, the processing methods used, information about persons who have
                    access to them or to whom they may be disclosed on the basis of a contract or
                    Law).
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (2) to receive data on the location and identification data of persons who
                    process Personal Data.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (3) to receive data on the terms of storage of Personal Data.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (4) receive data on the trans-border transfer of Personal Data that has been
                    carried out or is expected to be carried out.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (5) to receive information about the location and identification data of persons
                    who store Personal Data.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (6) appeal the actions or omissions of the Copyright Holder to the authorized
                    body for the protection of the rights of personal data subjects or in court.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (7) receive damages and/or compensation for moral damage in court as a result of
                    violations of the User&#39;s rights to the protection and protection of his
                    Personal Data committed by the Copyright Holder and/or third parties.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (8) exercise other rights in the field of personal data protection provided for
                    by Law or the provisions of this Policy.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>3. LIST OF PERSONAL DATA COLLECTED</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>Non-personalized user information</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    In connection with the use of the Mobile Application, the Copyright Holder can
                    automatically collect and process the following non-personalized information
                    about the User:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (1) traffic information, the possible number of clicks made, logs and other
                    data.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (2) information about the User&#39;s location (geolocation). The user can
                    disable geolocation at any time by changing the settings of the device from
                    which the Mobile Application is logged in. Geolocation is used by a Mobile
                    Application only when the User is actively using such an application. When
                    exiting the Mobile App, geolocation stops functioning.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (3) information about the device (identification number, mobile operator
                    network) from which the login is performed, operating system, platform, browser
                    type and other browser information, IP address.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>Personal data about users</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The User provides the Copyright Holder with the following personal data about
                    himself:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(1) full surname, first name and patronymic.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(2) date of birth and age.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(3) email address.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(4) mobile phone number.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(5) the gender of the User.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (6) data contained in the personal account (profile) The User, all internal
                    correspondence of the User (if any), as well as other activity of the User&#39;s
                    personal account (profile).
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (7) data on orders made to Users/purchases and/or services received/paid for via
                    the Mobile App.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(8) the following data:</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Brand of electric car</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (9) data and information obtained as a result of combining certain Personal Data
                    of a particular User, as well as data and information obtained about the User
                    received from third parties (partners, marketers, researchers).
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The User is the only person responsible for the completeness of the personal
                    (personal) data provided and is obliged to change them in a timely manner
                    (update, check, correction) on a regular basis.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The Copyright Holder assumes that all personal (personal) data provided by the
                    User is reliable, and that the User maintains such information up to date.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>Information about transactions being made</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The user can pay for goods or services through the Mobile Application by
                    entering information about the payment card and the identification data of the
                    owner of such a card into a special field. The user can make payments in the
                    Mobile Application in the following way:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>&bull; by bank card.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>&bull; using the Apple Pay payment system.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>&bull; using the Google Pay payment system.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    In this case, the collection and processing of User data is carried out
                    exclusively for the purposes of payment, fraud prevention, as well as compliance
                    with other requirements of the Law:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The User gives his consent to access and collection by the Copyright Holder and
                    the relevant payment system or banking institution through which the payment is
                    made to such Personal Data, and also agrees to the policy confidentiality of the
                    relevant payment system or banking institution.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>Use of cookies</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    This Mobile Application uses certain cookies to save the IP address, User
                    preferences or the type of device used in order to (1) maintain statistics of
                    visits and traffic to the site, and (2) personalize the data displayed on the
                    User&#39;s screen, and (3) save data necessary for User identification,
                    including when accessing from different devices, and (4) display ads according
                    to the User&#39;s interests and preferences.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The mobile application can use both its own cookies belonging to the Copyright
                    Holder and Cookies of third parties.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The mobile application uses the following cookies:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (1) Technical (functional) cookies that are needed to control traffic and data
                    transmission, to identify Users and provide access to the content of the Mobile
                    Application to the User and without which the use of the Mobile Application is
                    functionally limited, as well as to prevent the provision of recommendations
                    that do not correspond to the interests of the User.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (2) Statistical cookies, which are needed to track the frequency of User visits
                    to the site, to identify how the User uses the Mobile Application, as well as to
                    identify the type and type of content that is popular or interesting to the
                    User.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (3) Geolocation cookies, which are needed to determine the location of the User
                    in order to personalize the content displayed on the screen of his device in the
                    Mobile Application.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (4) Third-party cookies, which are installed by third parties with the
                    User&#39;s permission and are intended for conducting statistical research
                    concerning the User&#39;s behavior on the Internet and/or sending personalized
                    advertising or marketing materials to the User and/or providing goods or
                    services.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The User has the right to disable Cookies in the Mobile Application at any time
                    by changing certain settings in his smartphone, tablet, watch or other mobile
                    device. Such disconnection may result in restriction or modification of the
                    User&#39;s access to the functionality of the Mobile Application and/or content.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>
                    4. PURPOSES OF PERSONAL DATA COLLECTION AND PROCESSING
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>Definition of processing purposes</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The collection and processing of Personal Data is carried out for the following
                    purposes:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (1) to analyze User behavior, as well as to identify User preferences for a
                    certain type of content.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (2) for the prompt and correct operation of the Mobile Application, improving
                    the functioning of the Mobile Application, improving the content of the Mobile
                    Application, improving the internal architecture and functionality of the Mobile
                    Application.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(3) to identify the User.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (4) to send personalized advertising and marketing materials to the specified
                    email address and/or mobile phone of the User.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(5) to comply with the requirements of the Law.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (6) to track orders/purchases made by the User through the Mobile Application.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(7) to determine the location of the User.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (8) for technical support of the Mobile Application, identification of problems
                    in its operation and their elimination.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (9) to maintain communication with the User (communication).
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (10) to fulfill other obligations of the Copyright Holder that have arisen
                    before the User.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(11) for conducting statistical research.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (12) for any other purposes, subject to obtaining separate consent from the
                    User.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The processing of Personal Data is carried out on the basis of the principles:
                    (1) legality of the purposes and methods of processing; and (2) good faith; and
                    (3) compliance of the purposes of Personal Data processing with the goals
                    previously defined and declared when collecting such Personal Data; and (4)
                    compliance of the volume and nature of the processed Personal Data with the
                    stated purposes of their processing.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>Personal data processing conditions</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The processing of Personal Data is carried out in the following cases:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(1) obtaining consent from the User; or</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (2) the Rightholder&#39;s achievement of the goals stipulated by an
                    international treaty or law; or
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (3) providing the User with his Personal Data to an unlimited number of persons;
                    or
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (4) fulfilling other obligations of the Copyright Holder to the User, including,
                    but not limited to, providing certain content to the User; or
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (5) saving the life or health of the User when consent to the processing of his
                    Personal Data cannot be obtained in advance.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    In the case of depersonalization of Personal Data, which does not directly or
                    indirectly identify the User, the subsequent use and disclosure of such data to
                    third parties is allowed and the rules of this Policy no longer apply to them.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The Copyright Holder takes all possible measures to protect the confidentiality
                    of the Personal Data received, except in cases when the User has made such data
                    publicly available.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The processing of Personal Data is carried out with the use of automation tools
                    and without the use of such automation tools.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>
                    5. SENDING COMPLAINTS AND REQUESTS TO THE COPYRIGHT HOLDER
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>
                    The requirement to terminate the processing of personal data
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Each User has the right to express his objection to the Copyright Holder against
                    the processing and/or storage of his Personal Data. Such an objection can be
                    expressed as follows: the request will have to be sent to the Copyright
                    Holder&#39;s mail at pdm@adele.energy with the subject of the letter
                    &quot;Termination of processing of personal (personal) data&quot;
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>Request for information about personal data</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    If the User has any questions related to the procedure for applying or using
                    this Policy, the procedure and/or method of processing Personal Data, the User
                    can ask such a question as follows: the question should be sent to the Copyright
                    Holder&#39;s email address pdm@adele.energy with the subject of the letter
                    &quot;Termination of processing of personal (personal) data&quot;
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>
                    Modification (updating, addition, correction) or deletion of personal data
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The User has the right to independently change or delete his Personal Data at
                    any time, except in cases where such a change or deletion may lead to
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(1) to violate the rules of this Policy; or</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(2) violation of the Law;</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (3) the nature of such Personal Data is evidence in any legal process that has
                    arisen between the Copyright Holder and the User. To do this, the User needs to
                    delete his personal account (profile) in the Mobile application.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The Copyright Holder has the right at any time to delete the User&#39;s personal
                    account/profile, as well as all Personal Data about the User, if he violated the
                    terms of this Policy and/or the User Agreement.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>6. TERMS AND PROCEDURE FOR STORING PERSONAL DATA</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Storage is carried out by third parties on behalf of the Copyright Holder. The
                    User gives his consent to the storage of his Personal Data by third parties on
                    behalf of the Copyright Holder, provided that such third parties preserve the
                    confidentiality of the Personal Data received.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Storage is carried out during the entire period of use by the User of this
                    Mobile Application.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The Copyright Holder undertakes to destroy or depersonalize his Personal Data
                    immediately after the User stops using the Mobile Application.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>7. ACCESS OF MINORS TO THE MOBILE APPLICATION</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The use of the Mobile Application is intended for persons over the age of 18 who
                    gain access to it only if they provide prior consent to the processing of their
                    Personal Data. The Copyright Holder checks the User&#39;s age as follows:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    the user will need to enter the full date of his birth in a special window. If
                    the User is a minor, in this case, he must immediately stop using this Mobile
                    Application.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>8. PERSONAL DATA PROTECTION PROCEDURE</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Protecting the confidentiality of Personal Data is a primary and important task
                    for the Copyright Holder. The Copyright Holder adheres to all required
                    international standards, rules and recommendations for the protection of
                    Personal Data.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The copyright holder has implemented a number of technical and organizational
                    methods aimed at protecting Personal Data from disclosure or unauthorized access
                    to them by third parties.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>9. FINAL PROVISIONS</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>Availability of the policy text for review</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Users can read the terms of this Policy at the following link:
                    https://guest.ladergy.com/privacy/adele-cloud/en
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>Changing and supplementing the policy</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c4}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    This Policy may be changed from time to time. The Copyright Holder does not bear
                    any responsibility to the User for changing the terms of this Policy without the
                    permission and/or consent of the User.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    The User himself undertakes to regularly check the provisions of this Policy for
                    possible changes or additions. However, in case of a significant change in the
                    terms of this Policy, the Copyright Holder undertakes to notify Users in the
                    following way: automatic mailing to all users to the email address specified
                    when registering the profile in the mobile application
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>Applicable law</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    This Policy has been developed in accordance with the current legislation on the
                    protection of personal data of the Republic of Kazakhstan, in particular, with
                    the provisions of State Law No. 94-V dated May 21, 2013 &quot;On Personal Data
                    and their Protection&quot; (with all additions and amendments), State Law No.
                    96-VII dated December 30, 2021 &quot;On amendments and additions to some
                    legislative acts of the Republic of Kazakhstan on trading activities,
                    development of exchange trading and protection of personal data&quot; (with all
                    additions and changes).
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c4}>Risk of disclosure</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Regardless of the measures taken by the Copyright Holder to protect the
                    confidentiality of the personal data received, the User is hereby deemed to be
                    duly informed that any transfer of Personal Data on the Internet cannot be
                    guaranteed safe, and therefore the User carries out such transfer at his own
                    risk.
                </span>
            </p>
        </div>
    );
};
