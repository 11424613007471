import { css } from '@emotion/react';

const mainStyles = {
    container: css({
        padding: 0,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#101010',
        gap: '10px',
    }),
    list: css({
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
        padding: '0 15px',
        width: '100%',
        boxSizing: 'border-box',
    }),
    inputWrapper: css({
        padding: '20px',
    }),
    charge: css({
        display: 'flex',
        background: '#000000',
        boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
        borderRadius: '8px',
        margin: '20px 20px 0',
        padding: '8px 16px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    }),
    chargeInfoWrapper: css({
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    }),
    chargeTitle: css({
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '22px',
    }),
    chargeSubtitle: css({
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '16px',
        lineHeight: '20px',
    }),
};

export default mainStyles;
