import React, { FC } from 'react';

import styles from './privacy-ru.module.css';

export const PrivacyRuView: FC = () => {
    return (
        <>
            <div className={styles.c7}>
                <p className={styles.c6}>
                    <span className={styles.c3}>
                        ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ДЛЯ МОБИЛЬНОГО ПРИЛОЖЕНИЯ
                        <br />
                        ADELE CLOUD
                    </span>
                </p>
                <p className={styles.c6 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Для мобильного приложения Adele Cloud интересы пользователей превыше всего.
                        Этот принцип лежит в основе всех принимаемых нами решений, в том числе при
                        сборе и обработке Ваших персональных данных.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Мы постарались сделать Политику конфиденциальности как можно более понятной
                        и ясной. Наша цель заключается в том, чтобы Вы — пользователь Adele Cloud  —
                        всегда чувствовали себя уверенно, когда речь заходит о конфиденциальности.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Сохранение Вашего доверия также является одним из приоритетов Adele Cloud.
                        Для защиты конфиденциальности Ваших персональных данных используются
                        указанные ниже принципы.
                    </span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br /> 
                    </span>
                    <span className={styles.c3}>1.</span>
                    <span className={styles.c0}> </span>
                    <span className={styles.c3 + ' ' + styles.c5}>ОПРЕДЕЛЕНИЕ ПОНЯТИЙ</span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>«</span>
                    <span className={styles.c3}>Закон</span>
                    <span className={styles.c0}>
                        » означает Государственный закон Республики Казахстан «О персональных данных
                        и их защите» со всеми изменениями и дополнениями, а также иные
                        законодательные акты Республики Казахстан.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>«</span>
                    <span className={styles.c3}>Мобильное приложение</span>
                    <span className={styles.c0}>
                        » это программное обеспечение (со всеми существующими дополнениями и
                        улучшениями), предназначенное для работы на смартфонах, планшетах, часах и
                        других мобильных устройствах, и разработанное для конкретной платформы (iOS,
                        Android, Windows Phone и т. д.). Для целей настоящей Политики под Мобильным
                        приложением подразумевается следующее программное обеспечение: Adele Cloud.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>«</span>
                    <span className={styles.c3}>Персональные данные</span>
                    <span className={styles.c0}>
                        » означает совокупность личных данных и/или неперсонифицированной информации
                        о Пользователе, предоставляемую им самим Правообладателю и/или автоматически
                        собираемую Правообладателем и/или третьими лицами.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>«</span>
                    <span className={styles.c3}>Политика</span>
                    <span className={styles.c0}>
                        » означает настоящею Политику конфиденциальности мобильного приложения (со
                        всеми существующими дополнениями и изменениями).
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>«</span>
                    <span className={styles.c3}>Пользователь</span>
                    <span className={styles.c0}>
                        » означает юридическое или физическое лицо, которое загрузило Мобильное
                        приложение на смартфон, планшет, часы или любое другое мобильное устройство
                        и/или осуществило активацию такого Мобильного приложения на одном из
                        указанных устройств.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>«</span>
                    <span className={styles.c3}>Пользовательское соглашение</span>
                    <span className={styles.c0}>
                        » означает соглашение, заключаемое между Правообладателем и Пользователем в
                        отношении порядка, правил и особенностей использования Пользователем
                        Мобильного приложения. Пользователь присоединяется к такому соглашению и не
                        имеет права вносить и/или требовать внесения в него каких-либо изменений или
                        дополнений.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>«</span>
                    <span className={styles.c3}>Правообладатель</span>
                    <span className={styles.c0}>
                        » означает следующее лицо, которому принадлежат исключительные права
                        владения Мобильным приложением
                    </span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0 + ' ' + styles.c5}>
                        Adele Energy Group Ltd.
                        <br />
                        БИН: 210240900314
                        <br />
                        ИИК KZ8796503F0010225426 БИК IRTYKZKA
                        <br />
                        Филиал АО «ForteBank» в г. Астана
                        <br />
                        БИН Банка 990740000683 <br />
                        Юридическии‌ адрес:
                        <br />
                        Республика Казахстан, город Астана, раи‌он Есиль, Проспект Мангилик Ел,
                        здание 55/22, почтовыи‌ индекс Z05T3F5 <br />
                        Фактический адрес:
                        <br />
                        Республика Казахстан, город Астана, раи‌он Есиль, проспект Мангилик Ел,
                        55/22, блок С3.5, офис 314
                        <br />
                        почтовыи‌ индекс 010020 <br />
                        Тел.: +7 777 0011350
                        <br />
                        ruslan.dyussenov@adele.energy 
                        <br />
                        Генеральный директор
                        <br />
                        Дюсенов Руслан Тельманович
                    </span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>«</span>
                    <span className={styles.c3}>Файлы куки</span>
                    <span className={styles.c0}>
                        » означает небольшие файлы, отправляемые каким-либо мобильным приложениям
                        или сайтом, и размещаемые на смартфонах, планшетах, часах и других мобильных
                        устройствах Пользователя, для улучшения работы таких приложений или сайтов,
                        а также качества размещенного в них контента.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>
                        2. ОТНОШЕНИЯ, НА КОТОРЫЕ РАСПРОСТРАНЯЕТСЯ ПОЛИТИКА
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>Общие положения</span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Данная Политика используется и применима исключительно к Персональным
                        данным, получаемым от Пользователя в связи с использованием им Мобильного
                        приложения. Положения данной Политики направлены на:
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(1)</span>
                    <span className={styles.c0}>
                         определение видов и типов получаемых Персональных данных, направлений и
                        целей использования (обработки) Персональных данных, а также источников
                        получения таких Персональных данных; и
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(2)</span>
                    <span className={styles.c0}>
                         определение прав Пользователя в отношении защиты конфиденциальности
                        передаваемых им Персональных данных; и
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(3)</span>
                    <span className={styles.c0}>
                         определение лиц, ответственных за обработку и хранение Персональных данных,
                        а также третьих лиц, которым такие данные раскрываются (полностью или
                        частично).
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Правила настоящей Политики не применяются в случае обработки третьими лицами
                        Персональных данных, которые добровольно предоставляются Пользователем.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Посредством установки и/или активации Мобильного приложения на смартфоне,
                        планшете, часах или другом мобильном устройстве Пользователь соглашается с
                        условиями данной Политики и дает свое согласие Правообладателю на сбор,
                        обработку, удержание и хранение Персональных данных в порядке и на условиях,
                        предусмотренных настоящей Политикой.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Если Пользователь не согласен с условиями Политики и/или отдельные условия
                        Политики ему не понятны, в таком случае Пользователь обязан немедленно
                        прекратить использование Мобильного приложения.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>
                        Права пользователя по защите персональных данных
                    </span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        В связи с предоставлением Персональных данных Пользователь автоматически
                        получает следующие права:
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(1)</span>
                    <span className={styles.c0}>
                         получать данные, касающиеся их обработки (основания и цели такой обработки,
                        применяемые способы обработки, сведения о лицах, которые имеют доступ к ним
                        или которым они могут быть раскрыты на основании договора или Закона).
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(2)</span>
                    <span className={styles.c0}>
                         получать данные о месте нахождения и идентификационных данных лиц,
                        совершающих обработку Персональных данных.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(3)</span>
                    <span className={styles.c0}>
                         получать данные о сроках хранения Персональных данных.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(4)</span>
                    <span className={styles.c0}>
                         получать данные об осуществленной или о предполагаемой трансграничной
                        передаче Персональных данных.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(5)</span>
                    <span className={styles.c0}>
                         получать информацию о месте нахождения и идентификационных данных лиц,
                        совершающих хранение Персональных данных.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(6)</span>
                    <span className={styles.c0}>
                         обжаловать действия или бездействие Правообладателя в уполномоченный орган
                        по защите прав субъектов персональных данных или в судебном порядке.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(7)</span>
                    <span className={styles.c0}>
                         получать возмещение убытков и/или компенсацию морального вреда в судебном
                        порядке в следствие допущенных Правообладателем и/или третьими лицами
                        нарушений прав Пользователя на охрану и защиту его Персональных данных.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(8)</span>
                    <span className={styles.c0}>
                         реализовывать иные права в области защиты персональных данных,
                        предусмотренные Законом или положениями данной Политики.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>3. ПЕРЕЧЕНЬ СОБИРАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ</span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>
                        Неперсонифицированная информация о пользователях
                    </span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        В связи с использованием Мобильного приложения Правообладатель может
                        автоматически собирать и обрабатывать следующею неперсонифицированную
                        информацию о Пользователе:
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(1)</span>
                    <span className={styles.c0}>
                         информацию о трафике, возможном количестве совершенных кликов, логи и
                        другие данные.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(2)</span>
                    <span className={styles.c0}>
                         информацию о месте нахождении Пользователя (геолокация). Пользователь может
                        в любой момент отключить геолокацию путем изменения настроек устройства, с
                        которого осуществлен вход в Мобильное приложение. Геолокация используется
                        Мобильным приложением только тогда, когда Пользователь активно использует
                        такое приложение. При выходе из Мобильного приложения геолокация перестает
                        функционировать.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(3)</span>
                    <span className={styles.c0}>
                         информацию об устройстве (идентификационный номер, сеть мобильного
                        оператора), с которого выполняется вход, операционная система, платформа,
                        тип браузера и другая информация о браузере, IP адрес.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>Личные данные о пользователях</span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Пользователь предоставляет Правообладателю о себе следующие личные данные:
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(1)</span>
                    <span className={styles.c0}> полные фамилию, имя и отчество.</span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(2)</span>
                    <span className={styles.c0}> дату рождения и возраст.</span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(3)</span>
                    <span className={styles.c0}> адрес электронной почты.</span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(4)</span>
                    <span className={styles.c0}> номер мобильного телефона.</span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(5)</span>
                    <span className={styles.c0}> пол Пользователя.</span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(6)</span>
                    <span className={styles.c0}>
                         данные, которые содержатся в личном кабинете (профиле) Пользователя, все
                        внутренние переписки Пользователя (если есть), а также иная активность
                        личного кабинета (профиля) Пользователя.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(7)</span>
                    <span className={styles.c0}>
                         данные о совершенных Пользователям заказах/покупках и/или
                        полученных/оплаченных услугах через Мобильное приложение.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(8)</span>
                    <span className={styles.c0}>
                         следующие данные:
                        <br />
                        Марка электромобиля
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(9)</span>
                    <span className={styles.c0}>
                         данные и информация, получаемая в результате объединения тех или иных
                        Персональных данных конкретного Пользователя, а также данные и информация,
                        получаемые данные о Пользователе, получаемые от третьих лиц (партнеров,
                        маркетологов, исследователей).
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Пользователь является единственным ответственным лицом за полноту
                        предоставляемых личных (персональных) данных и обязан осуществлять
                        своевременное их изменение (обновление, проверку, корректировку) на
                        регулярной основе.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Правообладатель исходит из того, что все предоставленные Пользователем
                        личные (персональные) данные являются достоверными, и что Пользователь
                        поддерживает такую информацию в актуальном состоянии.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>Информация о совершаемых транзакциях</span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Пользователь через Мобильное приложение может осуществлять оплату за товары
                        или услуги, посредством введения в специальное поле информации о платежной
                        карте и идентификационных данных собственника такой карты. Пользователь
                        может совершать оплату в Мобильном приложении следующим способом:
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>• посредством банковской карты.</span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>• с помощью платежной системы Apple Pay.</span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>• с помощью платежной системы Google Pay.</span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Сбор и обработка данных о Пользователе в данном случае осуществляется
                        исключительно для целей проведения оплаты, недопущения мошенничества, а
                        также соблюдения иных требований Закона:{' '}
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Пользователь дает свое согласие на доступ и сбор Правообладателем и
                        соответствующей платежной системой или банковским учреждением, через
                        которую/которое проводится оплата, к таким Персональным данным, а также
                        соглашается с политикой конфиденциальности соответствующей платежной системы
                        или банковского учреждения.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>Использование файлов куки</span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Данное Мобильное приложение применяет определенные Файлы куки для сохранения
                        IP-адреса, предпочтений Пользователей или типа используемого устройства с
                        целью 
                    </span>
                    <span className={styles.c3}>(1)</span>
                    <span className={styles.c0}>
                         ведения статистики посещений и трафика сайта, и 
                    </span>
                    <span className={styles.c3}>(2)</span>
                    <span className={styles.c0}>
                         персонализации выводимых на экран Пользователя данных, и 
                    </span>
                    <span className={styles.c3}>(3)</span>
                    <span className={styles.c0}>
                        сохранения данных, необходимых для идентификации Пользователя, в том числе
                        при доступе с разных устройств, и 
                    </span>
                    <span className={styles.c3}>(4)</span>
                    <span className={styles.c0}>
                         показа рекламы в соответствии с интересами и предпочтениями Пользователя.{' '}
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Мобильное приложение может использовать как собственные Файлы куки,
                        принадлежащие Правообладателю, так и Файлы куки третьих лиц.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Мобильное приложение использует следующие Файлы куки:
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(1)</span>
                    <span className={styles.c0}>
                         Технические (функциональные) Файлы куки, которые нужны для контроля трафика
                        и передачи данных, для идентификации Пользователей и предоставления доступа
                        Пользователю к контенту Мобильного приложения и без которых использование
                        Мобильного приложения является функционально ограниченным, а также для
                        предотвращения предоставления рекомендаций, не соответствующих интересам
                        Пользователя.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(2)</span>
                    <span className={styles.c0}>
                         Статистические Файлы куки, которые нужны для отслеживания частоты
                        посещаемости сайта Пользователями, для выявления способов использования
                        Пользователем Мобильного приложения, а также для выявления типа и вида
                        контента, который является популярными или интересным для Пользователя.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(3)</span>
                    <span className={styles.c0}>
                         Геолокационные Файлы куки, которые нужны для определения места нахождения
                        Пользователя для персонализации выводимого на экран его устройства контента
                        в Мобильном приложении.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(4)</span>
                    <span className={styles.c0}>
                         Файлы куки третьих лиц, которые устанавливаются третьими лицами с
                        разрешения Пользователя и предназначены для проведения статистических
                        исследований, касающихся поведения Пользователя в сети Интернет и/или
                        направления персонализиированных рекламных или маркетинговых материалов
                        Пользователю и/или предоставления товаров или услуг.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Пользователь имеет право в любой момент отключить Файлы куки в Мобильном
                        приложении путем изменения определенных настроек в своем смартфоне,
                        планшете, часах или другом мобильном устройстве. Такое отключение может
                        повлечь за собой ограничение или изменение доступа Пользователя к
                        функциональным возможностям Мобильного приложения и/или контенту.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>4. ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>Определение целей обработки</span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Сбор и обработка Персональных данных осуществляется в следующих целях:
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(1)</span>
                    <span className={styles.c0}>
                         для анализа поведения Пользователя, а также выявления предпочтений
                        Пользователя к определенному виду контента.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(2)</span>
                    <span className={styles.c0}>
                         для оперативной и корректной работы Мобильного приложения, улучшения
                        функционирования работы Мобильного приложения, улучшения контента Мобильного
                        приложения, улучшения внутренней архитектуры и функциональности Мобильного
                        приложения.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(3)</span>
                    <span className={styles.c0}> для идентификации Пользователя.</span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(4)</span>
                    <span className={styles.c0}>
                         для направления персонализированных рекламных и маркетинговых материалов на
                        указанный электронный адрес и/или мобильный телефон Пользователя.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(5)</span>
                    <span className={styles.c0}> для соблюдения требований Закона.</span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(6)</span>
                    <span className={styles.c0}>
                         для отслеживания заказов/покупок, совершенных Пользователем через Мобильное
                        приложение.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(7)</span>
                    <span className={styles.c0}>
                         для определения места нахождения Пользователя.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(8)</span>
                    <span className={styles.c0}>
                         для технической поддержки Мобильного приложения, выявления проблем в его
                        работе и их устранение.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(9)</span>
                    <span className={styles.c0}>
                         для поддержания связи с Пользователем (коммуникация).
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(10)</span>
                    <span className={styles.c0}>
                         для выполнения иных обязательств Правообладателя, которые возникли перед
                        Пользователем.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(11)</span>
                    <span className={styles.c0}> для проведения статистических исследований.</span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(12)</span>
                    <span className={styles.c0}>
                         для любых других целей, при условии получения отдельного согласия от
                        Пользователя.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Обработка Персональных данных осуществляется на основе принципов:{' '}
                    </span>
                    <span className={styles.c3}>(1)</span>
                    <span className={styles.c0}> законности целей и способов обработки; и </span>
                    <span className={styles.c3}>(2)</span>
                    <span className={styles.c0}> добросовестности; и </span>
                    <span className={styles.c3}>(3)</span>
                    <span className={styles.c0}>
                         соответствия целей обработки Персональных данных целям, заранее
                        определенным и заявленным при сборе таких Персональных данных; и{' '}
                    </span>
                    <span className={styles.c3}>(4)</span>
                    <span className={styles.c0}>
                        соответствия объема и характера обрабатываемых Персональных данных
                        заявленным целям их обработки.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>Условия обработки персональных данных</span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Обработка Персональных данных проводится в случаях:{' '}
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(1)</span>
                    <span className={styles.c0}> получения согласия от Пользователя; или </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(2)</span>
                    <span className={styles.c0}>
                         достижения Правообладателем целей, предусмотренных международным договором
                        или Законом; или{' '}
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(3)</span>
                    <span className={styles.c0}>
                         предоставления Пользователем своих Персональных данных неограниченному
                        кругу лицу; или{' '}
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(4)</span>
                    <span className={styles.c0}>
                         выполнения иных обязательств Правообладателя перед Пользователем, включая,
                        однако не ограничиваясь, предоставление определенного контента Пользователю;
                        или{' '}
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(5)</span>
                    <span className={styles.c0}>
                         спасения жизни или здоровья Пользователя, когда согласие на обработку его
                        Персональных данных не удается получить заблаговременно.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        В случае обезличивания Персональных данных, что не позволяет прямо или
                        опосредованно определить Пользователя, последующее использование и раскрытие
                        таких данных третьим лицам допускается и в отношении их более не применяются
                        правила данной Политики.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Правообладатель принимает все возможные меры для защиты конфиденциальности
                        полученных Персональных данных, за исключением случаев, когда Пользователь
                        сделал такие данные общедоступными.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Обработка Персональных данных осуществляется с использованием средств
                        автоматизации и без использования таких средств автоматизации.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>
                        5. НАПРАВЛЕНИЕ ЖАЛОБ И ЗАПРОСОВ ПРАВООБЛАДАТЕЛЮ
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>
                        Требование о прекращении обработки персональных данных
                    </span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Каждый Пользователь имеет право выразить свое возражение Правообладателю
                        против обработки и/или хранения его Персональных данных. Такое возражение
                        может быть выражено следующим образом: запрос должен будет направлен на
                        почту Правообладателя по адресу pdm@adele.energy с темой письма «Прекращение
                        обработки персональных (личных) данных»
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>
                        Запрос на получение информации о персональных данных
                    </span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Если у Пользователя возникают вопросы, связанные с порядком применения или
                        использования настоящий Политики, порядком и/или способом обработки
                        Персональных данных, Пользователь может задать такой вопрос следующим
                        образом: вопрос должен будет направлен на почту Правообладателя по адресу
                        pdm@adele.energy с темой письма «Прекращение обработки персональных (личных)
                        данных»
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>
                        Изменение (обновление, дополнение, корректировка) или удалении персональных
                        данных
                    </span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Пользователь имеет право в любой момент самостоятельно изменить или удалить
                        свои Персональные данные, за исключением случаев, когда такое изменение или
                        удаление может привести
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(1)</span>
                    <span className={styles.c0}> к нарушению правил настоящей Политики; или</span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(2)</span>
                    <span className={styles.c0}> к нарушению Закона; </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c3}>(3)</span>
                    <span className={styles.c0}>
                         характер таких Персональных данных является доказательством в каком-либо
                        судебном процессе, возникшем между Правообладателем и Пользователем. Для
                        этого Пользователю требуется удалить свой личный аккаунт (профиль) в
                        Мобильном приложении.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Правообладатель имеет право в любой момент удалить личный аккаунт/профиль
                        Пользователя, а также все Персональные данные о Пользователе, если он
                        нарушил условия данной Политики и/или Пользовательского соглашения.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>
                        6. СРОКИ И ПОРЯДОК ХРАНЕНИЯ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Хранение осуществляется третьими лицами по поручению Правообладателя.
                        Пользователь дает свое согласие на хранение его Персональных данных третьими
                        лицами по поручению Правообладателя, при условии сохранения такими третьими
                        лицами конфиденциальности полученных Персональных данных.{' '}
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Хранение осуществляется в течение всего срока использования Пользователем
                        данного Мобильного приложения.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Правообладатель обязуется немедленно после прекращения использования
                        Пользователем Мобильного приложения уничтожить или же обезличить его
                        Персональные данные.
                    </span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>
                        7. ДОСТУП НЕСОВЕРШЕННОЛЕТНИХ К МОБИЛЬНОМУ ПРИЛОЖЕНИЮ
                    </span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Использование Мобильного приложения предназначено для лиц старше 18 лет,
                        которые получают к нему доступ только при условии предоставления
                        предварительного согласия на обработку их Персональных данных.
                        Правообладатель проверяет возраст Пользователя следующим образом:
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        пользователю потребуется ввести полную дату своего рождения в специальном
                        окне. Если Пользователь является несовершеннолетним лицом, в таком случае он
                        должен немедленно прекратить использование данного Мобильного приложения.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>8. ПОРЯДОК ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ</span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Защита конфиденциальности Персональных данных является первостепенной и
                        важной задачей для Правообладателя. Правообладатель придерживается всех
                        требуемых международных стандартов, правил и рекомендаций по защите
                        Персональных данных.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Правообладатель внедрил ряд технических и организационных методов,
                        направленных на защиту Персональных данных от разглашения или
                        несанкционированного доступа к ним третьих лиц.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>Доступность текста политики для ознакомления</span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Пользователи могут ознакомиться с условиями данной Политики по следующей
                        ссылке: https://guest.ladergy.com/privacy/adele-cloud/ru
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>Изменение и дополнение политики</span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Настоящая Политика может быть изменена время от времени. Правообладатель не
                        несет какой-либо ответственности перед Пользователем за изменение условий
                        данной Политики без разрешения и/или согласия Пользователя.
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Пользователь сам обязуется на регулярной основе проверять положения данной
                        Политики на предмет ее возможного изменения или дополнения. Однако, в случае
                        существенного изменения условий данной Политики, Правообладатель обязуется
                        уведомить Пользователей следующим способом: автоматическая рассылка всем
                        пользователям на электронный адрес, указанный при регистрации профиля в
                        мобильном приложении
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>Применимое законодательство</span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Настоящая Политика разработана в соответствие с действующим
                        законодательством о защите персональных данных Республики Казахстан, в
                        частности, с нормами Государственного закона от 21 мая 2013 года № 94-V «О
                        персональных данных и их защите» (со всеми дополнениями и изменениями),
                        Государственного закона от 30 декабря 2021 года № 96-VII «О внесении
                        изменений и дополнений в некоторые законодательные акты Республики Казахстан
                        по вопросам торговой деятельности, развития биржевой торговли и защиты
                        персональных данных» (со всеми дополнениями и изменениями).
                    </span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        <br />
                    </span>
                    <span className={styles.c3}>Риск разглашения</span>
                </p>
                <p className={styles.c1 + ' ' + styles.c4}>
                    <span className={styles.c2}></span>
                </p>
                <p className={styles.c1}>
                    <span className={styles.c0}>
                        Вне зависимости от предпринимаемых Правообладателем мер защиты
                        конфиденциальности получаемых персональных данных, Пользователь настоящим
                        считается должным образом ознакомлен с тем, что любая передача Персональных
                        данных в сети Интернет не может быть гарантировано безопасной, а потому
                        Пользователь осуществляет такую передачу на свой собственный риск.
                    </span>
                </p>
            </div>
        </>
    );
};
