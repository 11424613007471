/** @jsxImportSource @emotion/react */
import React, { FC, useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import {
    InvalidConfigError,
    MessageFormatError,
    MissingDataError,
    MissingTranslationError,
    UnsupportedFormatterError,
} from '@formatjs/intl/src/error';
import { FormatError } from 'intl-messageformat';

import locales from '../assets/i18n';
import { store } from '../store/store';
import { selectAppState } from '../store/app/app.selector';
import { changeFavicon } from '../utils/utils';
import { AppTheme } from '../app.theme';

import routesConfig from './app.routes';
import appStyles from './app.styles';

const App: FC = () => {
    const { favicon } = AppTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const { locale } = useSelector(selectAppState);
    const routes = useRoutes(routesConfig);
    const messages = {
        ...locales.ru,
        ...locales[locale],
    };
    const logIntlError = (
        message:
            | MissingTranslationError
            | MessageFormatError
            | MissingDataError
            | InvalidConfigError
            | UnsupportedFormatterError
            | FormatError
    ): void => {
        console.warn(message);
    };

    useEffect(() => {
        changeFavicon(`${window.location}/${favicon}.png`);
        const params = new URLSearchParams(location.search);
        const guestId = params.get('guestId');
        if (guestId) {
            localStorage.setItem('guestId', guestId);
            params.delete('guestId');
            navigate(`${location.pathname}?${params.toString()}`, { replace: true });
        }
    }, []);

    return (
        <IntlProvider
            defaultLocale={'en'}
            locale={locale === 'ua' ? 'uk-UA' : locale}
            messages={messages}
            onError={logIntlError}>
            <div css={appStyles.root}>{routes}</div>
        </IntlProvider>
    );
};

if (window.Cypress) {
    window.store = store;
}

const ConnectedApp: FC = () => (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

export default ConnectedApp;
