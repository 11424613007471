import { css } from '@emotion/react';

const stationIsChargingStyles = {
    title: css({
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '19px',
        lineHeight: '23px',
        color: '#000000',
        margin: '15px 15px',
    }),
    description: css({
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '20px',
        color: '#000000',
    }),
};

export default stationIsChargingStyles;
