import { css } from '@emotion/react';

const stopButtonStyles = {
    powerStopButton: css({
        borderRadius: '5px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.3)',
        border: 'solid 1px #FFF300',
        backgroundColor: '#FFF300',
        color: '#101010',
        fontWeight: 'bold',
        letterSpacing: '-0.12px',
        padding: '0.75rem 1rem',
        margin: '1rem',
    }),
};

export default stopButtonStyles;
