import { css } from '@emotion/react';

const inputStyles = {
    label: css({
        position: 'relative',
    }),
    input: css({
        width: '100%',
        height: '44px',
        paddingLeft: '63px',
        border: 'none',
        borderRadius: '8px',
        boxSizing: 'border-box',
        background: '#F6F8FA',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '20px',
        ':focus': {
            outline: 'none',
        },
    }),
    icon: css({
        paddingLeft: '20px',
        paddingRight: '20px',
        position: 'absolute',
        boxSizing: 'border-box',
        top: 0,
        bottom: 0,
        margin: 'auto 0',
    }),
};

export default inputStyles;
