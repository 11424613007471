/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { LangList } from '../../../components/lang-list/lang-list.component';
import { Menu } from '../../../components/menu/menu.component';
import { AppTheme } from '../../../../../app.theme';

import mainHeaderStyles from './main-header.styles';

export const MainHeader: FC = () => {
    const { logo } = AppTheme();
    return (
        <div css={mainHeaderStyles.container}>
            <div css={mainHeaderStyles.content}>
                <NavLink to={'/'}>
                    <img css={mainHeaderStyles.logo} height={30} src={logo} />
                </NavLink>
                <div css={mainHeaderStyles.stationName}></div>
                <div css={mainHeaderStyles.headActions}>
                    <LangList top={57} color={'black'} />
                    <Menu color={'black'} />
                </div>
            </div>
        </div>
    );
};
