import { css } from '@emotion/react';

const chargeStyles = {
    battery: css({
        borderRadius: '8px',
        backgroundColor: '#101010',
        height: '6rem',
        width: '100%',
        maxWidth: 'calc(100vw - 20%)',
        margin: '1rem 3rem 1rem 2rem',
        position: 'relative',
        padding: '0.5rem',
        display: 'flex',
        justifyContent: 'flex-start',
        ':after': {
            content: '""',
            width: '10px',
            height: '30px',
            position: 'absolute',
            right: '-15px',
            top: 'calc(50% - 15px)',
            background: '#FFF300',
            borderRadius: '4px 8px 8px 4px',
        },
    }),
    batteryEmpty: css({
        height: '100%',
        background: '#FFF300',
        borderRadius: '8px',
        opacity: 0.9,
    }),
    batteryPercent: css({
        position: 'absolute',
        top: 'calc(50% - 2rem)',
        left: 0,
        width: '100%',
        textAlign: 'center',
        fontSize: '3.125rem',
        fontWeight: 800,
        color: 'white',
        mixBlendMode: 'difference',
        ':after': {
            content: '"%"',
            fontSize: '1.5rem',
        },
    }),
};

export default chargeStyles;
