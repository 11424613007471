/** @jsxImportSource @emotion/react */
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useScript from '../../../../hooks/script.hook';
import { selectAppState } from '../../../../store/app/app.selector';
import { REACT_APP_LIQPAY_WIDGET_LIB } from '../../../app.config';

import liqPayStyles from './liqpay-widget.styles';
declare let LiqPayCheckout: any;

type PropTypes = {
    data: string;
    signature: string;
    onSuccess: (id: string) => void;
};

export const LiqpayWidget: FC<PropTypes> = ({ data, signature, onSuccess }) => {
    const status = useScript(`${REACT_APP_LIQPAY_WIDGET_LIB}`, {
        removeOnUnmount: true,
    });
    const { locale } = useSelector(selectAppState);

    useEffect(() => {
        if (status === 'ready') {
            LiqPayCheckout.init({
                data,
                signature,
                embedTo: '#liqpay_checkout',
                language: locale === 'ua' ? 'uk' : locale,
                mode: 'embed',
            })
                .on('liqpay.callback', (data: any) => {
                    onSuccess(data.order_id);
                })
                .on('liqpay.ready', (data: any) => {
                    // ready
                    console.log(data);
                })
                .on('liqpay.close', (data: any) => {
                    // close
                    console.log(data);
                });
        }
    }, [status]);
    return <div css={liqPayStyles.container} id={'liqpay_checkout'}></div>;
};
