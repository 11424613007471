import React, { FC } from 'react';

import styles from './privacy-kk.module.css';

export const PrivacyKkView: FC = () => {
    return (
        <div className={styles.c10}>
            <p className={styles.c6}>
                <span className={styles.c5}>МОБИЛЬДІ ҚОЛДАНБАҒА АРНАЛҒАН ҚҰПИЯЛЫҚ САЯСАТЫ</span>
            </p>
            <p className={styles.c6}>
                <span className={styles.c5}>ADEL CLOUD</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c5}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Adele Cloud мобильді қосымшасы үшін пайдаланушылардың мүдделері бірінші орында.
                    Бұл қағида жеке деректеріңізді жинау мен өңдеуді қоса алғанда, біз қабылдайтын
                    барлық шешімдердің негізінде жатыр.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Біз Құпиялылық саясатын барынша анық және түсінікті етуге тырыстық. Біздің
                    мақсатымыз - сіз, Adele Cloud пайдаланушысы, сіздің жеке өміріңізге қатысты
                    әрқашан сенімді сезіну.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Сеніміңізді сақтау да Адель Cloudтың басымдылықтарының бірі болып табылады. Жеке
                    ақпаратыңыздың құпиялылығын қорғау үшін келесі принциптер қолданылады.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c5}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>  1. ҰҒЫМДАРДЫ АНЫҚТАУ</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c7}>«Заң»</span>
                <span className={styles.c1}>
                     барлық өзгерістер мен толықтырулармен, сондай-ақ Қазақстан Республикасының өзге
                    де заңнамалық актілерімен «Дербес деректер және оларды қорғау туралы» Қазақстан
                    Республикасының Мемлекеттік Заңын білдіреді.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c7}>«Мобильді қосымша»</span>
                <span className={styles.c1}>
                     – смартфондарда, планшеттерде, сағаттарда және басқа мобильді құрылғыларда
                    жұмыс істеуге арналған және белгілі бір платформаға (iOS, Android, Windows Phone
                    және т.б.) әзірленген бағдарламалық құрал (барлық бар толықтырулары мен
                    жақсартулары бар). Осы Саясаттың мақсаттары үшін Мобильді қосымша келесі
                    бағдарламалық құралды білдіреді: Adele Cloud.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c7}>«Жеке деректер»</span>
                <span className={styles.c1}>
                     Пайдаланушының өзі Авторлық құқық иеленушісіне берген және/немесе Авторлық
                    құқық иесі және/немесе үшінші тұлғалар автоматты түрде жинайтын Пайдаланушы
                    туралы жеке деректердің және/немесе жекелендірілмеген ақпараттың жиынтығын
                    білдіреді.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c7}>«Саясат»</span>
                <span className={styles.c1}>
                     осы мобильді қолданбаның құпиялылық саясатын білдіреді (барлық бар толықтырулар
                    мен өзгерістермен).
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c7}>«Пайдаланушы»</span>
                <span className={styles.c1}>
                     Мобильді қосымшаны смартфонға, планшетке, сағатқа немесе кез келген басқа
                    мобильді құрылғыға жүктеп алған және/немесе көрсетілген құрылғылардың бірінде
                    осындай мобильді қосымшаны іске қосқан заңды немесе жеке тұлғаны білдіреді.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c7}>«Пайдаланушы келісімі»</span>
                <span className={styles.c9}>
                     Пайдаланушының мобильді қосымшаны пайдалану тәртібі, ережелері мен
                    ерекшеліктеріне қатысты Авторлық құқық иесі мен Пайдаланушы арасында жасалған
                    келісімді білдіреді. Пайдаланушы мұндай келісімге қосылады және оған қандай да
                    бір өзгерістер мен толықтырулар енгізуге және/немесе талап етуге құқығы жоқ.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c7}>«Авторлық құқық иеленушісі»</span>
                <span className={styles.c1}>
                     мобильді қосымшаға айрықша меншік құқығына ие келесі тұлғаны білдіреді
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Adele Energy Group Ltd.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>БИН: 210240900314</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>ИИК KZ8796503F0010225426 BIC IRTYKZKA</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>«ForteBank» АҚ Астана қаласындағы филиалы</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Банк БСН 990740000683</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Заңды мекенжайы:</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Қазақстан Республикасы, Астана қаласы, Есіл ауданы, Мәңгілік Ел даңғылы, 55/22
                    ғимарат, пошталық индекс Z05T3F5
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Нақты мекен-жайы:</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Қазақстан Республикасы, Астана қаласы, Есіл ауданы, Мәңгілік Ел даңғылы, 55/22,
                    С3.5 блогы, 314 каб.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>пошталық индекс 010020</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Тел.: +7 777 0011350</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>ruslan.dyussenov@adele.energy</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>бас атқарушы директор</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Дүйсенов Руслан Тельманұлы</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c7}>«Cookie файлдары»</span>
                <span className={styles.c1}>
                     кез келген мобильді қосымшаға немесе сайтқа жіберілген және пайдаланушының
                    смартфондарына, планшеттеріне, сағаттарына және басқа мобильді құрылғыларына
                    осындай қолданбалардың немесе сайттардың жұмысын, сондай-ақ олардағы мазмұнның
                    сапасын жақсарту үшін орналастырылған шағын файлдарды білдіреді.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c5}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>2. САЯСАТ ҚАМТАТЫН ҚАТЫНАСТАР</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>Жалпы ережелер</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Осы Саясат мобильді қосымшаны пайдалануына байланысты Пайдаланушыдан алынған
                    Жеке деректерге ғана қолданылады және қолданылады. Осы Саясаттың ережелері
                    мыналарға бағытталған:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (1) алынған Дербес деректердің түрлері мен түрлерін, Дербес деректерді пайдалану
                    (өңдеу) бағыттары мен мақсаттарын, сондай-ақ осындай Дербес деректерді алу
                    көздерін анықтау; Және
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (2) Пайдаланушының өзі берген Дербес деректердің құпиялылығын қорғауға қатысты
                    құқықтарын анықтау; Және
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (3) Дербес деректерді өңдеуге және сақтауға жауапты тұлғаларды, сондай-ақ мұндай
                    деректер ашылатын үшінші тұлғаларды (толық немесе ішінара) анықтау.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Осы Саясаттың ережелері Пайдаланушы өз еркімен берген Дербес деректерді үшінші
                    тұлғалар өңдеген жағдайда қолданылмайды.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Мобильді қосымшаны смартфонға, планшетке, сағатқа немесе басқа мобильді
                    құрылғыға орнату және/немесе іске қосу арқылы Пайдаланушы осы Саясаттың
                    шарттарымен келіседі және Авторлық құқық иеленушісіне Жеке деректерді жинауға,
                    өңдеуге, сақтауға және сақтауға келісімін береді. осы Саясатта көзделген
                    тәртіппен және шарттармен.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Егер Пайдаланушы Саясаттың шарттарымен келіспесе және/немесе Саясаттың кейбір
                    шарттары оған түсініксіз болса, бұл жағдайда Пайдаланушы Мобильді қосымшаны
                    пайдалануды дереу тоқтатуға міндетті.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>Жеке деректерді қорғауға пайдаланушы құқықтары</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Жеке деректерді ұсынуға байланысты Пайдаланушы автоматты түрде келесі құқықтарды
                    алады:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (1) оларды өңдеуге қатысты деректерді (осындай өңдеудің негіздері мен
                    мақсаттары, қолданылатын өңдеу әдістері, оларға рұқсаты бар адамдар туралы
                    ақпарат) алуға немесе олар шарт немесе заң бойынша кімге ашылуы мүмкін).
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (2) Дербес деректерді өңдейтін тұлғалардың орналасқан жері және сәйкестендіру
                    деректері туралы деректерді алуға.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (3) Дербес деректерді сақтау мерзімдері туралы ақпаратты алуға.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (4) Жеке деректердің аяқталған немесе жоспарланған трансшекаралық тасымалдануы
                    туралы ақпаратты алу.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (5) Дербес деректерді сақтайтын тұлғалардың орналасқан жері және сәйкестендіру
                    деректері туралы ақпаратты алуға.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (6) Авторлық құқық иеленушінің әрекетіне немесе әрекетсіздігіне жеке деректер
                    субъектілерінің құқықтарын қорғау жөніндегі уәкілетті органға немесе сотқа
                    шағымдануға.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (7) Авторлық құқық иеленушінің және/немесе үшінші тұлғалардың оның Жеке
                    деректерін қорғауға және қорғауға Пайдаланушының құқықтарын бұзу нәтижесінде
                    келтірілген залалдар және/немесе моральдық залалдың орнын толтыру үшін сот
                    тәртібімен өтемақы алу.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (8) Заңда немесе осы Саясаттың ережелерінде көзделген жеке деректерді қорғау
                    саласындағы өзге де құқықтарды жүзеге асыруға.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>3. ЖИНАҒАН ЖЕКЕ ДЕРЕКТЕР ТІЗІМІ</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>Пайдаланушылар туралы жеке ақпарат тізімі</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Мобильді қосымшаны пайдалануға байланысты Авторлық құқық иесі Пайдаланушы туралы
                    келесі тізім жеке ақпаратты автоматты түрде жинап, өңдей алады:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (1) трафик, шертулердің ықтимал саны, журналдар және басқа деректер туралы
                    ақпарат.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (2) Пайдаланушының орналасқан жері (геолокация) туралы ақпарат. Пайдаланушы кез
                    келген уақытта Мобильді қосымшаға кірген құрылғының параметрлерін өзгерту арқылы
                    геолокацияны өшіре алады. Геолокацияны мобильді қосымша қолданушы мұндай
                    қолданбаны белсенді түрде пайдаланған кезде ғана пайдаланады. Мобильді
                    қолданбадан шыққан кезде геолокация жұмысын тоқтатады.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (3) жүйеге кіріп жатқан құрылғы (сәйкестендіру нөмірі, ұялы байланыс операторы
                    желісі), амалдық жүйе, платформа, шолғыш түрі және шолғыш туралы басқа ақпарат,
                    IP мекенжайы туралы ақпарат.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>Пайдаланушылар туралы жеке деректер</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Пайдаланушы Авторлық құқық иесіне өзі туралы келесі жеке ақпаратты береді:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>1) толық тегі, аты және әкесінің аты.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(2) туған күні мен жасы.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(3) электрондық пошта мекенжайы.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(4) ұялы телефон нөмірі.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(5) Пайдаланушының жынысы.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (6) Пайдаланушының жеке кабинетінде (профильінде) қамтылған деректер,
                    Пайдаланушының барлық ішкі хат-хабарлары (бар болса), сондай-ақ Пайдаланушының
                    жеке кабинетіндегі (профильіндегі) басқа да әрекеттер.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (7) Пайдаланушылар жасаған тапсырыстар/сатып алулар және/немесе мобильді қосымша
                    арқылы алынған/төленген қызметтер туралы деректер.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(8) мынадай деректер:</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>Электрлік автокөлік бренді</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (9) белгілі бір Пайдаланушының жеке деректерін біріктіру нәтижесінде алынған
                    деректер мен ақпарат, сондай-ақ пайдаланушы туралы үшінші тұлғалардан
                    (серіктестер, маркетологтар, зерттеушілер) алынған деректер мен ақпарат.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Пайдаланушы берілген жеке деректердің толықтығына бірден-бір жауапты тұлға болып
                    табылады және оны тұрақты негізде уақтылы өзгертуге (жаңарту, тексеру, түзету)
                    міндетті.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Авторлық құқық иесі Пайдаланушы берген барлық жеке деректер сенімді және
                    Пайдаланушы мұндай ақпаратты жаңартып отырады деп есептейді.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c5}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>Аяқталған транзакциялар туралы ақпарат</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Пайдаланушы Мобильді қосымша арқылы төлем картасы туралы ақпаратты және осындай
                    карта иесінің сәйкестендіру деректерін арнайы өріске енгізу арқылы тауарлар
                    немесе қызметтер үшін төлей алады. Пайдаланушы мобильді қосымшада келесі жолмен
                    төлемдер жасай алады:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>• банк картасы арқылы.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>• Apple Pay төлем жүйесін пайдалану.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>• Google Pay төлем жүйесін пайдалану.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Бұл ретте, Пайдаланушы туралы деректерді жинау және өңдеу тек төлем жасау,
                    алаяқтықтың алдын алу және Заңның басқа талаптарын орындау мақсатында ғана
                    жүзеге асырылады:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Пайдаланушы Авторлық құқық иеленушінің және осындай Дербес деректерге төлем
                    жүргізілетін/осы арқылы/арқылы төлем жүргізілетін банк мекемесінің тиісті төлем
                    жүйесінің немесе банк мекемесінің қол жеткізуіне және жинауына келісімін береді,
                    сондай-ақ тиісті төлем жүйесінің немесе банк мекемесінің құпиялылық саясатымен
                    келіседі.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>Cookie файлдарын пайдалану</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Бұл мобильді қосымша (1) сайтқа кіру және трафик статистикасын жүргізу және (2)
                    пайдаланушы экранында көрсетілетін деректерді жекелендіру, және (3)
                    Пайдаланушыны сәйкестендіру үшін қажетті деректерді сақтау, соның ішінде әртүрлі
                    құрылғылардан кіру кезінде және (4) Пайдаланушының мүдделері мен қалауларына
                    сәйкес жарнаманы көрсету.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Мобильді қосымша авторлық құқық иесіне тиесілі өзінің cookie файлдарын да,
                    үшінші тараптардың cookie файлдарын да пайдалана алады.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Мобильді қолданба келесі cookie файлдарын пайдаланады:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (1) Техникалық (функционалдық) Cookie файлдары, олар трафикті және деректерді
                    беруді бақылау үшін, Пайдаланушыларды анықтау және Пайдаланушыға мобильді
                    қосымшаның мазмұнына қол жеткізуді қамтамасыз ету үшін қажет.{' '}
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Мобильді қосымшаны пайдаланудың функционалдық шектелуін қамтамасыз ету,
                    сондай-ақ Пайдаланушының мүдделеріне сәйкес келмейтін ұсыныстарды беруді
                    болдырмау.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (2) Пайдаланушылардың сайтқа кіру жиілігін қадағалау, Пайдаланушының Мобильді
                    қосымшаны қалай пайдаланатынын анықтау және Пайдаланушы үшін танымал немесе
                    қызықты мазмұн түрі мен түрін анықтау үшін қажет статистикалық cookie файлдары.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (3) Мобильді қолданбада оның құрылғысының экранында көрсетілетін мазмұнды
                    жекелендіру үшін пайдаланушының орналасқан жерін анықтау үшін қажет
                    геолокациялық cookie файлдары.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (4) Пайдаланушының рұқсатымен үшінші тұлғалар орнатқан және Интернеттегі
                    Пайдаланушының мінез-құлқына қатысты статистикалық зерттеулер жүргізуге
                    және/немесе Пайдаланушыға жекелендірілген жарнамалық немесе маркетингтік
                    материалдарды жіберуге және/немесе тауарларды ұсынуға арналған үшінші тарап
                    cookie файлдары немесе қызметтер.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Пайдаланушы кез келген уақытта смартфонында, планшетінде, сағатында немесе басқа
                    мобильді құрылғысында белгілі бір параметрлерді өзгерту арқылы Мобильді
                    қосымшадағы cookie файлдарын өшіруге құқылы. Мұндай өшіру Пайдаланушының
                    мобильді қосымшаның және/немесе мазмұнның функционалдық мүмкіндіктеріне қол
                    жеткізуін шектеуге немесе өзгертуге әкелуі мүмкін.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c5}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>4. ЖЕКЕ ДЕРЕКТЕРДІ ЖИНАУ ЖӘНЕ ӨҢДЕУ МАҚСАТТАРЫ</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c5}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>Өңдеу мақсаттарын анықтау</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Жеке деректерді жинау және өңдеу келесі мақсаттарда жүзеге асырылады:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (1) Пайдаланушының мінез-құлқын талдау, сондай-ақ белгілі бір мазмұн түріне
                    Пайдаланушының қалауларын анықтау.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (2) Мобильді қосымшаның жедел және дұрыс жұмыс істеуі, Мобильді қосымшаның
                    жұмысын жақсарту, Мобильдік қосымшаның мазмұнын жақсарту, Мобильді қосымшаның
                    ішкі архитектурасы мен функционалдығын жақсарту үшін.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(3) Пайдаланушыны анықтау.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (4) Пайдаланушының көрсетілген электрондық пошта мекенжайына және/немесе ұялы
                    телефонына жекелендірілген жарнамалық және маркетингтік материалдарды жіберу.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(5) Заңның талаптарын сақтауға.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (6) мобильді қосымша арқылы пайдаланушы жасаған тапсырыстарды/сатып алуларды
                    қадағалау.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(7) Пайдаланушының орналасқан жерін анықтау.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (8) Мобильді қосымшаға техникалық қолдау көрсету, оның жұмысындағы ақауларды
                    анықтау және оларды жою.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(9) Пайдаланушымен байланыста болу (байланыс).</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (10) Авторлық құқық иеленушінің Пайдаланушы алдында туындаған басқа да
                    міндеттемелерін орындау.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(11) статистикалық зерттеулер жүргізу.</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (12) Пайдаланушының жеке келісімімен кез келген басқа мақсаттар үшін.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Дербес деректерді өңдеу келесі принциптер негізінде жүзеге асырылады: (1) өңдеу
                    мақсаттары мен әдістерінің заңдылығы; және (2) тұтастық; және (3) Дербес
                    деректерді өңдеу мақсаттарының осындай Дербес деректерді жинау кезінде бұрын
                    анықталған және мәлімделген мақсаттарға сәйкестігі; және (4) өңделген Дербес
                    деректердің көлемі мен сипатының оларды өңдеудің белгіленген мақсаттарына
                    сәйкестігі.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>Дербес деректерді өңдеу шарттары</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Дербес деректерді өңдеу келесі жағдайларда жүзеге асырылады:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(1) Пайдаланушыдан келісім алу; немесе</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (2) Авторлық құқық иеленушінің халықаралық шартта немесе заңда көзделген
                    мақсаттарға қол жеткізуі; немесе
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (3) Пайдаланушының жеке деректерін адамдардың шектеусіз санына беруі; немесе
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (4) Авторлық құқық иеленушінің Пайдаланушы алдындағы басқа да міндеттемелерін
                    орындау, оның ішінде, бірақ олармен шектелмей, Пайдаланушыға белгілі бір
                    мазмұнды ұсыну; немесе
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (5) Пайдаланушының жеке деректерін өңдеуге келісімін алдын ала алу мүмкін
                    болмаған кезде оның өмірін немесе денсаулығын сақтау.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Пайдаланушыны тікелей немесе жанама сәйкестендіруге мүмкіндік бермейтін Дербес
                    деректерді иесіздандыру жағдайында мұндай деректерді кейіннен пайдалануға және
                    үшінші тұлғаларға ашуға рұқсат етіледі және оларға осы Саясаттың ережелері енді
                    қолданылмайды.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Авторлық құқық иеленушісі, Пайдаланушы мұндай деректерді жалпыға қолжетімді
                    еткен жағдайларды қоспағанда, алынған Дербес деректердің құпиялылығын қорғау
                    үшін барлық мүмкін шараларды қабылдайды.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Дербес деректерді өңдеу автоматтандыру құралдарын пайдалана отырып және мұндай
                    автоматтандыру құралдарын пайдаланбай жүзеге асырылады.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c5}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>
                    5. АВТОРЛЫҚ ҚҰҚЫҚ ИЕСІНЕ ШАҒЫМДАРЫ МЕН СҰРАҚТАРЫНЫҢ БАҒЫТЫ
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c5}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>Жеке деректерді өңдеуді тоқтатуды сұрау</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Әрбір Пайдаланушы Авторлық құқық иесіне өзінің жеке деректерін өңдеуге
                    және/немесе сақтауға қарсылығын білдіруге құқылы. Мұндай қарсылықты келесідей
                    көрсетуге болады: сұрау Авторлық құқық иеленушінің электрондық пошта мекенжайына
                    pdm@adele.energy «Жеке (жеке) деректерді өңдеуді тоқтату» тақырыбымен жіберілуі
                    керек.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>Жеке деректер туралы ақпаратты сұрау</span>
            </p>
            <p className={styles.c2}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Егер Пайдаланушыда осы Саясатты қолдану немесе пайдалану тәртібіне, Жеке
                    деректерді өңдеу тәртібіне және/немесе әдісіне қатысты сұрақтар туындаса,
                    Пайдаланушы мынадай сұрақ қоя алады: сұрақты Авторлық құқық иеленушінің
                    электрондық поштасына pdm@ жіберу керек. adele.energy тақырып жолы « Жеке (жеке)
                    деректерді өңдеуді тоқтату »
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c5}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>
                    Жеке деректерді өзгерту (жаңарту, қосу, түзету) немесе жою
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Пайдаланушы кез келген уақытта өзінің Жеке деректерін өз бетінше өзгертуге
                    немесе жоюға құқылы, мұндай өзгерту немесе жою мыналарға әкелуі мүмкін
                    жағдайларды қоспағанда
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(1) осы Саясаттың ережелерін бұзу; немесе</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>(2) Заңды бұзу;</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    (3) мұндай Жеке деректердің сипаты Авторлық құқық иесі мен Пайдаланушы арасында
                    туындайтын кез келген сот ісін жүргізуде дәлел болып табылады. Ол үшін
                    Пайдаланушы Мобильді қосымшадағы өзінің жеке кабинетін (профильін) жоюы қажет.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Авторлық құқық иесі кез келген уақытта Пайдаланушының жеке кабинетін/профилін,
                    сондай-ақ Пайдаланушы туралы барлық Жеке деректерді, егер ол осы Саясаттың
                    және/немесе Пайдаланушы келісімінің шарттарын бұзса, жоюға құқылы.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c5}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c7}>6. ЖЕКЕ ДЕРЕКТЕРДІ САҚТАУДЫҢ ШАРТТАРЫ МЕН ТӘРТІБІ</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Сақтауды Авторлық құқық иесінің атынан үшінші тұлғалар жүзеге асырады.
                    Пайдаланушы, мұндай үшінші тұлғалар алынған Дербес деректердің құпиялығын сақтау
                    шартымен, Авторлық құқық иеленушінің атынан үшінші тұлғалардың өзінің Жеке
                    деректерін сақтауға келісімін береді.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Сақтау осы мобильді қосымшаны пайдаланушының бүкіл пайдалану мерзімі ішінде
                    жүзеге асырылады.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Авторлық құқық иесі Пайдаланушы Мобильді қосымшаны пайдалануды тоқтатқаннан
                    кейін өзінің Жеке деректерін дереу жоюға немесе анонимді етуге міндеттенеді.
                </span>
            </p>
            <p className={styles.c2}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c7}>
                    7. КӘМЕЛЕТКЕ ТОЛМАҒАНДАРДЫҢ МОБИЛЬДІ ҚОСЫМШАҒА ҚОСУ
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Мобильді қосымшаны пайдалану 18 жастан асқан адамдарға арналған, олар оған жеке
                    деректерін өңдеуге алдын ала келісімімен ғана қол жеткізе алады. Авторлық құқық
                    иесі Пайдаланушының жасын келесідей тексереді:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    пайдаланушы арнайы терезеде өзінің толық туған күнін енгізуі керек. Егер
                    Пайдаланушы кәмелетке толмаған болса, онда ол осы мобильді қосымшаны пайдалануды
                    дереу тоқтатуы керек.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>8. ЖЕКЕ ДЕРЕКТЕРДІ ҚОРҒАУ ТӘРТІБІ</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Жеке деректердің құпиялылығын қорғау авторлық құқық иеленушінің негізгі және
                    маңызды міндеті болып табылады. Авторлық құқық иесі жеке деректерді қорғау
                    бойынша барлық талап етілетін халықаралық стандарттарды, ережелер мен
                    ұсыныстарды ұстанады.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Авторлық құқық иеленушісі жеке деректерді үшінші тұлғалардың жария етуінен
                    немесе оларға рұқсатсыз кіруден қорғауға бағытталған бірқатар техникалық және
                    ұйымдастырушылық әдістерді енгізді.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c5}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>9. ҚОРЫТЫНДЫ ЕРЕЖЕЛЕР</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c5}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>Қарау үшін саясат мәтінінің қолжетімділігі</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c9}>
                    Пайдаланушылар осы Саясат шарттарын келесі сілтеме бойынша қарай алады:{' '}
                </span>
                <span className={styles.c8}>https://guest.ladergy.com/privacy/adele-cloud/kk</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>Саясатқа өзгертулер мен толықтырулар</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Бұл Саясат мезгіл-мезгіл өзгертілуі мүмкін. Авторлық құқық иесі Пайдаланушының
                    рұқсатынсыз және/немесе келісімінсіз осы Саясат шарттарын өзгерту үшін
                    Пайдаланушы алдында ешқандай жауапкершілік көтермейді.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Пайдаланушы ықтимал өзгерістер немесе толықтырулар үшін осы Саясаттың ережелерін
                    жүйелі түрде тексеруге міндеттенеді. Алайда, осы Саясаттың шарттарында елеулі
                    өзгерістер болған жағдайда, Авторлық құқық иесі Пайдаланушыларды келесі жолмен
                    хабардар етуге міндеттенеді: мобильді қосымшада профильді тіркеу кезінде
                    көрсетілген электрондық пошта мекенжайына барлық пайдаланушыларға автоматты
                    түрде жіберу.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c5}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>Қолданылатын заң</span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Осы Саясат Қазақстан Республикасының жеке деректерді қорғау туралы қолданыстағы
                    заңнамасына, атап айтқанда, «Дербес деректер және оларды қорғау туралы» 2013
                    жылғы 21 мамырдағы № 94-V Мемлекеттік Заңының нормаларына сәйкес әзірленген. »
                    (барлық толықтырулармен және өзгертулермен), «Қазақстан Республикасының кейбір
                    заңнамалық актілеріне сауда қызметін, биржалық сауданы дамыту және дербес
                    деректерді қорғау мәселелері бойынша өзгерістер мен толықтырулар енгізу туралы»
                    2021 жылғы 30 желтоқсандағы № 96-VII Мемлекеттік Заңы. барлық толықтырулар мен
                    өзгертулер).
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c3}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c5}>Ашылу қаупі</span>
            </p>
            <p className={styles.c2}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Алынған жеке деректердің құпиялылығын қорғау үшін Авторлық құқық иеленушінің
                    қабылдаған шараларына қарамастан, Пайдаланушы Интернеттегі Жеке деректердің кез
                    келген тасымалдануының қауіпсіздігіне кепілдік берілмейтінін дұрыс біледі,
                    сондықтан Пайдаланушы мұндай беруді жүзеге асырады. өз тәуекелімен.
                </span>
            </p>
        </div>
    );
};
