/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { LangList } from '../../../components/lang-list/lang-list.component';
import { selectStation } from '../../../../../store/station/station.selector';
import { Menu } from '../../../components/menu/menu.component';

import stationHeaderStyles from './station-header.styles';

export const StationHeader: FC = () => {
    const { entry: station } = useSelector(selectStation);

    const getBusinessTitle = () => {
        return (
            station.business &&
            `${station.business.country}*${station.business.abbreviation}*${station.identity}`
        );
    };

    const renderBusinessCard = () => {
        return (
            <div css={stationHeaderStyles.businessCard}>
                <div css={stationHeaderStyles.businessCardLogo}>
                    {station.business?.abbreviation}
                </div>
                <div css={stationHeaderStyles.businessCardInfo}>
                    <div css={stationHeaderStyles.businessCardInfoTitle}>
                        {station.business?.name}
                    </div>
                    <div>{station.address}</div>
                </div>
            </div>
        );
    };

    return (
        <div css={stationHeaderStyles.container}>
            <div css={stationHeaderStyles.head}>
                <div css={stationHeaderStyles.headTitle}>{getBusinessTitle()}</div>
                <div css={stationHeaderStyles.headActions}>
                    <LangList top={57} />
                    <Menu />
                </div>
            </div>

            {renderBusinessCard()}
        </div>
    );
};
