import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { MainLayoutView } from './layouts/main/main-layout.view';
import { MainView } from './main/main.view';
import { SetUp } from './set-up/set-up.view';
import { Pay } from './pay/pay.view';
import { Charge } from './charge/charge.view';
import { StationLayoutView } from './layouts/station/station-layout.view';
import { TokenPayView } from './token-pay/token-pay.view';
import { PrivacyView } from './docs/privacy.view';
import { OfferView } from './docs/offer.view';
import { DeleteAccountView } from './delete-account/delete-account.view';

const routesConfig: RouteObject[] = [
    {
        element: <MainLayoutView />,
        children: [
            {
                path: '/',
                element: <MainView />,
            },
            {
                path: '/delete-account',
                element: <DeleteAccountView />,
            },
            {
                path: '*',
                element: <Navigate replace to="/" />,
            },
        ],
    },
    {
        element: <StationLayoutView />,
        children: [
            {
                path: '/:stationId',
                element: <SetUp />,
            },
            {
                path: '/:stationId/:connectorId',
                element: <SetUp />,
            },
            {
                path: '/pay/:guestId',
                element: <Pay />,
            },
            {
                path: '/charge/:guestId',
                element: <Charge />,
            },
            {
                path: '*',
                element: <Navigate replace to="/" />,
            },
        ],
    },
    {
        path: '/payment',
        element: <TokenPayView />,
    },
    {
        path: '/privacy/:app/:lang',
        element: <PrivacyView />,
    },
    {
        path: '/offer/:app/:lang',
        element: <OfferView />,
    },
];

export default routesConfig;
