import { css } from '@emotion/react';

const chargeProcessStyles = {
    container: css({
        display: 'flex',
        flexDirection: 'column',
    }),
    containerTitle: css({
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '19px',
        lineHeight: '23px',
        color: '#000000',
        margin: '15px 15px',
    }),
    content: css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    }),
    state: css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
    }),
    statePower: css({
        fontSize: '3.125rem',
        fontWeight: 800,
        textAlign: 'center',
        color: '#101010',
        position: 'relative',
    }),
    statePowerValue: css({
        padding: '0 1rem',
        letterSpacing: '-1.5px',
    }),
    statePowerUnit: css({
        fontSize: '1.5rem',
        fontWeight: 300,
        letterSpacing: '-0.75px',
    }),
    statePowerInfo: css({
        fontSize: '1rem',
        fontWeight: 800,
        color: '#101010',
    }),
};
export default chargeProcessStyles;
