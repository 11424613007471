import { css } from '@emotion/react';

const bankInfoStyles = {
    container: css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '36px',
    }),
};

export default bankInfoStyles;
