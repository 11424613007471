/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { DownloadAppModal } from '../../set-up/components/download-app-modal/download-app-modal.component';

import stationLayoutStyles from './station-layout.styles';
import { StationHeader } from './components/header/station-header.component';
import { StationFooter } from './components/footer/station-footer.component';

export const StationLayoutView: FC = () => {
    return (
        <main>
            <StationHeader />
            <div css={stationLayoutStyles.container}>
                <div css={stationLayoutStyles.outletWrapper}>
                    <Outlet />
                    <DownloadAppModal onClose={() => null} />
                </div>
                <StationFooter />
            </div>
        </main>
    );
};
