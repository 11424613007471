import { css } from '@emotion/react';

const tabsStyles = {
    container: css({}),
    tabList: css({
        display: 'flex',
        flexWrap: 'wrap',
        borderBottom: '2px solid #DDDDDD',
        position: 'relative',
        marginLeft: '20px',
    }),
    tabPanel: css({
        paddingTop: '20px',
    }),
    button: css({
        color: '#000000',
        fontWeight: 600,
        fontSize: '15px',
        lineHeight: '16px',
        letterSpacing: '-0.12px',
        boxShadow: 'none',
        border: 'none',
        backgroundColor: 'inherit',
    }),
    buttonActive: css({
        boxShadow: 'none',
        borderBottom: '2px solid #000000',
        marginBottom: '-2px',
        paddingBottom: '1px',
    }),
};

export default tabsStyles;
