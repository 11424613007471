import { css } from '@emotion/react';

const appStyles = {
    root: css({
        height: '100vh',
        backgroundColor: '#ffffff',
    }),
};

export default appStyles;
