/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { fullFillLink, getDurationInMinutes } from '../../../../utils/utils';
import { IGuest } from '../../../../api/guest/types';
import { ICharge } from '../../../../api/types';

import chargePanelStyles from './info-panel.styles';
import infoPanelStyles from './info-panel.styles';

type PropTypes = {
    guest: IGuest;
    charge: ICharge;
};

export const InfoPanel: FC<PropTypes> = ({ guest, charge }) => {
    const intl = useIntl();

    const block = (
        key: number,
        title: string | number,
        value: string | number,
        unit: string | number
    ) => (
        <div css={chargePanelStyles.infoBlock} key={key}>
            <div css={chargePanelStyles.infoBlockTitle}>{title}</div>
            <div css={chargePanelStyles.infoBlockValue}>
                {value}
                <div css={chargePanelStyles.infoBlockValueSymbol}>{unit}</div>
            </div>
        </div>
    );

    const getConnectorInfo = () => {
        const { station } = guest;
        const connector = station.connectors.find(it => it.id === charge.connectorId);
        return connector
            ? { name: connector.type, power: connector.power.toString() }
            : { name: '', power: '0' };
    };

    const getDuration = () => {
        return getDurationInMinutes(charge.startAt);
    };

    const getStartTime = () => {
        const __ = (_: number) => (`${_}`.length < 2 ? `0${_}` : `${_}`);
        const start = new Date(charge.startAt);
        return `${__(start.getHours())}:${__(start.getMinutes())}`;
    };

    return (
        <div css={chargePanelStyles.row}>
            {[
                [
                    intl.formatMessage({ id: 'duration' }),
                    getDuration(),
                    intl.formatMessage({ id: 'min' }),
                ],
                [
                    intl.formatMessage({ id: 'connector' }),
                    (charge.connectorId < 10 ? 0 : '') + charge.connectorId.toString(),
                    getConnectorInfo().name,
                ],
                [
                    intl.formatMessage({ id: 'consumed' }),
                    `${charge.energy / 1000}`,
                    intl.formatMessage({ id: 'unit.kWh' }),
                ],
                [
                    intl.formatMessage({ id: 'paidEnergy' }),
                    Math.round(guest.energy / 1000),
                    intl.formatMessage({ id: 'unit.kWh' }),
                ],
                [intl.formatMessage({ id: 'rate' }), guest.priceKW, `${guest.currency} / 1kW*h`],
                [intl.formatMessage({ id: 'cost' }), guest.priceTotal, guest.currency],
                [intl.formatMessage({ id: 'startAt' }), getStartTime()],
                [intl.formatMessage({ id: 'startSOC' }), charge.socStart, '%'],
            ].map((value, key) => {
                return block(key, value[0], value[1], value[2]);
            })}
            {guest.business && (
                <div css={infoPanelStyles.businessInfo}>
                    <a
                        href={fullFillLink(guest.business.site) || '#'}
                        target="_blank"
                        rel="noopener noreferrer">
                        {guest.business.name}
                    </a>
                </div>
            )}
        </div>
    );
};
