import { css } from '@emotion/react';

const payStyles = {
    container: css({
        display: 'flex',
        flexDirection: 'column',
    }),
    infoWrapper: css({
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    }),
    info: css({
        display: 'flex',
        flexWrap: 'wrap',
    }),
    infoBlock: css({
        display: 'flex',
        flexGrow: 1,
        flexBasis: 0,
        flexDirection: 'column',
    }),
    infoBlockTitle: css({
        fontWeight: 700,
        fontSize: '10px',
        lineHeight: '10px',
        color: '#000000',
        opacity: 0.5,
    }),
    infoBlockValue: css({
        display: 'flex',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '24px',
        color: '#101010',
        alignItems: 'flex-end',
    }),
    infoBlockValueSymbol: css({
        fontWeight: 700,
        fontSize: '10px',
        lineHeight: '18px',
        color: '#101010',
        paddingLeft: '5px',
    }),
    infoBlockDescription: css({
        color: '#000000',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '20px',
    }),
    actionWrapper: css({
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '22px',
    }),
    termsLink: css({
        textDecoration: 'underline',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '20px',
        color: '#000000',
    }),
};

export default payStyles;
