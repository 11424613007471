/** @jsxImportSource @emotion/react */
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    AppMarketsButtons,
    AppName,
} from '../../../shared/components/app-markets-buttons/app-markets-buttons.component';
import { Button } from '../../../../components/button/button.component';
import { selectStation } from '../../../../store/station/station.selector';
import { AppTheme } from '../../../../app.theme';

import downloadModalStyles from './download-app-modal.styles';

interface PropTypes {
    onClose: () => void;
}

export const DownloadAppModal: FC<PropTypes> = ({ onClose }) => {
    const { downloadAppLiBackgroundColor, downloadAppLiTextColor } = AppTheme();
    const intl = useIntl();
    const { entry: station } = useSelector(selectStation);

    const [isOpen, setIsOpen] = useState(true);

    const closeModal = () => {
        setIsOpen(false);
        onClose();
    };

    return (
        <div css={downloadModalStyles}>
            {isOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <div className="download-app__header">
                            <span className="download-app__header-title">
                                {intl.formatMessage({ id: 'downloadApp.title' })}
                            </span>
                            <span className="close" onClick={closeModal}>
                                &times;
                            </span>
                        </div>
                        <div className="download-app__container">
                            <p className="download-app__container-title">
                                {intl.formatMessage({ id: 'downloadApp.features.title' })}
                            </p>

                            <div className="dowload-app__container-list">
                                <div className="download-app__container-list-item">
                                    <div
                                        className="download-app__container-list-item__number"
                                        style={{
                                            background: downloadAppLiBackgroundColor,
                                            color: downloadAppLiTextColor,
                                        }}>
                                        1.
                                    </div>
                                    <div className="download-app__container-list-item__text">
                                        {intl.formatMessage({
                                            id: 'downloadApp.features.list.item-1',
                                        })}
                                    </div>
                                </div>
                                <div className="download-app__container-list-item">
                                    <div
                                        className="download-app__container-list-item__number"
                                        style={{
                                            background: downloadAppLiBackgroundColor,
                                            color: downloadAppLiTextColor,
                                        }}>
                                        2.
                                    </div>
                                    <div className="download-app__container-list-item__text">
                                        {intl.formatMessage({
                                            id: 'downloadApp.features.list.item-2',
                                        })}
                                    </div>
                                </div>
                                <div className="download-app__container-list-item">
                                    <div
                                        className="download-app__container-list-item__number"
                                        style={{
                                            background: downloadAppLiBackgroundColor,
                                            color: downloadAppLiTextColor,
                                        }}>
                                        3.
                                    </div>
                                    <div className="download-app__container-list-item__text">
                                        {intl.formatMessage({
                                            id: 'downloadApp.features.list.item-3',
                                        })}
                                    </div>
                                </div>
                            </div>

                            <p className="download-app__container-description">
                                {intl.formatMessage({ id: 'downloadApp.features.description' })}
                            </p>
                        </div>

                        <AppMarketsButtons
                            app={
                                station.business &&
                                station.business?.country?.toLowerCase() === 'kz'
                                    ? AppName.ADELE_CLOUD
                                    : AppName.LADERGY
                            }
                        />

                        <Button
                            type={'link'}
                            text={intl.formatMessage({ id: 'downloadApp.action.later' })}
                            onClick={() => setIsOpen(false)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
