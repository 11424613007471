import { css } from '@emotion/react';

const idTagStyles = {
    containerTitle: css({
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '19px',
        lineHeight: '23px',
        color: '#000000',
        margin: '15px 15px',
    }),
    form: css({
        background: '#F6F8FA',
        borderRadius: '5px',
        fontSize: '1.5rem',
        fontWeight: 300,
        textAlign: 'center',
        padding: '0 1rem',
    }),
    input: css({
        background: 'none',
        outline: 'none',
        padding: '1.5rem 0',
        width: '100%',
        fontSize: '1.25rem',
        fontWeight: 300,
        lineHeight: 1.2,
        letterSpacing: '4px',
        border: 'none',
        textAlign: 'center',
    }),
    errorMessage: css({
        transition: '0.2s',
        color: '#f14b4b',
        display: 'flex',
        justifyContent: 'center',
    }),
    actionWrapper: css({
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        marginTop: '50px',
        marginBottom: '30px',
    }),
};

export default idTagStyles;
