import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { post } from '../api-config';

import { ICreateCardTransactionRequest, ICreateCardTransactionResponse } from './types';

export const postCreateCardTransaction = createAsyncThunk(
    'card/createCardTransaction',
    async (
        createCardTransactionData: ICreateCardTransactionRequest,
        { signal, rejectWithValue }
    ) => {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });

        try {
            return await post<ICreateCardTransactionResponse, ICreateCardTransactionRequest>(
                `/card`,
                createCardTransactionData,
                source.token
            );
        } catch ({ response: { data } }) {
            return rejectWithValue(data);
        }
    }
);
