import React, { FC } from 'react';

import styles from './privacy-en.module.css';

export const LadergyPrivacyEnView: FC = () => {
    return (
        <div className={styles.c9}>
            <p className={styles.c7}>
                <span className={styles.c0}>PRIVACY POLICY FOR THE MOBILE APPLICATION LADERGY</span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c3}>
                    For the Ladergy mobile application, user interests are paramount. This principle
                    underlies all the decisions we make, including the collection and processing of
                    your personal data. We&#39;ve strived to make the Privacy Policy as clear and
                    transparent as possible. Our goal is for you, as a Ladergy user, to always feel
                    confident when it comes to privacy.
                </span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c3}>
                    Preserving your trust is also one of Ladergy&#39;s priorities. The following
                    principles are used to protect the confidentiality of your personal data.
                </span>
            </p>
            <p className={styles.c8 + ' ' + styles.c13}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c7 + ' ' + styles.c13}>
                <span className={styles.c0}>1. DEFINITION OF TERMS</span>
            </p>
            <p className={styles.c8 + ' ' + styles.c13}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c12}>&laquo;Law&raquo;</span>
                <span className={styles.c3}>
                    &nbsp;refers to the State Law of Ukraine &quot;On Personal Data and Their
                    Protection&quot; with all amendments and other legislative acts of Ukraine.
                </span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c12}>&laquo;Mobile Application&raquo;</span>
                <span className={styles.c3}>
                    &nbsp;is software (with all existing additions and improvements) designed to
                    work on smartphones, tablets, watches, and other mobile devices, developed for a
                    specific platform (iOS, Android, Windows Phone, etc.). For the purposes of this
                    Policy, the Mobile Application refers to the following software: Ladergy.
                </span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c12}>&laquo;Personal Data&raquo;</span>
                <span className={styles.c3}>
                    &nbsp;means a set of personal and/or non-personal information about the User,
                    which they provide to the Owner themselves and/or is automatically collected by
                    the Owner and/or third parties.
                </span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c12}>&laquo;Policy&raquo;</span>
                <span className={styles.c3}>
                    &nbsp;refers to this Privacy Policy of the mobile application (with all existing
                    additions and changes).
                </span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c12}>&laquo;User&raquo;</span>
                <span className={styles.c3}>
                    &nbsp;means a legal or natural person who has downloaded the Mobile Application
                    to a smartphone, tablet, watch, or any other mobile device and/or has activated
                    such Mobile Application on one of the specified devices.
                </span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c12}>&laquo;User Agreement&raquo;</span>
                <span>
                    &nbsp;means the agreement concluded between the Owner and the User regarding the
                    procedure, rules, and features of the User&#39;s use of the Mobile Application.
                    The User agrees to such an agreement and has no right to make or demand any
                    changes or additions to it.
                </span>
                <span className={styles.c3}>.</span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c12}>&laquo;Owner&raquo;</span>
                <span className={styles.c3}>
                    &nbsp;means the person to whom exclusive ownership rights of the Mobile
                    Application belong.
                </span>
            </p>
            <p className={styles.c8 + ' ' + styles.c13}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c3}>Owner Details:</span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c3}>FOP Stal Iryna Viktorivna</span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c3}>3324109961</span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c3}>UA893052990000026003050029451</span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c3}>AT KB &quot;PRIVATBANK&quot;</span>
            </p>
            <p className={styles.c8 + ' ' + styles.c13}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c4}>
                <span className={styles.c12}>&laquo;Cookie Files&raquo;</span>
                <span className={styles.c3}>
                    &nbsp;means small files sent by any mobile applications or websites and placed
                    on the User&#39;s smartphones, tablets, watches, and other mobile devices to
                    enhance the functionality of such applications or websites, as well as the
                    quality of the content hosted on them.
                </span>
            </p>
            <p className={styles.c2}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>2. RELATIONSHIPS COVERED BY THE POLICY</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>From a general position</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    This Policy is used and applied exclusively to Personal Data received from the
                    User in connection with the use of the Mobile Application. The provisions of
                    this Policy are aimed at:
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(1) </span>
                <span className={styles.c3}>
                    determination of types and types of received Personal Data, directions and
                    purposes of use (processing) of Personal Data, as well as sources of obtaining
                    such Personal Data;
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(2) </span>
                <span className={styles.c3}>
                    determination of the User&#39;s rights to protect the confidentiality of the
                    Personal Data provided to them; and
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(3) </span>
                <span className={styles.c3}>
                    identification of persons responsible for the processing and storage of personal
                    data, as well as third parties to whom such data is disclosed (in whole or in
                    part).
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The rules of this Policy do not apply in case of processing by third parties of
                    Personal data voluntarily provided by the User.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    By installing and/or activating the Mobile Application on a smartphone, tablet,
                    watch or other mobile device, the User agrees to the terms of this Policy and
                    gives his consent to the Rightholder for the collection, processing, maintenance
                    and storage of Personal Data in the manner and under the conditions stipulated
                    by this Policy.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    If the User does not agree with the terms of the Policy and/or individual terms
                    of the Policy are not clear to him, in this case the User is obliged to
                    immediately stop using the Mobile Application.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>Personal data protection user rights</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    In connection with the provision of Personal Data, the User automatically
                    receives the following rights:
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(1) </span>
                <span className={styles.c3}>
                    receive data regarding their processing (grounds and purposes of such
                    processing, applied processing methods, information about persons who have
                    access to them or to whom they may be disclosed on the basis of a contract or
                    the Law).
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(2) </span>
                <span className={styles.c3}>
                    receive data on the location and identification data of the persons processing
                    the Personal Data.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(3) </span>
                <span className={styles.c3}>
                    receive data on the period of storage of personal data.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(4) </span>
                <span className={styles.c3}>
                    receive data on the completed or anticipated cross-border transfer of Personal
                    Data.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(5) </span>
                <span className={styles.c3}>
                    receive information about the location and identification data of the persons
                    who carry out the storage of Personal Data.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(6) </span>
                <span className={styles.c3}>
                    appeal the actions or inaction of the Rightholder to the authorized body for the
                    protection of the rights of personal data subjects or in court.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(7) </span>
                <span className={styles.c3}>
                    receive compensation for damages and/or compensation for moral damages in a
                    court of law as a result of violations of the User&#39;s rights to the
                    protection and protection of his Personal Data committed by the Rightholder
                    and/or third parties.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(8) </span>
                <span className={styles.c3}>
                    exercise other rights in the field of personal data protection provided by the
                    Law or provisions of this Policy.
                </span>
            </p>
            <p className={styles.c2}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>3. LIST OF PERSONAL DATA TO BE COLLECTED</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>Non-personalized information about users</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    In connection with the use of the Mobile Application, the Rightholder may
                    automatically collect and process the following non-personalized information
                    about the User:
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(1) </span>
                <span className={styles.c3}>
                    traffic information, possible number of clicks , logs and other data.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(2) </span>
                <span className={styles.c3}>
                    information about the location of the User (geolocation). The user can disable
                    geolocation at any time by changing the settings of the device from which the
                    entry to the Mobile application was made. Geolocation is used by the Mobile
                    application only when the User actively uses such an application. When you exit
                    the mobile application, geolocation stops functioning.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(3) </span>
                <span className={styles.c3}>
                    information about the device (identification number, network of the mobile
                    operator) from which the login is performed, operating system, platform, browser
                    type and other information about the browser, IP address.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>Personal data about users</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The user provides the right holder with the following personal data:
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(1) </span>
                <span className={styles.c3}>full surname, first name and patronymic.</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(2) </span>
                <span className={styles.c3}>date of birth and age.</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(3) </span>
                <span className={styles.c3}>email address.</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(4) </span>
                <span className={styles.c3}>mobile phone number.</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(5) the User&#39;s </span>
                <span className={styles.c3}>gender .</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(6) </span>
                <span className={styles.c3}>
                    data contained in the User&#39;s personal account (profile), all internal
                    correspondence of the User (if any), as well as other activity of the User&#39;s
                    personal account (profile).
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(7) </span>
                <span className={styles.c3}>
                    data on orders/purchases made by the User and /or received/paid services through
                    the Mobile Application.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(8) </span>
                <span className={styles.c3}>m ark of an electric car .</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(9) </span>
                <span className={styles.c3}>
                    data and information obtained as a result of combining certain personal data of
                    a specific User, as well as data and information obtained about the User from
                    third parties (partners, marketers, researchers).
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The user is the only person responsible for the completeness of the personal
                    (personal) data provided, and is obliged to change them in a timely manner
                    (update, check, correct) on a regular basis.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The Rightholder assumes that all personal (personal) data provided by the User
                    are reliable and that the User keeps such information up-to-date.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>Information about transactions carried out</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The user can pay for goods or services through the Mobile application by
                    entering information about the payment card and identification data of the owner
                    of such card in a special field. The user can pay in the Mobile application in
                    the following way:
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>&bull; using a bank card.</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>&bull; using the Apple payment system Pay .</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>&bull; using the Google payment system Pay .</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The collection and processing of data about the User in this case is carried out
                    exclusively for the purpose of payment, prevention of fraud, as well as
                    compliance with other requirements of the Law:
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The User consents to the access and collection of such Personal Data by the
                    Rightholder and the relevant payment system or banking institution through which
                    the payment is made, and also agrees to the privacy policy of the relevant
                    payment system or banking institution.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>Use of cookies</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    This Mobile Application uses certain Cookies to save the IP address, preferences
                    of the Users or the type of device used for the purpose of{' '}
                </span>
                <span className={styles.c0}>(1) </span>
                <span className={styles.c3}>
                    keeping statistics of site visits and traffic, and{' '}
                </span>
                <span className={styles.c0}>(2) </span>
                <span className={styles.c3}>
                    personalizing the data displayed on the User&#39;s screen, and{' '}
                </span>
                <span className={styles.c0}>(3) </span>
                <span className={styles.c3}>
                    saving the data necessary to identify the User in including when accessed from
                    different devices, and{' '}
                </span>
                <span className={styles.c0}>(4) </span>
                <span className={styles.c3}>
                    displaying advertisements in accordance with the User&#39;s interests and
                    preferences.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The mobile application can use both its own Cookies belonging to the Rightholder
                    and Cookies third parties.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The mobile application uses the following cookies :
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(1) </span>
                <span className={styles.c3}>
                    Technical (functional) cookies , which are required for traffic control and data
                    transfer, to identify Users and provide access to the content of the Mobile
                    Application and without which the use of the Mobile Application is functionally
                    limited, as well as to prevent the provision of recommendations that do not
                    correspond interests of the User.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(2) </span>
                <span className={styles.c3}>
                    Statistical Cookies, which are required to track the frequency of visits to the
                    site by Users, to identify the ways in which the User uses the Mobile
                    Application, as well as to identify the type and type of content that is popular
                    or interesting for the User.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(3) </span>
                <span className={styles.c3}>
                    Geolocation Cookies, which are required to determine the location of the User in
                    order to personalize the content displayed on the screen of his device in the
                    Mobile Application.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(4) </span>
                <span className={styles.c3}>
                    Third-party cookies, which are set by third parties with the User&#39;s
                    permission and are intended to conduct statistical research on the User&#39;s
                    behavior on the Internet and/or the direction of personalized advertising or
                    marketing materials to the User and/or the provision of goods or services.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    cookies at any time in the Mobile Application by changing certain settings on
                    your smartphone, tablet, watch or other mobile device. Such disconnection may
                    limit or change the User&#39;s access to the functionality of the Mobile
                    Application and/or content.
                </span>
            </p>
            <p className={styles.c2}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>
                    4. PURPOSES OF PERSONAL DATA COLLECTION AND PROCESSING
                </span>
            </p>
            <p className={styles.c8}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c15}>
                <span className={styles.c0}>Definition of processing purposes</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The collection and processing of Personal Data is carried out for the following
                    purposes:
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(1) </span>
                <span className={styles.c3}>
                    to analyze the User&#39;s behavior, as well as identify the User&#39;s
                    preferences for a certain type of content.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(2) </span>
                <span className={styles.c3}>
                    for the prompt and correct operation of the Mobile Application, improving the
                    operation of the Mobile Application, improving the content of the Mobile
                    Application, improving the internal architecture and functionality of the Mobile
                    Application.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(3) </span>
                <span className={styles.c3}>to identify the User.</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(4) </span>
                <span className={styles.c3}>
                    to send personalized advertising and marketing materials to the specified email
                    address and/or mobile phone of the User.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(5) </span>
                <span className={styles.c3}>to comply with the requirements of the Law.</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(6) </span>
                <span className={styles.c3}>
                    to track orders/purchases made by the User through the Mobile Application.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(7) </span>
                <span className={styles.c3}>determining the location of the User.</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(8) </span>
                <span className={styles.c3}>
                    for technical support of the Mobile Application, detection of problems in its
                    operation and their elimination.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(9) </span>
                <span className={styles.c3}>
                    maintaining communication with the User (communication).
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(10) </span>
                <span className={styles.c3}>
                    to fulfill other obligations of the Rightholder that have arisen before the
                    User.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(11) </span>
                <span className={styles.c3}>regarding statistical studies.</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(12) </span>
                <span className={styles.c3}>
                    for any other purposes, subject to separate consent from the User.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    Processing of Personal data is carried out on the basis of the following
                    principles:{' '}
                </span>
                <span className={styles.c0}>(1) </span>
                <span className={styles.c3}>
                    legality of purposes and methods of processing; and{' '}
                </span>
                <span className={styles.c0}>(2) </span>
                <span className={styles.c3}>good faith; and </span>
                <span className={styles.c0}>(3) </span>
                <span className={styles.c3}>
                    compliance of the purposes of processing Personal Data with the purposes
                    previously determined and declared when such Personal Data were collected; and{' '}
                </span>
                <span className={styles.c0}>(4) </span>
                <span className={styles.c3}>
                    conformity of the scope and nature of the processed personal data to the
                    declared purposes of their processing.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>Terms of personal data processing</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    Personal data is processed in the following cases:
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(1) </span>
                <span className={styles.c3}>obtaining consent from the User; or</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(2) </span>
                <span className={styles.c3}>
                    achievement by the Rightholder of the goals stipulated by the international
                    agreement or the Law; or
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(3) </span>
                <span className={styles.c3}>
                    provision by the User of his Personal Data to an unlimited number of persons ;
                    or
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(4) </span>
                <span className={styles.c3}>
                    fulfillment of other obligations of the Rightholder to the User, including, but
                    not limited to, providing certain content to the User; or
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(5) </span>
                <span className={styles.c3}>
                    saving the User&#39;s life or health, when consent to the processing of his
                    Personal Data cannot be obtained in advance.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    In case of depersonalization of Personal data, which does not allow directly or
                    indirectly to identify the User, further use and disclosure of such data to
                    third parties is allowed and the rules of this Policy no longer apply to them.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The right holder takes all possible measures to protect the confidentiality of
                    received personal data, except for cases where the User has made such data
                    publicly available.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    Processing of Personal Data is carried out with the use of automation tools and
                    without the use of such automation tools.
                </span>
            </p>
            <p className={styles.c2}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>
                    5. DIRECTION OF COMPLAINTS AND REQUESTS TO THE RIGHT OWNER
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>Request to stop processing personal data</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    Each User has the right to express his objection to the Rightholder against the
                    processing and storage of his Personal Data. Such an objection can be expressed
                    as follows: the request should be sent to the Rightholder&#39;s mail at the
                    address hello @ladergy . _ com with the subject of the letter &quot;Stopping the
                    processing of personal (personal) data&quot; .
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>Request for information on personal data</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    If the User has questions related to the procedure for applying or using this
                    Policy, the procedure and/or method of processing Personal Data, the User can
                    ask such a question as follows: the question should be sent to the
                    Rightholder&#39;s mail at the address hello @ladergy . _ com with the subject of
                    the letter &quot;Stopping the processing of personal (personal) data&quot; .
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>
                    Changing (updating, supplementing, correcting) or deleting personal data
                </span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The user has the right to independently change or delete his personal data at
                    any time, except when such change or deletion may result in :
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(1) </span>
                <span className={styles.c3}>to violate the rules of this Policy; or</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(2) </span>
                <span className={styles.c3}>before violating the Law;</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c0}>(3) </span>
                <span className={styles.c3}>
                    the nature of such Personal Data is evidence in any legal proceedings arising
                    between the Rightholder and the User. For this, the User needs to delete his
                    personal account (profile) in the Mobile application.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The Rightholder has the right to delete the User&#39;s personal account/profile
                    at any time, as well as all Personal Data about the User, if he has violated the
                    terms of this Policy and/or the User Agreement.
                </span>
            </p>
            <p className={styles.c2}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>6. PERSONAL DATA STORAGE TERMS AND PROCEDURE</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c0}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    Storage is carried out by third parties on behalf of the right holder. The user
                    gives his consent to the storage of his personal data by third parties on behalf
                    of the right holder, provided that such third parties preserve the
                    confidentiality of the received personal data.
                </span>
            </p>
            <p className={styles.c14}>
                <span className={styles.c3}>
                    Storage is carried out during the entire period of use of this Mobile
                    application by the User.
                </span>
            </p>
            <p className={styles.c14}>
                <span className={styles.c3}>
                    The Rightholder undertakes to destroy or anonymize his Personal Data immediately
                    after the User stops using the Mobile Application.
                </span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c2}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>7. ACCESS OF MINORS TO THE MOBILE APPLICATION</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c0}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The use of the Mobile Application is intended for persons over the age of 18,
                    who gain access to it only if they provide their prior consent to the processing
                    of their Personal Data. The right holder checks the age of the User as follows:
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    the user will need to confirm familiarization with the privacy policy in a
                    special window. If the User is a minor, he must immediately stop using this
                    Mobile Application.
                </span>
            </p>
            <p className={styles.c2}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>8. PERSONAL DATA PROTECTION PROCEDURE</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    Protecting the confidentiality of Personal Data is a primary and important task
                    for the Rightholder. The right holder adheres to all necessary international
                    standards, rules and recommendations regarding the protection of personal data.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The right holder has implemented a number of technical and organizational
                    methods aimed at protecting personal data from disclosure or unauthorized access
                    to them by third parties.
                </span>
            </p>
            <p className={styles.c2}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>9. Final provisions</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>Availability of the text of the policy for review</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    Users can familiarize themselves with the terms of this Policy at the following
                    link:{' '}
                </span>
                <span className={styles.c10 + ' ' + styles.c11}>
                    https://guest.ladergy.com/privacy/ladergy/en
                </span>
                <span className={styles.c3}>.</span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>Changes and additions to the policy</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    This Policy may be changed from time to time. The Rightholder bears no
                    responsibility to the User for changing the terms of this Policy without the
                    permission and/or consent of the User.
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    The user undertakes to check the provisions of this Policy on a regular basis
                    for possible changes or additions. However, in the event of a significant change
                    to the terms of this Policy, the Rightholder undertakes to notify Users in the
                    following manner: automatic mailing to all users to the email address specified
                    when registering a profile in the mobile application .
                </span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    <br />
                </span>
                <span className={styles.c0}>Risk of disclosure</span>
            </p>
            <p className={styles.c1}>
                <span className={styles.c3}></span>
            </p>
            <p className={styles.c5}>
                <span className={styles.c3}>
                    Regardless of the measures taken by the Rightholder to protect the
                    confidentiality of received personal data, the User is hereby deemed duly
                    informed that any transmission of Personal Data on the Internet cannot be
                    guaranteed to be secure, and therefore the User carries out such transmission at
                    his own risk.
                </span>
            </p>
        </div>
    );
};
