import { css } from '@emotion/react';

const mainHeaderStyle = {
    container: css({
        position: 'relative',
        zIndex: 999,
    }),
    content: css({
        display: 'flex',
        padding: '15px 20px',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '600px',
        margin: '0 auto',
        boxSizing: 'border-box',
    }),
    logo: css({}),
    stationName: css({
        flexGrow: 1,
        fontWeight: 300,
        textAlign: 'right',
        padding: '0 1rem',
    }),
    headActions: css({
        display: 'flex',
        gap: '20px',
    }),
};

export default mainHeaderStyle;
