/** @jsxImportSource @emotion/react */
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { useApp } from '../../../app.hook';
import { selectAppState } from '../../../../store/app/app.selector';
import { AppTheme } from '../../../../app.theme';

import langListStyle from './lang-list.styles';

type PropTypes = {
    top?: number;
    color?: 'white' | 'black';
};

export const LangList: FC<PropTypes> = ({ top, color = 'white' }) => {
    const { languageSelectorTextColor, languageNames } = AppTheme();
    const intl = useIntl();
    const { appLocale } = useApp();
    const { locale } = useSelector(selectAppState);
    const [langListToggled, setLangListToggled] = useState(false);

    const toggleLangList = () => {
        setLangListToggled(prevState => {
            return !prevState;
        });
    };

    const handleChangeLanguage = (lang: string) => {
        appLocale(lang).then(() => toggleLangList());
    };

    return (
        <>
            <button
                css={[
                    langListStyle.buttonLang,
                    color === 'white'
                        ? langListStyle.buttonLangWhite
                        : langListStyle.buttonLangBlack,
                ]}
                onClick={toggleLangList}>
                {locale}
            </button>
            <div
                css={langListStyle.langList}
                style={{ height: langListToggled ? 'auto' : '0', top: top ? `${top}px` : 0 }}>
                {languageNames.map(lang => (
                    <div
                        css={[
                            langListStyle.langListItem,
                            lang === locale && {
                                fontWeight: 'bold',
                                color: languageSelectorTextColor,
                            },
                        ]}
                        onClick={() => handleChangeLanguage(lang)}
                        key={lang}>
                        {intl.formatMessage({ id: lang })}
                    </div>
                ))}
            </div>
        </>
    );
};
