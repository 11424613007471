import { css } from '@emotion/react';

const buttonStyles = {
    button: css({
        borderRadius: '9px',
        color: '#000000',
        fontWeight: 600,
        fontSize: '15px',
        lineHeight: '16px',
        letterSpacing: '-0.12px',
        cursor: 'pointer',
    }),
    key: css({
        height: '42px',
        width: '42px',
        border: 'solid 1px #EDEDED',
        backgroundColor: '#EDEDED',
        padding: '12px',
        boxShadow: 'none',
    }),
    link: css({
        boxShadow: 'none',
        border: 'none',
        backgroundColor: 'inherit',
    }),
    black: css({
        border: 'none',
        color: '#FFFFFF',
        backgroundColor: '#000000',
        padding: '12px 25px',
    }),
    primary: css({
        border: 'none',
        padding: '12px 25px',
    }),
    disabled: css({
        backgroundColor: '#C7C3A720',
        border: 'solid 1px #C7C3A720',
    }),
};

export default buttonStyles;
