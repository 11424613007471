/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';

import { AppTheme } from '../../../../app.theme';

import poweredByStyles from './powered-by.styles';

export const PoweredBy: FC = () => {
    const { logo, showPoweredBy } = AppTheme();

    const renderPoweredBy = () => {
        if (showPoweredBy) {
            return <div css={poweredByStyles.poweredByText}>Powered by Ladergy Software GmbH</div>;
        }
    };

    return (
        <>
            <div css={poweredByStyles.poweredBy}>
                <img height={23} src={logo} alt={'Ladergy logo'} />
                {renderPoweredBy()}
            </div>
        </>
    );
};
