import { useDispatch } from 'react-redux';

import { ICreateGuestRequest } from '../api/guest/types';
import { getGuestById, postCreateGuest } from '../api/guest/guest.api';
import { actionClearGuest } from '../store/guest/guest.slice';

export const useGuest = (): {
    createGuest: (createGuestData: ICreateGuestRequest) => Promise<void>;
    fetchGuestById: (guestId: string) => Promise<void>;
    clearGuest: () => Promise<void>;
} => {
    const dispatch = useDispatch();

    const createGuest = async (createGuestData: ICreateGuestRequest): Promise<void> => {
        await dispatch(postCreateGuest(createGuestData));
    };

    const fetchGuestById = async (guestId: string): Promise<void> => {
        await dispatch(getGuestById(guestId));
    };

    const clearGuest = async (): Promise<void> => {
        await dispatch(actionClearGuest());
    };

    return {
        createGuest,
        clearGuest,
        fetchGuestById,
    };
};
