import { createSlice } from '@reduxjs/toolkit';

import { IApiError } from '../../api/types';
import { IGuest } from '../../api/guest/types';
import { getGuestById, postCreateGuest } from '../../api/guest/guest.api';

export interface IGuestState {
    entry: IGuest;
    loading: boolean;
    error: IApiError;
    created: boolean;
    deleted: boolean;
}

const initialState: IGuestState = {
    entry: <IGuest>{},
    loading: true,
    error: <IApiError>{},
    created: false,
    deleted: false,
};

const guestSlice = createSlice({
    name: 'guestItem',
    initialState,
    reducers: {
        actionClearGuest: (state): void => {
            state.entry = initialState.entry;
            state.error = initialState.error;
            state.loading = true;
            state.created = false;
            state.deleted = false;
        },
    },
    extraReducers: builder => {
        // CREATE GUEST
        builder.addCase(postCreateGuest.pending, state => {
            state.loading = true;
            state.error = initialState.error;
            state.created = false;
            state.deleted = false;
        });
        builder.addCase(postCreateGuest.fulfilled, (state, { payload }) => {
            state.entry = payload;
            state.loading = false;
            state.error = initialState.error;
            state.created = true;
            state.deleted = false;
        });
        builder.addCase(postCreateGuest.rejected, (state, { payload }) => {
            state.entry = initialState.entry;
            state.error = <IApiError>payload;
            state.loading = false;
            state.created = false;
            state.deleted = false;
        });

        // GET GUEST
        builder.addCase(getGuestById.pending, state => {
            state.loading = true;
            state.error = initialState.error;
            state.created = false;
            state.deleted = false;
        });
        builder.addCase(getGuestById.fulfilled, (state, { payload }) => {
            state.entry = payload;
            state.loading = false;
            state.error = initialState.error;
            state.created = false;
            state.deleted = false;
        });
        builder.addCase(getGuestById.rejected, (state, { payload }) => {
            state.entry = initialState.entry;
            state.error = <IApiError>payload;
            state.loading = false;
            state.created = false;
            state.deleted = false;
        });
    },
});

export const { actionClearGuest } = guestSlice.actions;
export const guestReducer = guestSlice.reducer;
