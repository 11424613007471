import { css } from '@emotion/react';

const setUpStyles = {
    nextButton: css({
        borderRadius: '5px',
        boxShadow: '0 1px 8px 0 rgba(26, 26, 26, 0.2)',
        backgroundColor: '#FFF300',
        position: 'absolute',
        left: '50%',
        fontSize: '1.75rem',
        fontWeight: 'bold',
        color: '#101010',
        padding: '1rem 3rem 1rem 1rem',
        zIndex: 99,
        ':after': {
            content: '"〉"',
            position: 'absolute',
            right: 0,
            top: 0,
            height: '100%',
            width: '2rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }),
    errorMessage: css({
        transition: '0.2s',
        color: '#f14b4b',
        display: 'flex',
        justifyContent: 'center',
    }),
};

export default setUpStyles;
