/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { IStation } from '../../../../api/station/types';
import arrowRight from '../../../../assets/icons/arrow-right.svg';
import lightning from '../../../../assets/icons/lightning.svg';
import { AppTheme } from '../../../../app.theme';
import { IconCharging } from '../../../../assets/icons/icon-charging';

import stationItemStyles from './station-item.styles';

type PropTypes = {
    station: IStation;
    onClick?: () => void;
};

export const StationItem: FC<PropTypes> = ({ station, onClick }) => {
    const { connectorsBadgeColor, connectorsBadgeTextColor, connectorsBadgeIconColor } = AppTheme();
    const intl = useIntl();
    const getBusinessTitle = () => {
        return (
            station.business &&
            `${station.business.country}*${station.business.abbreviation}*${station.identity}`
        );
    };

    const getDistance = () => {
        if (station.distance < 1000) {
            return station.distance.toFixed(0) + intl.formatMessage({ id: 'unit.m' });
        } else {
            return (station.distance / 1000).toFixed(2) + intl.formatMessage({ id: 'unit.km' });
        }
    };

    const getMinMaxPower = () => {
        if (station.connectors.length === 0) {
            return 0;
        }
        const min = station.connectors.reduce((prev, curr) =>
            prev.maxPower < curr.maxPower ? prev : curr
        );
        const max = station.connectors.reduce((prev, curr) =>
            prev.maxPower > curr.maxPower ? prev : curr
        );

        if (min.maxPower === max.maxPower) {
            return min.maxPower;
        } else {
            return `${min.maxPower} - ${max.maxPower}`;
        }
    };

    const renderPowerBadge = () => {
        const power = getMinMaxPower();

        if (power) {
            return (
                <div css={stationItemStyles.powerBadge}>
                    <img src={lightning} width={15} height={15} alt="Ladergy" />
                    {power} {intl.formatMessage({ id: 'unit.kW' })}
                </div>
            );
        }

        return null;
    };

    return (
        <div css={stationItemStyles.container} onClick={onClick}>
            <div css={stationItemStyles.infoBlock}>
                <div css={stationItemStyles.infoBlockBadges}>
                    {renderPowerBadge()}
                    <div
                        css={[
                            stationItemStyles.connectorsBadge,
                            { background: connectorsBadgeColor, color: connectorsBadgeTextColor },
                        ]}>
                        <IconCharging color={connectorsBadgeIconColor} size={12} />

                        {station.connectors.length}
                    </div>
                </div>
                <div css={stationItemStyles.subtitle}>{station.business.name}</div>
                <div css={stationItemStyles.title}>{station.address}</div>
                <div css={stationItemStyles.subtitle}>
                    {getDistance()} - {getBusinessTitle()}
                </div>
            </div>
            <div css={stationItemStyles.infoAction}>
                <div css={stationItemStyles.abbreviation}>{station.business.abbreviation}</div>
                <img src={arrowRight} width={15} height={15} alt="Ladergy" />
            </div>
        </div>
    );
};
