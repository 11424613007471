/** @jsxImportSource @emotion/react */
import React, { ForwardRefRenderFunction, InputHTMLAttributes, ReactElement } from 'react';

import inputStyles from './input.styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    ref?: string;
    icon?: ReactElement;
}

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
    { name, label, icon, ...otherProps },
    ref
) => {
    return (
        <label css={inputStyles.label}>
            {label}
            <div css={inputStyles.icon}>{icon}</div>
            <input css={inputStyles.input} {...otherProps} name={name} ref={ref} />
        </label>
    );
};

const FormInput = React.forwardRef(Input);

export default FormInput;
