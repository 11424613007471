import React, { FC } from 'react';

//import styles from './offer-ru.module.css';
import './offer-ru.css';

export const OfferRuView: FC = () => {
    return (
        <div className="c40 doc-content">
            <h2 className="c99">
                <span className="c4">Договор оферты (пользовательское соглашение)</span>
            </h2>
            <p className="c139">
                <span className="c4">
                    оказания услуг по зарядке электрического транспортного средства Редакция No 1 от
                    «10» января 2023 г.
                </span>
            </p>
            <p className="c29" id="h.1fob9te">
                <span className="c0">
                    Настоящий Договор оферты адресовано Пользователям, что является официальным и
                    публичным в соответствии со статьи 395 Гражданского кодекса Республики
                    Казахстан. Договор оферты оказания услуг по зарядке электрического транспортного
                    средства (далее — «Договор оферты») устанавливает условия использования
                    веб-приложения (мобильного приложения) (далее – «Приложение») «Adele Cloud» и
                    сервиса «Ladergy.com» для зарядки электрического транспортного средства и
                    заключено на условиях, изложенных ниже между дееспособным и правоспособным лицом
                    (Пользователем), использующим веб- приложение(мобильное приложение)
                    правообладателем которых является Частная Компания Adele Energy Group ltd. и
                    владельцем объекта зарядной инфраструктуры, (далее именуемое, «Исполнитель»)
                    совместно именуемыми Сторонами, а по отдельности – Сторона.
                </span>
            </p>
            <p className="c2">
                <span className="c0"></span>
            </p>
            <ol className="c21 lst-kix_list_1-0 start" start={1}>
                <li className="c141 li-bullet-0">
                    <h1 style={{ display: 'inline' }}>
                        <span className="c4">ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</span>
                    </h1>
                </li>
            </ol>
            <p className="c61">
                <span className="c0">
                    В настоящей оферте используются следующие термины и определения:
                </span>
            </p>
            <ol className="c21 lst-kix_list_1-1 start" start={1}>
                <li className="c71 c78">
                    <span className="c4">Услуга по зарядки </span>
                    <span className="c0">
                        – услуги по зарядки электрических транспортных средств по заказу
                        Пользователя, оказываемые Исполнителем в порядке, описанном в настоящем
                        Договоре оферты.
                    </span>
                </li>
                <li className="c49">
                    <span className="c4">Сайт </span>
                    <span className="c0">
                        – официальный сайт ЧК «Adele Energy Group ltd.», доступный по следующей
                        ссылке в сети Интернет: https://guest.ladergy.com/. Все права в отношении
                        Сайта принадлежат ЧК «Adele Energy Group ltd.».
                    </span>
                </li>
                <li className="c51 c71">
                    <span className="c4">Продавец </span>
                    <span className="c0">
                        - ЧК «Adele Energy Group ltd.», зарегистрированное по адресу: Республика
                        Казахстан, город Астана, район Есиль, Проспект Мангилик Ел, здание 55/22, и
                        аффилированные с ним юридические лица, продающие Товары и/или Услуги через
                        Сайт.
                    </span>
                </li>
                <li className="c102 c71 c110">
                    <span className="c4">Исполнитель </span>
                    <span className="c0">
                        – зарегистрированное в предусмотренном законодательством РК порядке
                        юридическое лицо или индивидуальный предприниматель, управляющее объектом
                        зарядной инфраструктуры, и являющееся непосредственным исполнителем Услуги
                        по зарядке электрических транспортных средств. Исполнитель поручил ЧК «Adele
                        Energy Group ltd.» от имени и за счет Исполнителя осуществлять поиск и
                        привлечение потенциальных Пользователей и заключать с Пользователями
                        договоры оказания услуг по зарядке электрических транспортных средств.
                    </span>
                </li>
                <li className="c68">
                    <span className="c4">Пользователь </span>
                    <span className="c0">
                        – дееспособное физическое лицо либо сотрудник Корпоративного клиент,
                        заключившее настоящее Договор оферты в предусмотренном порядке.
                    </span>
                </li>
                <li className="c45">
                    <span className="c4">Корпоративный клиент </span>
                    <span className="c0">
                        – зарегистрированное в предусмотренном законодательством РК порядке
                        юридическое лицо или индивидуальный предприниматель, заключившее настоящий
                        Договор оферты. Корпоративный клиент заключает настоящий Договор оферты с
                        целью использования Приложения и Сервиса своими сотрудниками.
                    </span>
                </li>
                <li className="c13">
                    <span className="c4">Служба Поддержки </span>
                    <span className="c0">
                        - внутренняя служба и специалисты ЧК «Adele Energy Group ltd.», или третьи
                        лица, привлеченные ЧК «Adele Energy Group ltd.», осуществляющие
                        консультирование Пользователя по вопросам использования Приложения и Сервиса
                        в порядке, предусмотренном настоящим Договором оферты, а также
                        осуществляющие иные действия, предусмотренные настоящим Договором оферты.
                    </span>
                </li>
                <li className="c51 c71">
                    <span className="c4">Агрегатор электронных рассылок </span>
                    <span className="c0">
                        – зарегистрированное в предусмотренном законодательством РК порядке
                        юридическое лицо или индивидуальный предприниматель, заключившее с ЧК «Adele
                        Energy Group ltd.» соответствующее соглашение о выполнении отправки
                        сообщений о порядке оказания услуг или сообщений рекламного характера
                        Пользователям Сервиса.
                    </span>
                </li>
                <li className="c110 c102 c71">
                    <span className="c4">Приложение </span>
                    <span className="c0">
                        - программа для ЭВМ и для мобильных устройств « Adele Cloud» (включая все
                        обновления и улучшения), предназначенная для использования Пользователями
                        для целей размещения Заявок в порядке, предусмотренном настоящим Договором
                        оферты.
                    </span>
                </li>
                <li className="c13">
                    <span className="c4">Сервис </span>
                    <span className="c0">
                        - программный комплекс, имеющий наименование «Adele Cloud» и осуществляющий
                        функции по приёму, обработке, хранению, формированию, изменению и передаче
                        Заявок, направлению Исполнителю информации о размещённой Заявке, внесению,
                        хранению, изменению, удалению, обработке и формированию консолидированных
                        сведений о объектах зарядной инфраструктуры, обеспечению консультационного,
                        технологического и информационного взаимодействия между пользователями,
                        формированию и выгрузке статистических сведений (количество Заявок,
                        стоимость Заявок, статусы Заявок и т.д.), отображению статусов объектов
                        зарядной инфраструктуры в режиме реального времени.
                    </span>
                </li>
                <li className="c45">
                    <span className="c4">Заявка </span>
                    <span className="c0">
                        - информация о потенциальном спросе Пользователя на Услуги по зарядке
                        электрических транспортных средств, размещенная Пользователем в Приложении в
                        порядке, предусмотренном настоящим Договором оферты.
                    </span>
                </li>
                <li className="c71 c100">
                    <span className="c4">Аккаунт </span>
                    <span className="c0">
                        – персональный раздел Пользователя в Приложении, создаваемый в порядке,
                        предусмотренном Договором оферты, содержащий информацию о Пользователе, об
                        использовании Пользователем Сервиса, а также иную информацию,
                        предусмотренную функционалом Приложения.
                    </span>
                </li>
                <li className="c71 c113">
                    <span className="c4">ЭЗС </span>
                    <span className="c0">
                        – электрическая зарядная станция, предназначенная для оказания услуг по
                        зарядке электрических транспортных средств.
                    </span>
                </li>
                <li className="c128 c71 c155">
                    <span className="c4">Оферта </span>
                    <span className="c0">
                        — настоящее пользовательское соглашение, то есть публичное предложение
                        Продавца, адресованное неограниченному кругу физических и юридических лиц, о
                        заключении договора купли- продажи товаров и/или услуг.
                    </span>
                </li>
                <li className="c3">
                    <span className="c4">Акцепт </span>
                    <span className="c0">
                        — полное и безоговорочное принятие Пользователем от своего имени, а также от
                        лица тех, кто будет использовать его аккаунт, условий настоящей Оферты путем
                        регистрации в качестве пользователя на Сайте.
                    </span>
                </li>
                <li className="c57">
                    <span className="c4">Холдирование </span>
                    <span className="c0">
                        — операция оплаты, при которой сумма платежа, оплачиваемая Пользователем при
                        заявке в Приложении, на первой стадии резервируется (холдируется) на счете,
                        к которому выпущена банковская карта Пользователя, а на второй стадии, после
                        подтверждения Заказа сторонами и пересчета суммы, списывается со счета
                        Пользователя, а разница, если таковая имеется, возвращается Пользователю.
                    </span>
                </li>
                <li className="c71 c91">
                    <span className="c4">Фактическая стоимость </span>
                    <span className="c0">
                        — окончательная стоимость Заказа, рассчитанная исходя из конечного веса и
                        цены товаров на момент начала осуществления доставки Пользователю.
                    </span>
                </li>
                <li className="c53">
                    <span className="c4">Персональные данные </span>
                    <span className="c0">
                        - означает совокупность личных данных и/или неперсонифицированной информации
                        о Пользователе, предоставляемую им самим Правообладателю и/или автоматически
                        собираемую Правообладателем и/или третьими лицами.
                    </span>
                </li>
                <li className="c13">
                    <span className="c4">Файлы куки (cookies) </span>
                    <span className="c0">
                        - означает небольшие файлы, отправляемые каким-либо мобильным (web)
                        приложениям или сайтом, и размещаемые на смартфонах, планшетах, часах и
                        других мобильных устройствах Пользователя, для улучшения работы таких
                        приложений или сайтов, а также качества размещенного в них контента.
                    </span>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-0" start={2}>
                <li className="c31 li-bullet-1">
                    <h1 style={{ display: 'inline' }}>
                        <span className="c4">ОБЩИЕ ПОЛОЖЕНИЯ</span>
                    </h1>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-1 start" start={1}>
                <li className="c71 c92">
                    <span className="c0">
                        В соответствии с настоящим соглашением Пользователь через Приложение
                        размещает Заявку на Услугу по зарядке электрического транспортного средства
                        на выбранной ЭЗС Исполнителя.
                    </span>
                </li>
                <li className="c71 c118">
                    <span className="c0">
                        Исполнитель        оказывает
                        Услугу        по        зарядке        электрического
                        транспортного        средства Пользователя на выбранной ЭЗС.
                    </span>
                </li>
                <li className="c76 c71 c102">
                    <span className="c0">
                        Пользователь оплачивает Услугу по зарядке электрического транспортного
                        средства путём использования функционала Приложения, описанного в данном
                        соглашении.
                    </span>
                </li>
            </ol>
            <p className="c38">
                <span className="c0"></span>
            </p>
            <ol className="c21 lst-kix_list_1-0" start={3}>
                <li className="c46 li-bullet-1">
                    <h1 style={{ display: 'inline' }}>
                        <span className="c4">ФУНКЦИОНАЛ ПРИЛОЖЕНИЯ И ОПИСАНИЕ СЕРВИСА</span>
                    </h1>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-1 start" start={1}>
                <li className="c56">
                    <span className="c0">
                        Посредством использования Приложения Пользователь получает доступ к Сервису.
                        Сервис является информационным сервисом и предлагает Пользователю
                        возможность разместить информацию (разместить Заявку) о потенциальном спросе
                        Пользователя на Услуги по зарядке электрических транспортных средств, а
                        также возможность ознакомиться с информацией о предложениях Исполнителя,
                        оказывающего Услуги по зарядке электрических транспортных средств, и
                        осуществить поиск таких предложений по заданным Пользователем параметрам.
                    </span>
                </li>
                <li className="c103 c71">
                    <span className="c0">
                        Перечень Исполнителей, оказывающих Услуги по зарядке электрических
                        транспортных средств представлен в Приложении №1 к настоящему Договору
                        оферты.
                    </span>
                </li>
                <li className="c64">
                    <span className="c0">
                        В различных ситуациях (включая, но не ограничиваясь, регион местонахождения
                        Пользователя, технические неисправности, распространение функций в рамках
                        тестирования на ограниченный круг Пользователей) все или некоторые функции
                        Приложения могут быть недоступны или ограничены для использования
                        Пользователем. Функции считаются недоступными (ограниченными) для
                        Пользователя, если такой Пользователь не может ими воспользоваться
                        посредством использования Приложения. Применение любых технических и
                        программных методов обхода данных ограничений запрещено. Положения
                        настоящего Договора оферты, регулирующие недоступные (ограниченные) для
                        Пользователя функции Приложения, не применяются до тех пор, пока
                        Пользователю такие функции не станут доступны посредством использования
                        Приложения.
                    </span>
                </li>
                <li className="c18">
                    <span className="c0">
                        При отсутствии у Пользователя возможности использовать Приложение или
                        получить доступ к Сервису иным способом, указанным в п. 3.1 Договора оферты
                        (включая, но не ограничиваясь, при отсутствии доступа к сети Интернет, при
                        проблемах в использовании Приложения) Пользователь может обратиться в Службу
                        Поддержки за помощью в использовании Сервиса. Положения настоящего Договора
                        оферты распространяются на обращения Пользователя в Службу Поддержки для
                        целей оформления Заявки в той мере, в которой это применимо. Все разговоры
                        Пользователя со специалистами Службы Поддержки, а также с Исполнителем могут
                        быть записаны в целях контроля качества и совершенствования обслуживания.
                    </span>
                </li>
                <li className="c66">
                    <span className="c0">
                        Посредством использования Приложения Пользователь может получить информацию
                        об Исполнителях,  оказывающих  Услуги  по  зарядке  электрических
                         транспортных  средств. ЧК «Adele Energy Group ltd.» не обязана проверять
                        полноту и достоверность предоставленной информации, а также ЧК «Adele Energy
                        Group ltd.» не изменяет предоставленную Исполнителем информацию. Перечень
                        Исполнителей доступен на Сайте. Получение Пользователем более подробной
                        информации об
                    </span>
                </li>
            </ol>
            <p className="c23">
                <span className="c0">
                    Исполнителе и предлагаемых ими Услугах по зарядке электрических транспортных
                    средств может быть осуществлено Пользователем путем самостоятельного обращения к
                    Исполнителю.
                </span>
            </p>
            <ol className="c21 lst-kix_list_1-1" start={6}>
                <li className="c53">
                    <span className="c0">
                        В рамках использования Сервиса Пользователю могут предлагаться к
                        использованию сервисы третьих лиц, являющихся партнерами ЧК «Adele Energy
                        Group ltd.» (далее - «Сервисы третьих лиц»). Использование таких Сервисов
                        третьих лиц осуществляется на основании заключения между Пользователем и
                        партнером ЧК «Adele Energy Group ltd.» соответствующих пользовательских
                        соглашений. В случае, если такие услуги или их часть оказываются посредством
                        Приложения «Adele Cloud», Пользователь знакомится с соглашением о
                        присоединении к пользовательскому соглашению Сервиса третьих лиц в
                        Приложении и обязан принять его условия прежде, чем начать пользование услуг
                        партнера ЧК «AdeleEnergy Group ltd.».
                    </span>
                </li>
                <li className="c24">
                    <span className="c0">
                        Для информирования Пользователя о порядке и ходе оказания услуг ЧК «Adele
                        Energy Group ltd.» в праве привлекать Агрегаторов электронных рассылок.
                    </span>
                </li>
            </ol>
            <p className="c5">
                <span className="c0"></span>
            </p>
            <ol className="c21 lst-kix_list_1-0" start={4}>
                <li className="c28 li-bullet-1">
                    <h1 style={{ display: 'inline' }}>
                        <span className="c4">
                            ИСПОЛЬЗОВАНИЕ ПОЛЬЗОВАТЕЛЕМ ПРИЛОЖЕНИЯ И ПОРЯДОК ПРИСОЕДИНЕНИЯ К
                            НАСТОЯЩЕМУ ДОГОВОРУ
                        </span>
                    </h1>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-1 start" start={1}>
                <li className="c45">
                    <span className="c0">
                        Для целей использования Приложения, Пользователь обязан указать в Приложении
                        свой номер мобильного телефона. Указанные данные должны быть полными,
                        точными и достоверные.
                    </span>
                </li>
                <li className="c60">
                    <span className="c0">
                        После указания номера мобильного телефона Пользователь получает
                        SMS-сообщение со ссылкой для отслеживания и управления процессом зарядки.
                    </span>
                </li>
                <li className="c71 c82">
                    <span className="c0">
                        После подтверждения принадлежности указанного номера мобильного телефона
                        Пользователь считается зарегистрировавшимся в Приложении, и в Приложении
                        формируется Аккаунт Пользователя с присвоением Аккаунту уникального
                        идентификационного номера.
                    </span>
                </li>
                <li className="c110 c102 c71">
                    <span className="c0">
                        Пользователь понимает и соглашается, что все действия, совершенные с
                        использованием Аккаунта Пользователя, считаются совершенными Пользователем.
                        Пользователь обязан обеспечить конфиденциальность своих регистрационных
                        данных и ограничить для третьих лиц доступ к персональному мобильному
                        устройства Пользователя. Пользователь самостоятельно несет риск
                        возникновения неблагоприятных последствий, вызванных нарушением
                        конфиденциальности регистрационных данных Пользователя и/или предоставлением
                        третьим лицам доступа к персональному мобильному устройству Пользователя.
                    </span>
                </li>
                <li className="c67">
                    <span className="c0">
                        Акцепт настоящего Договора оферты осуществляется путём действий в
                        Приложении, в результате которых начинается зарядка электрического
                        транспортного средства.
                    </span>
                </li>
                <li className="c45">
                    <span className="c0">
                        Акцепт настоящего Договора оферты означает согласие Пользователя на
                        обработку персональных данных, согласно Приложению №2 к настоящему Договору
                        оферты.
                    </span>
                </li>
                <li className="c137 li-bullet-2">
                    <span className="c0">
                        Удаление Аккаунта может быть осуществлено в следующих случаях:
                    </span>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-2 start" start={1}>
                <li className="c110 c123 c71">
                    <span className="c0">
                        По усмотрению Исполнителя, если Пользователем подана заявка на удаление
                        Аккаунта, при этом Исполнитель может отказать в удалении Аккаунта без
                        объяснения причин. В случае, если Исполнитель отказал в удалении Аккаунта он
                        имеется право осуществить техническое ограничение доступа к личным данным
                        Пользователя, сохранив Аккаунт и личные данные Пользователя на серверах
                        Исполнителя;
                    </span>
                </li>
                <li className="c6">
                    <span className="c0">
                        По усмотрению Исполнителя в отношении Пользователя, пользующегося
                        исключительно активированными данными и командами.
                    </span>
                </li>
                <li className="c51 c71">
                    <span className="c0">
                        В случае неоднократного или существенного нарушения Пользователем условий
                        настоящего Соглашения, признание того или иного нарушения существенным
                        осуществляется Исполнителем самостоятельно на основании оценки конкретных
                        обстоятельств нарушения. К существенным нарушениям, в том числе, могут быть
                        отнесены действия, осуществление которых запрещено разделом 11 настоящего
                        Соглашения или нарушения, не устраненные Пользователем в срок после
                        получения от Исполнителя соответствующего требования и др.
                    </span>
                </li>
                <li className="c48 li-bullet-3">
                    <span className="c0">
                        Исполнитель имеет право не объяснять причины удаления Аккаунта.
                    </span>
                </li>
            </ol>
            <p className="c105">
                <span className="c0"></span>
            </p>
            <ol className="c21 lst-kix_list_1-0" start={5}>
                <li className="c73 li-bullet-4">
                    <h1 style={{ display: 'inline' }}>
                        <span className="c4">ПОРЯДОК ОКАЗАНИЯ УСЛУГ ИСПОЛНИТЕЛЕМ</span>
                    </h1>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-1 start" start={1}>
                <li className="c7">
                    <span className="c0">
                        Услуги по зарядке, Заявки на которые были размещены Пользователем
                        посредством использования Приложения или иным способом, предусмотренным
                        настоящим Договором оферты, непосредственно оказываются Исполнителем.
                        Указанные в настоящем Договоре оферты условия оказания Услуг по зарядке
                        Исполнителя доводятся до сведения Пользователя по заказу Исполнителя.
                    </span>
                </li>
                <li className="c50">
                    <span className="c0">
                        ЧК «Adele Energy Group ltd.» не является владельцем зарядных станций для
                        оказания услуг по зарядке, посредством Сервиса заключает от имени
                        Исполнителя договор с Пользователем на Услуги по зарядке. ЧК «Adele Energy
                        Group ltd.» является владельцем агрегатора информации о услугах по зарядке.
                    </span>
                </li>
                <li className="c51 c71">
                    <span className="c0">
                        Размещение Пользователем Заявки не является принятием ЧК «Adele Energy Group
                        ltd.» к выполнению заказа Пользователя на оказание Услуг по зарядке.
                        Принятие к выполнению заказа Пользователя на оказание Услуг по зарядке в
                        соответствии с условиями Заявки осуществляется Исполнителем и считается
                        состоявшимся в момент, когда до сведения Исполнителя посредством интерфейса
                        Приложения доводится информация о принятии Исполнителем.
                    </span>
                </li>
                <li className="c102 c71 c132">
                    <span className="c0">
                        ЧК «Adele Energy Group ltd.» не гарантирует Пользователю, что размещенная
                        Пользователем Заявка будет принята к выполнению Исполнителем. ЧК «Adele
                        Energy Group ltd.» не гарантирует, что принятая Исполнителем Заявка будет
                        выполнена Исполнителем и не несет ответственность за неоказание Услуг по
                        зарядке по Заявкам, принятым Исполнителем.
                    </span>
                </li>
                <li className="c71 c104">
                    <span className="c0">
                        Стоимость Услуг по зарядке устанавливается Исполнителем, но не может
                        превышать тарифы, указанные в Приложении, а исключением случаев, указанных в
                        настоящем Договоре оферты. Стоимость Услуг по зарядке определяется исходя из
                        выбранного Исполнителем тарифа. Стоимость Услуг по зарядке может быть
                        изменена в случаях, указанных в настоящем Договоре оферты.
                    </span>
                </li>
                <li className="c13">
                    <span className="c0">
                        В случае отказа Пользователя от оказания Услуг по зарядке после момента
                        запуска сессии бронирования в соответствии с условиями Заявки (о чем
                        Пользователь информируется посредством функционала Приложения или
                        СМС-сообщения), а также в иных случаях, указанных на Приложении, ЧК
                    </span>
                </li>
            </ol>
            <p className="c127 c128">
                <span className="c0">
                    «Adele Energy Group ltd.», действуя по поручению Исполнителя, взимает с такого
                    Пользователя фактически понесенные Исполнителем расходы, которые могут
                    рассчитываться как указанная в Приложении минимальная стоимость Услуги по
                    зарядке по выбранному тарифу или иным способом, указанным на Сайте, что не
                    является платой Пользователя за отказ от исполнения договора.
                </span>
            </p>
            <ol className="c21 lst-kix_list_1-1" start={7}>
                <li className="c45">
                    <span className="c0">
                        ЧК «Adele Energy Group ltd.» по заказу Исполнителя доводит до сведения
                        Пользователя правила предоставления Исполнителем Услуг по зарядке.
                    </span>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-0" start={6}>
                <li className="c34 li-bullet-4">
                    <h1 style={{ display: 'inline' }}>
                        <span className="c4">ОПЛАТА УСЛУГ ПО ЗАРЯДКЕ ПОЛЬЗОВАТЕЛЯМИ</span>
                    </h1>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-1 start" start={1}>
                <li className="c142 c102 c71">
                    <span className="c0">
                        Для целей возможности использования Пользователя безналичного способа
                        оплаты, Пользователь обязан указать свою банковскую карту в порядке,
                        предусмотренном настоящим Договором оферты, с использованием функционала
                        Приложения (далее – «Привязанная банковская карта»).
                    </span>
                </li>
                <li className="c128 c71 c133">
                    <span className="c0">
                        Создание Привязанной банковской карты производится Пользователем посредством
                        сервиса уполномоченного оператора по приему платежей, страница которого
                        открывается в Приложении. Пользователь обязуется указать следующие данные,
                        используя функционал Приложения 1) номер банковской карты; 2) срок действия;
                        3) защитный код (CVC/CVV).
                    </span>
                </li>
                <li className="c71 c151">
                    <span className="c0">
                        После указания данных банковской карты на счете Привязанной банковской карты
                        осуществляется резервация (холдирование) в размере суммы, указанной в
                        Приложении, а разница, если таковая имеется, возвращается Пользователю. Если
                        данные банковской карты полны и достоверны и использование данной карты
                        технически возможно, указанная Пользователем банковская карта
                        рассматривается в рамках настоящего Договора оферты в качестве Привязанной
                        банковской карты и может быть использована для безналичного способа оплаты.
                    </span>
                </li>
                <li className="c47">
                    <span className="c0">
                        ЧК «Adele Energy Group ltd.» не имеет доступа к указанным Пользователем в
                        сервисе уполномоченного оператора по приему платежей данным Привязанной
                        банковской карты и не несет ответственности за сохранность и
                        конфиденциальность передаваемых данных при проведении оплаты безналичным
                        способом оплаты. Оплата безналичным способом оплаты осуществляется
                        Пользователем с участием уполномоченного оператора по приему платежей, или
                        оператора электронных денежных средств, или иных участников расчетов,
                        информационно- технологического взаимодействия, и регулируется правилами
                        международных платежных систем, банков (в том числе банка-эмитента
                        Привязанной банковской карты) и других участников расчетов.
                    </span>
                </li>
                <li className="c41">
                    <span className="c0">
                        При указании своих данных банковской карты, как указано в п. 6.2. Договора
                        оферты и дальнейшем использовании Привязанной карты, Пользователь
                        подтверждает и гарантирует указание им достоверной и полной информации о
                        банковской карте, выданной на его имя; соблюдение им правил международных
                        платежных систем и требований банка-эмитента, выпустившего банковскую карту,
                        в том числе в отношении порядка проведения безналичных расчетов.
                    </span>
                </li>
                <li className="c71 c114">
                    <span className="c0">
                        Пользователь понимает и соглашается, что все действия, совершенные в рамках
                        использования Приложения с использованием подтвержденного им номера
                        мобильного телефона, в том числе по оплате безналичным способом оплаты с
                        использованием Привязанной банковской карты, считаются совершенными
                        Пользователем.
                    </span>
                </li>
                <li className="c10">
                    <span className="c0">
                        В случае несогласия Пользователя с фактом и/или суммой оплаты безналичным
                        способом оплаты и по иным связанным с использованием Привязанной карты в
                        рамках использования Сервиса вопросам Пользователь вправе обратиться в
                        Службу Поддержки в течение 14 (четырнадцати) календарных дней со дня
                        совершения безналичного способа оплаты. В случае если по результатам
                        проверки по такому обращению ЧК «Adele Energy Group ltd.» принимает решение
                        о возврате суммы оплаты безналичным способом оплаты полностью или частично,
                        указанный возврат осуществляется на банковский счет Привязанной банковской
                        карты, с которой была произведена оплата безналичным способом оплаты.
                        Порядок возврата денежных средств осуществляется с участием уполномоченного
                        оператора по приему платежей или оператора электронных денежных средств, или
                        иных участников расчетов, информационно- технологического взаимодействия, и
                        регулируется правилами международных платежных систем, банков (в том числе
                        банка-эмитента Привязанной банковской карты) и других участников расчетов.
                        По истечении
                    </span>
                </li>
            </ol>
            <p className="c119">
                <span className="c0">
                    14 (четырнадцати) календарных дней со дня совершения безналичного способа оплаты
                    обращения о
                </span>
            </p>
            <p className="c96">
                <span className="c0">
                    несогласии Пользователя с фактом и/или суммой оплаты безналичным способом оплаты
                    и по иным связанным с использованием Привязанной карты в рамках использования
                    Сервиса вопросам не принимаются.
                </span>
            </p>
            <ol className="c21 lst-kix_list_1-1" start={8}>
                <li className="c41">
                    <span className="c0">
                        ЧК «Adele Energy Group ltd.» оставляет за собой право в любой момент
                        потребовать от Пользователя подтверждения данных, указанных им при заведении
                        Привязанной банковской карты, и запросить, в связи с этим подтверждающие
                        документы (в частности, документы, удостоверяющие личность),
                        непредоставление которых может быть приравнено к предоставлению
                        недостоверной информации и повлечь ограничение доступа Пользователя к
                        использованию Приложения.
                    </span>
                </li>
                <li className="c71 c124">
                    <span className="c0">
                        По заказу Исполнителя ЧК «Adele Energy Group ltd.» доводит до сведения
                        Пользователей информацию о порядке оплаты Услуг по зарядке, оказанных
                        Исполнителем. Оплата Услуги по зарядке в рамках настоящего Договора оферты
                        может быть произведена безналичным способом путем использования Привязанной
                        банковской карты или путем использования платежного приложения (Apple Pay,
                        Google Pay и иные, если доступно), или с помощью сервиса «KaspiPay» (если
                        доступно) в соответствии с условиями поставщика платежного приложения (далее
                        – «Безналичный способ оплаты»). В случае оплаты Пользователем оказываемых
                        Исполнителем Услуг по зарядке безналичным способом оплаты, ЧК «Adele Energy
                        Group ltd.» действует по поручению, от имени и за счёт соответствующего
                        Исполнителя с привлечением уполномоченного оператора по приему платежей, или
                        оператора электронных денежных средств, или иных участников расчетов,
                        информационно-технологического взаимодействия, и является получателем
                        платежа в качестве агента Исполнителя.
                    </span>
                </li>
                <li className="c71 c89">
                    <span className="c0">
                        При этом Пользователь принимает во внимание, что способ оплаты безналичным
                        способом с помощью сервиса «KaspiPay» возможен исключительно с помощью
                        доступа Пользователя к приложению
                    </span>
                </li>
            </ol>
            <p className="c74">
                <span className="c0">«Kaspi.kz».</span>
            </p>
            <ol className="c21 lst-kix_list_1-1" start={11}>
                <li className="c59">
                    <span className="c0">
                        ЧК «Adele Energy Group ltd.» не гарантирует отсутствия ошибок и сбоев при
                        безналичном способе оплаты.
                    </span>
                </li>
                <li className="c95 li-bullet-5">
                    <h2 style={{ display: 'inline' }}>
                        <span className="c4">Возврат денежных средств</span>
                    </h2>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-2 start" start={1}>
                <li className="c7">
                    <span className="c0">
                        При проведении онлайн-оплаты посредством платежных карт не допускается
                        возврат наличными денежными средствами. Порядок возврата регулируется
                        правилами международных платежных систем:
                    </span>
                </li>
            </ol>
            <ul className="c21 lst-kix_list_2-0 start">
                <li className="c72 li-bullet-6">
                    <span className="c0">
                        Потребитель вправе отказаться от товара в любое время до его передачи, после
                        передачи товара отказ необходимо оформить в течение 14 дней;
                    </span>
                </li>
                <li className="c63 li-bullet-7">
                    <span className="c0">
                        Потребитель не вправе отказаться от товара надлежащего качества, имеющего
                        индивидуально- определенные свойства, если указанный товар может быть
                        использован исключительно приобретающим его человеком;
                    </span>
                </li>
                <li className="c140 li-bullet-8">
                    <span className="c0">
                        При отказе от товара со стороны потребителя продавец должен вернуть ему
                        денежную сумму, уплаченную потребителем, не позднее чем через десять дней со
                        дня предъявления потребителем соответствующего требования.
                    </span>
                </li>
            </ul>
            <ol className="c21 lst-kix_list_1-2 start" start={1}>
                <li className="c119 c71 c123">
                    <span className="c0">
                        Возврат денежных средств будет осуществлен на банковскую карту в течение 15
                        рабочего дня со дня получения «Заявление о возврате денежных средств»
                        Исполнителем, при условии подтверждением Пользователя о непредоставление
                        товара и услуг Исполнителем.
                    </span>
                </li>
                <li className="c25">
                    <span className="c0">
                        Для возврата денежных средств на банковскую карту необходимо заполнить
                        «Заявление о возврате денежных средств», которое высылается по требованию
                        компанией на электронный адрес, и отправить его вместе с приложением копии
                        документа, удостоверяющего личность, по адресу{' '}
                    </span>
                    <span className="c0">
                        <a className="c20" href="mailto:info@adele.energy">
                            info@adele.energy
                        </a>
                    </span>
                </li>
                <li className="c71 c79">
                    <span className="c0">
                        В случае положительного ответа в пользу Пользователя, сумма возврата будет
                        равняться сумме покупки. Срок рассмотрения Заявления и возврата денежных
                        средств начинает исчисляться с момента получения Исполнителем Заявления и
                        рассчитывается в рабочих днях без учета праздников/выходных дней.
                    </span>
                </li>
            </ol>
            <p className="c90">
                <span className="c0"></span>
            </p>
            <ol className="c21 lst-kix_list_1-0" start={7}>
                <li className="c16 li-bullet-1">
                    <h2 style={{ display: 'inline' }}>
                        <span className="c4">ПРАВА И ОБЯЗАННОСТИ ЧК «Adele Energy Group ltd.»</span>
                    </h2>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-1 start" start={1}>
                <li className="c81 li-bullet-9">
                    <span className="c4">ЧК «Adele Energy Group ltd.» имеет право:</span>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-2 start" start={1}>
                <li className="c47">
                    <span className="c0">
                        В любое время в одностороннем порядке ограничить, расширить, изменить
                        содержание Приложения и Сервиса, а также изменить и модифицировать
                        Приложение и Сервис без предварительного уведомления Пользователя;
                    </span>
                </li>
                <li className="c47">
                    <span className="c0">
                        В целях сбора статистических данных и идентификации Пользователя
                        устанавливать и сохранять информацию об IP-адресах Пользователя,
                        использовать файлы технической информации (cookies), размещаемые на
                        персональном мобильном устройстве или персональном компьютере Пользователя;
                    </span>
                </li>
                <li className="c123 c71 c129">
                    <span className="c0">
                        Во время использования Пользователем Сервиса предупреждать, уведомлять,
                        информировать о несоблюдении Пользователем условий настоящего Договора
                        оферты;
                    </span>
                </li>
                <li className="c37">
                    <span className="c0">
                        Направлять Пользователю сообщения технического и информационного характера,
                        касающиеся процесса использования Приложения и Сервиса и/или оказания Услуг
                        по зарядке
                    </span>
                </li>
            </ol>
            <p className="c150">
                <span className="c0">
                    Исполнителем (последнее осуществляется по заказу Исполнителя) посредством
                    функционала Приложения и посредством SMS-сообщений по номеру телефона,
                    указанному Пользователем при регистрации, и сообщений на электронную почту;
                </span>
            </p>
            <ol className="c21 lst-kix_list_1-2" start={5}>
                <li className="c27">
                    <span className="c0">
                        Самостоятельно или с привлечением третьих лиц связываться с Пользователем по
                        предоставленному номеру мобильного телефона или адресу электронной почты для
                        целей проведения исследований для целей улучшения качества Приложения и
                        Сервиса. При этом Пользователь может отказаться от участия в исследованиях
                        путем обращения в Службу Поддержки;
                    </span>
                </li>
                <li className="c9">
                    <span className="c0">
                        В случае нарушения Пользователем условия настоящего Договора оферты
                        ограничить полностью или в части функциональные возможности Приложения и
                        Сервиса;
                    </span>
                </li>
                <li className="c117 c71">
                    <span className="c0">
                        При неоплате Услуг за один отчетный период Корпоративным клиентом ЧК «Adele
                        Energy Group ltd.» ограничить для Корпоративного клиента полностью или в
                        части функциональные Возможности Приложения и Сервиса;
                    </span>
                </li>
                <li className="c53">
                    <span className="c0">
                        В случае подозрений в недобросовестном пользовании услугами по зарядке
                        электрических транспортных средств со стороны Пользователя, для выяснения
                        обстоятельств задерживать сумму по Заявкам, вызывающим подозрение, сроком до
                        2 (двух) отчётных периодов.
                    </span>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-1" start={2}>
                <li className="c126 li-bullet-9">
                    <h2 style={{ display: 'inline' }}>
                        <span className="c4">ЧК «Adele Energy Group ltd.» обязан:</span>
                    </h2>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-2 start" start={1}>
                <li className="c11">
                    <span className="c0">
                        С учетом условий и ограничений, изложенных в настоящем Договоре оферты,
                        обеспечить возможность Пользователю использовать Приложение и Сервис;
                    </span>
                </li>
                <li className="c115 c71 c131">
                    <span className="c0">
                        Предоставить права использования Приложения и Сервиса, как предусмотрено
                        настоящим Договором оферты;
                    </span>
                </li>
                <li className="c3">
                    <span className="c0">
                        Оказывать Пользователю поддержку в части использования Приложения и Сервиса,
                        оплаты Услуг по зарядке и в части пользования Услугами по зарядке (последнее
                        осуществляется по заказу Исполнителя) посредством Службы Поддержки, через
                        функционал Приложения или иным способом, при этом Пользователь должен
                        обратиться за такой поддержкой в срок не позднее 14 дней с момента
                        размещения Заявки, в отношении которой Пользователь запрашивает поддержку;
                    </span>
                </li>
                <li className="c133 c71 c145">
                    <span className="c0">
                        Довести до Пользователя информацию об Исполнителе, выступающим исполнителем
                        Услуги по зарядке.
                    </span>
                </li>
            </ol>
            <p className="c75">
                <span className="c0"></span>
            </p>
            <ol className="c21 lst-kix_list_1-0" start={8}>
                <li className="c135 li-bullet-4">
                    <h1 style={{ display: 'inline' }}>
                        <span className="c4">ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ</span>
                    </h1>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-1 start" start={1}>
                <li className="c83 li-bullet-9">
                    <h2 style={{ display: 'inline' }}>
                        <span className="c4">Пользователь имеет право:</span>
                    </h2>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-2 start" start={1}>
                <li className="c71 c130">
                    <span className="c0">
                        Использовать Приложение и Сервис способами, указанными в настоящем Договоре
                        оферты, с учетом ограничений, установленных настоящим Договором оферты;
                    </span>
                </li>
                <li className="c57">
                    <span className="c0">
                        Обращаться в Службу Поддержки для целей получения информации и консультаций
                        по вопросам использования Приложения и Сервиса, оплаты Услуг по зарядке, и в
                        части пользования Услугами по зарядке (последнее осуществляется по заказу
                        Исполнителя)
                    </span>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-1" start={2}>
                <li className="c32 li-bullet-10">
                    <h2 style={{ display: 'inline' }}>
                        <span className="c4">Пользователь обязуется:</span>
                    </h2>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-2 start" start={1}>
                <li className="c93 li-bullet-11">
                    <span className="c0">
                        Соблюдать условия настоящего Договора оферты без каких-либо ограничений и
                        изъятий;
                    </span>
                </li>
                <li className="c71 c120">
                    <span className="c0">
                        Использовать Приложение и Сервис исключительно способами, указанными в
                        настоящем Договоре оферты;
                    </span>
                </li>
                <li className="c57">
                    <span className="c0">
                        Не нарушать каким-либо образом права интеллектуальной собственности ЧК
                        «Adele Energy Group ltd.» в отношении Приложения и Сервиса и/или каких-либо
                        составляющих Приложения и Сервиса, в частности, Пользователь не имеет права
                        распространять, доводить до всеобщего сведения, изменять, модифицировать,
                        осуществлять декомпиляцию или дизассемблирование Приложения и Сервиса,
                        копировать, транслировать, рассылать, публиковать, и иным образом
                        распространять и воспроизводить материалы (текстовые, графические,
                        аудио-видео), находящиеся в составе Приложения без письменного согласия ЧК
                        «Adele Energy Group ltd.»;
                    </span>
                </li>
                <li className="c3">
                    <span className="c0">
                        Самостоятельно предпринимать должные меры, обеспечивающие безопасность его
                        Аккаунта и предотвращающие несанкционированное пользование третьими лицами
                        Аккаунтом; самостоятельно предпринимать должные меры по обеспечению
                        безопасности и конфиденциальности банковских карт Пользователя;
                    </span>
                </li>
                <li className="c44">
                    <span className="c0">
                        Не осуществлять преднамеренные действия, направленные на создание чрезмерной
                        нагрузки на сервера, на которых размещено Приложение и Сервисы;
                    </span>
                </li>
                <li className="c12">
                    <span className="c0">
                        Не осуществлять любые действия, не направленные на обычное использование
                        Приложения и Сервиса в соответствии с его функционалом, включая, но не
                        ограничиваясь, не размещать Заявки в отсутствие реального потенциального
                        желания воспользоваться Услугами по зарядке, не осуществлять изменение
                        способа оплаты Услуг по зарядке с целью избежания оплаты Услуг по зарядке,
                        не осуществлять мошеннические действия, направленные на получение скидок и
                        иных преимуществ, включая, но не ограничиваясь, предусмотренных Программой
                        лояльности для Пользователей, вступление с Исполнителем, выступающим
                        непосредственным исполнителем Услуги по зарядке, в сговор для целей
                        получения необоснованной выгоды;
                    </span>
                </li>
                <li className="c102 c71 c143">
                    <span className="c0">
                        Бережно относится к оборудованию зарядной инфраструктуры, исключать случаи
                        её порчи и/или загрязнения;
                    </span>
                </li>
                <li className="c55 li-bullet-12">
                    <span className="c0">
                        Освободить парковочное место по завершению сессии зарядки;
                    </span>
                </li>
                <li className="c69 li-bullet-13">
                    <span className="c0">
                        Исполнять иные обязательства, предусмотренные настоящим Договором оферты.
                    </span>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-0" start={9}>
                <li className="c149 li-bullet-4">
                    <h1 style={{ display: 'inline' }}>
                        <span className="c4">ПРАВА И ОБЯЗАННОСТИ ИСПОЛНИТЕЛЯ</span>
                    </h1>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-1 start" start={1}>
                <li className="c154 li-bullet-14">
                    <h2 style={{ display: 'inline' }}>
                        <span className="c4">Исполнитель имеет право:</span>
                    </h2>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-2 start" start={1}>
                <li className="c17 li-bullet-12">
                    <span className="c0">
                        Устанавливать тарифы на услуги по зарядке электрических транспортных средств
                        для каждой{' '}
                    </span>
                    <span className="c0">ЭЗС.</span>
                </li>
                <li className="c17 li-bullet-15">
                    <span className="c0">
                        В любое время в одностороннем порядке осуществлять ввод/вывод в/из
                        эксплуатации ЭЗС.
                    </span>
                </li>
                <li className="c17 li-bullet-16">
                    <span className="c0">
                         Передать право требования не поступившей оплаты от Пользователя за
                        оказанные Услуги по
                    </span>
                </li>
            </ol>
            <p className="c76">
                <span className="c98">
                    зарядке третьим лицам с уведомлением Пользователя о состоявшемся переходе прав
                    кредитора к другому лицу.
                </span>
            </p>
            <ol className="c21 lst-kix_list_1-1" start={2}>
                <li className="c144 li-bullet-17">
                    <h2 style={{ display: 'inline' }}>
                        <span className="c4">Исполнитель обязуется:</span>
                    </h2>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-2 start" start={1}>
                <li className="c102 c71 c142">
                    <span className="c0">
                        Обеспечить совместно с подключение, корректную работу ЭЗС и максимальную
                        техническую готовность всех подключенных к Сервису.
                    </span>
                </li>
                <li className="c71 c88">
                    <span className="c0">
                        Обеспечить совместно с ЧК «Adele Energy Group ltd.» своевременное
                        информирование Пользователей о изменении тарифов на услуги по зарядке
                        электрических транспортных средств.
                    </span>
                </li>
                <li className="c110 c115 c71">
                    <span className="c0">
                        Обеспечить совместно с ЧК «Adele Energy Group ltd.» своевременное
                        информирование Пользователей о изменении состава ЭЗС доступных к оказанию
                        услуги по зарядке электрических транспортных средств.
                    </span>
                </li>
                <li className="c102 c71 c127">
                    <span className="c0">
                        Предпринять все необходимые меры для предоставления услуг на ЭЗС
                        Пользователям с учётом утверждённого порогового значения показателя
                        простоя/недоступности ЭЗС для оказания услуг по зарядке электрических
                        транспортных средств.
                    </span>
                </li>
                <li className="c71 c103">
                    <span className="c0">
                        Производить за свой счёт работы по капитальному и текущему ремонту (в том
                        числе в случае возникновения технологических нарушений), эксплуатации и
                        техническому обслуживанию ЭЗС.
                    </span>
                </li>
                <li className="c35">
                    <span className="c0">
                        Нести все расходы на содержание и работоспособность ЭЗС (в том числе
                        оплачивать электроэнергию и услуги беспроводной связи).
                    </span>
                </li>
                <li className="c110 c102 c71">
                    <span className="c0">
                        Содержать ЭЗС в полной исправности и в надлежащем состоянии, соблюдать
                        требования противопожарной безопасности, техники безопасности и охраны
                        труда, производственной ЧК «Adele Energy Group ltd.» санитарии,
                        экологического и природоохранного законодательства, правил технической
                        эксплуатации электрических станций и сетей Республики Казахстан, а также
                        использовать ЭЗС в соответствии с иными нормативно-правовыми актами
                        Республики Казахстан.
                    </span>
                </li>
                <li className="c71 c117">
                    <span className="c0">
                        Гарантировать функционирование круглосуточной поддержки Пользователей
                        второго уровня, а именно обрабатывать обращения, поступающие от ЧК «Adele
                        Energy Group ltd.» с первого уровня поддержки Пользователей, связанные с
                        технической исправностью и работоспособностью ЭЗС и. т.п.
                    </span>
                </li>
                <li className="c3">
                    <span className="c0">
                        В полном объёме нести ответственность за любой вред, причинённый третьим
                        лицам (Пользователям) или их имуществу в результате оказания услуг по
                        зарядке электрических транспортных средств.
                    </span>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-0" start={10}>
                <li className="c42 li-bullet-18">
                    <h1 style={{ display: 'inline' }}>
                        <span className="c4">ОТВЕТСТВЕННОСТЬ</span>
                    </h1>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-1 start" start={1}>
                <li className="c62">
                    <span className="c0">
                        Приложение, Сервис и права их использования в соответствии с настоящим
                        Договором оферты предоставляются Пользователю в состоянии «как есть».
                    </span>
                </li>
                <li className="c53">
                    <span className="c0">
                        ЧК «Adele Energy Group ltd.» не гарантирует, что: 1) Приложение и Сервис
                        будут удовлетворять субъективным ожиданиям и требованиям Пользователя; 2)
                        использование Приложения и Сервиса будут протекать непрерывно, быстро, без
                        технических сбоев, надежно и без ошибок; 3) результаты использования
                        Пользователем Приложения и Сервиса будут безошибочными; 4) Приложение и
                        Сервис будут доступны и могут быть использованы круглосуточно, в какой-то
                        конкретный момент времени или в течение какого- либо периода времени. ЧК
                        «Adele Energy Group ltd.» не несет ответственности за временные перебои,
                        технические неполадки и прекращение работы Приложения и Сервиса на
                        персональном мобильном устройстве Пользователя.
                    </span>
                </li>
                <li className="c62">
                    <span className="c0">
                        ЧК «Adele Energy Group ltd.» не несет ответственности за возникновение
                        убытков, прямого или косвенного ущерба, возникшего у Пользователя в связи с
                        использованием и/или невозможностью использования Приложения и Сервиса.
                    </span>
                </li>
                <li className="c3">
                    <span className="c0">
                        ЧК «Adele Energy Group ltd.» не несет ответственности за вред, причиненный
                        Пользователю и лицам, в отношении которых Пользователем была размещена
                        Заявка, в процессе оказания Услуг по зарядке. Ответственность за вред,
                        причиненный Пользователю и лицам, в отношении которых, Пользователем была
                        размещена Заявка в процессе оказания Услуг по зарядке несет Исполнитель в
                        соответствии с положениями применимого законодательства.
                    </span>
                </li>
                <li className="c71 c121">
                    <span className="c0">
                        ЧК «Adele Energy Group ltd.» не несет ответственности за содержание и/или
                        актуальность информации, предоставляемой Исполнителем, включая информацию об
                        Исполнителе. ЧК «Adele Energy
                    </span>
                </li>
            </ol>
            <p className="c22">
                <span className="c0">
                    Group ltd.» не несет ответственности за финансовые и любые другие операции,
                    совершаемые Пользователем и Исполнителем, а также за любые последствия
                    приобретения Пользователем Услуг по зарядке, оказываемых Исполнителем.
                </span>
            </p>
            <ol className="c21 lst-kix_list_1-1" start={6}>
                <li className="c49">
                    <span className="c0">
                        ЧК «Adele Energy Group ltd.» не несет ответственности за отсутствие у
                        Пользователя доступа в Интернет, за качество услуг провайдеров связи сети
                        Интернет, за качество услуг телефонной связи, с которыми Пользователем
                        заключены соглашения о предоставлении услуг связи.
                    </span>
                </li>
                <li className="c45">
                    <span className="c0">
                        Пользователь несет ответственность за нарушение прав интеллектуальной
                        собственности и иных прав ЧК «Adele Energy Group ltd.» в соответствии с
                        применимым законодательством.
                    </span>
                </li>
                <li className="c110 c71 c115">
                    <span className="c0">
                        ЧК «Adele Energy Group ltd.» не несет ответственности за вред и убытки,
                        причиненные Пользователю при использовании Сервисов третьих лиц.
                        Ответственность партнера ЧК «Adele Energy Group ltd.», предоставляющего
                        Сервис третьего лица, определяется на основании заключенного Пользователем и
                        партнером ЧК «Adele Energy Group ltd.» договора и действующего
                        законодательства.
                    </span>
                </li>
            </ol>
            <p className="c5">
                <span className="c0"></span>
            </p>
            <ol className="c21 lst-kix_list_1-0" start={11}>
                <li className="c73 li-bullet-4">
                    <h1 style={{ display: 'inline' }}>
                        <span className="c4">ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</span>
                    </h1>
                </li>
            </ol>
            <ol className="c21 lst-kix_list_1-1 start" start={1}>
                <li className="c27">
                    <span className="c0">
                        Настоящий Договор оферты действует с момента акцепта Пользователем оферты,
                        как предусмотрено настоящим Договором оферты, и действует до полной оплаты
                        Пользователем Услуги по зарядке.
                    </span>
                </li>
                <li className="c54">
                    <span className="c0">
                        Настоящий Договор оферты, а также применимые документы могут быть изменены
                        Исполнителем без какого-либо предварительного уведомления. Любые изменения в
                        Договоре оферты и применимых документах вступают в силу в день, следующий за
                        днем опубликования Договора оферты и применимых документов в измененной
                        версии. Пользователь обязуется самостоятельно проверять Договор оферты и
                        применимые документы на предмет изменений. Неосуществление Пользователем
                        действий по ознакомлению с Договором оферты и применимыми документами и/или
                        измененной редакцией Договора оферты и применимых документов не может
                        служить основанием для неисполнения Пользователем своих обязательств и
                        несоблюдения Пользователем ограничений, установленных Договором оферты и
                        применимыми документами. Пользователь самостоятельно несет риск
                        возникновения неблагоприятных последствий, вызванных не ознакомлением
                        Пользователя с измененной редакцией Договора оферты и применимых документов.
                    </span>
                </li>
                <li className="c71 c107">
                    <span className="c0">
                        Договор оферты регулируется и толкуется в соответствии с законодательством
                        Республики Казахстан. Вопросы, не урегулированные Договором оферты, подлежат
                        разрешению в соответствии с законодательством Республики Казахстан.
                    </span>
                </li>
                <li className="c54">
                    <span className="c0">
                        В случае возникновения любых споров или разногласий, связанных с исполнением
                        Договора оферты, Стороны приложат все усилия для их разрешения путем
                        проведения переговоров. В случае если споры не будут разрешены путем
                        переговоров, споры подлежат разрешению в порядке, установленном
                        законодательством Республики Казахстан.
                    </span>
                </li>
                <li className="c71 c107">
                    <span className="c0">
                        К настоящему Договору прилагается и является его неотъемлемой частью:
                    </span>
                </li>
            </ol>
            <p className="c87">
                <span className="c0">
                    Приложение №1 – Перечень Партнёров, оказывающих Услуги по зарядке электрических
                    транспортных средств;
                </span>
            </p>
            <p className="c61">
                <span className="c0">
                    Приложение №2 - Согласие Пользователя на обработку персональных данных.
                </span>
            </p>
            <p className="c101">
                <span className="c0">Приложение №1</span>
            </p>
            <p className="c147">
                <span className="c0">
                    к Договор оферты (пользовательское соглашение) оказания услуг по зарядке
                    электрического транспортного средства
                </span>
            </p>
            <p className="c58">
                <span className="c0"></span>
            </p>
            <h2 className="c153">
                <span className="c4">
                    Перечень партнёров, оказывающих услуги по зарядке электрических транспортных
                    средств
                </span>
            </h2>
            <a id="t.1b44c9cd2bcc7d2e8204269173d63527aa87c9b9"></a>
            <a id="t.0"></a>
            <table className="c65">
                <tbody>
                    <tr className="c33">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c70">
                                <span className="c0">№</span>
                            </p>
                        </td>
                        <td className="c52" colSpan={1} rowSpan={1}>
                            <p className="c1 c138">
                                <span className="c0">Наименование партнера и его данные</span>
                            </p>
                        </td>
                        <td className="c112" colSpan={1} rowSpan={1}>
                            <p className="c1">
                                <span className="c0">
                                    Местонахождение Исполнителя и контактные данные
                                </span>
                            </p>
                        </td>
                        <td className="c30" colSpan={1} rowSpan={1}>
                            <p className="c116">
                                <span className="c0">Адреса сайта Исполнителя</span>
                            </p>
                        </td>
                    </tr>
                    <tr className="c148">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c58">
                                <span className="c4"></span>
                            </p>
                            <p className="c26">
                                <span className="c0">1</span>
                            </p>
                        </td>
                        <td className="c52" colSpan={1} rowSpan={1}>
                            <p className="c15">
                                <span className="c0">
                                    ТОО «Adele Energy Qazaqstan» БИН: 220440045257
                                </span>
                            </p>
                        </td>
                        <td className="c112" colSpan={1} rowSpan={1}>
                            <p className="c146">
                                <span className="c0">
                                    Республика Казахстан, город Астана, район Есиль, проспект
                                    Мангилик Ел, 55/22, блок С3.5, офис 314{' '}
                                </span>
                                <span className="c98 c108">
                                    <a className="c20" href="mailto:aeq@adele.energy">
                                        aeq@adele.energy
                                    </a>
                                </span>
                            </p>
                            <p className="c134">
                                <span className="c0">+7 777 00 11350</span>
                            </p>
                        </td>
                        <td className="c30" colSpan={1} rowSpan={1}>
                            <p className="c39">
                                <span className="c0">https://adele.energy</span>
                            </p>
                        </td>
                    </tr>
                    <tr className="c84">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c58">
                                <span className="c4"></span>
                            </p>
                            <p className="c26">
                                <span className="c0">2</span>
                            </p>
                        </td>
                        <td className="c52" colSpan={1} rowSpan={1}>
                            <p className="c39">
                                <span className="c0">ТОО «Оператор ЭЗС»</span>
                            </p>
                        </td>
                        <td className="c112" colSpan={1} rowSpan={1}>
                            <p className="c19">
                                <span className="c0">
                                    Республика Казахстан, город Астана, район Есиль, проспект
                                    Кабанбай батыра, 11/5, Офис 704, 705.{' '}
                                </span>
                                <span className="c108 c98">
                                    <a className="c20" href="mailto:info@oezs.kz">
                                        info@oezs.kz
                                    </a>
                                </span>
                            </p>
                            <p className="c97">
                                <span className="c0">+7 (702) 520-03-51</span>
                            </p>
                        </td>
                        <td className="c30" colSpan={1} rowSpan={1}>
                            <p className="c39">
                                <span className="c0">https://oezs.kz/ru/</span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="c122">
                <span className="c0"></span>
            </p>
            <p className="c101">
                <span className="c0">Приложение №2</span>
            </p>
            <p className="c147">
                <span className="c0">
                    к Договор оферты (пользовательское соглашение) оказания услуг по зарядке
                    электрического транспортного средства
                </span>
            </p>
            <p className="c58">
                <span className="c0"></span>
            </p>
            <h2 className="c111">
                <span className="c4">
                    Согласие на сбор, обработку, хранение и передачу персональных данных
                </span>
            </h2>
            <p className="c80">
                <span className="c4"></span>
            </p>
            <p className="c29">
                <span className="c0">
                    В соответствии с Законом Республики «О персональных данных и их защите» (далее –
                    Закон), при принятии Договора оферты оказания услуг по зарядке электрического
                    транспортного средства, даю свое согласие ЧК «Adele Energy Group ltd.» (далее -
                    Исполнитель), на сбор и обработку персональных данных, запись, систематизацию,
                    накопление, хранение, уточнение (обновление, изменение), извлечение,
                    использование, обезличивание, блокирование, удаление и уничтожение, в том числе
                    автоматизированные, своих персональных данных в специализированной электронной
                    базе данных о моих фамилии, имени, отчестве, дате и месте рождения, адресе, а
                    также иной информации личного характера, которая может быть использована при
                    предоставлении Исполнителем услуг по зарядке электрического транспортного
                    средства, и в целях участия в опросах/анкетировании, проводимых Исполнителем для
                    изучения и исследования мнения клиентов о качестве обслуживания и услугах
                    Исполнителя, при условии гарантии неразглашения данной информации третьим лицам,
                    за исключением лиц от имени и по поручениюкоторых Исполнитель заключает с
                    Пользователями договор оказания услуг по зарядке электрических транспортных
                    средств.
                </span>
            </p>
            <p className="c109">
                <span className="c0">
                    В соответствии с подпунктом 2) статьи 1 Закона под персональными данными
                    понимаются сведения, относящиеся к определенному или определяемому на их
                    основании субъекту персональных данных (физическому лицу, к которому относятся
                    персональные данные), зафиксированные на электронном, бумажном и (или) ином
                    материальном носителе.
                </span>
            </p>
            <p className="c136">
                <span className="c0">
                    Я согласен на предоставление мне информации путем направления почтовой
                    корреспонденции по моему домашнему адресу, посредством электронной почты,
                    телефонных обращений, СМС-сообщений.
                </span>
            </p>
            <p className="c38">
                <span className="c0"></span>
            </p>
            <p className="c51">
                <span className="c0">
                    Данное согласие действует с момента принятии Договора оферты оказания услуг по
                    зарядке электрического транспортного средства и пяти лет после прекращения
                    указанных услуг. По истечении указанного срока действие настоящего заявления
                    считается продленным на каждые следующие 5 лет при отсутствии у Исполнителя
                    сведений о его отзыве.
                </span>
            </p>
            <p className="c77">
                <span className="c0">
                    Данное согласие может быть отозвано путем представления Исполнителю письменного
                    заявления. Договор оферты согласован:
                </span>
            </p>
            <h2 className="c36">
                <span className="c4">Исполнитель:</span>
            </h2>
            <p className="c156">
                <span className="c0">Генеральный директор</span>
            </p>
            <p className="c125">
                <span className="c0">ЧК «Adele Energy Group ltd.»</span>
            </p>
            <p className="c94">
                <span className="c0"></span>
            </p>
            <p className="c85">
                <span className="c43">                   </span>
                <span className="c86">/</span>
                <span className="c43">                  </span>
                <span className="c86 c106">/</span>
            </p>
            <p className="c85">
                <span className="c86">м. п.</span>
            </p>
        </div>
    );
};

// <div className={styles.c40}>
//             <h2 className={styles.c99}>
//                 <span className={styles.c4}>Договор оферты (пользовательское соглашение)</span>
//             </h2>
//             <p className={styles.c139}>
//                 <span className={styles.c4}>
//                     оказания услуг по зарядке электрического транспортного средства Редакция No 1 от
//                     «10» января 2023 г.
//                 </span>
//             </p>
//             <p className={styles.c29} id="h.1fob9te">
//                 <span className={styles.c0}>
//                     Настоящий Договор оферты адресовано Пользователям, что является официальным и
//                     публичным в соответствии со статьи 395 Гражданского кодекса Республики
//                     Казахстан. Договор оферты оказания услуг по зарядке электрического транспортного
//                     средства (далее — «Договор оферты») устанавливает условия использования
//                     веб-приложения (мобильного приложения) (далее – «Приложение») «Adele Cloud» и
//                     сервиса «Ladergy.com» для зарядки электрического транспортного средства и
//                     заключено на условиях, изложенных ниже между дееспособным и правоспособным лицом
//                     (Пользователем), использующим веб- приложение(мобильное приложение)
//                     правообладателем которых является Частная Компания Adele Energy Group ltd. и
//                     владельцем объекта зарядной инфраструктуры, (далее именуемое, «Исполнитель»)
//                     совместно именуемыми Сторонами, а по отдельности – Сторона.
//                 </span>
//             </p>
//             <p className={styles.c2}>
//                 <span className={styles.c0}></span>
//             </p>
//             <ol
//                 className={styles.c21 + ' ' + styles.lst_kix_list_1_0 + ' ' + styles.start}
//                 start={1}>
//                 <li className={styles.c141 + ' ' + styles.li_bullet_0}>
//                     <h1>
//                         <span className={styles.c4}>ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</span>
//                     </h1>
//                 </li>
//             </ol>
//             <p className={styles.c61}>
//                 <span className={styles.c0}>
//                     В настоящей оферте используются следующие термины и определения:
//                 </span>
//             </p>
//             <ol className={styles.c21} start={1}>
//                 <li className={styles.c71 + ' ' + styles.c78}>
//                     <span className={styles.c4}>Услуга по зарядке </span>
//                     <span className={styles.c0}>
//                         – услуги по зарядке электрических транспортных средств по заказу
//                         Пользователя, оказываемые Исполнителем в порядке, описанном в настоящем
//                         Договоре оферты.
//                     </span>
//                 </li>
//                 <li className={styles.c49}>
//                     <span className={styles.c4}>Сайт </span>
//                     <span className={styles.c0}>
//                         – официальный сайт ЧК «Adele Energy Group ltd.», доступный по следующей
//                         ссылке в сети Интернет: https://guest.ladergy.com/. Все права в отношении
//                         Сайта принадлежат ЧК «Adele Energy Group ltd.».
//                     </span>
//                 </li>
//                 <li className={styles.c51 + ' ' + styles.c71}>
//                     <span className={styles.c4}>Продавец </span>
//                     <span className={styles.c0}>
//                         - ЧК «Adele Energy Group ltd.», зарегистрированное по адресу: Республика
//                         Казахстан, город Астана, район Есиль, Проспект Мангилик Ел, здание 55/22, и
//                         аффилированные с ним юридические лица, продающие Товары и/или Услуги через
//                         Сайт.
//                     </span>
//                 </li>
//                 <li className={styles.c102 + ' ' + styles.c71 + ' ' + styles.c110}>
//                     <span className={styles.c4}>Исполнитель </span>
//                     <span className={styles.c0}>
//                         – зарегистрированное в предусмотренном законодательством РК порядке
//                         юридическое лицо или индивидуальный предприниматель, управляющее объектом
//                         зарядной инфраструктуры, и являющееся непосредственным исполнителем Услуги
//                         по зарядке электрических транспортных средств. Исполнитель поручил ЧК «Adele
//                         Energy Group ltd.» от имени и за счет Исполнителя осуществлять поиск и
//                         привлечение потенциальных Пользователей и заключать с Пользователями
//                         договоры оказания услуг по зарядке электрических транспортных средств.
//                     </span>
//                 </li>
//                 <li className={styles.c68}>
//                     <span className={styles.c4}>Пользователь </span>
//                     <span className={styles.c0}>
//                         – дееспособное физическое лицо либо сотрудник Корпоративного клиент,
//                         заключившее настоящее Договор оферты в предусмотренном порядке.
//                     </span>
//                 </li>
//                 <li className={styles.c45}>
//                     <span className={styles.c4}>Корпоративный клиент </span>
//                     <span className={styles.c0}>
//                         – зарегистрированное в предусмотренном законодательством РК порядке
//                         юридическое лицо или индивидуальный предприниматель, заключившее настоящий
//                         Договор оферты. Корпоративный клиент заключает настоящий Договор оферты с
//                         целью использования Приложения и Сервиса своими сотрудниками.
//                     </span>
//                 </li>
//                 <li className={styles.c13}>
//                     <span className={styles.c4}>Служба Поддержки </span>
//                     <span className={styles.c0}>
//                         - внутренняя служба и специалисты ЧК «Adele Energy Group ltd.», или третьи
//                         лица, привлеченные ЧК «Adele Energy Group ltd.», осуществляющие
//                         консультирование Пользователя по вопросам использования Приложения и Сервиса
//                         в порядке, предусмотренном настоящим Договором оферты, а также
//                         осуществляющие иные действия, предусмотренные настоящим Договором оферты.
//                     </span>
//                 </li>
//                 <li className={styles.c51 + ' ' + styles.c71}>
//                     <span className={styles.c4}>Агрегатор электронных рассылок </span>
//                     <span className={styles.c0}>
//                         – зарегистрированное в предусмотренном законодательством РК порядке
//                         юридическое лицо или индивидуальный предприниматель, заключившее с ЧК «Adele
//                         Energy Group ltd.» соответствующее соглашение о выполнении отправки
//                         сообщений о порядке оказания услуг или сообщений рекламного характера
//                         Пользователям Сервиса.
//                     </span>
//                 </li>
//                 <li className={styles.c110 + ' ' + styles.c102 + ' ' + styles.c71}>
//                     <span className={styles.c4}>Приложение </span>
//                     <span className={styles.c0}>
//                         - программа для ЭВМ и для мобильных устройств « Adele Cloud» (включая все
//                         обновления и улучшения), предназначенная для использования Пользователями
//                         для целей размещения Заявок в порядке, предусмотренном настоящим Договором
//                         оферты.
//                     </span>
//                 </li>
//                 <li className={styles.c13}>
//                     <span className={styles.c4}>Сервис </span>
//                     <span className={styles.c0}>
//                         - программный комплекс, имеющий наименование «Adele Cloud» и осуществляющий
//                         функции по приёму, обработке, хранению, формированию, изменению и передаче
//                         Заявок, направлению Исполнителю информации о размещённой Заявке, внесению,
//                         хранению, изменению, удалению, обработке и формированию консолидированных
//                         сведений о объектах зарядной инфраструктуры, обеспечению консультационного,
//                         технологического и информационного взаимодействия между пользователями,
//                         формированию и выгрузке статистических сведений (количество Заявок,
//                         стоимость Заявок, статусы Заявок и т.д.), отображению статусов объектов
//                         зарядной инфраструктуры в режиме реального времени.
//                     </span>
//                 </li>
//                 <li className={styles.c45}>
//                     <span className={styles.c4}>Заявка </span>
//                     <span className={styles.c0}>
//                         - информация о потенциальном спросе Пользователя на Услуги по зарядке
//                         электрических транспортных средств, размещенная Пользователем в Приложении в
//                         порядке, предусмотренном настоящим Договором оферты.
//                     </span>
//                 </li>
//                 <li className="c71 c100">
//                     <span className={styles.c4}>Аккаунт </span>
//                     <span className={styles.c0}>
//                         – персональный раздел Пользователя в Приложении, создаваемый в порядке,
//                         предусмотренном Договором оферты, содержащий информацию о Пользователе, об
//                         использовании Пользователем Сервиса, а также иную информацию,
//                         предусмотренную функционалом Приложения.
//                     </span>
//                 </li>
//                 <li className="c71 c113">
//                     <span className={styles.c4}>ЭЗС </span>
//                     <span className={styles.c0}>
//                         – электрическая зарядная станция, предназначенная для оказания услуг по
//                         зарядке электрических транспортных средств.
//                     </span>
//                 </li>
//                 <li className="c128 c71 c155">
//                     <span className={styles.c4}>Оферта </span>
//                     <span className={styles.c0}>
//                         — настоящее пользовательское соглашение, то есть публичное предложение
//                         Продавца, адресованное неограниченному кругу физических и юридических лиц, о
//                         заключении договора купли- продажи товаров и/или услуг.
//                     </span>
//                 </li>
//                 <li className={styles.c3}>
//                     <span className={styles.c4}>Акцепт </span>
//                     <span className={styles.c0}>
//                         — полное и безоговорочное принятие Пользователем от своего имени, а также от
//                         лица тех, кто будет использовать его аккаунт, условий настоящей Оферты путем
//                         регистрации в качестве пользователя на Сайте.
//                     </span>
//                 </li>
//                 <li className={styles.c57}>
//                     <span className={styles.c4}>Холдирование </span>
//                     <span className={styles.c0}>
//                         — операция оплаты, при которой сумма платежа, оплачиваемая Пользователем при
//                         заявке в Приложении, на первой стадии резервируется (холдируется) на счете,
//                         к которому выпущена банковская карта Пользователя, а на второй стадии, после
//                         подтверждения Заказа сторонами и пересчета суммы, списывается со счета
//                         Пользователя, а разница, если таковая имеется, возвращается Пользователю.
//                     </span>
//                 </li>
//                 <li className="c71 c91">
//                     <span className={styles.c4}>Фактическая стоимость </span>
//                     <span className={styles.c0}>
//                         — окончательная стоимость Заказа, рассчитанная исходя из конечного веса и
//                         цены товаров на момент начала осуществления доставки Пользователю.
//                     </span>
//                 </li>
//                 <li className={styles.c53}>
//                     <span className={styles.c4}>Персональные данные </span>
//                     <span className={styles.c0}>
//                         - означает совокупность личных данных и/или неперсонифицированной информации
//                         о Пользователе, предоставляемую им самим Правообладателю и/или автоматически
//                         собираемую Правообладателем и/или третьими лицами.
//                     </span>
//                 </li>
//                 <li className={styles.c13}>
//                     <span className={styles.c4}>Файлы куки (cookies) </span>
//                     <span className={styles.c0}>
//                         - означает небольшие файлы, отправляемые каким-либо мобильным (web)
//                         приложениям или сайтом, и размещаемые на смартфонах, планшетах, часах и
//                         других мобильных устройствах Пользователя, для улучшения работы таких
//                         приложений или сайтов, а также качества размещенного в них контента.
//                     </span>
//                 </li>
//             </ol>
//             <ol className="c21 lst_kix_list_1_0" start={2}>
//                 <li className="c31 li-bullet-1">
//                     <h1>
//                         <span className={styles.c4}>ОБЩИЕ ПОЛОЖЕНИЯ</span>
//                     </h1>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-1 start" start={1}>
//                 <li className="c71 c92">
//                     <span className={styles.c0}>
//                         В соответствии с настоящим соглашением Пользователь через Приложение
//                         размещает Заявку на Услугу по зарядке электрического транспортного средства
//                         на выбранной ЭЗС Исполнителя.
//                     </span>
//                 </li>
//                 <li className="c71 c118">
//                     <span className={styles.c0}>
//                         Исполнитель        оказывает
//                         Услугу        по        зарядке        электрического
//                         транспортного        средства Пользователя на выбранной ЭЗС.
//                     </span>
//                 </li>
//                 <li className="c76 c71 c102">
//                     <span className={styles.c0}>
//                         Пользователь оплачивает Услугу по зарядке электрического транспортного
//                         средства путём использования функционала Приложения, описанного в данном
//                         соглашении.
//                     </span>
//                 </li>
//             </ol>
//             <p className={styles.c38}>
//                 <span className={styles.c0}></span>
//             </p>
//             <ol className="c21 lst_kix_list_1_0" start={3}>
//                 <li className="c46 li-bullet-1">
//                     <h1>
//                         <span className={styles.c4}>ФУНКЦИОНАЛ ПРИЛОЖЕНИЯ И ОПИСАНИЕ СЕРВИСА</span>
//                     </h1>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-1 start" start={1}>
//                 <li className={styles.c56}>
//                     <span className={styles.c0}>
//                         Посредством использования Приложения Пользователь получает доступ к Сервису.
//                         Сервис является информационным сервисом и предлагает Пользователю
//                         возможность разместить информацию (разместить Заявку) о потенциальном спросе
//                         Пользователя на Услуги по зарядке электрических транспортных средств, а
//                         также возможность ознакомиться с информацией о предложениях Исполнителя,
//                         оказывающего Услуги по зарядке электрических транспортных средств, и
//                         осуществить поиск таких предложений по заданным Пользователем параметрам.
//                     </span>
//                 </li>
//                 <li className="c103 c71">
//                     <span className={styles.c0}>
//                         Перечень Исполнителей, оказывающих Услуги по зарядке электрических
//                         транспортных средств представлен в Приложении №1 к настоящему Договору
//                         оферты.
//                     </span>
//                 </li>
//                 <li className={styles.c64}>
//                     <span className={styles.c0}>
//                         В различных ситуациях (включая, но не ограничиваясь, регион местонахождения
//                         Пользователя, технические неисправности, распространение функций в рамках
//                         тестирования на ограниченный круг Пользователей) все или некоторые функции
//                         Приложения могут быть недоступны или ограничены для использования
//                         Пользователем. Функции считаются недоступными (ограниченными) для
//                         Пользователя, если такой Пользователь не может ими воспользоваться
//                         посредством использования Приложения. Применение любых технических и
//                         программных методов обхода данных ограничений запрещено. Положения
//                         настоящего Договора оферты, регулирующие недоступные (ограниченные) для
//                         Пользователя функции Приложения, не применяются до тех пор, пока
//                         Пользователю такие функции не станут доступны посредством использования
//                         Приложения.
//                     </span>
//                 </li>
//                 <li className={styles.c18}>
//                     <span className={styles.c0}>
//                         При отсутствии у Пользователя возможности использовать Приложение или
//                         получить доступ к Сервису иным способом, указанным в п. 3.1 Договора оферты
//                         (включая, но не ограничиваясь, при отсутствии доступа к сети Интернет, при
//                         проблемах в использовании Приложения) Пользователь может обратиться в Службу
//                         Поддержки за помощью в использовании Сервиса. Положения настоящего Договора
//                         оферты распространяются на обращения Пользователя в Службу Поддержки для
//                         целей оформления Заявки в той мере, в которой это применимо. Все разговоры
//                         Пользователя со специалистами Службы Поддержки, а также с Исполнителем могут
//                         быть записаны в целях контроля качества и совершенствования обслуживания.
//                     </span>
//                 </li>
//                 <li className={styles.c66}>
//                     <span className={styles.c0}>
//                         Посредством использования Приложения Пользователь может получить информацию
//                         об Исполнителях,  оказывающих  Услуги  по  зарядке  электрических
//                          транспортных  средств. ЧК «Adele Energy Group ltd.» не обязана проверять
//                         полноту и достоверность предоставленной информации, а также ЧК «Adele Energy
//                         Group ltd.» не изменяет предоставленную Исполнителем информацию. Перечень
//                         Исполнителей доступен на Сайте. Получение Пользователем более подробной
//                         информации об
//                     </span>
//                 </li>
//             </ol>
//             <p className={styles.c23}>
//                 <span className={styles.c0}>
//                     Исполнителе и предлагаемых ими Услугах по зарядке электрических транспортных
//                     средств может быть осуществлено Пользователем путем самостоятельного обращения к
//                     Исполнителю.
//                 </span>
//             </p>
//             <ol className="c21 lst-kix_list_1-1" start={6}>
//                 <li className={styles.c53}>
//                     <span className={styles.c0}>
//                         В рамках использования Сервиса Пользователю могут предлагаться к
//                         использованию сервисы третьих лиц, являющихся партнерами ЧК «Adele Energy
//                         Group ltd.» (далее - «Сервисы третьих лиц»). Использование таких Сервисов
//                         третьих лиц осуществляется на основании заключения между Пользователем и
//                         партнером ЧК «Adele Energy Group ltd.» соответствующих пользовательских
//                         соглашений. В случае, если такие услуги или их часть оказываются посредством
//                         Приложения «Adele Cloud», Пользователь знакомится с соглашением о
//                         присоединении к пользовательскому соглашению Сервиса третьих лиц в
//                         Приложении и обязан принять его условия прежде, чем начать пользование услуг
//                         партнера ЧК «AdeleEnergy Group ltd.».
//                     </span>
//                 </li>
//                 <li className={styles.c24}>
//                     <span className={styles.c0}>
//                         Для информирования Пользователя о порядке и ходе оказания услуг ЧК «Adele
//                         Energy Group ltd.» в праве привлекать Агрегаторов электронных рассылок.
//                     </span>
//                 </li>
//             </ol>
//             <p className={styles.c6}>
//                 <span className={styles.c0}></span>
//             </p>
//             <ol className="c21 lst_kix_list_1_0" start={4}>
//                 <li className="c28 li-bullet-1">
//                     <h1>
//                         <span className={styles.c4}>
//                             ИСПОЛЬЗОВАНИЕ ПОЛЬЗОВАТЕЛЕМ ПРИЛОЖЕНИЯ И ПОРЯДОК ПРИСОЕДИНЕНИЯ К
//                             НАСТОЯЩЕМУ ДОГОВОРУ
//                         </span>
//                     </h1>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-1 start" start={1}>
//                 <li className={styles.c45}>
//                     <span className={styles.c0}>
//                         Для целей использования Приложения, Пользователь обязан указать в Приложении
//                         свой номер мобильного телефона. Указанные данные должны быть полными,
//                         точными и достоверные.
//                     </span>
//                 </li>
//                 <li className={styles.c60}>
//                     <span className={styles.c0}>
//                         После указания номера мобильного телефона Пользователь получает
//                         SMS-сообщение со ссылкой для отслеживания и управления процессом зарядки.
//                     </span>
//                 </li>
//                 <li className="c71 c82">
//                     <span className={styles.c0}>
//                         После подтверждения принадлежности указанного номера мобильного телефона
//                         Пользователь считается зарегистрировавшимся в Приложении, и в Приложении
//                         формируется Аккаунт Пользователя с присвоением Аккаунту уникального
//                         идентификационного номера.
//                     </span>
//                 </li>
//                 <li className={styles.c110 + ' ' + styles.c102 + ' ' + styles.c71}>
//                     <span className={styles.c0}>
//                         Пользователь понимает и соглашается, что все действия, совершенные с
//                         использованием Аккаунта Пользователя, считаются совершенными Пользователем.
//                         Пользователь обязан обеспечить конфиденциальность своих регистрационных
//                         данных и ограничить для третьих лиц доступ к персональному мобильному
//                         устройства Пользователя. Пользователь самостоятельно несет риск
//                         возникновения неблагоприятных последствий, вызванных нарушением
//                         конфиденциальности регистрационных данных Пользователя и/или предоставлением
//                         третьим лицам доступа к персональному мобильному устройству Пользователя.
//                     </span>
//                 </li>
//                 <li className={styles.c67}>
//                     <span className={styles.c0}>
//                         Акцепт настоящего Договора оферты осуществляется путём действий в
//                         Приложении, в результате которых начинается зарядка электрического
//                         транспортного средства.
//                     </span>
//                 </li>
//                 <li className={styles.c45}>
//                     <span className={styles.c0}>
//                         Акцепт настоящего Договора оферты означает согласие Пользователя на
//                         обработку персональных данных, согласно Приложению №2 к настоящему Договору
//                         оферты.
//                     </span>
//                 </li>
//                 <li className="c137 li-bullet-2">
//                     <span className={styles.c0}>
//                         Удаление Аккаунта может быть осуществлено в следующих случаях:
//                     </span>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-2 start" start={1}>
//                 <li className="c110 c123 c71">
//                     <span className={styles.c0}>
//                         По усмотрению Исполнителя, если Пользователем подана заявка на удаление
//                         Аккаунта, при этом Исполнитель может отказать в удалении Аккаунта без
//                         объяснения причин. В случае, если Исполнитель отказал в удалении Аккаунта он
//                         имеется право осуществить техническое ограничение доступа к личным данным
//                         Пользователя, сохранив Аккаунт и личные данные Пользователя на серверах
//                         Исполнителя;
//                     </span>
//                 </li>
//                 <li className={styles.c6}>
//                     <span className={styles.c0}>
//                         По усмотрению Исполнителя в отношении Пользователя, пользующегося
//                         исключительно активированными данными и командами.
//                     </span>
//                 </li>
//                 <li className={styles.c51 + ' ' + styles.c71}>
//                     <span className={styles.c0}>
//                         В случае неоднократного или существенного нарушения Пользователем условий
//                         настоящего Соглашения, признание того или иного нарушения существенным
//                         осуществляется Исполнителем самостоятельно на основании оценки конкретных
//                         обстоятельств нарушения. К существенным нарушениям, в том числе, могут быть
//                         отнесены действия, осуществление которых запрещено разделом 11 настоящего
//                         Соглашения или нарушения, не устраненные Пользователем в срок после
//                         получения от Исполнителя соответствующего требования и др.
//                     </span>
//                 </li>
//                 <li className="c48 li-bullet-3">
//                     <span className={styles.c0}>
//                         Исполнитель имеет право не объяснять причины удаления Аккаунта.
//                     </span>
//                 </li>
//             </ol>
//             <p className={styles.c105}>
//                 <span className={styles.c0}></span>
//             </p>
//             <ol className="c21 lst_kix_list_1_0" start={5}>
//                 <li className="c73 li-bullet-4">
//                     <h1>
//                         <span className={styles.c4}>ПОРЯДОК ОКАЗАНИЯ УСЛУГ ИСПОЛНИТЕЛЕМ</span>
//                     </h1>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-1 start" start={1}>
//                 <li className={styles.c7}>
//                     <span className={styles.c0}>
//                         Услуги по зарядке, Заявки на которые были размещены Пользователем
//                         посредством использования Приложения или иным способом, предусмотренным
//                         настоящим Договором оферты, непосредственно оказываются Исполнителем.
//                         Указанные в настоящем Договоре оферты условия оказания Услуг по зарядке
//                         Исполнителя доводятся до сведения Пользователя по заказу Исполнителя.
//                     </span>
//                 </li>
//                 <li className={styles.c50}>
//                     <span className={styles.c0}>
//                         ЧК «Adele Energy Group ltd.» не является владельцем зарядных станций для
//                         оказания услуг по зарядке, посредством Сервиса заключает от имени
//                         Исполнителя договор с Пользователем на Услуги по зарядке. ЧК «Adele Energy
//                         Group ltd.» является владельцем агрегатора информации о услугах по зарядке.
//                     </span>
//                 </li>
//                 <li className={styles.c51 + ' ' + styles.c71}>
//                     <span className={styles.c0}>
//                         Размещение Пользователем Заявки не является принятием ЧК «Adele Energy Group
//                         ltd.» к выполнению заказа Пользователя на оказание Услуг по зарядке.
//                         Принятие к выполнению заказа Пользователя на оказание Услуг по зарядке в
//                         соответствии с условиями Заявки осуществляется Исполнителем и считается
//                         состоявшимся в момент, когда до сведения Исполнителя посредством интерфейса
//                         Приложения доводится информация о принятии Исполнителем.
//                     </span>
//                 </li>
//                 <li className="c102 c71 c132">
//                     <span className={styles.c0}>
//                         ЧК «Adele Energy Group ltd.» не гарантирует Пользователю, что размещенная
//                         Пользователем Заявка будет принята к выполнению Исполнителем. ЧК «Adele
//                         Energy Group ltd.» не гарантирует, что принятая Исполнителем Заявка будет
//                         выполнена Исполнителем и не несет ответственность за неоказание Услуг по
//                         зарядке по Заявкам, принятым Исполнителем.
//                     </span>
//                 </li>
//                 <li className="c71 c104">
//                     <span className={styles.c0}>
//                         Стоимость Услуг по зарядке устанавливается Исполнителем, но не может
//                         превышать тарифы, указанные в Приложении, а исключением случаев, указанных в
//                         настоящем Договоре оферты. Стоимость Услуг по зарядке определяется исходя из
//                         выбранного Исполнителем тарифа. Стоимость Услуг по зарядке может быть
//                         изменена в случаях, указанных в настоящем Договоре оферты.
//                     </span>
//                 </li>
//                 <li className={styles.c13}>
//                     <span className={styles.c0}>
//                         В случае отказа Пользователя от оказания Услуг по зарядке после момента
//                         запуска сессии бронирования в соответствии с условиями Заявки (о чем
//                         Пользователь информируется посредством функционала Приложения или
//                         СМС-сообщения), а также в иных случаях, указанных на Приложении, ЧК
//                     </span>
//                 </li>
//             </ol>
//             <p className="c127 c128">
//                 <span className={styles.c0}>
//                     «Adele Energy Group ltd.», действуя по поручению Исполнителя, взимает с такого
//                     Пользователя фактически понесенные Исполнителем расходы, которые могут
//                     рассчитываться как указанная в Приложении минимальная стоимость Услуги по
//                     зарядке по выбранному тарифу или иным способом, указанным на Сайте, что не
//                     является платой Пользователя за отказ от исполнения договора.
//                 </span>
//             </p>
//             <ol className="c21 lst-kix_list_1-1" start={7}>
//                 <li className={styles.c45}>
//                     <span className={styles.c0}>
//                         ЧК «Adele Energy Group ltd.» по заказу Исполнителя доводит до сведения
//                         Пользователя правила предоставления Исполнителем Услуг по зарядке.
//                     </span>
//                 </li>
//             </ol>
//             <ol className="c21 lst_kix_list_1_0" start={6}>
//                 <li className="c34 li-bullet-4">
//                     <h1>
//                         <span className={styles.c4}>ОПЛАТА УСЛУГ ПО ЗАРЯДКЕ ПОЛЬЗОВАТЕЛЯМИ</span>
//                     </h1>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-1 start" start={1}>
//                 <li className="c142 c102 c71">
//                     <span className={styles.c0}>
//                         Для целей возможности использования Пользователя безналичного способа
//                         оплаты, Пользователь обязан указать свою банковскую карту в порядке,
//                         предусмотренном настоящим Договором оферты, с использованием функционала
//                         Приложения (далее – «Привязанная банковская карта»).
//                     </span>
//                 </li>
//                 <li className="c128 c71 c133">
//                     <span className={styles.c0}>
//                         Создание Привязанной банковской карты производится Пользователем посредством
//                         сервиса уполномоченного оператора по приему платежей, страница которого
//                         открывается в Приложении. Пользователь обязуется указать следующие данные,
//                         используя функционал Приложения 1) номер банковской карты; 2) срок действия;
//                         3) защитный код (CVC/CVV).
//                     </span>
//                 </li>
//                 <li className="c71 c151">
//                     <span className={styles.c0}>
//                         После указания данных банковской карты на счете Привязанной банковской карты
//                         осуществляется резервация (холдирование) в размере суммы, указанной в
//                         Приложении, а разница, если таковая имеется, возвращается Пользователю. Если
//                         данные банковской карты полны и достоверны и использование данной карты
//                         технически возможно, указанная Пользователем банковская карта
//                         рассматривается в рамках настоящего Договора оферты в качестве Привязанной
//                         банковской карты и может быть использована для безналичного способа оплаты.
//                     </span>
//                 </li>
//                 <li className={styles.c47}>
//                     <span className={styles.c0}>
//                         ЧК «Adele Energy Group ltd.» не имеет доступа к указанным Пользователем в
//                         сервисе уполномоченного оператора по приему платежей данным Привязанной
//                         банковской карты и не несет ответственности за сохранность и
//                         конфиденциальность передаваемых данных при проведении оплаты безналичным
//                         способом оплаты. Оплата безналичным способом оплаты осуществляется
//                         Пользователем с участием уполномоченного оператора по приему платежей, или
//                         оператора электронных денежных средств, или иных участников расчетов,
//                         информационно- технологического взаимодействия, и регулируется правилами
//                         международных платежных систем, банков (в том числе банка-эмитента
//                         Привязанной банковской карты) и других участников расчетов.
//                     </span>
//                 </li>
//                 <li className={styles.c41}>
//                     <span className={styles.c0}>
//                         При указании своих данных банковской карты, как указано в п. 6.2. Договора
//                         оферты и дальнейшем использовании Привязанной карты, Пользователь
//                         подтверждает и гарантирует указание им достоверной и полной информации о
//                         банковской карте, выданной на его имя; соблюдение им правил международных
//                         платежных систем и требований банка-эмитента, выпустившего банковскую карту,
//                         в том числе в отношении порядка проведения безналичных расчетов.
//                     </span>
//                 </li>
//                 <li className="c71 c114">
//                     <span className={styles.c0}>
//                         Пользователь понимает и соглашается, что все действия, совершенные в рамках
//                         использования Приложения с использованием подтвержденного им номера
//                         мобильного телефона, в том числе по оплате безналичным способом оплаты с
//                         использованием Привязанной банковской карты, считаются совершенными
//                         Пользователем.
//                     </span>
//                 </li>
//                 <li className={styles.c10}>
//                     <span className={styles.c0}>
//                         В случае несогласия Пользователя с фактом и/или суммой оплаты безналичным
//                         способом оплаты и по иным связанным с использованием Привязанной карты в
//                         рамках использования Сервиса вопросам Пользователь вправе обратиться в
//                         Службу Поддержки в течение 14 (четырнадцати) календарных дней со дня
//                         совершения безналичного способа оплаты. В случае если по результатам
//                         проверки по такому обращению ЧК «Adele Energy Group ltd.» принимает решение
//                         о возврате суммы оплаты безналичным способом оплаты полностью или частично,
//                         указанный возврат осуществляется на банковский счет Привязанной банковской
//                         карты, с которой была произведена оплата безналичным способом оплаты.
//                         Порядок возврата денежных средств осуществляется с участием уполномоченного
//                         оператора по приему платежей или оператора электронных денежных средств, или
//                         иных участников расчетов, информационно- технологического взаимодействия, и
//                         регулируется правилами международных платежных систем, банков (в том числе
//                         банка-эмитента Привязанной банковской карты) и других участников расчетов.
//                         По истечении
//                     </span>
//                 </li>
//             </ol>
//             <p className={styles.c119}>
//                 <span className={styles.c0}>
//                     14 (четырнадцати) календарных дней со дня совершения безналичного способа оплаты
//                     обращения о
//                 </span>
//             </p>
//             <p className={styles.c96}>
//                 <span className={styles.c0}>
//                     несогласии Пользователя с фактом и/или суммой оплаты безналичным способом оплаты
//                     и по иным связанным с использованием Привязанной карты в рамках использования
//                     Сервиса вопросам не принимаются.
//                 </span>
//             </p>
//             <ol className="c21 lst-kix_list_1-1" start={8}>
//                 <li className={styles.c41}>
//                     <span className={styles.c0}>
//                         ЧК «Adele Energy Group ltd.» оставляет за собой право в любой момент
//                         потребовать от Пользователя подтверждения данных, указанных им при заведении
//                         Привязанной банковской карты, и запросить, в связи с этим подтверждающие
//                         документы (в частности, документы, удостоверяющие личность),
//                         непредоставление которых может быть приравнено к предоставлению
//                         недостоверной информации и повлечь ограничение доступа Пользователя к
//                         использованию Приложения.
//                     </span>
//                 </li>
//                 <li className="c71 c124">
//                     <span className={styles.c0}>
//                         По заказу Исполнителя ЧК «Adele Energy Group ltd.» доводит до сведения
//                         Пользователей информацию о порядке оплаты Услуг по зарядке, оказанных
//                         Исполнителем. Оплата Услуги по зарядке в рамках настоящего Договора оферты
//                         может быть произведена безналичным способом путем использования Привязанной
//                         банковской карты или путем использования платежного приложения (Apple Pay,
//                         Google Pay и иные, если доступно), или с помощью сервиса «KaspiPay» (если
//                         доступно) в соответствии с условиями поставщика платежного приложения (далее
//                         – «Безналичный способ оплаты»). В случае оплаты Пользователем оказываемых
//                         Исполнителем Услуг по зарядке безналичным способом оплаты, ЧК «Adele Energy
//                         Group ltd.» действует по поручению, от имени и за счёт соответствующего
//                         Исполнителя с привлечением уполномоченного оператора по приему платежей, или
//                         оператора электронных денежных средств, или иных участников расчетов,
//                         информационно-технологического взаимодействия, и является получателем
//                         платежа в качестве агента Исполнителя.
//                     </span>
//                 </li>
//                 <li className="c71 c89">
//                     <span className={styles.c0}>
//                         При этом Пользователь принимает во внимание, что способ оплаты безналичным
//                         способом с помощью сервиса «KaspiPay» возможен исключительно с помощью
//                         доступа Пользователя к приложению
//                     </span>
//                 </li>
//             </ol>
//             <p className={styles.c74}>
//                 <span className={styles.c0}>«Kaspi.kz».</span>
//             </p>
//             <ol className="c21 lst-kix_list_1-1" start={11}>
//                 <li className={styles.c59}>
//                     <span className={styles.c0}>
//                         ЧК «Adele Energy Group ltd.» не гарантирует отсутствия ошибок и сбоев при
//                         безналичном способе оплаты.
//                     </span>
//                 </li>
//                 <li className="c95 li-bullet-5">
//                     <h2>
//                         <span className={styles.c4}>Возврат денежных средств</span>
//                     </h2>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-2 start" start={1}>
//                 <li className={styles.c7}>
//                     <span className={styles.c0}>
//                         При проведении онлайн-оплаты посредством платежных карт не допускается
//                         возврат наличными денежными средствами. Порядок возврата регулируется
//                         правилами международных платежных систем:
//                     </span>
//                 </li>
//             </ol>
//             <ul className="c21 lst-kix_list_2-0 start">
//                 <li className="c72 li-bullet-6">
//                     <span className={styles.c0}>
//                         Потребитель вправе отказаться от товара в любое время до его передачи, после
//                         передачи товара отказ необходимо оформить в течение 14 дней;
//                     </span>
//                 </li>
//                 <li className="c63 li-bullet-7">
//                     <span className={styles.c0}>
//                         Потребитель не вправе отказаться от товара надлежащего качества, имеющего
//                         индивидуально- определенные свойства, если указанный товар может быть
//                         использован исключительно приобретающим его человеком;
//                     </span>
//                 </li>
//                 <li className="c140 li-bullet-8">
//                     <span className={styles.c0}>
//                         При отказе от товара со стороны потребителя продавец должен вернуть ему
//                         денежную сумму, уплаченную потребителем, не позднее чем через десять дней со
//                         дня предъявления потребителем соответствующего требования.
//                     </span>
//                 </li>
//             </ul>
//             <ol className="c21 lst-kix_list_1-2 start" start={1}>
//                 <li className="c119 c71 c123">
//                     <span className={styles.c0}>
//                         Возврат денежных средств будет осуществлен на банковскую карту в течение 15
//                         рабочего дня со дня получения «Заявление о возврате денежных средств»
//                         Исполнителем, при условии подтверждением Пользователя о непредоставление
//                         товара и услуг Исполнителем.
//                     </span>
//                 </li>
//                 <li className={styles.c25}>
//                     <span className={styles.c0}>
//                         Для возврата денежных средств на банковскую карту необходимо заполнить
//                         «Заявление о возврате денежных средств», которое высылается по требованию
//                         компанией на электронный адрес, и отправить его вместе с приложением копии
//                         документа, удостоверяющего личность, по адресу{' '}
//                     </span>
//                     <span className={styles.c0}>
//                         <a className={styles.c20} href="mailto:info@adele.energy">
//                             info@adele.energy
//                         </a>
//                     </span>
//                 </li>
//                 <li className="c71 c79">
//                     <span className={styles.c0}>
//                         В случае положительного ответа в пользу Пользователя, сумма возврата будет
//                         равняться сумме покупки. Срок рассмотрения Заявления и возврата денежных
//                         средств начинает исчисляться с момента получения Исполнителем Заявления и
//                         рассчитывается в рабочих днях без учета праздников/выходных дней.
//                     </span>
//                 </li>
//             </ol>
//             <p className={styles.c90}>
//                 <span className={styles.c0}></span>
//             </p>
//             <ol className="c21 lst_kix_list_1_0" start={7}>
//                 <li className="c16 li-bullet-1">
//                     <h2>
//                         <span className={styles.c4}>
//                             ПРАВА И ОБЯЗАННОСТИ ЧК «Adele Energy Group ltd.»
//                         </span>
//                     </h2>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-1 start" start={1}>
//                 <li className="c81 li-bullet-9">
//                     <span className={styles.c4}>ЧК «Adele Energy Group ltd.» имеет право:</span>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-2 start" start={1}>
//                 <li className={styles.c47}>
//                     <span className={styles.c0}>
//                         В любое время в одностороннем порядке ограничить, расширить, изменить
//                         содержание Приложения и Сервиса, а также изменить и модифицировать
//                         Приложение и Сервис без предварительного уведомления Пользователя;
//                     </span>
//                 </li>
//                 <li className={styles.c47}>
//                     <span className={styles.c0}>
//                         В целях сбора статистических данных и идентификации Пользователя
//                         устанавливать и сохранять информацию об IP-адресах Пользователя,
//                         использовать файлы технической информации (cookies), размещаемые на
//                         персональном мобильном устройстве или персональном компьютере Пользователя;
//                     </span>
//                 </li>
//                 <li className="c123 c71 c129">
//                     <span className={styles.c0}>
//                         Во время использования Пользователем Сервиса предупреждать, уведомлять,
//                         информировать о несоблюдении Пользователем условий настоящего Договора
//                         оферты;
//                     </span>
//                 </li>
//                 <li className={styles.c37}>
//                     <span className={styles.c0}>
//                         Направлять Пользователю сообщения технического и информационного характера,
//                         касающиеся процесса использования Приложения и Сервиса и/или оказания Услуг
//                         по зарядке
//                     </span>
//                 </li>
//             </ol>
//             <p className={styles.c150}>
//                 <span className={styles.c0}>
//                     Исполнителем (последнее осуществляется по заказу Исполнителя) посредством
//                     функционала Приложения и посредством SMS-сообщений по номеру телефона,
//                     указанному Пользователем при регистрации, и сообщений на электронную почту;
//                 </span>
//             </p>
//             <ol className="c21 lst-kix_list_1-2" start={5}>
//                 <li className={styles.c27}>
//                     <span className={styles.c0}>
//                         Самостоятельно или с привлечением третьих лиц связываться с Пользователем по
//                         предоставленному номеру мобильного телефона или адресу электронной почты для
//                         целей проведения исследований для целей улучшения качества Приложения и
//                         Сервиса. При этом Пользователь может отказаться от участия в исследованиях
//                         путем обращения в Службу Поддержки;
//                     </span>
//                 </li>
//                 <li className={styles.c9}>
//                     <span className={styles.c0}>
//                         В случае нарушения Пользователем условия настоящего Договора оферты
//                         ограничить полностью или в части функциональные возможности Приложения и
//                         Сервиса;
//                     </span>
//                 </li>
//                 <li className="c117 c71">
//                     <span className={styles.c0}>
//                         При неоплате Услуг за один отчетный период Корпоративным клиентом ЧК «Adele
//                         Energy Group ltd.» ограничить для Корпоративного клиента полностью или в
//                         части функциональные Возможности Приложения и Сервиса;
//                     </span>
//                 </li>
//                 <li className={styles.c53}>
//                     <span className={styles.c0}>
//                         В случае подозрений в недобросовестном пользовании услугами по зарядке
//                         электрических транспортных средств со стороны Пользователя, для выяснения
//                         обстоятельств задерживать сумму по Заявкам, вызывающим подозрение, сроком до
//                         2 (двух) отчётных периодов.
//                     </span>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-1" start={2}>
//                 <li className="c126 li-bullet-9">
//                     <h2>
//                         <span className={styles.c4}>ЧК «Adele Energy Group ltd.» обязан:</span>
//                     </h2>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-2 start" start={1}>
//                 <li className={styles.c11}>
//                     <span className={styles.c0}>
//                         С учетом условий и ограничений, изложенных в настоящем Договоре оферты,
//                         обеспечить возможность Пользователю использовать Приложение и Сервис;
//                     </span>
//                 </li>
//                 <li className="c115 c71 c131">
//                     <span className={styles.c0}>
//                         Предоставить права использования Приложения и Сервиса, как предусмотрено
//                         настоящим Договором оферты;
//                     </span>
//                 </li>
//                 <li className={styles.c3}>
//                     <span className={styles.c0}>
//                         Оказывать Пользователю поддержку в части использования Приложения и Сервиса,
//                         оплаты Услуг по зарядке и в части пользования Услугами по зарядке (последнее
//                         осуществляется по заказу Исполнителя) посредством Службы Поддержки, через
//                         функционал Приложения или иным способом, при этом Пользователь должен
//                         обратиться за такой поддержкой в срок не позднее 14 дней с момента
//                         размещения Заявки, в отношении которой Пользователь запрашивает поддержку;
//                     </span>
//                 </li>
//                 <li className="c133 c71 c145">
//                     <span className={styles.c0}>
//                         Довести до Пользователя информацию об Исполнителе, выступающим исполнителем
//                         Услуги по зарядке.
//                     </span>
//                 </li>
//             </ol>
//             <p className="c75">
//                 <span className={styles.c0}></span>
//             </p>
//             <ol className="c21 lst_kix_list_1_0" start={8}>
//                 <li className="c135 li-bullet-4">
//                     <h1>
//                         <span className={styles.c4}>ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ</span>
//                     </h1>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-1 start" start={1}>
//                 <li className="c83 li-bullet-9">
//                     <h2>
//                         <span className={styles.c4}>Пользователь имеет право:</span>
//                     </h2>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-2 start" start={1}>
//                 <li className="c71 c130">
//                     <span className={styles.c0}>
//                         Использовать Приложение и Сервис способами, указанными в настоящем Договоре
//                         оферты, с учетом ограничений, установленных настоящим Договором оферты;
//                     </span>
//                 </li>
//                 <li className={styles.c57}>
//                     <span className={styles.c0}>
//                         Обращаться в Службу Поддержки для целей получения информации и консультаций
//                         по вопросам использования Приложения и Сервиса, оплаты Услуг по зарядке, и в
//                         части пользования Услугами по зарядке (последнее осуществляется по заказу
//                         Исполнителя)
//                     </span>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-1" start={2}>
//                 <li className="c32 li-bullet-10">
//                     <h2>
//                         <span className={styles.c4}>Пользователь обязуется:</span>
//                     </h2>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-2 start" start={1}>
//                 <li className="c93 li-bullet-11">
//                     <span className={styles.c0}>
//                         Соблюдать условия настоящего Договора оферты без каких-либо ограничений и
//                         изъятий;
//                     </span>
//                 </li>
//                 <li className="c71 c120">
//                     <span className={styles.c0}>
//                         Использовать Приложение и Сервис исключительно способами, указанными в
//                         настоящем Договоре оферты;
//                     </span>
//                 </li>
//                 <li className={styles.c57}>
//                     <span className={styles.c0}>
//                         Не нарушать каким-либо образом права интеллектуальной собственности ЧК
//                         «Adele Energy Group ltd.» в отношении Приложения и Сервиса и/или каких-либо
//                         составляющих Приложения и Сервиса, в частности, Пользователь не имеет права
//                         распространять, доводить до всеобщего сведения, изменять, модифицировать,
//                         осуществлять декомпиляцию или дизассемблирование Приложения и Сервиса,
//                         копировать, транслировать, рассылать, публиковать, и иным образом
//                         распространять и воспроизводить материалы (текстовые, графические,
//                         аудио-видео), находящиеся в составе Приложения без письменного согласия ЧК
//                         «Adele Energy Group ltd.»;
//                     </span>
//                 </li>
//                 <li className={styles.c3}>
//                     <span className={styles.c0}>
//                         Самостоятельно предпринимать должные меры, обеспечивающие безопасность его
//                         Аккаунта и предотвращающие несанкционированное пользование третьими лицами
//                         Аккаунтом; самостоятельно предпринимать должные меры по обеспечению
//                         безопасности и конфиденциальности банковских карт Пользователя;
//                     </span>
//                 </li>
//                 <li className={styles.c44}>
//                     <span className={styles.c0}>
//                         Не осуществлять преднамеренные действия, направленные на создание чрезмерной
//                         нагрузки на сервера, на которых размещено Приложение и Сервисы;
//                     </span>
//                 </li>
//                 <li className={styles.c12}>
//                     <span className={styles.c0}>
//                         Не осуществлять любые действия, не направленные на обычное использование
//                         Приложения и Сервиса в соответствии с его функционалом, включая, но не
//                         ограничиваясь, не размещать Заявки в отсутствие реального потенциального
//                         желания воспользоваться Услугами по зарядке, не осуществлять изменение
//                         способа оплаты Услуг по зарядке с целью избежания оплаты Услуг по зарядке,
//                         не осуществлять мошеннические действия, направленные на получение скидок и
//                         иных преимуществ, включая, но не ограничиваясь, предусмотренных Программой
//                         лояльности для Пользователей, вступление с Исполнителем, выступающим
//                         непосредственным исполнителем Услуги по зарядке, в сговор для целей
//                         получения необоснованной выгоды;
//                     </span>
//                 </li>
//                 <li className="c102 c71 c143">
//                     <span className={styles.c0}>
//                         Бережно относится к оборудованию зарядной инфраструктуры, исключать случаи
//                         её порчи и/или загрязнения;
//                     </span>
//                 </li>
//                 <li className="c55 li-bullet-12">
//                     <span className={styles.c0}>
//                         Освободить парковочное место по завершению сессии зарядки;
//                     </span>
//                 </li>
//                 <li className="c69 li-bullet-13">
//                     <span className={styles.c0}>
//                         Исполнять иные обязательства, предусмотренные настоящим Договором оферты.
//                     </span>
//                 </li>
//             </ol>
//             <ol className="c21 lst_kix_list_1_0" start={9}>
//                 <li className="c149 li-bullet-4">
//                     <h1>
//                         <span className={styles.c4}>ПРАВА И ОБЯЗАННОСТИ ИСПОЛНИТЕЛЯ</span>
//                     </h1>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-1 start" start={1}>
//                 <li className="c154 li-bullet-14">
//                     <h2>
//                         <span className={styles.c4}>Исполнитель имеет право:</span>
//                     </h2>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-2 start" start={1}>
//                 <li className="c17 li-bullet-12">
//                     <span className={styles.c0}>
//                         Устанавливать тарифы на услуги по зарядке электрических транспортных средств
//                         для каждой{' '}
//                     </span>
//                     <span className={styles.c0}>ЭЗС.</span>
//                 </li>
//                 <li className="c17 li-bullet-15">
//                     <span className={styles.c0}>
//                         В любое время в одностороннем порядке осуществлять ввод/вывод в/из
//                         эксплуатации ЭЗС.
//                     </span>
//                 </li>
//                 <li className="c17 li-bullet-16">
//                     <span className={styles.c0}>
//                          Передать право требования не поступившей оплаты от Пользователя за
//                         оказанные Услуги по
//                     </span>
//                 </li>
//             </ol>
//             <p className={styles.c76}>
//                 <span className={styles.c98}>
//                     зарядке третьим лицам с уведомлением Пользователя о состоявшемся переходе прав
//                     кредитора к другому лицу.
//                 </span>
//             </p>
//             <ol className="c21 lst-kix_list_1-1" start={2}>
//                 <li className="c144 li-bullet-17">
//                     <h2>
//                         <span className={styles.c4}>Исполнитель обязуется:</span>
//                     </h2>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_1-2 start" start={1}>
//                 <li className="c102 c71 c142">
//                     <span className={styles.c0}>
//                         Обеспечить совместно с подключение, корректную работу ЭЗС и максимальную
//                         техническую готовность всех подключенных к Сервису.
//                     </span>
//                 </li>
//                 <li className="c71 c88">
//                     <span className={styles.c0}>
//                         Обеспечить совместно с ЧК «Adele Energy Group ltd.» своевременное
//                         информирование Пользователей о изменении тарифов на услуги по зарядке
//                         электрических транспортных средств.
//                     </span>
//                 </li>
//                 <li className="c110 c115 c71">
//                     <span className={styles.c0}>
//                         Обеспечить совместно с ЧК «Adele Energy Group ltd.» своевременное
//                         информирование Пользователей о изменении состава ЭЗС доступных к оказанию
//                         услуги по зарядке электрических транспортных средств.
//                     </span>
//                 </li>
//                 <li className="c102 c71 c127">
//                     <span className={styles.c0}>
//                         Предпринять все необходимые меры для предоставления услуг на ЭЗС
//                         Пользователям с учётом утверждённого порогового значения показателя
//                         простоя/недоступности ЭЗС для оказания услуг по зарядке электрических
//                         транспортных средств.
//                     </span>
//                 </li>
//                 <li className="c71 c103">
//                     <span className={styles.c0}>
//                         Производить за свой счёт работы по капитальному и текущему ремонту (в том
//                         числе в случае возникновения технологических нарушений), эксплуатации и
//                         техническому обслуживанию ЭЗС.
//                     </span>
//                 </li>
//                 <li className={styles.c35}>
//                     <span className={styles.c0}>
//                         Нести все расходы на содержание и работоспособность ЭЗС (в том числе
//                         оплачивать электроэнергию и услуги беспроводной связи).
//                     </span>
//                 </li>
//                 <li className={styles.c110 + ' ' + styles.c102 + ' ' + styles.c71}>
//                     <span className={styles.c0}>
//                         Содержать ЭЗС в полной исправности и в надлежащем состоянии, соблюдать
//                         требования противопожарной безопасности, техники безопасности и охраны
//                         труда, производственной ЧК «Adele Energy Group ltd.» санитарии,
//                         экологического и природоохранного законодательства, правил технической
//                         эксплуатации электрических станций и сетей Республики Казахстан, а также
//                         использовать ЭЗС в соответствии с иными нормативно-правовыми актами
//                         Республики Казахстан.
//                     </span>
//                 </li>
//                 <li className={styles.c71 + ' ' + styles.c117}>
//                     <span className={styles.c0}>
//                         Гарантировать функционирование круглосуточной поддержки Пользователей
//                         второго уровня, а именно обрабатывать обращения, поступающие от ЧК «Adele
//                         Energy Group ltd.» с первого уровня поддержки Пользователей, связанные с
//                         технической исправностью и работоспособностью ЭЗС и. т.п.
//                     </span>
//                 </li>
//                 <li className={styles.c3}>
//                     <span className={styles.c0}>
//                         В полном объёме нести ответственность за любой вред, причинённый третьим
//                         лицам (Пользователям) или их имуществу в результате оказания услуг по
//                         зарядке электрических транспортных средств.
//                     </span>
//                 </li>
//             </ol>
//             <ol className="c21 lst_kix_list_1_0" start={10}>
//                 <li className="c42 li-bullet-18">
//                     <h1>
//                         <span className={styles.c4}>ОТВЕТСТВЕННОСТЬ</span>
//                     </h1>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_3-2 start" start={1}>
//                 <li className={styles.c62}>
//                     <span className={styles.c0}>
//                         Приложение, Сервис и права их использования в соответствии с настоящим
//                         Договором оферты предоставляются Пользователю в состоянии «как есть».
//                     </span>
//                 </li>
//                 <li className={styles.c53}>
//                     <span className={styles.c0}>
//                         ЧК «Adele Energy Group ltd.» не гарантирует, что: 1) Приложение и Сервис
//                         будут удовлетворять субъективным ожиданиям и требованиям Пользователя; 2)
//                         использование Приложения и Сервиса будут протекать непрерывно, быстро, без
//                         технических сбоев, надежно и без ошибок; 3) результаты использования
//                         Пользователем Приложения и Сервиса будут безошибочными; 4) Приложение и
//                         Сервис будут доступны и могут быть использованы круглосуточно, в какой-то
//                         конкретный момент времени или в течение какого- либо периода времени. ЧК
//                         «Adele Energy Group ltd.» не несет ответственности за временные перебои,
//                         технические неполадки и прекращение работы Приложения и Сервиса на
//                         персональном мобильном устройстве Пользователя.
//                     </span>
//                 </li>
//                 <li className={styles.c62}>
//                     <span className={styles.c0}>
//                         ЧК «Adele Energy Group ltd.» не несет ответственности за возникновение
//                         убытков, прямого или косвенного ущерба, возникшего у Пользователя в связи с
//                         использованием и/или невозможностью использования Приложения и Сервиса.
//                     </span>
//                 </li>
//                 <li className={styles.c3}>
//                     <span className={styles.c0}>
//                         ЧК «Adele Energy Group ltd.» не несет ответственности за вред, причиненный
//                         Пользователю и лицам, в отношении которых Пользователем была размещена
//                         Заявка, в процессе оказания Услуг по зарядке. Ответственность за вред,
//                         причиненный Пользователю и лицам, в отношении которых, Пользователем была
//                         размещена Заявка в процессе оказания Услуг по зарядке несет Исполнитель в
//                         соответствии с положениями применимого законодательства.
//                     </span>
//                 </li>
//                 <li className="c71 c121">
//                     <span className={styles.c0}>
//                         ЧК «Adele Energy Group ltd.» не несет ответственности за содержание и/или
//                         актуальность информации, предоставляемой Исполнителем, включая информацию об
//                         Исполнителе. ЧК «Adele Energy
//                     </span>
//                 </li>
//             </ol>
//             <p className={styles.c22}>
//                 <span className={styles.c0}>
//                     Group ltd.» не несет ответственности за финансовые и любые другие операции,
//                     совершаемые Пользователем и Исполнителем, а также за любые последствия
//                     приобретения Пользователем Услуг по зарядке, оказываемых Исполнителем.
//                 </span>
//             </p>
//             <ol className="c21 lst-kix_list_3-2" start={6}>
//                 <li className={styles.c49}>
//                     <span className={styles.c0}>
//                         ЧК «Adele Energy Group ltd.» не несет ответственности за отсутствие у
//                         Пользователя доступа в Интернет, за качество услуг провайдеров связи сети
//                         Интернет, за качество услуг телефонной связи, с которыми Пользователем
//                         заключены соглашения о предоставлении услуг связи.
//                     </span>
//                 </li>
//                 <li className={styles.c45}>
//                     <span className={styles.c0}>
//                         Пользователь несет ответственность за нарушение прав интеллектуальной
//                         собственности и иных прав ЧК «Adele Energy Group ltd.» в соответствии с
//                         применимым законодательством.
//                     </span>
//                 </li>
//                 <li className="c110 c71 c115">
//                     <span className={styles.c0}>
//                         ЧК «Adele Energy Group ltd.» не несет ответственности за вред и убытки,
//                         причиненные Пользователю при использовании Сервисов третьих лиц.
//                         Ответственность партнера ЧК «Adele Energy Group ltd.», предоставляющего
//                         Сервис третьего лица, определяется на основании заключенного Пользователем и
//                         партнером ЧК «Adele Energy Group ltd.» договора и действующего
//                         законодательства.
//                     </span>
//                 </li>
//             </ol>
//             <p className={styles.c6}>
//                 <span className={styles.c0}></span>
//             </p>
//             <ol className="c21 lst_kix_list_1_0" start={11}>
//                 <li className="c73 li-bullet-4">
//                     <h1>
//                         <span className={styles.c4}>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</span>
//                     </h1>
//                 </li>
//             </ol>
//             <ol className="c21 lst-kix_list_4-2 start" start={1}>
//                 <li className={styles.c27}>
//                     <span className={styles.c0}>
//                         Настоящий Договор оферты действует с момента акцепта Пользователем оферты,
//                         как предусмотрено настоящим Договором оферты, и действует до полной оплаты
//                         Пользователем Услуги по зарядке.
//                     </span>
//                 </li>
//                 <li className={styles.c54}>
//                     <span className={styles.c0}>
//                         Настоящий Договор оферты, а также применимые документы могут быть изменены
//                         Исполнителем без какого-либо предварительного уведомления. Любые изменения в
//                         Договоре оферты и применимых документах вступают в силу в день, следующий за
//                         днем опубликования Договора оферты и применимых документов в измененной
//                         версии. Пользователь обязуется самостоятельно проверять Договор оферты и
//                         применимые документы на предмет изменений. Неосуществление Пользователем
//                         действий по ознакомлению с Договором оферты и применимыми документами и/или
//                         измененной редакцией Договора оферты и применимых документов не может
//                         служить основанием для неисполнения Пользователем своих обязательств и
//                         несоблюдения Пользователем ограничений, установленных Договором оферты и
//                         применимыми документами. Пользователь самостоятельно несет риск
//                         возникновения неблагоприятных последствий, вызванных не ознакомлением
//                         Пользователя с измененной редакцией Договора оферты и применимых документов.
//                     </span>
//                 </li>
//                 <li className="c71 c107">
//                     <span className={styles.c0}>
//                         Договор оферты регулируется и толкуется в соответствии с законодательством
//                         Республики Казахстан. Вопросы, не урегулированные Договором оферты, подлежат
//                         разрешению в соответствии с законодательством Республики Казахстан.
//                     </span>
//                 </li>
//                 <li className={styles.c54}>
//                     <span className={styles.c0}>
//                         В случае возникновения любых споров или разногласий, связанных с исполнением
//                         Договора оферты, Стороны приложат все усилия для их разрешения путем
//                         проведения переговоров. В случае если споры не будут разрешены путем
//                         переговоров, споры подлежат разрешению в порядке, установленном
//                         законодательством Республики Казахстан.
//                     </span>
//                 </li>
//                 <li className="c152 li-bullet-19">
//                     <span className={styles.c0}>
//                         К настоящему Договору прилагается и является его неотъемлемой частью:
//                     </span>
//                 </li>
//             </ol>
//             <p className={styles.c87}>
//                 <span className={styles.c0}>
//                     Приложение №1 – Перечень Партнёров, оказывающих Услуги по зарядке электрических
//                     транспортных средств;
//                 </span>
//             </p>
//             <p className={styles.c61}>
//                 <span className={styles.c0}>
//                     Приложение №2 - Согласие Пользователя на обработку персональных данных.
//                 </span>
//             </p>
//             <p className={styles.c101}>
//                 <span className={styles.c0}>Приложение №1</span>
//             </p>
//             <p className={styles.c147}>
//                 <span className={styles.c0}>
//                     к Договор оферты (пользовательское соглашение) оказания услуг по зарядке
//                     электрического транспортного средства
//                 </span>
//             </p>
//             <p className={styles.c58}>
//                 <span className={styles.c0}></span>
//             </p>
//             <h2 className={styles.c153}>
//                 <span className={styles.c4}>
//                     Перечень партнёров, оказывающих услуги по зарядке электрических транспортных
//                     средств
//                 </span>
//             </h2>
//             <a id="t.1b44c9cd2bcc7d2e8204269173d63527aa87c9b9"></a>
//             <a id="t.0"></a>
//             <table className={styles.c65}>
//                 <tbody>
//                     <tr className={styles.c33}>
//                         <td className={styles.c8} colSpan={1} rowSpan={1}>
//                             <p className={styles.c70}>
//                                 <span className={styles.c0}>№</span>
//                             </p>
//                         </td>
//                         <td className={styles.c52} colSpan={1} rowSpan={1}>
//                             <p className="c1 c138">
//                                 <span className={styles.c0}>
//                                     Наименование партнера и его данные
//                                 </span>
//                             </p>
//                         </td>
//                         <td className={styles.c112} colSpan={1} rowSpan={1}>
//                             <p className={styles.c1}>
//                                 <span className={styles.c0}>
//                                     Местонахождение Исполнителя и контактные данные
//                                 </span>
//                             </p>
//                         </td>
//                         <td className={styles.c30} colSpan={1} rowSpan={1}>
//                             <p className={styles.c116}>
//                                 <span className={styles.c0}>Адреса сайта Исполнителя</span>
//                             </p>
//                         </td>
//                     </tr>
//                     <tr className={styles.c148}>
//                         <td className={styles.c8} colSpan={1} rowSpan={1}>
//                             <p className={styles.c58}>
//                                 <span className={styles.c4}></span>
//                             </p>
//                             <p className={styles.c26}>
//                                 <span className={styles.c0}>1</span>
//                             </p>
//                         </td>
//                         <td className={styles.c52} colSpan={1} rowSpan={1}>
//                             <p className={styles.c15}>
//                                 <span className={styles.c0}>
//                                     ТОО «Adele Energy Qazaqstan» БИН: 220440045257
//                                 </span>
//                             </p>
//                         </td>
//                         <td className={styles.c112} colSpan={1} rowSpan={1}>
//                             <p className={styles.c146}>
//                                 <span className={styles.c0}>
//                                     Республика Казахстан, город Астана, район Есиль, проспект
//                                     Мангилик Ел, 55/22, блок С3.5, офис 314{' '}
//                                 </span>
//                                 <span className="c98 c108">
//                                     <a className={styles.c20} href="mailto:aeq@adele.energy">
//                                         aeq@adele.energy
//                                     </a>
//                                 </span>
//                             </p>
//                             <p className={styles.c134}>
//                                 <span className={styles.c0}>+7 777 00 11350</span>
//                             </p>
//                         </td>
//                         <td className={styles.c30} colSpan={1} rowSpan={1}>
//                             <p className={styles.c39}>
//                                 <span className={styles.c0}>https://adele.energy</span>
//                             </p>
//                         </td>
//                     </tr>
//                     <tr className={styles.c84}>
//                         <td className={styles.c8} colSpan={1} rowSpan={1}>
//                             <p className={styles.c58}>
//                                 <span className={styles.c4}></span>
//                             </p>
//                             <p className={styles.c26}>
//                                 <span className={styles.c0}>2</span>
//                             </p>
//                         </td>
//                         <td className={styles.c52} colSpan={1} rowSpan={1}>
//                             <p className={styles.c39}>
//                                 <span className={styles.c0}>ТОО «Оператор ЭЗС»</span>
//                             </p>
//                         </td>
//                         <td className={styles.c112} colSpan={1} rowSpan={1}>
//                             <p className={styles.c19}>
//                                 <span className={styles.c0}>
//                                     Республика Казахстан, город Астана, район Есиль, проспект
//                                     Кабанбай батыра, 11/5, Офис 704, 705.{' '}
//                                 </span>
//                                 <span className="c108 c98">
//                                     <a className={styles.c20} href="mailto:info@oezs.kz">
//                                         info@oezs.kz
//                                     </a>
//                                 </span>
//                             </p>
//                             <p className={styles.c97}>
//                                 <span className={styles.c0}>+7 (702) 520-03-51</span>
//                             </p>
//                         </td>
//                         <td className={styles.c30} colSpan={1} rowSpan={1}>
//                             <p className={styles.c39}>
//                                 <span className={styles.c0}>https://oezs.kz/ru/</span>
//                             </p>
//                         </td>
//                     </tr>
//                 </tbody>
//             </table>
//             <p className={styles.c122}>
//                 <span className={styles.c0}></span>
//             </p>
//             <p className={styles.c101}>
//                 <span className={styles.c0}>Приложение №2</span>
//             </p>
//             <p className={styles.c147}>
//                 <span className={styles.c0}>
//                     к Договор оферты (пользовательское соглашение) оказания услуг по зарядке
//                     электрического транспортного средства
//                 </span>
//             </p>
//             <p className={styles.c58}>
//                 <span className={styles.c0}></span>
//             </p>
//             <h2 className={styles.c111}>
//                 <span className={styles.c4}>
//                     Согласие на сбор, обработку, хранение и передачу персональных данных
//                 </span>
//             </h2>
//             <p className={styles.c80}>
//                 <span className={styles.c4}></span>
//             </p>
//             <p className={styles.c29}>
//                 <span className={styles.c0}>
//                     В соответствии с Законом Республики «О персональных данных и их защите» (далее –
//                     Закон), при принятии Договора оферты оказания услуг по зарядке электрического
//                     транспортного средства, даю свое согласие ЧК «Adele Energy Group ltd.» (далее -
//                     Исполнитель), на сбор и обработку персональных данных, запись, систематизацию,
//                     накопление, хранение, уточнение (обновление, изменение), извлечение,
//                     использование, обезличивание, блокирование, удаление и уничтожение, в том числе
//                     автоматизированные, своих персональных данных в специализированной электронной
//                     базе данных о моих фамилии, имени, отчестве, дате и месте рождения, адресе, а
//                     также иной информации личного характера, которая может быть использована при
//                     предоставлении Исполнителем услуг по зарядке электрического транспортного
//                     средства, и в целях участия в опросах/анкетировании, проводимых Исполнителем для
//                     изучения и исследования мнения клиентов о качестве обслуживания и услугах
//                     Исполнителя, при условии гарантии неразглашения данной информации третьим лицам,
//                     за исключением лиц от имени и по поручениюкоторых Исполнитель заключает с
//                     Пользователями договор оказания услуг по зарядке электрических транспортных
//                     средств.
//                 </span>
//             </p>
//             <p className={styles.c109}>
//                 <span className={styles.c0}>
//                     В соответствии с подпунктом 2) статьи 1 Закона под персональными данными
//                     понимаются сведения, относящиеся к определенному или определяемому на их
//                     основании субъекту персональных данных (физическому лицу, к которому относятся
//                     персональные данные), зафиксированные на электронном, бумажном и (или) ином
//                     материальном носителе.
//                 </span>
//             </p>
//             <p className={styles.c136}>
//                 <span className={styles.c0}>
//                     Я согласен на предоставление мне информации путем направления почтовой
//                     корреспонденции по моему домашнему адресу, посредством электронной почты,
//                     телефонных обращений, СМС-сообщений.
//                 </span>
//             </p>
//             <p className={styles.c38}>
//                 <span className={styles.c0}></span>
//             </p>
//             <p className={styles.c51}>
//                 <span className={styles.c0}>
//                     Данное согласие действует с момента принятии Договора оферты оказания услуг по
//                     зарядке электрического транспортного средства и пяти лет после прекращения
//                     указанных услуг. По истечении указанного срока действие настоящего заявления
//                     считается продленным на каждые следующие 5 лет при отсутствии у Исполнителя
//                     сведений о его отзыве.
//                 </span>
//             </p>
//             <p className={styles.c77}>
//                 <span className={styles.c0}>
//                     Данное согласие может быть отозвано путем представления Исполнителю письменного
//                     заявления. Договор оферты согласован:
//                 </span>
//             </p>
//             <h2 className={styles.c36}>
//                 <span className={styles.c4}>Исполнитель:</span>
//             </h2>
//             <p className={styles.c156}>
//                 <span className={styles.c0}>Генеральный директор</span>
//             </p>
//             <p className={styles.c125}>
//                 <span className={styles.c0}>ЧК «Adele Energy Group ltd.»</span>
//             </p>
//             <p className={styles.c94}>
//                 <span className={styles.c0}></span>
//             </p>
//             <p className={styles.c85}>
//                 <span className={styles.c43}>                   </span>
//                 <span className={styles.c86}>/</span>
//                 <span className={styles.c43}>                  </span>
//                 <span className="c86 c106">/</span>
//             </p>
//             <p className={styles.c85}>
//                 <span className={styles.c86}>м. п.</span>
//             </p>
//         </div>
