import { IStation } from '../station/types';
import { IBusinessCurrency, ICharge } from '../types';

export interface IGuest {
    liqpay: ILiqPay;
    currency: string;
    archived: boolean;
    paymentStatus: string;
    _id: string;
    station: IStation;
    connectorId: number;
    energy: number;
    priceKW: number;
    rentPriceKW: number;
    priceTotal: number;
    language: string;
    phone: string;
    date: string;
    business: IGuestBusiness;
    charge: ICharge;
    payment: IPaymentType<ILiqPay | IIoka | IMonoBank>;
}

export interface IGuestBusiness {
    name: string;
    site: string;
    currency: IBusinessCurrency;
}

export interface ICreateGuestRequest {
    connectorId: number;
    energy: number;
    language: string;
    phone: string;
    stationId: string;
}

export enum EPaymentProvider {
    IOKA = 'IOKA',
    LIQPAY = 'LIQPAY',
    MONO_BANK = 'MONO_BANK',
}

export interface IPaymentType<Provider> {
    provider: EPaymentProvider;
    content: Provider;
}

export interface IMonoBank {
    request: IMonoBankRequest;
}

export interface IMonoBankRequest {
    invoiceId: string;
    pageUrl: string;
}

export interface ILiqPay {
    request: ILiqPayRequest;
}

export interface ILiqPayRequest {
    data: string;
    signature: string;
}

export interface IIoka {
    request: IIokaRequest;
}

export interface IIokaRequest {
    order: IIokaOrder;
    order_access_token: string;
}

export interface IIokaOrder {
    id: string;
}
