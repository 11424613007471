export const fullFillLink = (link: string): string => {
    if (!link) return '';
    if (link.substr(0, 4) === 'http') return link;
    return `http://${link}`;
};

export const dateWithoutTimeZone = (date: number | string | Date) => {
    const currentDate = new Date(date);
    return new Date(currentDate.toISOString().slice(0, 19).replace('T', ' '));
};

export const getDurationInMinutes = (
    start: number | string | Date,
    end?: number | string | Date
): number => {
    const startNew = dateWithoutTimeZone(start);
    const endNew = end ? dateWithoutTimeZone(end) : dateWithoutTimeZone(new Date());
    return Math.floor(Math.abs(endNew.getTime() - startNew.getTime()) / (1000 * 60));
};

export const changeFavicon = (src: string): void => {
    const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
    let link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");

    if (!link) {
        link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        head.appendChild(link);
    }

    link.href = src;
};
