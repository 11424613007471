/** @jsxImportSource @emotion/react */
import React, { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';

import menuWhiteIcon from '../../../../assets/menu/menu-white.svg';
import menuBlackIcon from '../../../../assets/menu/menu-black.svg';
import { Conditions } from '../conditions/conditions.component';
import { AppTheme } from '../../../../app.theme';

import menuStyles from './menu.styles';

type PropTypes = {
    color?: 'white' | 'black';
};

export const Menu: FC<PropTypes> = ({ color = 'white' }) => {
    const { menuAboutTranslateKey, menuAboutLink, menuNewsLink } = AppTheme();
    const intl = useIntl();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    return (
        <>
            <div
                css={[
                    menuStyles.menuButton,
                    color === 'white' ? menuStyles.menuButtonWhite : menuStyles.menuButtonBlack,
                ]}>
                <img
                    onClick={() => setModalIsOpen(true)}
                    src={color === 'white' ? menuWhiteIcon : menuBlackIcon}
                    alt={'Menu icon'}
                />
            </div>
            {modalIsOpen && (
                <div css={menuStyles.menuModal}>
                    <div css={menuStyles.menuModalWrapper}>
                        <i
                            onClick={() => setModalIsOpen(false)}
                            className={'icon-close'}
                            style={{ fontSize: '30px' }}
                        />
                        <ul>
                            <li>
                                <NavLink onClick={() => setModalIsOpen(false)} to={'/'}>
                                    {intl.formatMessage({ id: 'menu.main' })}
                                </NavLink>
                            </li>
                            <li>
                                <a
                                    onClick={() => setModalIsOpen(false)}
                                    href={menuNewsLink}
                                    target={'_blank'}
                                    rel="noreferrer">
                                    {intl.formatMessage({ id: 'menu.news' })}
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={() => setModalIsOpen(false)}
                                    href={menuAboutLink}
                                    target={'_blank'}
                                    rel="noreferrer">
                                    {intl.formatMessage({ id: menuAboutTranslateKey })}
                                </a>
                            </li>
                        </ul>
                        <div css={menuStyles.menuFooter}>
                            <Conditions color={'white'} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
