/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';

import { PoweredBy } from '../../../components/powered-by/powered-by.component';
import { BankInfo } from '../../../components/bank-info/bank-info.component';

export const MainFooter: FC = () => {
    return (
        <div>
            <PoweredBy />
            <BankInfo />
        </div>
    );
};
