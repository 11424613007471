import { css } from '@emotion/react';

const mainLayoutStyles = {
    container: css({
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '600px',
        position: 'relative',
        margin: '0 auto',
        flexGrow: 1,
        width: '100%',
        justifyContent: 'space-between',
    }),
};

export default mainLayoutStyles;
