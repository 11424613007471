/** @jsxImportSource @emotion/react */
import React, { FC, useState } from 'react';
import InputMask from 'react-input-mask';
import { useIntl } from 'react-intl';

import { ISetUpState, SetUpStep } from '../../set-up.view';
import setEnergyStyles from '../set-energy/set-energy.styles';
import { Button } from '../../../../components/button/button.component';

import setPhoneStyles from './set-phone.styles';

type PropTypes = {
    phone?: string;
    changeStep: (step: SetUpStep) => void;
    onSetSetUpStep: (state: ISetUpState) => void;
};

const phoneFormats: { [k: string]: string } = {
    '+380': '+999 99 999 99 99',
    '+7': '+9 999 999 99 99',
};
export const SetPhone: FC<PropTypes> = ({ onSetSetUpStep, changeStep }) => {
    const intl = useIntl();
    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const [phone, setPhone] = useState<string>(localStorage.getItem('phone') || '');

    const getMask = () => {
        for (const code of Object.keys(phoneFormats)) {
            if (phone?.startsWith(code)) return phoneFormats[code];
        }
        return '+999999999999';
    };

    const handleSubmit = () => {
        if (phone.includes('_')) {
            setIsInvalid(true);
        } else {
            setIsInvalid(false);
            onSetSetUpStep({ phone });
            localStorage.setItem('phone', phone);
        }
    };
    return (
        <>
            <h4 css={setPhoneStyles.containerTitle}>{intl.formatMessage({ id: 'enterPhone' })}</h4>
            <form css={setPhoneStyles.form}>
                <InputMask
                    css={setPhoneStyles.input}
                    mask={getMask()}
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    inputMode={'numeric'}
                    alwaysShowMask
                />
            </form>
            {isInvalid && (
                <div style={{ margin: '0 2rem' }}>
                    <small css={setPhoneStyles.errorMessage}>
                        {intl.formatMessage({ id: 'invalidPhone' })}
                    </small>
                </div>
            )}
            <p css={setPhoneStyles.description}>{intl.formatMessage({ id: 'enterPhoneInfo' })}</p>

            <div css={setEnergyStyles.actionWrapper}>
                <Button
                    type={'link'}
                    text={intl.formatMessage({ id: 'back' })}
                    onClick={() => changeStep('energy')}
                />

                <Button text={intl.formatMessage({ id: 'next' }) + '  ➜'} onClick={handleSubmit} />
            </div>
        </>
    );
};
