/** @jsxImportSource @emotion/react */
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Button } from '../../../../components/button/button.component';
import { selectCardTransaction } from '../../../../store/card-transaction/card-transaction.selector';
import { useCardTransaction } from '../../../../hooks/card-transaction.hook';

import idTagStyles from './id-tag.styles';

type PropTypes = {
    guestId: string;
    businessId: string;
    onBack: () => void;
    onSuccess: (id: string) => void;
};

export const IdTag: FC<PropTypes> = ({ onSuccess, onBack, guestId, businessId }) => {
    const intl = useIntl();
    const { createCardTransaction, clearCardTransaction } = useCardTransaction();
    const { entry: cardTransaction, error } = useSelector(selectCardTransaction);
    const [cardTagId, setCardTagId] = useState<string>();

    const handleSubmit = () => {
        if (cardTagId) {
            createCardTransaction({
                guestId,
                cardTagId,
                businessId,
            }).then();
        }
    };

    useEffect(() => {
        if (cardTransaction && cardTransaction.status === 'OK') {
            onSuccess(guestId);
        }
    }, [cardTransaction]);

    useEffect(() => {
        return (): void => {
            clearCardTransaction().then();
        };
    }, []);

    return (
        <>
            <h4 css={idTagStyles.containerTitle}>
                {intl.formatMessage({ id: 'payment.idTag.title' })}
            </h4>
            <form css={idTagStyles.form}>
                <input css={idTagStyles.input} onChange={e => setCardTagId(e.target.value)} />
            </form>
            {error?.message && <div css={idTagStyles.errorMessage}>Id тег не найден</div>}

            <div css={idTagStyles.actionWrapper}>
                <Button
                    type={'link'}
                    text={intl.formatMessage({ id: 'back' })}
                    onClick={() => onBack()}
                />

                <Button text={intl.formatMessage({ id: 'next' }) + '  ➜'} onClick={handleSubmit} />
            </div>
        </>
    );
};
