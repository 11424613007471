import { css } from '@emotion/react';

const stationLayoutStyles = {
    container: css({
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '600px',
        position: 'relative',
        margin: '0 auto',
        flexGrow: 1,
        width: '100%',
    }),
    outletWrapper: css({
        flex: 1,
        padding: '0 20px',
        marginBottom: '50px',
        color: 'black',
    }),
};

export default stationLayoutStyles;
