import { css } from '@emotion/react';

const stationItemStyles = {
    container: css({
        display: 'flex',
        backgroundColor: '#F6F8FA',
        boxShadow: '0px 2px 10px 8px rgba(152, 152, 152, 0.15)',
        borderRadius: '8px',
        justifyContent: 'space-between',
        gap: '15px',
    }),
    infoBlock: css({
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        gap: '3px',
        padding: '8px 0 8px 10px',
    }),
    infoBlockBadges: css({
        display: 'flex',
        gap: '10px',
    }),
    infoAction: css({
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
        alignItems: 'center',
        justifyContent: 'space-between',
    }),
    powerBadge: css({
        display: 'flex',
        padding: '3px 8px',
        gap: '5px',
        background: '#000000',
        borderRadius: '3px',
        alignItems: 'center',
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '12px',
        lineHeight: '10px',
        color: '#FFFFFF',
    }),
    connectorsBadge: css({
        display: 'flex',
        padding: '3px 8px',
        gap: '5px',
        background: '#FCE03E',
        borderRadius: '3px',
        alignItems: 'center',
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '12px',
        lineHeight: '10px',
    }),
    title: css({
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '13px',
        lineHeight: '15px',
    }),
    subtitle: css({
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '10px',
        lineHeight: '15px',
    }),
    abbreviation: css({
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: '16px',
        lineHeight: '18px',
    }),
};

export default stationItemStyles;
