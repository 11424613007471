import { createAction, createSlice } from '@reduxjs/toolkit';

export type AppState = {
    loading: boolean;
    locale: string;
};

const initialState: AppState = {
    loading: true,
    locale: localStorage.getItem('language') || 'ru',
};

export const setAppLoading = createAction<boolean>('app/SetLoading');
export const setAppLocale = createAction<string>('app/SetLocale');

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(setAppLoading, (state, { payload }) => {
            state.loading = payload;
        });
        builder.addCase(setAppLocale, (state, { payload }) => {
            state.locale = payload;
            state.loading = false;
            localStorage.setItem('language', payload);
        });
    },
});

export const appReducer = appSlice.reducer;
