/** @jsxImportSource @emotion/react */
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectGuest } from '../../../../store/guest/guest.selector';
import { StopConfirm } from '../stop-confirm/stop-confirm.component';
import { stopCharge } from '../../../../api/guest/guest.api';
import { selectCharge } from '../../../../store/charge/charge.selector';
import { AppTheme } from '../../../../app.theme';

import stopButtonStyles from './stop-button.styles';

interface IStopButtonState {
    showConfirmButton: boolean;
    stopping: boolean;
}

export const StopButton: FC = () => {
    const { buttonPrimaryColor, buttonPrimaryTextColor } = AppTheme();
    const intl = useIntl();
    const { entry: guest } = useSelector(selectGuest);
    const { entry: charge } = useSelector(selectCharge);
    const [stopButtonState, setStopButtonState] = useState<IStopButtonState>({
        showConfirmButton: false,
        stopping: false,
    });

    const updateState = (showConfirmButton: boolean, stopping: boolean) => {
        setStopButtonState(prevState => {
            return {
                ...prevState,
                showConfirmButton,
                stopping,
            };
        });
    };

    const onStopConfirm = (stop: boolean) => {
        updateState(false, stop);
        if (stop) {
            stopCharge(guest._id)
                .then(data => {
                    console.log(data);
                    updateState(false, stopButtonState.stopping);
                })
                .catch(error => {
                    console.error(error);
                    updateState(false, stopButtonState.stopping);
                });
        }
    };

    return (
        <>
            <button
                css={[
                    stopButtonStyles.powerStopButton,
                    { backgroundColor: buttonPrimaryColor, color: buttonPrimaryTextColor },
                ]}
                onClick={() => updateState(true, stopButtonState.stopping)}
                disabled={stopButtonState.stopping}>
                {stopButtonState.stopping
                    ? `${intl.formatMessage({ id: 'stopping' })}...`
                    : intl.formatMessage({ id: 'stopCharge' })}
            </button>
            {stopButtonState.showConfirmButton && (
                <StopConfirm
                    currency={guest.currency}
                    // TODO: socket data
                    cost={Math.round(((guest.priceKW + guest.rentPriceKW) / 1000) * charge.energy)}
                    onStopConfirm={onStopConfirm}
                />
            )}
        </>
    );
};
