/** @jsxImportSource @emotion/react */
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useReadLocalStorage } from 'usehooks-ts';

import { selectGuest } from '../../store/guest/guest.selector';
import { useGuest } from '../../hooks/guest.hook';
import { useStations } from '../../hooks/stations.hook';
import { EPaymentProvider, IIoka, ILiqPay, IMonoBank } from '../../api/guest/types';
import { Button } from '../../components/button/button.component';
import { selectStation } from '../../store/station/station.selector';

import payStyles from './pay.styles';
import { IokaPaymentWidget } from './components/ioka/ioka-widget.component';
import { LiqpayWidget } from './components/liqpay/liqpay-widget.component';
import { IdTag } from './components/id-tag/id-tag.component';

export const Pay: FC = () => {
    const intl = useIntl();
    const navigation = useNavigate();
    const recentStations = useReadLocalStorage<string[]>('recentStations');

    const { guestId } = useParams();
    const { fetchGuestById, clearGuest } = useGuest();
    const { fetchStationById } = useStations();
    const { entry: guest, loading, error } = useSelector(selectGuest);
    const {
        entry: station,
        loading: stationLoading,
        error: stationError,
    } = useSelector(selectStation);
    const [acceptTerms, setAcceptTerms] = useState(false);

    const [showWidget, setShowWidget] = useState(false);
    const [showIdTag, setShowIdTag] = useState(false);

    function handleChangeTerms() {
        setAcceptTerms(!acceptTerms);
    }

    const handleOpenChargePage = (id: string) => {
        const recent = recentStations ? recentStations : [];
        recent.push(station._id);
        localStorage.setItem(
            'recentStations',
            JSON.stringify(recent.filter((v, i, a) => a.indexOf(v) === i))
        );
        localStorage.setItem('guestId', id);
        navigation(`/charge/${id}`);
    };

    const renderActivateByIdTag = () => {
        return (
            <IdTag
                guestId={guest._id}
                businessId={station.business._id}
                onSuccess={id => handleOpenChargePage(id)}
                onBack={() => setShowIdTag(false)}
            />
        );
    };

    const renderPaymentWidget = () => {
        const { payment } = guest;

        if (payment.provider === EPaymentProvider.MONO_BANK) {
            const content = payment.content as IMonoBank;
            window.location.replace(content.request.pageUrl);
            return;
        }

        if (payment.provider === EPaymentProvider.IOKA) {
            const content = payment.content as IIoka;
            return (
                <IokaPaymentWidget
                    orderId={content.request.order.id}
                    orderAccessToken={content.request.order_access_token}
                    onSuccess={id => handleOpenChargePage(id)}
                />
            );
        }

        if (payment.provider === EPaymentProvider.LIQPAY) {
            const content = payment.content as ILiqPay;
            return (
                <LiqpayWidget
                    data={content.request.data}
                    signature={content.request.signature}
                    onSuccess={id => handleOpenChargePage(id)}
                />
            );
        }
    };

    const renderPaymentInfo = () => {
        if (!guest._id && loading && stationLoading) {
            return 'Loading...';
        }

        if (error?.message) {
            return error.message;
        }

        if (stationError?.message) {
            return stationError.message;
        }

        const connector = guest.station.connectors[guest.connectorId - 1];
        const connectorType = connector.type;
        return (
            <div css={payStyles.container}>
                {!showWidget && !showIdTag && (
                    <>
                        <div css={payStyles.infoWrapper}>
                            <div css={payStyles.info}>
                                <div css={payStyles.infoBlock}>
                                    <div css={payStyles.infoBlockTitle}>
                                        {intl.formatMessage({ id: 'connector' })}
                                    </div>
                                    <div css={payStyles.infoBlockValue}>
                                        {guest.connectorId < 10 ? 0 : ''}
                                        {guest.connectorId}
                                        <div css={payStyles.infoBlockValueSymbol}>
                                            {connectorType}
                                        </div>
                                    </div>
                                </div>
                                <div css={payStyles.infoBlock}>
                                    <div css={payStyles.infoBlockTitle}>
                                        {intl.formatMessage({ id: 'ofEnergy' })}
                                    </div>
                                    <div css={payStyles.infoBlockValue}>
                                        {Math.floor(guest.energy / 1000)}
                                        <div css={payStyles.infoBlockValueSymbol}>
                                            {intl.formatMessage({ id: 'unit.kWh' })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div css={payStyles.info}>
                                <div css={payStyles.infoBlock}>
                                    <div css={payStyles.infoBlockTitle}>
                                        {intl.formatMessage({ id: 'rate' })}
                                    </div>
                                    <div css={payStyles.infoBlockValue}>
                                        {guest.priceKW}
                                        <div css={payStyles.infoBlockValueSymbol}>
                                            {guest.currency} /{' '}
                                            {intl.formatMessage({ id: 'unit.kWh' })}
                                        </div>
                                    </div>
                                </div>
                                <div css={payStyles.infoBlock}>
                                    <div css={payStyles.infoBlockTitle}>
                                        {intl.formatMessage({ id: 'toPay' })}
                                    </div>
                                    <div css={payStyles.infoBlockValue}>
                                        {guest.priceTotal}
                                        <div css={payStyles.infoBlockValueSymbol}>
                                            {guest.currency}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {guest.rentPriceKW ? (
                                <div css={payStyles.info}>
                                    <div css={payStyles.infoBlock}>
                                        <div css={payStyles.infoBlockTitle}>
                                            {intl.formatMessage({ id: 'rent' })}
                                        </div>
                                        <div css={payStyles.infoBlockValue}>
                                            {((guest.rentPriceKW * guest.energy) / 1000).toFixed(2)}
                                            <div css={payStyles.infoBlockValueSymbol}>
                                                {guest.currency}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div css={payStyles.infoBlockDescription}>
                            <p>{intl.formatMessage({ id: 'payInfo1' })}</p>
                            <p>{intl.formatMessage({ id: 'payInfo2' })}</p>
                        </div>

                        <p css={payStyles.infoBlockDescription}>
                            <label>
                                <input type={'checkbox'} onChange={handleChangeTerms} />
                                <span style={{ marginLeft: '10px' }}>
                                    {intl.formatMessage(
                                        { id: 'payment.confirmOfferText' },
                                        {
                                            0: (
                                                <a
                                                    css={payStyles.termsLink}
                                                    href={require(`../../assets/docs/${
                                                        guest.payment.provider ===
                                                        EPaymentProvider.IOKA
                                                            ? 'kz'
                                                            : 'ua'
                                                    }/offer.pdf`)}
                                                    target={'_blank'}
                                                    rel="noreferrer">
                                                    {intl.formatMessage({
                                                        id: 'payment.confirmOfferLink',
                                                    })}
                                                </a>
                                            ),
                                        }
                                    )}
                                </span>
                            </label>
                        </p>

                        <div css={payStyles.actionWrapper}>
                            <Button
                                disabled={!acceptTerms}
                                text={`Pay ${guest.priceTotal} ${guest.currency}`}
                                onClick={() => setShowWidget(true)}
                            />

                            <Button
                                type={'link'}
                                text={intl.formatMessage({ id: 'payment.button.idTag' })}
                                onClick={() => setShowIdTag(true)}
                            />
                        </div>
                    </>
                )}
                {showWidget && renderPaymentWidget()}
                {showIdTag && renderActivateByIdTag()}
            </div>
        );
    };

    useEffect(() => {
        if (!guest._id && guestId && !error.message) {
            fetchGuestById(guestId).then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guest, guestId, error]);

    useEffect(() => {
        if (guest._id) {
            fetchStationById(guest.station._id).then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guest]);

    useEffect(() => {
        return () => {
            clearGuest().then();
            window.location.reload();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{renderPaymentInfo()}</>;
};
