/** @jsxImportSource @emotion/react */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { IGuest } from '../../../../api/guest/types';
import { StopButton } from '../../components/stop-button/stop-button.component';
import { InfoPanel } from '../../components/info-panel/info-panel.component';
import { selectCharge } from '../../../../store/charge/charge.selector';
import chargeStyles from '../../charge.styles';

import chargeProcessStyles from './charge-process.styles';

type PropTypes = {
    guest: IGuest;
};

export const ChargeProcess: FC<PropTypes> = ({ guest }) => {
    const intl = useIntl();
    const { entry: charge } = useSelector(selectCharge);
    const [percent, setPercent] = useState(0);
    const [socPercent, setSocPercent] = useState(0);

    const hasSock = useMemo(() => {
        const sockLength = charge.meterValues.SoC.length;
        return !!(
            charge.socStart &&
            sockLength &&
            charge.socStart > charge.meterValues.SoC[sockLength - 1]
        );
    }, [charge]);

    useEffect(() => {
        if (hasSock) {
            setSocPercent(charge.meterValues.SoC[charge.meterValues.SoC.length - 1] / 100);
        }
    }, [charge, hasSock]);

    useEffect(() => {
        let value = Math.round((charge.energy * 100) / guest.energy);
        if (value > 100) {
            value = 100;
        }

        setPercent(value);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guest.energy, charge.energy]);

    return (
        <div css={chargeProcessStyles.container}>
            <h4 css={chargeProcessStyles.containerTitle}>
                {intl.formatMessage({ id: 'chargeProcessTitle' })}
            </h4>

            <InfoPanel guest={guest} charge={charge} />

            <div css={chargeProcessStyles.content}>
                <div css={chargeStyles.battery}>
                    <div
                        css={chargeStyles.batteryEmpty}
                        style={{ width: `${socPercent ? socPercent : percent}%` }}
                    />
                    <div css={chargeStyles.batteryPercent}>{socPercent ? socPercent : percent}</div>
                </div>

                <div css={chargeProcessStyles.state}>
                    <div css={chargeProcessStyles.statePower}>
                        <span css={chargeProcessStyles.statePowerValue}>
                            {(charge.power / 1000).toFixed(2)}{' '}
                            <small css={chargeProcessStyles.statePowerUnit}>
                                {intl.formatMessage({ id: 'unit.kW' })}
                            </small>{' '}
                        </span>
                        <div css={chargeProcessStyles.statePowerInfo}>
                            {intl.formatMessage({ id: 'currentConsumption' })}
                        </div>
                    </div>
                    <StopButton />
                </div>
            </div>
        </div>
    );
};
