/** @jsxImportSource @emotion/react */
import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTokenPay } from '../../hooks/token-pay.hook';
import { selectPaymentToken } from '../../store/token-pay/token-pay.selector';
import { EPaymentProvider, IIoka, ILiqPay, IMonoBank } from '../../api/guest/types';
import { IokaPaymentWidget } from '../pay/components/ioka/ioka-widget.component';
import { LiqpayWidget } from '../pay/components/liqpay/liqpay-widget.component';

import tokenPayStyles from './token-pay.styles';

export const TokenPayView: FC = () => {
    const location = useLocation();
    const { entry, loading, error } = useSelector(selectPaymentToken);
    const queryParameters = new URLSearchParams(location.search);
    const { fetchTokenPaymentInfo } = useTokenPay();

    const paymentToken = queryParameters.get('paymentToken');

    const renderPaymentWidget = () => {
        const { payment } = entry;

        if (payment.provider === EPaymentProvider.MONO_BANK) {
            const content = payment.content as IMonoBank;
            window.location.replace(content.request.pageUrl);
            return;
        }

        if (payment.provider === EPaymentProvider.IOKA) {
            const content = payment.content as IIoka;
            return (
                <IokaPaymentWidget
                    orderId={content.request.order.id}
                    orderAccessToken={content.request.order_access_token}
                    onSuccess={id => console.log('Success', id)}
                />
            );
        }

        if (payment.provider === EPaymentProvider.LIQPAY) {
            const content = payment.content as ILiqPay;
            return (
                <LiqpayWidget
                    data={content.request.data}
                    signature={content.request.signature}
                    onSuccess={id => console.log('Success', id)}
                />
            );
        }
    };

    useEffect(() => {
        if (paymentToken) {
            fetchTokenPaymentInfo(paymentToken).then();
        }
    }, []);

    if (!entry.payment && loading) {
        return <div css={tokenPayStyles.container}>Loading...</div>;
    }

    if (error?.message) {
        return <div css={tokenPayStyles.container}>Incorrect token. Try again later.</div>;
    }

    return <>{renderPaymentWidget()}</>;
};
