/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';

import { AppTheme } from '../../app.theme';

import buttonStyles from './button.styles';

type ButtonType = 'key' | 'primary' | 'link' | 'black';

type PropTypes = {
    type?: ButtonType;
    text: string;
    onClick: () => void;
    disabled?: boolean;
};

export const Button: FC<PropTypes> = ({ type = 'primary', text, onClick, disabled = false }) => {
    const { buttonPrimaryColor, buttonPrimaryTextColor } = AppTheme();
    const getButtonTypeStyle = () => {
        switch (type) {
            case 'key':
                return buttonStyles.key;
            case 'link':
                return buttonStyles.link;
            case 'black':
                return buttonStyles.black;
            default: {
                return [
                    buttonStyles.primary,
                    { backgroundColor: buttonPrimaryColor, color: buttonPrimaryTextColor },
                ];
            }
        }
    };
    return (
        <>
            <button
                css={[
                    buttonStyles.button,
                    getButtonTypeStyle,
                    disabled ? buttonStyles.disabled : {},
                ]}
                onClick={() => !disabled && onClick()}>
                {text}
            </button>
        </>
    );
};
