/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';

import appStoreButton from '../../../../assets/app-buttons/app-store-button.svg';
import playMarketButton from '../../../../assets/app-buttons/play-market-button.svg';

import appMarketsButtonsStyles from './app-markets-buttons.styles';

export enum AppName {
    ADELE_CLOUD = 'ADELE_CLOUD',
    LADERGY = 'LADERGY',
}

const AppLinks = {
    [AppName.ADELE_CLOUD]: {
        AppStore: 'https://apps.apple.com/app/id6450279053',
        PlayMarket: 'https://play.google.com/store/apps/details?id=com.adele.cloud',
    },
    [AppName.LADERGY]: {
        AppStore: 'https://apps.apple.com/app/id6467765071',
        PlayMarket: 'https://play.google.com/store/apps/details?id=com.ladergy.network',
    },
};

type PropTypes = {
    app: AppName;
};

export const AppMarketsButtons: FC<PropTypes> = ({ app }) => {
    return (
        <div css={appMarketsButtonsStyles}>
            <div className="app-markets-buttons">
                <a href={AppLinks[app].AppStore}>
                    <img src={appStoreButton} alt={'App Store'} />
                </a>
                <a href={AppLinks[app].PlayMarket}>
                    <img src={playMarketButton} alt={'Play Market'} />
                </a>
            </div>
        </div>
    );
};
