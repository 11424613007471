import { css } from '@emotion/react';

const poweredByStyles = {
    poweredBy: css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '53px',
        paddingBottom: '36px',
    }),
    poweredByText: css({
        marginTop: '6px',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '9px',
        lineHeight: '11px',
        color: '#000000',
    }),
};

export default poweredByStyles;
