import { css } from '@emotion/react';

const conditionsStyles = {
    terms: css({
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '15px',
        paddingBottom: '15px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
    }),
    termsPartner: css({
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '15px',
    }),
    termsCompany: css({
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '10px',
        textAlign: 'center',
        lineHeight: '10px',
    }),
    termsEmail: css({
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '10px',
        lineHeight: '10px',
    }),
    termsLink: css({
        textDecoration: 'underline',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '10px',
    }),
};

export default conditionsStyles;
