import React, { FC } from 'react';

import { IIconType } from './types';

export const IconChargeComplete: FC<IIconType> = ({ size = 48 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 15 16" fill="none">
            <circle cx="7.5" cy="8" r="5.5" fill="#FCE03E" />
            <circle cx="7.5" cy="8" r="6.5" stroke="#FCE03E" strokeOpacity="0.6" strokeWidth="2" />
        </svg>
    );
};
