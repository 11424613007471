import { useDispatch } from 'react-redux';

import { setAppLoading, setAppLocale } from '../store/app/app.slice';

export const useApp = (): {
    appLoading: (state: boolean) => Promise<void>;
    appLocale: (state: string) => Promise<void>;
} => {
    const dispatch = useDispatch();

    const appLoading = async (state: boolean): Promise<void> => {
        await dispatch(setAppLoading(state));
    };

    const appLocale = async (state: string): Promise<void> => {
        await dispatch(setAppLocale(state));
    };

    return {
        appLoading,
        appLocale,
    };
};
