import { css } from '@emotion/react';

const fade = css({
    animationIterationCount: 1,
    animationTimingFunction: 'ease-in',
    animationDuration: '0.3s',
});

const stopConfirmStyles = {
    modal: css({
        zIndex: 999,
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'rgba(26, 26, 26, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    fadeIn: css({
        ...fade,
        opacity: 1,
        animationName: 'fadeInOpacity',
    }),
    fadeOut: css({
        ...fade,
        opacity: 0,
        animationName: 'fadeOutOpacity',
    }),
    stopWindow: css({
        borderRadius: '8px',
        boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.2)',
        backgroundColor: '#ffffff',
        padding: '1rem',
        width: '80%',
        maxWidth: '500px',
        color: '#1a1a1a',
    }),
    stopWindowTitle: css({
        fontSize: '1.5rem',
        fontWeight: 'bold',
        lineHeight: 1.25,
        marginTop: 0,
    }),
    stopWindowDescription: css({
        opacity: 0.7,
        fontWeight: 300,
    }),
    stopWindowButtonContainer: css({
        display: 'flex',
    }),
    stopWindowButton: css({
        width: '50%',
        margin: '0.25rem',
        padding: '0.25rem 0',
        fontSize: '1rem',
        borderRadius: '5px',
        fontWeight: 'bold',
    }),
    stopWindowButtonNo: css({
        border: 'solid 1px #dfdfdf',
        background: 'none',
        color: '#101010',
    }),
    stopWindowButtonYes: css({
        backgroundColor: '#f14b4b',
        color: '#ffffff',
        border: 'none',
    }),
};

export default stopConfirmStyles;
