import { useDispatch } from 'react-redux';
import { useRef } from 'react';

import { REACT_APP_WS_URL } from '../app/app.config';
import { setCharge, setError } from '../store/charge/charge.slice';

export const useCharge = () => {
    const dispatch = useDispatch();

    const socketRef = useRef<WebSocket>();
    const chargeIntervalRef = useRef<ReturnType<typeof setInterval>>();

    const connect = (guestId: string) => {
        socketRef.current = new WebSocket(`${REACT_APP_WS_URL}/ws/?id=${guestId}`);
        socketRef.current.onopen = () => {
            console.log('opened');
        };
        socketRef.current.onmessage = evt => {
            const charge = JSON.parse(evt.data);
            if (charge == 'fail') {
                dispatch(setError('fail'));
            } else {
                dispatch(setCharge(charge));
            }
        };
        socketRef.current.onerror = error => {
            console.log('On message event error', error);
            clearInterval(chargeIntervalRef.current);
            chargeIntervalRef.current = setInterval(() => connect(guestId), 15000);
        };
        socketRef.current.onclose = () => {
            console.log('connection closed');
            clearInterval(chargeIntervalRef.current);
            chargeIntervalRef.current = setInterval(() => connect(guestId), 15000);
        };
    };

    const disconnect = () => {
        clearInterval(chargeIntervalRef.current);
    };

    return {
        connect,
        disconnect,
    };
};
