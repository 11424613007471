import { css } from '@emotion/react';

const deleteAccountStyles = {
    container: css({
        maxWidth: '800px',
        margin: '50px 20px',
        padding: '20px',
        backgroundColor: '#ffffff',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
    }),
    title: css({
        fontSize: '24px',
        color: '#333',
        textAlign: 'center',
        marginBottom: '20px',
    }),
    text: css({
        lineHeight: '1.6',
        color: '#666',
        marginBottom: '20px',
    }),
    steps: css({
        margin: '20px 0',
        paddingLeft: '20px',
        listStyleType: 'none',
        '& li': {
            marginBottom: '10px',
            position: 'relative',
            paddingLeft: '20px',
        },
        '& li::before': {
            content: '"•"',
            position: 'absolute',
            left: '0',
            color: '#333',
            fontWeight: 'bold',
        },
    }),
    contactInfo: css({
        marginTop: '30px',
        padding: '15px',
        backgroundColor: '#848484',
        borderRadius: '8px',
        '& div': {
            margin: '5px 0',
        },
        '& div:first-of-type': {
            fontWeight: 'bold',
        },
    }),
    link: css({
        color: '#007BFF',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    }),
};

export default deleteAccountStyles;
