/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';

import { IconMasterCard } from '../../../../assets/icons/icon-master-card';
import { IconVisa } from '../../../../assets/icons/icon-visa';
import { IconApplePay } from '../../../../assets/icons/icon-apple-pay';

import bankInfoStyles from './bank-info.styles';

export const BankInfo: FC = () => {
    return (
        <div css={bankInfoStyles.container}>
            <IconMasterCard size={24} />
            <IconVisa size={24} />
            <IconApplePay size={24} />
        </div>
    );
};
