import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ILocation } from '../../api/types';

export interface IGeoLocationState {
    entry: ILocation;
    loading: boolean;
}

const initialState: IGeoLocationState = {
    entry: <ILocation>{},
    loading: true,
};

const geoLocationSlice = createSlice({
    name: 'charge',
    initialState,
    reducers: {
        changeGeoLocation: (state, data: PayloadAction<ILocation>): void => {
            state.entry = data.payload;
        },
        clearGeoLocation: (state): void => {
            state.entry = initialState.entry;
        },
    },
});

export const { changeGeoLocation, clearGeoLocation } = geoLocationSlice.actions;
export const geoLocationReducer = geoLocationSlice.reducer;
