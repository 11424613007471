/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { MainHeader } from './components/header/main-header.component';
import mainLayoutStyles from './main-layout.styles';
import { MainFooter } from './components/footer/main-footer.component';

export const MainLayoutView: FC = () => {
    return (
        <main>
            <MainHeader />

            <div css={mainLayoutStyles.container}>
                <Outlet />
                <MainFooter />
            </div>
        </main>
    );
};
