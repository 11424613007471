/** @jsxImportSource @emotion/react */
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import {
    selectStations,
    selectStationsParamsSearch,
} from '../../../../store/stations/stations-list.selector';
import { IFilterStations } from '../../../../api/station/types';
import mainStyles from '../../main.styles';
import { StationItem } from '../../components/station/station-item.component';
import { useStations } from '../../../../hooks/stations.hook';
import { Button } from '../../../../components/button/button.component';
import { selectGeoLocation } from '../../../../store/geo-location/geo-location.selector';
import { ITabComponent } from '../../../../components/tabs/tabs.component';

export const NearbyTab: FC<ITabComponent> = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { fetchStationsList, clearStations } = useStations();
    const search = useSelector(selectStationsParamsSearch);
    const { entry: location } = useSelector(selectGeoLocation);
    const { entry: stations, loading, reload, cursor, error } = useSelector(selectStations);

    const handleClick = (id: string) => {
        navigate(`/` + id);
    };

    const loadStations = (filter: IFilterStations) => {
        fetchStationsList({ ...filter, search }).then();
    };

    const renderStations = () => {
        const { rows } = stations;

        if (loading && reload) {
            return intl.formatMessage({ id: 'loading' });
        }

        if (error?.message) {
            return error.message;
        }

        if (!rows.length) {
            return intl.formatMessage({ id: 'noActiveStations' });
        }

        return (
            <div css={mainStyles.list}>
                {rows.map(station => (
                    <StationItem
                        key={station._id}
                        station={station}
                        onClick={() => handleClick(station._id)}
                    />
                ))}
            </div>
        );
    };

    useEffect(() => {
        if (Object.keys(location).length && location.lng !== null && location.lat !== null) {
            loadStations({
                lng: location.lng,
                lat: location.lat,
                reload: true,
                cursor: 0,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, search]);

    useEffect(() => {
        return (): void => {
            clearStations().then();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div css={mainStyles.container}>
            {renderStations()}
            <div>{loading && !reload && intl.formatMessage({ id: 'loading' })}</div>
            {!loading && (
                <Button
                    text={intl.formatMessage({ id: 'main.button.loadMore' })}
                    type={'black'}
                    onClick={() =>
                        !loading &&
                        location.lng !== null &&
                        location.lat !== null &&
                        loadStations({
                            lng: location.lng || 0,
                            lat: location.lat || 0,
                            reload: false,
                            cursor,
                        })
                    }
                />
            )}
        </div>
    );
};
