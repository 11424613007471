import { createSlice } from '@reduxjs/toolkit';

import { IApiError } from '../../api/types';
import { ITokenPayData } from '../../api/token-pay/types';
import { getPaymentTokenInfo } from '../../api/token-pay/token-pay.api';

export interface ITokenPayState {
    entry: ITokenPayData;
    loading: boolean;
    error: IApiError;
}

const initialState: ITokenPayState = {
    entry: <ITokenPayData>{},
    loading: true,
    error: <IApiError>{},
};

const stationSlice = createSlice({
    name: 'tokenPayItem',
    initialState,
    reducers: {
        actionClearTokenPay: (state): void => {
            state.entry = initialState.entry;
            state.error = initialState.error;
            state.loading = true;
        },
    },
    extraReducers: builder => {
        // GET TOKEN PAY
        builder.addCase(getPaymentTokenInfo.pending, state => {
            state.loading = true;
            state.error = initialState.error;
        });
        builder.addCase(getPaymentTokenInfo.fulfilled, (state, { payload }) => {
            state.entry = payload;
            state.loading = false;
            state.error = initialState.error;
        });
        builder.addCase(getPaymentTokenInfo.rejected, (state, { payload }) => {
            state.entry = initialState.entry;
            state.error = <IApiError>payload;
            state.loading = false;
        });
    },
});

export const { actionClearTokenPay } = stationSlice.actions;
export const paymentTokenReducer = stationSlice.reducer;
