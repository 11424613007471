/** @jsxImportSource @emotion/react */
import React, { FC, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ISetUpState, SetUpStep } from '../../set-up.view';
import { Button } from '../../../../components/button/button.component';
import { Notification } from '../../../../components/notification/notification.component';
import { IconEdit } from '../../../../assets/icons/icon-edit';
import { AppTheme } from '../../../../app.theme';

import setEnergyStyles from './set-energy.styles';

type PropTypes = {
    connectorId?: number;
    priceKW: number;
    rentPriceKW: number;
    currency: string;
    changeStep: (step: SetUpStep) => void;
    onSetSetUpStep: (state: ISetUpState) => void;
};

export const SetEnergy: FC<PropTypes> = ({
    connectorId,
    priceKW,
    rentPriceKW,
    currency,
    onSetSetUpStep,
    changeStep,
}) => {
    const maxEnergy = 100;
    const { batteryFirstColor, batterySecondColor } = AppTheme();
    const intl = useIntl();
    const inputRef = useRef<HTMLInputElement>(null);

    const [energy, setEnergy] = useState<number>(30);
    const [showInput, setShowInput] = useState(false);
    const [showEditButton, setShowEditButton] = useState(true);

    const updateEnergy = (value: number) => {
        setEnergy(prevState => {
            return Math.min(maxEnergy, Math.max(0, prevState + value));
        });
    };

    const getBatteryEnergyHeight = (): number => {
        return (160 / 100) * energy;
    };

    return (
        <div css={setEnergyStyles.container}>
            <div css={setEnergyStyles.columnWrapper}>
                <div css={setEnergyStyles.column}>
                    {connectorId ? (
                        <div css={setEnergyStyles.infoBlock}>
                            <div
                                css={[
                                    setEnergyStyles.infoBlockTitle,
                                    { textTransform: 'uppercase' },
                                ]}>
                                {intl.formatMessage({ id: 'connectorId' })}
                            </div>
                            <div css={setEnergyStyles.infoBlockValue}>{connectorId}</div>
                        </div>
                    ) : null}
                    <div css={setEnergyStyles.infoBlock}>
                        <div css={[setEnergyStyles.infoBlockTitle, { textTransform: 'uppercase' }]}>
                            {intl.formatMessage({ id: 'rate' })}
                        </div>
                        <div css={setEnergyStyles.infoBlockValue}>
                            {priceKW}
                            <div css={setEnergyStyles.infoBlockValueSymbol}>
                                {currency} / {intl.formatMessage({ id: 'unit.kWh' })}
                            </div>
                        </div>
                    </div>
                    {rentPriceKW ? (
                        <div css={setEnergyStyles.infoBlock}>
                            <div
                                css={[
                                    setEnergyStyles.infoBlockTitle,
                                    { textTransform: 'uppercase' },
                                ]}>
                                {intl.formatMessage({ id: 'rent' })}
                            </div>
                            <div css={setEnergyStyles.infoBlockValue}>
                                {(rentPriceKW * Number(energy)).toFixed(2)}
                                <div css={setEnergyStyles.infoBlockValueSymbol}>{currency}</div>
                            </div>
                        </div>
                    ) : null}
                    <div css={setEnergyStyles.infoBlock}>
                        <div css={[setEnergyStyles.infoBlockTitle, { textTransform: 'uppercase' }]}>
                            {intl.formatMessage({ id: 'toPay' })}
                        </div>
                        <div css={setEnergyStyles.infoBlockValue}>
                            {((priceKW + rentPriceKW) * Number(energy)).toFixed(2)}
                            <div css={setEnergyStyles.infoBlockValueSymbol}>{currency}</div>
                        </div>
                    </div>
                </div>
                <div css={[setEnergyStyles.column, { alignItems: 'center' }]}>
                    <div css={setEnergyStyles.battery}>
                        <div
                            css={[
                                setEnergyStyles.batteryEnergy,
                                {
                                    background: `linear-gradient(90deg, ${batteryFirstColor} 0%, ${batterySecondColor} 100%)`,
                                },
                                { height: getBatteryEnergyHeight() },
                            ]}></div>
                        <div css={setEnergyStyles.batteryEnergyValue}>
                            <div>
                                <div
                                    css={setEnergyStyles.batteryEnergyValueLabel}
                                    onClick={() => {
                                        setShowInput(true);
                                        inputRef.current?.focus();
                                    }}>
                                    {showInput ? (
                                        <input
                                            autoFocus={true}
                                            inputMode={'numeric'}
                                            ref={inputRef}
                                            onFocus={() => setShowEditButton(false)}
                                            onBlur={() => {
                                                setShowEditButton(true);
                                                setShowInput(false);
                                            }}
                                            type={'number'}
                                            css={setEnergyStyles.inputEnergy}
                                            value={energy || undefined}
                                            onChange={event =>
                                                setEnergy(
                                                    Math.min(
                                                        maxEnergy,
                                                        Math.max(
                                                            0,
                                                            Number(
                                                                Number(event.target.value).toFixed(
                                                                    1
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            }
                                        />
                                    ) : (
                                        energy
                                    )}
                                    {showEditButton && <IconEdit size={20} />}
                                </div>
                                <div css={setEnergyStyles.batteryEnergyValueSymbol}>
                                    {intl.formatMessage({ id: 'unit.kWh' })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div css={[setEnergyStyles.column, { alignItems: 'center' }]}>
                    <div css={setEnergyStyles.infoBlock}>
                        <div>
                            <div
                                css={setEnergyStyles.maxEnergy}
                                onClick={() => updateEnergy(maxEnergy)}>
                                MAX
                            </div>
                        </div>
                    </div>
                    <div css={setEnergyStyles.infoBlock}>
                        <div>
                            <Button type={'key'} text={'+'} onClick={() => updateEnergy(5)} />
                        </div>
                    </div>
                    <div css={setEnergyStyles.infoBlock}>
                        <div>
                            <Button type={'key'} text={'–'} onClick={() => updateEnergy(-5)} />
                        </div>
                    </div>
                </div>
            </div>

            <div css={setEnergyStyles.actionWrapper}>
                <Button
                    type={'link'}
                    text={intl.formatMessage({ id: 'back' })}
                    onClick={() => changeStep('connector')}
                />

                <Button
                    text={intl.formatMessage({ id: 'next' }) + '  ➜'}
                    onClick={() => onSetSetUpStep({ energy: Number(energy) })}
                />
            </div>

            <Notification text={intl.formatMessage({ id: 'setEnergy.infoBanner.text' })} />
        </div>
    );
};
