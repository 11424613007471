import iconAdeleLogo from './assets/icons/icon-adele-logo.svg';
import iconLadergyLogo from './assets/icons/icon-ladergy-logo.svg';
import { REACT_APP_THEME } from './app/app.config';

enum EAppTheme {
    LADERGY = 'LADERGY',
    ADELE = 'ADELE',
}

interface IThemeConfig {
    appName: string;
    logo: string;
    showPoweredBy: boolean;
    buttonPrimaryColor: string;
    buttonPrimaryTextColor: string;
    connectorsBadgeColor: string;
    connectorsBadgeTextColor: string;
    connectorsBadgeIconColor: string;
    menuAboutTranslateKey: string;
    menuAboutLink: string;
    menuNewsLink: string;
    favicon: string;
    downloadAppLiBackgroundColor: string;
    downloadAppLiTextColor: string;
    batteryFirstColor: string;
    batterySecondColor: string;
    languageSelectorTextColor: string;
    languageNames: string[];
}

const LadergyTheme: IThemeConfig = {
    appName: 'Ladergy',
    logo: iconLadergyLogo,
    showPoweredBy: true,
    buttonPrimaryColor: '#FCE63E',
    buttonPrimaryTextColor: 'black',
    connectorsBadgeColor: '#FCE03E',
    connectorsBadgeTextColor: 'black',
    connectorsBadgeIconColor: 'black',
    menuAboutTranslateKey: 'menu.aboutLadergy',
    menuAboutLink: 'https://ladergy.com',
    menuNewsLink: 'https://ladergy.com/posts',
    favicon: 'ladergy-favicon',
    downloadAppLiBackgroundColor: '#FCE63E',
    downloadAppLiTextColor: 'black',
    batteryFirstColor: '#FCE03E',
    batterySecondColor: '#FDF1AB',
    languageSelectorTextColor: '#FFF300',
    languageNames: ['ua', 'ru', 'en'],
};

const AdeleTheme: IThemeConfig = {
    appName: 'Adele Cloud',
    logo: iconAdeleLogo,
    showPoweredBy: false,
    buttonPrimaryColor: '#E30611',
    buttonPrimaryTextColor: 'white',
    connectorsBadgeColor: '#E30611',
    connectorsBadgeTextColor: 'white',
    connectorsBadgeIconColor: 'white',
    menuAboutTranslateKey: 'menu.aboutAdeleCloud',
    menuAboutLink: 'https://adele.energy',
    menuNewsLink: 'https://adele.energy/news',
    favicon: 'adele-favicon',
    downloadAppLiBackgroundColor: '#E30611',
    downloadAppLiTextColor: 'white',
    batteryFirstColor: 'rgb(0 49 255)',
    batterySecondColor: 'rgb(0 49 255 / 49%)',
    languageSelectorTextColor: '#E30611',
    languageNames: ['ru', 'en'],
};

export const AppTheme = () => {
    switch (REACT_APP_THEME) {
        case EAppTheme.ADELE:
            return AdeleTheme;
        case EAppTheme.LADERGY:
            return LadergyTheme;
        default:
            return LadergyTheme;
    }
};
