import { css } from '@emotion/react';

const chargeFinishedStyles = {
    infoTitle: css({
        fontSize: '1.5rem',
        fontWeight: 800,
        textAlign: 'left',
        color: '#101010',
        margin: 0,
    }),
    actionWrapper: css({
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    }),
    content: css({
        flexGrow: 1,
    }),
    info: css({
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: '3rem 0',
    }),
    infoBlock: css({
        margin: '0 0.5rem',
        textAlign: 'center',
        color: ' #101010',
    }),
    title: css({
        fontWeight: 'bold',
        lineHeight: 1.79,
    }),
    value: css({
        fontSize: '2rem',
        fontWeight: 800,
        lineHeight: 1.17,
    }),
    unit: css({
        fontSize: '1rem',
        fontWeight: 200,
        lineHeight: 0.8,
        letterSpacing: '-0.45px',
    }),
    batteryFinished: css({
        margin: '0 0.5rem 2rem 0',
        opacity: 0.5,
        height: '4rem',
        background: '#FFF300',
        ':after': {
            right: '-12px',
        },
    }),
    batteryFinishedEmpty: css({
        display: 'none',
    }),
    batteryFinishedPercent: css({
        color: '#101010',
        fontSize: '2rem',
        top: 'calc(50% - 1.3rem)',
        mixBlendMode: 'normal',
    }),
    downloadCheck: css({
        borderRadius: '5px',
        backgroundColor: '#FFF300',
        padding: '1rem',
        border: 'none',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: '#101010',
        marginBottom: '1rem',
        display: 'inline-block',
        textAlign: 'center',
        alignItems: 'flex-start',
        textDecoration: 'none',
        width: '250px',
    }),
    homePage: css({
        backgroundColor: 'white',
        padding: '1rem',
        border: 'none',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: '#101010',
        marginBottom: '1rem',
        display: 'inline-block',
        textAlign: 'center',
        alignItems: 'flex-start',
        textDecoration: 'none',
        width: '250px',
        cursor: 'pointer',
    }),
};
export default chargeFinishedStyles;
