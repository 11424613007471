import { css } from '@emotion/react';

const chargeWaitStyles = {
    containerTitle: css({
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '19px',
        lineHeight: '23px',
        color: '#000000',
        margin: '15px 15px',
    }),
    description: css({
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '20px',
        color: '#000000',
    }),
    infoImageContainer: css({
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
    }),
    infoImageContent: css({
        maxWidth: '20rem',
    }),
    infoImage: css({
        width: '100%',
        zIndex: 1,
        mixBlendMode: 'luminosity',
        ':after': {
            content: '""',
            background: 'white',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -1,
        },
    }),
};

export default chargeWaitStyles;
