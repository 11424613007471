import { css } from '@emotion/react';

const stationHeaderStyles = {
    container: css({
        backgroundColor: 'black',
        marginBottom: '43px',
    }),
    head: css({
        display: 'flex',
        height: '64px',
        padding: '15px 20px',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '600px',
        margin: '0 auto',
        boxSizing: 'border-box',
    }),
    headTitle: css({
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '24px',
    }),
    headActions: css({
        display: 'flex',
        gap: '20px',
    }),
    businessCard: css({
        background: ' #FFFFFF',
        boxShadow: ' 0px 3px 20px rgba(0, 0, 0, 0.160784)',
        borderRadius: '8px',
        padding: '24px 14px 31px 22px',
        margin: '40px auto -15px',
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        boxSizing: 'border-box',
        maxWidth: '600px',
        width: `calc(100% - 30px)`,
    }),
    businessCardLogo: css({
        background: '#EBEBEB',
        borderRadius: '19px',
        minWidth: '56px',
        height: '56px',
        fontWeight: 900,
        fontSize: '16px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#000000',
    }),
    businessCardInfo: css({
        color: '#000000',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
    }),
    businessCardInfoTitle: css({
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '18px',
    }),
    businessCardInfoDescription: css({
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '14px',
    }),
};

export default stationHeaderStyles;
