import { useDispatch } from 'react-redux';

import { getPaymentTokenInfo } from '../api/token-pay/token-pay.api';

export const useTokenPay = (): {
    fetchTokenPaymentInfo: (paymentToken: string) => Promise<void>;
} => {
    const dispatch = useDispatch();
    const fetchTokenPaymentInfo = async (paymentToken: string): Promise<void> => {
        await dispatch(getPaymentTokenInfo(paymentToken));
    };

    return {
        fetchTokenPaymentInfo,
    };
};
