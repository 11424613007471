import { createSlice } from '@reduxjs/toolkit';

import { IStation } from '../../api/station/types';
import { getStationById } from '../../api/station/station.api';
import { IApiError } from '../../api/types';

export interface IStationState {
    entry: IStation;
    loading: boolean;
    error: IApiError;
    changed: boolean;
    deleted: boolean;
}

const initialState: IStationState = {
    entry: <IStation>{},
    loading: true,
    error: <IApiError>{},
    changed: false,
    deleted: false,
};

const stationSlice = createSlice({
    name: 'stationItem',
    initialState,
    reducers: {
        actionClearStation: (state): void => {
            state.entry = initialState.entry;
            state.error = initialState.error;
            state.loading = true;
            state.changed = false;
            state.deleted = false;
        },
    },
    extraReducers: builder => {
        // GET STATION
        builder.addCase(getStationById.pending, state => {
            state.loading = true;
            state.error = initialState.error;
            state.changed = false;
            state.deleted = false;
        });
        builder.addCase(getStationById.fulfilled, (state, { payload }) => {
            state.entry = payload;
            state.loading = false;
            state.error = initialState.error;
            state.changed = false;
            state.deleted = false;
        });
        builder.addCase(getStationById.rejected, (state, { payload }) => {
            state.entry = initialState.entry;
            state.error = <IApiError>payload;
            state.loading = false;
            state.changed = false;
            state.deleted = false;
        });
    },
});

export const { actionClearStation } = stationSlice.actions;
export const stationReducer = stationSlice.reducer;
