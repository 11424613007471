/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { selectStation } from '../../../../../store/station/station.selector';
import timeSlot from '../../../../../assets/about/time-slot.svg';
import phone from '../../../../../assets/about/phone.svg';
import globe from '../../../../../assets/about/globe.svg';
import mail from '../../../../../assets/about/mail.svg';
import map from '../../../../../assets/about/map.svg';
import { PoweredBy } from '../../../components/powered-by/powered-by.component';
import { Conditions } from '../../../components/conditions/conditions.component';
import { BankInfo } from '../../../components/bank-info/bank-info.component';
import {
    AppMarketsButtons,
    AppName,
} from '../../../../shared/components/app-markets-buttons/app-markets-buttons.component';

import stationFooterStyle from './station-footer.styles';

export const StationFooter: FC = () => {
    const intl = useIntl();
    const { entry: station } = useSelector(selectStation);

    return (
        <>
            <div css={stationFooterStyle.container}>
                {station.business && (
                    <div css={stationFooterStyle.title}>
                        {intl.formatMessage({ id: 'footer.title.aboutUs' })}
                    </div>
                )}

                {station.business && (
                    <div css={stationFooterStyle.appButtons}>
                        <span css={stationFooterStyle.appButtonsDescription}>
                            {intl.formatMessage({ id: 'footer.info.appButtons.description' })}
                        </span>
                        <AppMarketsButtons
                            app={
                                station.business?.country?.toLowerCase() === 'kz'
                                    ? AppName.ADELE_CLOUD
                                    : AppName.LADERGY
                            }
                        />
                    </div>
                )}

                <div css={stationFooterStyle.content}>
                    <div css={stationFooterStyle.infoBlock}>
                        <div css={stationFooterStyle.infoBlockIcon}>
                            <img height={23} src={phone} alt={'Support'} />
                        </div>
                        <div>
                            <div css={stationFooterStyle.infoBlockTitle}>
                                {intl.formatMessage({ id: 'footer.info.title.support' })}
                            </div>
                            <div css={stationFooterStyle.infoBlockValue}>
                                {station.business?.country?.toLowerCase() === 'kz' ? (
                                    <a
                                        css={stationFooterStyle.link}
                                        href={'https://t.me/adelecloud'}
                                        target={'_blank'}
                                        rel="noreferrer">
                                        t.me/adelecloud
                                    </a>
                                ) : (
                                    <a
                                        css={stationFooterStyle.link}
                                        href={'https://t.me/ladergy'}
                                        target={'_blank'}
                                        rel="noreferrer">
                                        t.me/ladergy
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>

                    {station.business?.workHours && (
                        <div css={stationFooterStyle.infoBlock}>
                            <div css={stationFooterStyle.infoBlockIcon}>
                                <img height={23} src={timeSlot} alt={'Working hours'} />
                            </div>
                            <div>
                                <div css={stationFooterStyle.infoBlockTitle}>
                                    {intl.formatMessage({ id: 'footer.info.title.workingHours' })}
                                </div>
                                <div css={stationFooterStyle.infoBlockValue}>
                                    {station.business?.workHours}
                                </div>
                            </div>
                        </div>
                    )}

                    {station.business?.email && (
                        <div css={stationFooterStyle.infoBlock}>
                            <div css={stationFooterStyle.infoBlockIcon}>
                                <img height={15} src={mail} alt={'E-mail'} />
                            </div>
                            <div>
                                <div css={stationFooterStyle.infoBlockTitle}>
                                    {intl.formatMessage({ id: 'footer.info.title.email' })}
                                </div>
                                <div>
                                    <a
                                        target="_blank"
                                        css={stationFooterStyle.infoBlockValue}
                                        href={`mailto:${station.business?.email}`}
                                        rel="noreferrer">
                                        {station.business?.email}
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}

                    {station.business?.site && (
                        <div css={stationFooterStyle.infoBlock}>
                            <div css={stationFooterStyle.infoBlockIcon}>
                                <img height={23} src={globe} alt={'Website'} />
                            </div>
                            <div>
                                <div css={stationFooterStyle.infoBlockTitle}>
                                    {intl.formatMessage({ id: 'footer.info.title.website' })}
                                </div>
                                <div css={stationFooterStyle.infoBlockValue}>
                                    <a
                                        css={stationFooterStyle.link}
                                        href={station.business?.site}
                                        target={'_blank'}
                                        rel="noreferrer">
                                        {station.business?.site}
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}

                    {station.business?.address && (
                        <div css={stationFooterStyle.infoBlock}>
                            <div css={stationFooterStyle.infoBlockIcon}>
                                <img height={23} src={map} alt={'Location'} />
                            </div>
                            <div>
                                <div css={stationFooterStyle.infoBlockTitle}>
                                    {intl.formatMessage({ id: 'footer.info.title.location' })}
                                </div>
                                <div css={stationFooterStyle.infoBlockValue}>
                                    {station.business?.address}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <PoweredBy />
                {station.business && (
                    <Conditions color={'black'} country={station.business.country} />
                )}
                <BankInfo />
            </div>
        </>
    );
};
