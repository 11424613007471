import React, { FC } from 'react';

import styles from './offer-kk.module.css';

export const OfferKkView: FC = () => {
    return (
        <div className={styles.c20}>
            <p className={styles.c22}>
                <span className={styles.c5}>Оферта шарты (пайдаланушы келісімі)</span>
            </p>
            <p className={styles.c22}>
                <span className={styles.c5}>
                    электр көлік құралын зарядтау бойынша қызметтер көрсету{' '}
                </span>
            </p>
            <p className={styles.c38}>
                <span className={styles.c5}>редакциясы 2023 жылғы «10» қаңтардағы No 1</span>
            </p>
            <p className={styles.c27}>
                <span className={styles.c1}>
                    Осы Оферта шарты пайдаланушыларға жолданған, ол Қазақстан Республикасы Азаматтық
                    кодексінің 395-бабына сәйкес ресми және жария болып табылады. Электр көлік
                    құралын зарядтау бойынша қызметтер көрсету офертасының шарты (бұдан әрі —
                    «Офертаның шарты») «Adele Cloud» веб-қосымшасын (мобильдік қосымшасын) (бұдан
                    әрі – «қосымша») және сервисті пайдалану шарттарын белгілейді «Ladergy.com»
                    электр көлік құралын зарядтау үшін және әрекетке қабілетті және құқық қабілетті
                    тұлға арасында төменде көрсетілген шарттармен жасалған (Веб-қосымшаны (мобильді
                    қосымшаны) пайдаланатын, оның құқық иесі Adele Energy Group LTD жеке компаниясы
                    болып табылады. және зарядтау инфрақұрылымы объектісінің иесі (бұдан әрі
                    «Орындаушы» деп аталады) бірлесіп Тараптар, ал жеке – Тарап деп аталады.
                </span>
            </p>
            <p className={styles.c27 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>1. ТЕРМИНДЕРДІҢ АНЫҚТАМАСЫ</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Осы Офертада мынадай терминдер мен анықтамалар пайдаланылады:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    1.1. Зарядтау қызметі-осы Оферта шартында сипатталған тәртіппен Орындаушы
                    көрсететін пайдаланушының тапсырысы бойынша электр көлік құралдарын зарядтау
                    жөніндегі қызметтер.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.2. </span>
                <span className={styles.c9}>Сайт</span>
                <span className={styles.c1}>
                     - «Adele Energy Group ltd.», Интернет желісіндегі келесі сілтеме бойынша
                    қолжетімді: https://guest.ladergy.com/. сайтқа қатысты барлық құқықтар «Adele
                    Energy Group ltd.».
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.3. </span>
                <span className={styles.c9}>Сатушы</span>
                <span className={styles.c1}>
                     - «Adele Energy Group ltd.», Қазақстан Республикасы, Астана қаласы, Есіл
                    ауданы, Мәңгілік Ел даңғылы, 55/22 ғимарат және Сайт арқылы тауарлар және/немесе
                    қызметтер сататын онымен үлестес заңды тұлғалар мекенжайы бойынша тіркелген.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.4.</span>
                <span className={styles.c9}> Орындаушы</span>
                <span className={styles.c1}>
                    -ҚР заңнамасында көзделген тәртіппен тіркелген, зарядтау инфрақұрылымы
                    объектісін басқаратын және электр көлік құралдарын зарядтау жөніндегі қызметтің
                    тікелей орындаушысы болып табылатын заңды тұлға немесе дара кәсіпкер. Орындаушы
                    «Adele Energy Group ltd.» Орындаушының атынан және есебінен әлеуетті
                    пайдаланушыларды іздестіруді және тартуды жүзеге асыру және пайдаланушылармен
                    электр көлік құралдарын зарядтау бойынша қызметтер көрсету шарттарын жасасу.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.5. </span>
                <span className={styles.c9}>Пайдаланушы</span>
                <span className={styles.c1}>
                    -осы Оферта шартын көзделген тәртіппен жасасқан әрекетке қабілетті жеке тұлға не
                    корпоративтік клиент қызметкері.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.6. </span>
                <span className={styles.c9}>Корпоративтік клиент</span>
                <span className={styles.c1}>
                    -осы Оферта шартын жасасқан, ҚР заңнамасында көзделген тәртіппен тіркелген заңды
                    тұлға немесе дара кәсіпкер. Корпоративтік клиент өз қызметкерлерінің қосымшаны
                    және сервисті пайдалану мақсатында осы Оферта шартын жасасады.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.7. </span>
                <span className={styles.c9}>Қолдау қызметі</span>
                <span className={styles.c1}>
                    -ішкі қызмет және «Adele Energy Group ltd.» немесе «Adele Energy Group ltd.»
                    чека тартқан үшінші тұлғалар осы Оферта шартында көзделген тәртіппен қосымшаны
                    және сервисті пайдалану мәселелері бойынша пайдаланушыға консультация беруді
                    жүзеге асыратын, сондай-ақ осы Оферта шартында көзделген өзге де әрекеттерді
                    жүзеге асыратын.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.8. </span>
                <span className={styles.c9}>Электрондық тарату агрегаторы</span>
                <span className={styles.c1}>
                     – «Adele Energy Group ltd.» ЧЕКАСЫМЕН ҚР заңнамасында көзделген тәртіппен
                    тіркелген заңды тұлға немесе жеке кәсіпкер қызмет көрсету тәртібі туралы
                    хабарламаларды немесе сервис пайдаланушыларына жарнамалық сипаттағы
                    хабарламаларды жіберуді орындау туралы тиісті келісім.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.9. </span>
                <span className={styles.c9}>Қосымша</span>
                <span className={styles.c1}>
                    -осы Оферта шартында көзделген тәртіппен өтінімдерді орналастыру мақсаттары үшін
                    пайдаланушыларға пайдалануға арналған «Adele Cloud» (барлық жаңартулар мен
                    жақсартуларды қоса алғанда) компьютерлерге және мобильді құрылғыларға арналған
                    бағдарлама.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.10.</span>
                <span className={styles.c9}> Қызмет</span>
                <span className={styles.c1}>
                     - «Adele Cloud» атауы бар және өтінімдерді қабылдау, өңдеу, сақтау,
                    қалыптастыру, өзгерту және беру, орналастырылған өтінім туралы ақпаратты
                    орындаушыға жіберу, зарядтау инфрақұрылымы объектілері туралы шоғырландырылған
                    мәліметтерді енгізу, сақтау, өзгерту, жою, өңдеу және қалыптастыру,
                    пайдаланушылар арасындағы консультациялық, технологиялық және ақпараттық өзара
                    іс-қимылды қамтамасыз ету жөніндегі функцияларды жүзеге асыратын бағдарламалық
                    кешен, статистикалық мәліметтерді қалыптастыру және түсіру (өтінімдердің саны,
                    өтінімдердің құны, өтінімдердің мәртебесі және т. б.), нақты уақыт режимінде
                    зарядтау инфрақұрылымы объектілерінің мәртебесін көрсету.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.11. </span>
                <span className={styles.c9}>Өтінім</span>
                <span className={styles.c1}>
                    -пайдаланушы осы Оферта шартында көзделген тәртіппен қосымшада орналастырған
                    электр көлік құралдарын зарядтау жөніндегі қызметтерге пайдаланушының әлеуетті
                    сұранысы туралы ақпарат.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.12. </span>
                <span className={styles.c9}>Шот</span>
                <span className={styles.c1}>
                    -пайдаланушы туралы, пайдаланушының сервисті пайдаланғаны туралы ақпаратты,
                    сондай-ақ қосымшаның функционалында көзделген өзге де ақпаратты қамтитын
                    оферталық шартта көзделген тәртіппен құрылатын қосымшадағы Пайдаланушының дербес
                    бөлімі.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.13.</span>
                <span className={styles.c9}> ЭҚК</span>
                <span className={styles.c1}>
                    -электр көлік құралдарын зарядтау бойынша қызмет көрсетуге арналған электр
                    зарядтау станциясы.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.14. </span>
                <span className={styles.c9}>Оферта</span>
                <span className={styles.c1}>
                     - осы Пайдаланушы келісімі, яғни тауарларды және/немесе қызметтерді сатып
                    алу-сату шартын жасасу туралы Жеке және заңды тұлғалардың шектеусіз тобына
                    бағытталған сатушының жария ұсынысы.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.15. </span>
                <span className={styles.c9}>Акцепт</span>
                <span className={styles.c1}>
                    -пайдаланушының өз атынан, сондай-ақ оның аккаунтын пайдаланатындардың атынан
                    сайтта пайдаланушы ретінде тіркелу арқылы осы офертаның шарттарын толық және
                    сөзсіз қабылдауы.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.16.</span>
                <span className={styles.c9}> Холдинг</span>
                <span className={styles.c1}>
                     — қосымшада өтінім берген кезде Пайдаланушы төлейтін төлем сомасы бірінші
                    кезеңде пайдаланушының банктік картасы шығарылған шотта резервтелетін
                    (холдингтелетін) төлем операциясы, ал екінші кезеңде Тараптар тапсырысты
                    растағаннан және соманы қайта есептегеннен кейін пайдаланушының шотынан есептен
                    шығарылады, ал айырма, егер бар болса, қайтарылады Пайдаланушыға.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.17. </span>
                <span className={styles.c9}>Нақты құны</span>
                <span className={styles.c1}>
                    -пайдаланушыға жеткізу басталған кездегі тауарлардың түпкілікті салмағы мен
                    бағасына қарай есептелген тапсырыстың түпкілікті құны.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.18. </span>
                <span className={styles.c9}>Дербес деректер</span>
                <span className={styles.c1}>
                    -пайдаланушы туралы дербес деректердің және/немесе Дербестендірілмеген
                    ақпараттың Авторлық құқық иесінің өзі беретін және/немесе авторлық құқық иесі
                    және/немесе үшінші тұлғалар автоматты түрде жинайтын жиынтығын білдіреді.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>1.19. </span>
                <span className={styles.c9}>Cookie файлдары (cookies)</span>
                <span className={styles.c1}>
                     - мұндай қосымшалардың немесе сайттардың жұмысын, сондай-ақ оларда
                    орналастырылған мазмұнның сапасын жақсарту үшін пайдаланушының смартфондарында,
                    планшеттерінде, сағаттарында және басқа да мобильді құрылғыларында
                    орналастырылған, қандай да бір мобильді (web) қосымшаларға немесе сайтқа
                    жіберілетін шағын файлдарды білдіреді.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>2. ЖАЛПЫ ЕРЕЖЕЛЕР</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    2.1. Осы Келісімге сәйкес пайдаланушы қосымша арқылы электр көлік құралын
                    зарядтау жөніндегі қызметке өтінімді Орындаушының таңдаған ЭҚК-не орналастырады.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    2.2. Орындаушы Пайдаланушының электр көлік құралын таңдалған ЭҚК-де зарядтау
                    бойынша қызмет көрсетеді.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    2.3. Пайдаланушы осы Келісімде сипатталған қосымшаның функционалын пайдалану
                    арқылы электр көлігін зарядтау қызметіне ақы төлейді.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>
                    3. ҚОСЫМШАНЫҢ ФУНКЦИОНАЛДЫҒЫ ЖӘНЕ ҚЫЗМЕТ СИПАТТАМАСЫ
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    3.1. Қолданбаны пайдалану арқылы пайдаланушы қызметке қол жеткізе алады. Сервис
                    Ақпараттық сервис болып табылады және пайдаланушыға пайдаланушының электр көлік
                    құралдарын зарядтау жөніндегі қызметтерге әлеуетті сұранысы туралы ақпаратты
                    орналастыру (өтінімді орналастыру) мүмкіндігін, сондай-ақ электр көлік
                    құралдарын зарядтау жөніндегі қызметтерді көрсететін Орындаушының ұсыныстары
                    туралы ақпаратпен танысу және пайдаланушы берген параметрлер бойынша осындай
                    ұсыныстарды іздеуді жүзеге асыру мүмкіндігін ұсынады.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    3.2. Электр көлік құралдарын зарядтау бойынша қызмет көрсететін орындаушылардың
                    тізбесі осы Оферта шартына №1 қосымшада берілген.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    3.3. Әр түрлі жағдайларда (соның ішінде, бірақ онымен шектелмей, пайдаланушының
                    орналасқан жері, техникалық ақаулар, шектеулі пайдаланушылар тобына тестілеу
                    шеңберіндегі мүмкіндіктердің таралуы) қолданбаның барлық немесе кейбір
                    мүмкіндіктері пайдаланушының пайдалануы үшін қол жетімді болмауы немесе шектелуі
                    мүмкін. Егер мұндай пайдаланушы оларды қолданбаны пайдалану арқылы пайдалана
                    алмаса, функциялар пайдаланушы үшін қол жетімді емес (шектеулі) болып саналады.
                    Осы шектеулерді айналып өтудің кез келген техникалық және бағдарламалық
                    әдістерін қолдануға тыйым салынады. Осы Оферта шартының қосымшаның пайдаланушысы
                    үшін қолжетімсіз (шектелген) функцияларын реттейтін ережелері пайдаланушыға
                    мұндай функциялар қосымшаны пайдалану арқылы қолжетімді болғанға дейін
                    қолданылмайды.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    3.4. Пайдаланушының қосымшаны пайдалану немесе сервиске оферта шартының
                    3.1-тармағында көрсетілген өзге тәсілмен қол жеткізу мүмкіндігі болмаған кезде
                    (Интернет желісіне қол жеткізу болмаған кезде, қосымшаны пайдаланудағы
                    проблемалар кезінде қоса алғанда, бірақ онымен шектелмей) пайдаланушы сервисті
                    пайдалануда көмек алу үшін Қолдау қызметіне жүгіне алады. Осы Оферта шартының
                    ережелері пайдаланушының өтінімді ресімдеу мақсаттары үшін Қолдау қызметіне
                    өтініштеріне ол қолданылатын шамада қолданылады. Пайдаланушының қолдау
                    қызметінің мамандарымен, сондай-ақ орындаушымен барлық сөйлесулері сапаны
                    бақылау және қызмет көрсетуді жетілдіру мақсатында жазылуы мүмкін.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    3.5. Қолданбаны пайдалану арқылы пайдаланушы электр көліктерін зарядтау
                    қызметтерін көрсететін орындаушылар туралы ақпарат ала алады. Чек «Adele Energy
                    Group ltd.» берілген ақпараттың толықтығы мен дұрыстығын, сондай-ақ чекті
                    тексеруге міндетті емес «Adele Energy Group ltd.» Орындаушы берген ақпаратты
                    өзгертпейді. Орындаушылар тізімі сайтта қол жетімді. Пайдаланушы туралы
                    толығырақ ақпарат алу
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Орындаушыға және олар ұсынатын электр көлік құралдарын зарядтау жөніндегі
                    қызметтерге пайдаланушы орындаушыға өз бетінше жүгіну арқылы жүзеге асыра алады.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    3.6. Сервисті пайдалану шеңберінде пайдаланушыға «Adele Energy Group ltd.» чека
                    серіктестері болып табылатын үшінші тұлғалардың сервистерін пайдалануға ұсынылуы
                    мүмкін. (бұдан әрі - «үшінші тұлғалардың сервистері»). Үшінші тұлғалардың
                    осындай Сервистерін пайдалану Пайдаланушы мен «Adele Energy Group ltd.» чека
                    серіктесі арасындағы қорытынды негізінде жүзеге асырылады тиісті пайдаланушы
                    келісімдері. Егер мұндай қызметтер немесе олардың бір бөлігі «Adele Cloud»
                    қосымшасы арқылы көрсетілетін болса, пайдаланушы қосымшадағы үшінші тұлғалар
                    сервисінің Пайдаланушы келісіміне қосылу туралы келісіммен танысады және
                    «ADELEENERGY Group ltd» чека серіктесінің қызметтерін пайдалануды бастамас бұрын
                    оның шарттарын қабылдауға міндетті.».
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    3.7. Пайдаланушыны «Adele Energy Group ltd.» электрондық пошта Агрегаторларын
                    тарту құқығында.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>
                    4. ПАЙДАЛАНУШЫНЫҢ ҚОСЫМШАНЫ ПАЙДАЛАНУЫ ЖӘНЕ ОСЫ ШАРТҚА ҚОСЫЛУ ТӘРТІБІ
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    4.1. Қосымшаны пайдалану мақсатында пайдаланушы қосымшада өзінің ұялы телефон
                    нөмірін көрсетуге міндетті. Көрсетілген деректер толық, дәл және сенімді болуы
                    тиіс.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    4.2. Ұялы телефон нөмірін көрсеткеннен кейін пайдаланушы зарядтау процесін
                    бақылау және басқару сілтемесі бар SMS хабарлама алады.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    4.3. Көрсетілген ұялы телефон нөмірінің тиесілілігі расталғаннан кейін
                    пайдаланушы қосымшада тіркелген болып есептеледі және қосымшада есептік жазбаға
                    бірегей сәйкестендіру нөмірін бере отырып, пайдаланушының есептік жазбасы
                    қалыптастырылады.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    4.4. Пайдаланушы Пайдаланушы тіркелгісін пайдалану арқылы жасалған барлық
                    әрекеттер пайдаланушы жасаған деп есептелетінін түсінеді және келіседі.
                    Пайдаланушы өзінің тіркеу деректерінің құпиялылығын қамтамасыз етуге және
                    пайдаланушының жеке мобильді құрылғысына үшінші тұлғалардың кіруін шектеуге
                    міндетті. Пайдаланушы пайдаланушының тіркеу деректерінің құпиялылығын бұзудан
                    және/немесе үшінші тұлғаларға Пайдаланушының дербес мобильді құрылғысына қол
                    жеткізуден туындаған қолайсыз салдарлардың туындау қаупін дербес көтереді.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    4.5. Осы Оферта шартын акцептеу электр көлік құралын зарядтау басталатын
                    қосымшадағы іс-қимылдар жолымен жүзеге асырылады.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    4.6. Осы Оферта шартының акцепті пайдаланушының осы Оферта шартына №2 қосымшаға
                    сәйкес дербес деректерді өңдеуге келісімін білдіреді.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    4.7. Есептік жазбаны жою келесі жағдайларда жүзеге асырылуы мүмкін:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    4.7.1. Орындаушының қалауы бойынша, егер Пайдаланушы есептік жазбаны жоюға
                    өтінім берсе, бұл ретте Орындаушы себебін түсіндірместен Есептік жазбаны жоюдан
                    бас тарта алады. Егер Орындаушы Есептік жазбаны жоюдан бас тартқан жағдайда, ол
                    пайдаланушының жеке деректеріне қол жеткізуді техникалық шектеуді Орындаушының
                    серверлерінде Есептік жазбаны және пайдаланушының жеке деректерін сақтай отырып
                    жүзеге асыруға құқылы;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    4.7.2. Тек белсендірілген деректер мен командаларды пайдаланатын пайдаланушыға
                    қатысты Орындаушының қалауы бойынша.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    4.7.3. Пайдаланушы осы Келісімнің талаптарын бірнеше рет немесе елеулі түрде
                    бұзған жағдайда, белгілі бір бұзушылықты Елеулі деп тануды Орындаушы
                    бұзушылықтың нақты мән-жайларын бағалау негізінде дербес жүзеге асырады. Елеулі
                    бұзушылықтарға, оның ішінде жүзеге асыруға осы Келісімнің 11-бөлімінде тыйым
                    салынған әрекеттер немесе орындаушыдан тиісті талап алғаннан кейін мерзімде
                    пайдаланушы жоймаған бұзушылықтар және т. б. жатқызылуы мүмкін.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    4.7.4. Орындаушы есептік жазбаны жою себептерін түсіндірмеуге құқылы.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>5. ОРЫНДАУШЫНЫҢ ҚЫЗМЕТ КӨРСЕТУ ТӘРТІБІ</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    5.1. Пайдаланушы өтінімдерді қосымшаны пайдалану арқылы немесе осы Оферта
                    шартында көзделген өзге де тәсілмен орналастырған зарядтау жөніндегі қызметтерді
                    орындаушы тікелей көрсетеді. Осы Оферта шартында көрсетілген Орындаушыны
                    зарядтау бойынша қызмет көрсету шарттары Орындаушының тапсырысы бойынша
                    пайдаланушының назарына жеткізіледі.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    5.2. Чек «Adele Energy Group ltd.» зарядтау қызметтерін көрсету үшін зарядтау
                    станцияларының иесі болып табылмайды, сервис арқылы Орындаушының атынан
                    Пайдаланушымен зарядтау қызметтеріне шарт жасасады. Чек «Adele Energy Group
                    ltd.»зарядтау қызметтері туралы ақпарат агрегаторының иесі.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    5.3. Пайдаланушының өтінімді орналастыруы «Adele Energy Group ltd.»
                    пайдаланушының зарядтау қызметтерін көрсетуге тапсырысын орындауға. Өтінімнің
                    шарттарына сәйкес Зарядтау қызметтерін көрсетуге пайдаланушының тапсырысын
                    орындауға қабылдауды Орындаушы жүзеге асырады және Орындаушының назарына
                    қосымшаның интерфейсі арқылы Орындаушының қабылдағаны туралы ақпарат жеткізілген
                    сәтте өтті деп есептеледі.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    5.4. Чек «Adele Energy Group ltd.» пайдаланушыға пайдаланушы орналастырған
                    өтінімді Орындаушы орындауға қабылдайтынына кепілдік бермейді. Чек «Adele Energy
                    Group ltd.» Орындаушы қабылдаған өтінімді Орындаушы орындайтынына кепілдік
                    бермейді және орындаушы қабылдаған өтінімдер бойынша зарядтау қызметтерін
                    көрсетпегені үшін жауап бермейді.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    5.5. Зарядтау жөніндегі қызметтердің құнын Орындаушы белгілейді, бірақ осы
                    Оферта шартында көрсетілген жағдайларды қоспағанда, қосымшада көрсетілген
                    тарифтерден аспауға тиіс. Зарядтау Қызметтерінің құны Орындаушы таңдаған тариф
                    негізінде анықталады. Зарядтау жөніндегі қызметтердің құны осы Оферта шартында
                    көрсетілген жағдайларда өзгертілуі мүмкін.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    5.6. Пайдаланушы өтінім шарттарына сәйкес брондау сессиясы басталған сәттен
                    кейін зарядтау бойынша қызметтер көрсетуден бас тартқан жағдайда (бұл туралы
                    пайдаланушы қосымшаның функционалы немесе СМС-хабарлама арқылы хабардар
                    етіледі), сондай-ақ қосымшада көрсетілген өзге де жағдайларда чека
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    «Adele Energy Group ltd.» Орындаушының тапсырмасы бойынша әрекет ете отырып,
                    мұндай пайдаланушыдан Орындаушы нақты шеккен шығыстарды алады, олар қосымшада
                    көрсетілген таңдалған тариф бойынша немесе сайтта көрсетілген өзге де тәсілмен
                    зарядтау жөніндегі қызметтің ең төменгі құны ретінде есептелуі мүмкін, бұл
                    Пайдаланушының шартты орындаудан бас тартқаны үшін төлемі болып табылмайды.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c2}>
                    5.7. Чек «Adele Energy Group ltd.» Орындаушының тапсырысы бойынша Орындаушының
                    зарядтау бойынша қызмет көрсету ережесін пайдаланушының назарына жеткізеді.
                </span>
                <span className={styles.c8}> </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c33}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>6. ПАЙДАЛАНУШЫЛАРДЫҢ ЗАРЯДТАУ ҚЫЗМЕТТЕРІН ТӨЛЕУІ</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    6.1. Пайдаланушының қолма – қол ақшасыз төлем әдісін пайдалану мүмкіндігінің
                    мақсаттары үшін пайдаланушы өзінің банктік картасын осы Оферта шартында
                    көзделген тәртіппен қосымшаның функционалын (бұдан әрі - «байланыстырылған банк
                    картасы») пайдалана отырып көрсетуге міндетті.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    6.2. Байланыстырылған банк картасын жасауды пайдаланушы төлемдерді қабылдау
                    жөніндегі уәкілетті оператордың сервисі арқылы жүргізеді, оның беті қосымшада
                    ашылады. Пайдаланушы 1) банк картасының нөмірін; 2) қолданылу мерзімін; 3)
                    қорғау кодын (CVC/CVV) пайдалана отырып, мынадай деректерді көрсетуге
                    міндеттенеді.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    6.3. Байланыстырылған банк картасының шотында банк картасының деректері
                    көрсетілгеннен кейін қосымшада көрсетілген сома мөлшерінде резервация (холдинг)
                    жүзеге асырылады, ал айырма, егер бар болса, пайдаланушыға қайтарылады. Егер
                    банк картасының деректері толық және шынайы болса және осы картаны пайдалану
                    техникалық мүмкін болса, Пайдаланушы көрсеткен банк картасы осы Оферта шартының
                    шеңберінде байланыстырылған банк картасы ретінде қаралады және қолма-қол ақшасыз
                    төлем әдісі үшін пайдаланылуы мүмкін.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    6.4. Чек «Adele Energy Group ltd.» байланысқан банк картасының деректерімен
                    төлемдерді қабылдау жөніндегі уәкілетті оператордың сервисінде Пайдаланушы
                    көрсеткен деректерге қол жеткізе алмайды және төлемнің қолма-қол ақшасыз
                    тәсілімен төлем жүргізу кезінде берілетін деректердің сақталуына және
                    құпиялылығына жауапты болмайды. Төлемнің қолма - қол ақшасыз тәсілімен төлеуді
                    пайдаланушы төлемдерді қабылдау жөніндегі уәкілетті оператордың немесе
                    электрондық ақша қаражаты операторының немесе есеп айырысуға,
                    ақпараттық-технологиялық өзара іс-қимылға өзге де қатысушылардың қатысуымен
                    жүзеге асырады және халықаралық төлем жүйелерінің, банктердің (оның ішінде
                    байланыстырылған банк картасының эмитент-банкінің) және есеп айырысуға басқа да
                    қатысушылардың қағидаларымен реттеледі.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    6.5. 6.2-тармақта көрсетілгендей банк картасының деректерін көрсеткен кезде.
                    Оферта шартын және байланыстырылған картаны одан әрі пайдалануды растайды және
                    оның атына берілген банк картасы туралы дұрыс және толық ақпаратты көрсетуге
                    кепілдік береді; оның халықаралық төлем жүйелерінің ережелерін және банк
                    картасын шығарған эмитент банктің, оның ішінде қолма-қол ақшасыз есеп айырысу
                    тәртібіне қатысты талаптарын сақтауы.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    6.6. Пайдаланушы өзі растаған ұялы телефон нөмірін пайдалана отырып, қосымшаны
                    пайдалану шеңберінде жасалған барлық іс-әрекеттер, оның ішінде байланыстырылған
                    банк картасын пайдалана отырып, қолма-қол ақшасыз төлем тәсілімен төлеу бойынша
                    жасалған іс-әрекеттер пайдаланушы жасаған болып есептелетінін түсінеді және
                    келіседі.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    6.7. Пайдаланушы қолма-қол ақшасыз төлем тәсілімен төлем фактісімен және/немесе
                    сомасымен келіспеген жағдайда және сервисті пайдалану шеңберінде
                    байланыстырылған картаны пайдалануға байланысты өзге де мәселелер бойынша
                    пайдаланушы қолма-қол ақшасыз төлем әдісі жасалған күннен бастап күнтізбелік 14
                    (он төрт) күн ішінде Қолдау қызметіне жүгінуге құқылы. Егер осындай өтініш
                    бойынша тексеру нәтижелері бойынша «Adele Energy Group ltd.» төлем сомасын
                    қолма-қол ақшасыз төлеу тәсілімен толық немесе ішінара қайтару туралы шешім
                    қабылдайды, көрсетілген қайтару қолма-қол ақшасыз төлеу тәсілімен төлем жасалған
                    байланыстырылған банк картасының банктік шотына жүзеге асырылады. Ақшалай
                    қаражатты қайтару тәртібі төлемдерді қабылдау жөніндегі уәкілетті оператордың
                    немесе электрондық ақша қаражаты операторының немесе есеп айырысуға, ақпараттық
                    - технологиялық өзара іс-қимылға өзге де қатысушылардың қатысуымен жүзеге
                    асырылады және халықаралық төлем жүйелерінің, банктердің (оның ішінде
                    байланыстырылған банк картасының эмитент-банкінің) және есеп айырысуға басқа да
                    қатысушылардың қағидаларымен реттеледі. Өткеннен кейін
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Өтінішті төлеудің қолма-қол ақшасыз тәсілі жасалған күннен бастап күнтізбелік 14
                    (он төрт) күн
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    пайдаланушының қолма-қол ақшасыз төлем тәсілімен төлем фактісімен және/немесе
                    сомасымен келіспеуі және сервисті пайдалану шеңберінде байланыстырылған картаны
                    пайдалануға байланысты өзге де мәселелер бойынша қабылданбайды.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    6.8. Чек «Adele Energy Group ltd.» кез келген сәтте пайдаланушыдан
                    байланыстырылған банк картасын құру кезінде көрсеткен деректерді растауды талап
                    ету және осыған байланысты растайтын құжаттарды (атап айтқанда, жеке басын
                    куәландыратын құжаттарды) сұрау құқығын өзіне қалдырады, олардың берілмеуі
                    жалған ақпарат беруге теңестірілуі және пайдаланушының қосымшаны пайдалануға
                    қолжетімділігін шектеуге әкеп соғуы мүмкін.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    6.9. Чека орындаушысының тапсырысы бойынша «Adele Energy Group ltd.» орындаушы
                    көрсеткен зарядтау қызметтеріне ақы төлеу тәртібі туралы ақпаратты
                    пайдаланушылардың назарына жеткізеді. Осы Оферта шарты шеңберінде зарядтау
                    бойынша қызметке ақы төлеу байланыстырылған банк картасын пайдалану жолымен
                    немесе төлем қосымшасын (Apple Pay, Google Pay және басқалары, егер бар болса)
                    пайдалану жолымен немесе төлем қосымшасын берушінің талаптарына сәйкес
                    «KaspiPay» (егер бар болса) сервисінің көмегімен (бұдан әрі – «қолма-қол
                    ақшасыз») қолма-қол ақшасыз тәсілмен жүргізілуі мүмкін төлем әдісі). Пайдаланушы
                    Орындаушы көрсететін төлем тәсілімен қолма-қол ақшасыз зарядтау қызметтерін
                    төлеген жағдайда, «Adele Energy Group ltd.» төлемдерді қабылдау жөніндегі
                    уәкілетті операторды немесе электрондық ақша қаражаты операторын немесе есеп
                    айырысудың өзге де қатысушыларын, ақпараттық-технологиялық өзара іс-қимылды
                    тарта отырып, тиісті Орындаушының тапсырмасы бойынша, атынан және есебінен
                    әрекет етеді және төлемді Орындаушының агенті ретінде алушы болып табылады.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    6.10. Бұл ретте пайдаланушы «KaspiPay» сервисінің көмегімен қолма-қол ақшасыз
                    тәсілмен төлеу тәсілі пайдаланушының қосымшаға қол жеткізуі арқылы ғана мүмкін
                    болатындығын назарға алады
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>«Kaspi.kz».</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    6.11. Чек «Adele Energy Group ltd.» қолма-қол ақшасыз төлем әдісінде қателер мен
                    ақаулардың болмауына кепілдік бермейді.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>6.12. Ақшаны қайтару</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    6.12.1. Төлем карталары арқылы онлайн-төлем жүргізу кезінде қолма-қол ақшамен
                    қайтаруға жол берілмейді. Қайтару тәртібі халықаралық төлем жүйелерінің
                    ережелерімен реттеледі:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    * Тұтынушы тауарды бергенге дейін кез келген уақытта бас тартуға құқылы, тауарды
                    бергеннен кейін бас тартуды 14 күн ішінде ресімдеу қажет;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    * Егер көрсетілген тауарды тек оны сатып алатын адам ғана пайдалана алатын
                    болса, тұтынушының жеке - белгілі бір қасиеттері бар тиісті сападағы тауардан
                    бас тартуға құқығы жоқ;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    * Тұтынушы тарапынан тауардан бас тартқан кезде сатушы тұтынушы тиісті талап
                    қойған күннен бастап он күннен кешіктірмей тұтынушы төлеген ақшалай соманы оған
                    қайтаруға тиіс.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    6.12.2. Ақшалай қаражатты қайтару Орындаушы тауар мен қызметтерді ұсынбағаны
                    туралы пайдаланушыны растаған жағдайда, Орындаушы «ақшалай қаражатты қайтару
                    туралы өтінішті» алған күннен бастап 15 жұмыс күні ішінде банк картасына жүзеге
                    асырылады.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    6.12.3. Қолма-қол ақшаны банк картасына қайтару үшін компанияның сұранысы
                    бойынша электрондық мекенжайға жіберілетін «қолма-қол ақшаны қайтару туралы
                    өтінішті» толтырып, оны жеке басын куәландыратын құжаттың көшірмесімен бірге
                    info@adele мекенжайына жіберу қажет.energy
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    6.12.4. Пайдаланушының пайдасына оң жауап болған жағдайда, қайтару сомасы сатып
                    алу сомасына тең болады. Өтінішті қарау және ақшалай қаражатты қайтару мерзімі
                    орындаушы өтінішті алған сәттен бастап есептеле бастайды және мереке/демалыс
                    күндерін есептемегенде жұмыс күндерінде есептеледі.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>
                    7. Чек құқықтары мен міндеттері «Adele Energy Group ltd.»
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>7.1. Чек «Adele Energy Group ltd.» құқығы бар:</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    7.1.1. Кез келген уақытта біржақты тәртіпте қолданба мен қызметтің мазмұнын
                    шектеу, кеңейту, өзгерту, сондай-ақ қолданба Мен қызметті пайдаланушыға алдын
                    ала ескертусіз өзгерту және өзгерту;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    7.1.2. Статистикалық деректерді жинау және пайдаланушыны сәйкестендіру
                    мақсатында пайдаланушының IP-мекенжайлары туралы ақпаратты Орнату және сақтау,
                    Пайдаланушының дербес мобильді құрылғысында немесе дербес компьютерінде
                    орналастырылатын техникалық ақпарат файлдарын (cookies) пайдалану;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    7.1.3. Сервисті пайдаланушы пайдалану кезінде пайдаланушының осы Оферта шартының
                    талаптарын сақтамағаны туралы ескерту, хабардар ету, хабардар ету;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    7.1.4. Пайдаланушыға қосымшаны және сервисті пайдалану және/немесе зарядтау
                    қызметтерін көрсету процесіне қатысты техникалық және ақпараттық сипаттағы
                    хабарламалар жіберуге
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Орындаушы (соңғысы Орындаушының тапсырысы бойынша) қосымшаның функционалы арқылы
                    және тіркелу кезінде пайдаланушы көрсеткен телефон нөмірі бойынша
                    SMS-хабарламалар және электрондық поштаға хабарламалар арқылы жүзеге асырылады;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    7.1.5. Қосымшаның және сервистің сапасын жақсарту мақсаттары үшін зерттеу
                    жүргізу мақсаттары үшін Пайдаланушымен берілген ұялы телефон нөмірі немесе
                    электрондық пошта мекенжайы бойынша өз бетінше немесе үшінші тұлғаларды тарта
                    отырып байланысуға құқылы. Бұл ретте пайдаланушы қолдау қызметіне жүгіну арқылы
                    зерттеулерге қатысудан бас тарта алады;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    7.1.6. Пайдаланушы осы Оферта шартының талаптарын бұзған жағдайда қосымшаның
                    және сервистің функционалдық мүмкіндіктерін толық немесе бір бөлігінде шектеу;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    7.1.7. «Adele Energy Group ltd.» корпоративтік клиент үшін қолданба мен
                    қызметтің толық немесе ішінара функционалдық мүмкіндіктерін шектеу;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    7.1.8. Пайдаланушы тарапынан электр көлік құралдарын зарядтау жөніндегі
                    қызметтерді жосықсыз пайдаланды деген күдік туындаған жағдайда, мән-жайларды
                    анықтау үшін күдікті тудыратын өтінімдер бойынша соманы 2 (екі) есепті кезеңге
                    дейінгі мерзімге кешіктіруге тиіс.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>7.2. Чек «Adele Energy Group ltd.» міндетті:</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    7.2.1. Осы Оферта шартында жазылған шарттар мен шектеулерді ескере отырып,
                    пайдаланушыға қосымша мен сервисті пайдалану мүмкіндігін қамтамасыз ету;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    7.2.2. Осы Оферта шартында көзделгендей қосымшаны және сервисті пайдалану
                    құқығын беру;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    7.2.3. Пайдаланушыға қосымшаны және сервисті пайдалану бөлігінде, зарядтау
                    бойынша қызметтерге ақы төлеу бөлігінде және зарядтау жөніндегі қызметтерді
                    пайдалану бөлігінде (соңғысы Орындаушының тапсырысы бойынша жүзеге асырылады)
                    қолдау қызметі арқылы, қосымшаның функционалы арқылы немесе өзге де тәсілмен
                    қолдау көрсету, бұл ретте пайдаланушы өтінім орналастырылған сәттен бастап 14
                    күннен кешіктірілмейтін мерзімде осындай қолдауға жүгінуге тиіс. пайдаланушы
                    қолдауды сұрайды;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    7.2.4. Пайдаланушыға зарядтау қызметінің орындаушысы болып табылатын Орындаушы
                    туралы ақпаратты жеткізу.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>8. ПАЙДАЛАНУШЫНЫҢ ҚҰҚЫҚТАРЫ МЕН МІНДЕТТЕРІ</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>8.1. Пайдаланушы құқылы:</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    8.1.1. Қосымша мен сервисті осы Оферта шартында белгіленген шектеулерді ескере
                    отырып, осы Оферта шартында көрсетілген тәсілдермен пайдалану;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    8.1.2. Қосымшаны және сервисті пайдалану, зарядтау бойынша қызметтерге ақы төлеу
                    және зарядтау бойынша Қызметтерді пайдалану (соңғысы Орындаушының тапсырысы
                    бойынша жүзеге асырылады)мәселелері бойынша ақпарат және консультациялар алу
                    мақсатында Қолдау қызметіне хабарласуға
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>8.2. Пайдаланушы міндеттенеді:</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    8.2.1. Осы Оферта шартының талаптарын қандай да бір шектеусіз және алып қоюсыз
                    сақтау;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    8.2.2. Қосымша мен сервисті осы Оферта шартында көрсетілген тәсілдермен ғана
                    пайдалану;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    8.2.3. «Adele Energy Group ltd.» чека зияткерлік меншік құқығын қандай да бір
                    жолмен бұзбаңыз қосымшаға және сервиске және/немесе қосымшаның және сервистің
                    қандай да бір құрамдас бөліктеріне қатысты, атап айтқанда, пайдаланушының
                    қосымшаны және сервисті таратуға, жалпыға бірдей назарға жеткізуге, өзгертуге,
                    түрлендіруге, декомпиляциялауды немесе бөлшектеуді жүзеге асыруға, көшіруге,
                    таратуға, жіберуге, жариялауға, және өзге де жолмен материалдарды (мәтіндік,
                    «Adele Energy Group ltd.» ЧЕКАСЫНЫҢ жазбаша келісімінсіз қосымшаның құрамындағы
                    графикалық, аудио-бейне).»;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    8.2.4. Өзінің аккаунтының қауіпсіздігін қамтамасыз ететін және үшінші
                    тұлғалардың аккаунтты рұқсатсыз пайдалануына жол бермейтін тиісті шараларды өз
                    бетінше қабылдау; пайдаланушының банк карталарының қауіпсіздігі мен құпиялылығын
                    қамтамасыз ету жөнінде өз бетінше тиісті шаралар қабылдау;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    8.2.5. Қосымша мен сервистер орналастырылған серверлерге шамадан тыс жүктеме
                    жасауға бағытталған қасақана әрекеттерді жүзеге асырмау;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    8.2.6. Қосымшаны және сервисті оның функционалына сәйкес әдеттегідей пайдалануға
                    бағытталмаған кез келген іс-әрекетті жүзеге асырмауға, соның ішінде, бірақ
                    онымен шектелмей, зарядтау жөніндегі қызметтерді пайдалануға нақты әлеуетті ниет
                    болмаған кезде өтінімдерді орналастырмауға, зарядтау жөніндегі қызметтерге ақы
                    төлеуді болдырмау мақсатында зарядтау жөніндегі қызметтерге ақы төлеу тәсілін
                    өзгертпеуге, зарядтау жөніндегі қызметтерге ақы төлеуді болдырмау мақсатында
                    пайдаланушылар үшін адалдық бағдарламасында көзделген жеңілдіктер мен өзге де
                    артықшылықтарды, соның ішінде, бірақ олармен шектелмей, орындаушымен бірге
                    кіруді алуға, негізсіз пайда алу мақсаттары үшін сөз байласу арқылы тікелей
                    зарядтау қызметін орындаушы;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    8.2.7. Зарядтау инфрақұрылымының жабдықтарына ұқыпты қарайды, оның бүліну
                    және/немесе ластану жағдайларын болдырмайды;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    8.2.8. Зарядтау сессиясы аяқталғаннан кейін тұрақ орнын босатыңыз;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    8.2.9. Осы Оферта шартында көзделген өзге де міндеттемелерді орындау.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>9. ОРЫНДАУШЫНЫҢ ҚҰҚЫҚТАРЫ МЕН МІНДЕТТЕРІ</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>9.1. Орындаушының құқығы бар:</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    9.1.1. Әрқайсысы үшін электр көлік құралдарын зарядтау қызметтерінің тарифтерін
                    белгілеу ЭСС.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    9.1.2. Кез келген уақытта біржақты тәртіппен ЭЗЖ пайдалануға/пайдалануға
                    енгізуді/шығаруды жүзеге асыру.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    9.1.3. Көрсетілген қызметтер үшін пайдаланушыдан келіп түспеген төлемді талап
                    ету құқығын беру кредитор құқықтарының басқа тұлғаға ауысқаны туралы
                    пайдаланушыны хабардар ете отырып, үшінші тұлғаларға зарядтау.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>9.2. Орындаушы міндеттенеді:</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    9.2.1. ЭЦҚ-ның қосылуын, дұрыс жұмыс істеуін және сервиске қосылған барлық
                    адамдардың барынша техникалық дайындығын қамтамасыз ету.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    9.2.2. «Adele Energy Group ltd.» электр көлік құралдарын зарядтау қызметтеріне
                    тарифтердің өзгеруі туралы пайдаланушыларды уақтылы хабардар ету.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    9.2.3. «Adele Energy Group ltd.» пайдаланушыларды электр көлік құралдарын
                    зарядтау бойынша қызмет көрсетуге қолжетімді ЭҚК құрамының өзгеруі туралы
                    уақтылы хабардар ету.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    9.2.4. Электр көлік құралдарын зарядтау бойынша қызметтер көрсету үшін ЭҚЖ-ның
                    бос тұруы/қолжетімсіздігі көрсеткішінің бекітілген шекті мәнін ескере отырып,
                    пайдаланушыларға ЭҚЖ-да қызметтер көрсету үшін барлық қажетті шараларды
                    қабылдау.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    9.2.5. Өз есебінен күрделі және ағымдағы жөндеу (оның ішінде технологиялық
                    бұзушылықтар туындаған жағдайда), ЭЦҚ пайдалану және техникалық қызмет көрсету
                    жөніндегі жұмыстарды жүргізу.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    9.2.6. ЭҚК-ні ұстауға және жұмысқа қабілеттілігіне барлық шығындарды көтеруге
                    (соның ішінде электр энергиясы мен сымсыз байланыс қызметтеріне ақы төлеуге).
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    9.2.7. ЭЦҚ-ны толық жарамды және тиісті жағдайда ұстау, өртке қарсы қауіпсіздік,
                    еңбек қауіпсіздігі және еңбекті қорғау техникасы, «Adele Energy Group ltd.»
                    өндірістік чек талаптарын сақтау санитария, экологиялық және табиғат қорғау
                    заңнамасы, Қазақстан Республикасының электр станциялары мен желілерін техникалық
                    пайдалану қағидалары, сондай-ақ Қазақстан Республикасының өзге де
                    нормативтік-құқықтық актілеріне сәйкес ЭЦҚ пайдалану.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    9.2.8. Екінші деңгейдегі пайдаланушыларға тәулік бойы қолдаудың жұмыс істеуіне
                    кепілдік беру, атап айтқанда «Adele Energy Group ltd.» ЧЕКІНЕН келіп түскен
                    өтініштерді өңдеу пайдаланушыларды қолдаудың бірінші деңгейінен бастап ЭҚЖ
                    техникалық жарамдылығы мен жұмысқа қабілеттілігіне байланысты және т. б.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    9.2.9. Электр көлік құралдарын зарядтау бойынша қызметтер көрсету нәтижесінде
                    үшінші тұлғаларға (пайдаланушыларға) немесе олардың мүлкіне келтірілген кез
                    келген зиян үшін толық көлемде жауаптылықта болуға тиіс.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>10. Жауапкершілік</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    10.1.1.        Осы Оферта шартына сәйкес қосымша, Сервис және оларды пайдалану
                    құқықтары пайдаланушыға «сол күйінде» беріледі.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    10.1.2.        Чек «Adele Energy Group ltd.»: 1) қосымша Мен Сервис
                    пайдаланушының субъективті күтулері мен талаптарын қанағаттандыратынына; 2)
                    қосымшаны және сервисті пайдалану үздіксіз, жылдам, техникалық іркіліссіз,
                    сенімді және қатесіз өтетініне; 3) пайдаланушының қосымшаны және сервисті
                    пайдалану нәтижелері қатесіз болатынына; 4) қосымша мен Сервис қолжетімді және
                    қолжетімді болатынына кепілдік бермейді тәулік бойы, белгілі бір уақытта немесе
                    белгілі бір уақыт аралығында пайдаланылуы керек. Чек «Adele Energy Group ltd.»
                    уақытша үзілістерге, техникалық ақауларға және пайдаланушының дербес мобильді
                    құрылғысында қосымшаның және сервистің жұмысын тоқтатуға жауапты емес.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    10.1.3.        Чек «Adele Energy Group ltd.» қосымшаны және сервисті пайдалануға
                    және/немесе пайдалану мүмкін .стігіне байланысты пайдаланушыда туындаған
                    залалдың, тікелей немесе жанама залалдың туындауы үшін жауапты болмайды.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    10.1.4.        Чек «Adele Energy Group ltd.» зарядтау қызметтерін көрсету
                    процесінде пайдаланушыға және пайдаланушыға қатысты өтінім орналастырылған
                    тұлғаларға келтірілген зиян үшін жауап бермейді. Пайдаланушыға және оларға
                    қатысты Пайдаланушы зарядтау бойынша қызметтер көрсету процесінде өтінім
                    орналастырған тұлғаларға келтірілген зиян үшін Орындаушы қолданылатын заңнаманың
                    ережелеріне сәйкес жауапты болады.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    10.1.5.        Чек «Adele Energy Group ltd.» Орындаушы туралы ақпаратты қоса
                    алғанда, Орындаушы ұсынатын ақпараттың мазмұны және/немесе өзектілігі үшін
                    жауапты болмайды. «Adele Energy Group ltd.» пайдаланушы мен орындаушы жасайтын
                    қаржылық және кез келген басқа операциялар үшін, сондай-ақ Пайдаланушының
                    Орындаушы көрсететін зарядтау қызметтерін сатып алуының кез келген салдары үшін
                    жауапты болмайды.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    10.1.6.        Чек «Adele Energy Group ltd.» пайдаланушының Интернетке
                    қолжетімділігінің жоқтығы үшін, Интернет желісінің байланыс провайдерлері
                    қызметтерінің сапасы үшін, пайдаланушы байланыс қызметтерін ұсыну туралы
                    келісімдер жасаған телефон байланысы қызметтерінің сапасы үшін жауапты болмайды.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    10.1.7.        Пайдаланушы зияткерлік меншік құқықтарын және «Adele Energy Group
                    ltd.» қолданыстағы заңнамаға сәйкес.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    10.1.8.        Чек «Adele Energy Group ltd.» үшінші тұлғалардың қызметтерін
                    пайдалану кезінде пайдаланушыға келтірілген зиян мен залал үшін жауапкершілік
                    көтермейді «Adele Energy Group ltd.» үшінші тұлғаның сервисін ұсынатын «Adele
                    Energy Group ltd.» чека пайдаланушысы мен серіктесінің қорытындысы негізінде
                    анықталады шарттар мен қолданыстағы заңнама.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>11. ҚОРЫТЫНДЫ ЕРЕЖЕЛЕР</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    11.1.1.        Осы Оферта шарты осы Оферта шартында көзделгендей оферта
                    пайдаланушысы акцептеген сәттен бастап қолданылады және пайдаланушы зарядтау
                    жөніндегі қызметті толық төлегенге дейін қолданылады.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    11.1.2.        Осы Оферта шартын, сондай-ақ қолданылатын құжаттарды орындаушы
                    қандай да бір алдын ала ескертусіз өзгерте алады. Оферта шартындағы және
                    қолданылатын құжаттардағы кез келген өзгерістер оферта шарты мен қолданылатын
                    құжаттар өзгертілген нұсқада жарияланған күннен кейінгі күні күшіне енеді.
                    Пайдаланушы оферта шартын және қолданылатын құжаттарды өзгерістер мәніне дербес
                    тексеруге міндеттенеді. Пайдаланушының оферта шартымен және қолданылатын
                    құжаттармен және/немесе оферта шартының және қолданылатын құжаттардың
                    өзгертілген редакциясымен танысу жөніндегі іс-әрекеттерді жүзеге асырмауы
                    пайдаланушының өз міндеттемелерін орындамауы және пайдаланушының оферта шартында
                    және қолданылатын құжаттарда белгіленген шектеулерді сақтамауы үшін негіз бола
                    алмайды. Пайдаланушы пайдаланушының оферта шартының және қолданылатын
                    құжаттардың өзгертілген редакциясымен таныспауынан туындаған қолайсыз
                    салдарлардың туындау қаупін дербес көтереді.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    11.1.3.        Оферта шарты Қазақстан Республикасының заңнамасына сәйкес
                    реттеледі және түсіндіріледі. Оферта шартында реттелмеген мәселелер Қазақстан
                    Республикасының заңнамасына сәйкес шешілуге тиіс.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    11.1.4.        Оферта шартын орындауға байланысты кез келген даулар немесе
                    келіспеушіліктер туындаған жағдайда, Тараптар келіссөздер жүргізу арқылы оларды
                    шешу үшін барлық күш-жігерін салады. Егер даулар келіссөздер арқылы шешілмесе,
                    даулар Қазақстан Республикасының заңнамасында белгіленген тәртіппен шешілуге
                    тиіс.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    11.1.5.        Осы Шартқа қоса беріледі және оның ажырамас бөлігі болып
                    табылады:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    № 1 қосымша-электр көлік құралдарын зарядтау бойынша қызметтер көрсететін
                    серіктестердің тізбесі;
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    № 2 қосымша-Пайдаланушының дербес деректерді өңдеуге келісімі.
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c8}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c8}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c8}></span>
            </p>
            <p className={styles.c16 + ' ' + styles.c4}>
                <span className={styles.c8}></span>
            </p>
            <p className={styles.c16 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c1}>№1 қосымша к электр көлік</span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c1}> құралын зарядтау бойынша қызметтер</span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c1}> көрсету оферта шарты </span>
            </p>
            <p className={styles.c7}>
                <span className={styles.c1}>(пайдаланушы келісімі)</span>
            </p>
            <p className={styles.c7 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <a id="id.gjdgxs"></a>
            <h1 className={styles.c12}>
                <span className={styles.c5}>
                    Электр көлік құралдарын зарядтау бойынша қызметтер көрсететін серіктестердің
                    тізбесі
                </span>
            </h1>
            <a id="t.b8a760745fefada160a6ecd01faa4328ca1b2832"></a>
            <a id="t.0"></a>
            <table className={styles.c32}>
                <tbody>
                    <tr className={styles.c23}>
                        <td className={styles.c14} colSpan={1} rowSpan={1}>
                            <p className={styles.c36 + ' ' + styles.c37}>
                                <span className={styles.c1}>№</span>
                            </p>
                        </td>
                        <td className={styles.c10} colSpan={1} rowSpan={1}>
                            <p className={styles.c34}>
                                <span className={styles.c1}>
                                    Серіктестің атауы және оның деректері
                                </span>
                            </p>
                        </td>
                        <td className={styles.c13} colSpan={1} rowSpan={1}>
                            <p className={styles.c29}>
                                <span className={styles.c1}>
                                    Орындаушының орналасқан жері және байланыс деректері
                                </span>
                            </p>
                        </td>
                        <td className={styles.c19} colSpan={1} rowSpan={1}>
                            <p className={styles.c36}>
                                <span className={styles.c1}> Орындаушының сайт мекенжайлары</span>
                            </p>
                        </td>
                    </tr>
                    <tr className={styles.c21}>
                        <td className={styles.c14} colSpan={1} rowSpan={1}>
                            <p className={styles.c17 + ' ' + styles.c4}>
                                <span className={styles.c5}></span>
                            </p>
                            <p className={styles.c6}>
                                <span className={styles.c1}>1</span>
                            </p>
                        </td>
                        <td className={styles.c10} colSpan={1} rowSpan={1}>
                            <p className={styles.c15}>
                                <span className={styles.c1}>
                                    ТОО «Adele Energy Qazaqstan» БИН: 220440045257
                                </span>
                            </p>
                        </td>
                        <td className={styles.c13} colSpan={1} rowSpan={1}>
                            <p className={styles.c30}>
                                <span className={styles.c1}>
                                    Республика Казахстан, город Астана, район Есиль, проспект
                                    Мангилик Ел, 55/22, блок С3.5, офис 314{' '}
                                </span>
                                <span className={styles.c18 + ' ' + styles.c11 + ' ' + styles.c2}>
                                    <a className={styles.c26} href="mailto:aeq@adele.energy">
                                        aeq@adele.energy
                                    </a>
                                </span>
                            </p>
                            <p className={styles.c35}>
                                <span className={styles.c1}>+7 777 00 11350</span>
                            </p>
                        </td>
                        <td className={styles.c19} colSpan={1} rowSpan={1}>
                            <p className={styles.c3}>
                                <span className={styles.c1}>https://adele.energy</span>
                            </p>
                        </td>
                    </tr>
                    <tr className={styles.c28}>
                        <td className={styles.c14} colSpan={1} rowSpan={1}>
                            <p className={styles.c4 + ' ' + styles.c17}>
                                <span className={styles.c5}></span>
                            </p>
                            <p className={styles.c6}>
                                <span className={styles.c1}>2</span>
                            </p>
                        </td>
                        <td className={styles.c10} colSpan={1} rowSpan={1}>
                            <p className={styles.c3}>
                                <span className={styles.c1}>ТОО «Оператор ЭЗС»</span>
                            </p>
                        </td>
                        <td className={styles.c13} colSpan={1} rowSpan={1}>
                            <p className={styles.c31}>
                                <span className={styles.c1}>
                                    Республика Казахстан, город Астана, район Есиль, проспект
                                    Кабанбай батыра, 11/5, Офис 704, 705.{' '}
                                </span>
                                <span className={styles.c11 + ' ' + styles.c2 + ' ' + styles.c18}>
                                    <a className={styles.c26} href="mailto:info@oezs.kz">
                                        info@oezs.kz
                                    </a>
                                </span>
                            </p>
                            <p className={styles.c24}>
                                <span className={styles.c1}>+7 (702) 520-03-51</span>
                            </p>
                        </td>
                        <td className={styles.c19} colSpan={1} rowSpan={1}>
                            <p className={styles.c3}>
                                <span className={styles.c1}>https://oezs.kz/ru/</span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c4 + ' ' + styles.c16}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c25}>
                <span className={styles.c1}>№2 қосымша</span>
            </p>
            <p className={styles.c25}>
                <span className={styles.c1}>к электр көлік құралын зарядтау бойынша </span>
            </p>
            <p className={styles.c25}>
                <span className={styles.c1}>
                    қызметтер көрсету оферта шарты (пайдаланушы келісімі){' '}
                </span>
            </p>
            <p className={styles.c0 + ' ' + styles.c4}>
                <span className={styles.c1}></span>
            </p>
            <p className={styles.c22}>
                <span className={styles.c5}>
                    Дербес деректерді жинауға, өңдеуге, сақтауға және беруге келісім
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    «Дербес деректер және оларды қорғау туралы» Республика Заңына (бұдан әрі – заң)
                    сәйкес, электр көлік құралын зарядтау бойынша қызметтер көрсету офертасының
                    шартын қабылдау кезінде «Adele Energy Group ltd.»(бұдан әрі - Орындаушы), дербес
                    деректерді жинауға және өңдеуге, жазуға, жүйелеуге, жинақтауға, сақтауға,
                    нақтылауға (жаңартуға, өзгертуге), алуға, пайдалануға, иесіздендіруге,
                    бұғаттауға, жоюға және жоюға, оның ішінде автоматтандырылған дербес деректерін
                    менің тегім, атым, әкем, күнім және күнім туралы мамандандырылған электрондық
                    дерекқорда Орындаушының электр көлік құралын зарядтау жөніндегі қызметтерді
                    ұсынуы кезінде және сауалнамаларға/сауалнамаларға қатысу мақсатында пайдаланылуы
                    мүмкін туған жері, мекенжайы, сондай-ақ жеке сипаттағы өзге де ақпарат,
                    Орындаушы пайдаланушылармен электр көлік құралдарын зарядтау бойынша қызметтер
                    көрсету шартын жасасатын тұлғаларды қоспағанда, осы ақпаратты үшінші тұлғаларға
                    жария етпеу кепілдігі шартымен Орындаушының қызмет көрсету сапасы мен қызметтері
                    туралы клиенттердің пікірін зерделеу және зерттеу үшін өткізетін Орындаушы.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Заңның 1-бабының 2) тармақшасына сәйкес дербес деректер деп олардың негізінде
                    айқындалған немесе айқындалатын электрондық, қағаз және (немесе) өзге де
                    материалдық жеткізгіште тіркелген Дербес деректер субъектісіне (Дербес деректер
                    жататын жеке тұлғаға) қатысты мәліметтер түсініледі.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Маған үй мекенжайыма электрондық пошта, телефон арқылы өтініштер,
                    СМС-хабарламалар арқылы пошта хат-хабарларын жіберу арқылы ақпарат беруге
                    келісемін.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Бұл келісім электр көлік құралын зарядтау бойынша қызметтер көрсету офертасының
                    шарты қабылданған сәттен бастап және көрсетілген қызметтер тоқтатылғаннан кейін
                    бес жыл бойы қолданылады. Көрсетілген мерзім өткеннен кейін орындаушыда оны
                    қайтарып алу туралы мәліметтер болмаған кезде осы өтініштің қолданылуы әрбір
                    келесі 5 жылға ұзартылды деп есептеледі.
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c1}>
                    Бұл келісім Орындаушыға жазбаша өтініш беру жолымен кері қайтарып алынуы мүмкін.
                    Оферта шарты келісілді:
                </span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>Орындаушы:</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>Бас директор</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c5}>Чек «Adele Energy Group ltd.»</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c11}>                                </span>
                <span>/</span>
                <span className={styles.c11}>                       </span>
                <span className={styles.c8}>/</span>
            </p>
            <p className={styles.c0}>
                <span className={styles.c8}>м. п.</span>
            </p>
        </div>
    );
};
