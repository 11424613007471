/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';

import info from '../../assets/icons/info.svg';

import notificationStyles from './notification.styles';

type PropTypes = {
    text: string;
};

export const Notification: FC<PropTypes> = ({ text }) => {
    return (
        <div css={notificationStyles.container}>
            <div css={notificationStyles.text}>{text}</div>
            <div>
                <img height={23} src={info} alt={'Operator'} />
            </div>
        </div>
    );
};
