import { createSlice } from '@reduxjs/toolkit';

import { IApiError } from '../../api/types';
import { ISupport } from '../../api/support/types';
import { getSupport } from '../../api/support/support.api';

export interface ISupportState {
    entry: ISupport;
    loading: boolean;
    error: IApiError;
    changed: boolean;
    deleted: boolean;
}

const initialState: ISupportState = {
    entry: <ISupport>{},
    loading: true,
    error: <IApiError>{},
    changed: false,
    deleted: false,
};

const supportSlice = createSlice({
    name: 'support',
    initialState,
    reducers: {
        actionClearSupport: (state): void => {
            state.entry = initialState.entry;
            state.error = initialState.error;
            state.loading = true;
            state.changed = false;
            state.deleted = false;
        },
    },
    extraReducers: builder => {
        // GET SUPPORT
        builder.addCase(getSupport.pending, state => {
            state.loading = true;
            state.error = initialState.error;
            state.changed = false;
            state.deleted = false;
        });
        builder.addCase(getSupport.fulfilled, (state, { payload }) => {
            state.entry = payload;
            state.loading = false;
            state.error = initialState.error;
            state.changed = false;
            state.deleted = false;
        });
        builder.addCase(getSupport.rejected, (state, { payload }) => {
            state.entry = initialState.entry;
            state.error = <IApiError>payload;
            state.loading = false;
            state.changed = false;
            state.deleted = false;
        });
    },
});

export const { actionClearSupport } = supportSlice.actions;
export const supportReducer = supportSlice.reducer;
