import { css } from '@emotion/react';

const downloadModalStyles = css`
    .modal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
    }

    .modal-content {
        width: 345px;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        gap: 25px;
        background-color: #fff;
        border-radius: 8px;
        padding: 24px 15px 24px 15px;
    }

    .download-app__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }

    .download-app__header-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        text-align: center;
    }

    .download-app__container {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .download-app__container-title {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
    }

    .download-app__container-list {
        display: flex;
        flex-direction: column;
    }

    .download-app__container-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 0;
        gap: 8px;
    }

    .download-app__container-list-item__number {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        width: 24px;
        height: 24px;
        padding: 10px;
        border-radius: 8px;
        gap: 10px;
        box-sizing: border-box;
    }

    .download-app__container-list-item__text {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
    }

    .download-app__container-description {
        font-size: 15px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
    }

    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
        cursor: pointer;
    }

    .close:hover {
        color: black;
    }
`;

export default downloadModalStyles;
