import { css } from '@emotion/react';

const tokenPayStyles = {
    container: css({
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        height: '100vh',
    }),
};

export default tokenPayStyles;
