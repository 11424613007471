import React, { FC } from 'react';

import { IIconType } from './types';

export const IconMasterCard: FC<IIconType> = ({ size = 48 }) => {
    return (
        <svg width="80" height={size} viewBox="0 0 80 48" fill="none">
            <rect width="80" height="48" rx="4" fill="white" />
            <rect
                x="0.5"
                y="0.5"
                width="79"
                height="47"
                rx="3.5"
                stroke="black"
                strokeOpacity="0.12"
            />
            <path d="M46.1586 12.957H34.1718V34.703H46.1586V12.957Z" fill="#FF5F00" />
            <path
                d="M34.9327 23.8298C34.9308 21.7355 35.4009 19.6682 36.3075 17.7845C37.214 15.9007 38.5331 14.2498 40.1651 12.9568C38.1441 11.3532 35.7171 10.3559 33.1612 10.079C30.6054 9.80205 28.024 10.2566 25.712 11.3908C23.4 12.5249 21.4507 14.2929 20.087 16.4925C18.7232 18.6922 18 21.2348 18 23.8298C18 26.4248 18.7232 28.9674 20.087 31.1671C21.4507 33.3668 23.4 35.1347 25.712 36.2689C28.024 37.403 30.6054 37.8576 33.1612 37.5806C35.7171 37.3037 38.1441 36.3064 40.1651 34.7028C38.5331 33.4098 37.214 31.7589 36.3075 29.8752C35.401 27.9914 34.9309 25.9241 34.9327 23.8298V23.8298Z"
                fill="#EB001B"
            />
            <path
                d="M62.3292 23.8298C62.3293 26.4248 61.6061 28.9674 60.2424 31.1671C58.8788 33.3667 56.9296 35.1347 54.6176 36.2688C52.3057 37.403 49.7243 37.8576 47.1685 37.5807C44.6127 37.3037 42.1857 36.3064 40.1648 34.7028C41.7953 33.4085 43.1134 31.7574 44.0198 29.8739C44.9261 27.9904 45.3971 25.9238 45.3971 23.8298C45.3971 21.7359 44.9261 19.6692 44.0198 17.7858C43.1134 15.9023 41.7953 14.2511 40.1648 12.9568C42.1857 11.3532 44.6127 10.3559 47.1685 10.079C49.7243 9.80205 52.3057 10.2566 54.6176 11.3908C56.9296 12.525 58.8788 14.2929 60.2424 16.4926C61.6061 18.6922 62.3293 21.2349 62.3292 23.8298V23.8298Z"
                fill="#F79E1B"
            />
            <path
                d="M61.0226 32.3996V31.9544H61.2004V31.8637H60.7476V31.9544H60.9255V32.3996H61.0226ZM61.9018 32.3996V31.8628H61.763L61.6033 32.232L61.4436 31.8628H61.3047V32.3996H61.4028V31.9947L61.5525 32.3438H61.6541L61.8039 31.9938V32.3996H61.9018Z"
                fill="#F79E1B"
            />
        </svg>
    );
};
