/** @jsxImportSource @emotion/react */
import React, {
    BaseSyntheticEvent,
    FC,
    FormEventHandler,
    useEffect,
    useMemo,
    useState,
} from 'react';
import axios from 'axios';
import { useIntl } from 'react-intl';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useStations } from '../../hooks/stations.hook';
import { useGuest } from '../../hooks/guest.hook';
import { Tabs } from '../../components/tabs/tabs.component';
import { ILocation } from '../../api/types';
import { useGeoLocation } from '../../hooks/geo-location.hook';
import FormInput from '../../components/input/input.component';
import { IconSearch } from '../../assets/icons/icon-search';
import { selectGuest } from '../../store/guest/guest.selector';
import { IconChargeComplete } from '../../assets/icons/icon-charge-complete';
import { IconChargeActive } from '../../assets/icons/icon-charge-active';
import { IconArrowRight } from '../../assets/icons/icon-arrow-right';

import { NearbyTab } from './tabs/nearby/nearby.tab';
import { RecentTab } from './tabs/recent/recent.tab';
import mainStyles from './main.styles';

export const MainView: FC = () => {
    const intl = useIntl();
    const navigation = useNavigate();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const { entry: guest } = useSelector(selectGuest);

    const { fetchGuestById, clearGuest } = useGuest();
    const { setGeoLocation, clearLocation } = useGeoLocation();
    const { clearStations, clearStation, setStationsFilterSearch } = useStations();

    const changeSearchHandler = (event: BaseSyntheticEvent) => {
        const value = event.target.value;
        setStationsFilterSearch(value).then();
    };

    const debouncedChangeHandler = useMemo<FormEventHandler<HTMLInputElement>>(
        () => debounce(changeSearchHandler, 500),
        []
    );
    const setLocation = (location: ILocation) => {
        localStorage.setItem('geoLocation', JSON.stringify(location));
        setGeoLocation(location);
    };

    const renderLastCharge = useMemo(() => {
        if (guest?.charge && Object.keys(guest?.charge).length) {
            return (
                <div css={mainStyles.charge} onClick={() => navigation(`/charge/${guest._id}`)}>
                    {guest.charge.status == 1 ? (
                        <div css={mainStyles.chargeInfoWrapper}>
                            <div css={mainStyles.chargeTitle}>
                                <IconChargeComplete size={16} />
                                {intl.formatMessage({ id: 'main.last.charge.title.complete' })}
                            </div>
                            <div css={mainStyles.chargeSubtitle}>
                                {intl.formatMessage({ id: 'main.last.charge.subtitle.complete' })}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div css={mainStyles.chargeTitle}>
                                <IconChargeActive size={16} />
                                {intl.formatMessage({ id: 'main.last.charge.title.inProgress' })}
                            </div>
                            <div css={mainStyles.chargeSubtitle}>
                                {intl.formatMessage({ id: 'main.last.charge.subtitle.inProgress' })}
                            </div>
                        </div>
                    )}
                    <div>
                        <IconArrowRight color={'white'} />
                    </div>
                </div>
            );
        }
    }, [guest]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            position => {
                setLocation({
                    lng: position.coords.longitude,
                    lat: position.coords.latitude,
                });
            },
            async () => {
                try {
                    const response = await axios.get(
                        'https://ipgeolocation.abstractapi.com/v1/?api_key=b6e10c9c746345238e76736de3275c01'
                    );
                    setLocation({
                        lng: response.data.longitude,
                        lat: response.data.latitude,
                    });
                } catch (error) {
                    console.error('Something went wrong getting Geolocation from API!');
                }
            }
        );

        const guestId = localStorage.getItem('guestId');

        if (guestId) {
            fetchGuestById(guestId).then();
        }

        clearGuest().then();
        clearStation().then();

        return (): void => {
            clearLocation();
            clearStations().then();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {renderLastCharge}
            <div css={mainStyles.inputWrapper}>
                <FormInput
                    name={'search'}
                    icon={<IconSearch />}
                    placeholder={intl.formatMessage({ id: 'search.placeholder' })}
                    onChange={event => debouncedChangeHandler(event)}
                />
            </div>
            <Tabs
                tabs={[
                    {
                        label: intl.formatMessage({ id: 'main.tab.nearby' }),
                        index: 0,
                        Component: NearbyTab,
                    },
                    {
                        label: intl.formatMessage({ id: 'main.tab.recent' }),
                        index: 1,
                        Component: RecentTab,
                    },
                ]}
                selectedTab={selectedTab}
                onClick={setSelectedTab}
            />
        </div>
    );
};
