import React, { FC } from 'react';

import { IIconType } from './types';

export const IconCharging: FC<IIconType> = ({ size = 48 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.916 3.20166V1.82806C10.9111 1.58045 10.7089 1.38223 10.4612 1.38232C10.205 1.3866 10.0007 1.59764 10.005 1.8539C10.0052 1.86345 10.0056 1.87309 10.0063 1.88264V4.11133C10.0063 4.61374 10.4136 5.021 10.916 5.021V10.0242C10.916 10.2753 10.7123 10.479 10.4612 10.479C10.21 10.479 10.0063 10.2753 10.0063 10.0242V8.20483C10.0063 7.45127 9.39541 6.84033 8.64185 6.84033H7.27734V1.38232C7.27734 0.879915 6.87009 0.472656 6.36768 0.472656H0.909668C0.407258 0.472656 0 0.879915 0 1.38232V11.3887C0 11.8911 0.407258 12.2983 0.909668 12.2983H6.36768C6.87008 12.2983 7.27734 11.8911 7.27734 11.3887V7.75H8.64185C8.89301 7.75 9.09668 7.95367 9.09668 8.20483V10.0242C9.09668 10.7777 9.70761 11.3887 10.4612 11.3887C11.2148 11.3887 11.8257 10.7777 11.8257 10.0242V4.11133C11.8257 3.60892 11.4184 3.20166 10.916 3.20166ZM5.64031 6.29408L3.27462 9.44826L3.2597 9.44171C3.2174 9.5133 3.15355 9.56934 3.06449 9.56934C2.93022 9.56934 2.82143 9.46054 2.82143 9.32627C2.82143 9.3008 2.84117 9.28415 2.84844 9.26105L2.83234 9.25395L3.53879 7.13943C3.58791 6.99234 3.47839 6.84033 3.32329 6.84033H1.81879C1.63158 6.84033 1.52479 6.62665 1.63704 6.47692L4.00272 3.32274L4.01764 3.32929C4.05994 3.2577 4.1238 3.20166 4.21285 3.20166C4.34712 3.20166 4.45592 3.31046 4.45592 3.44472C4.45592 3.47019 4.43618 3.48684 4.4289 3.50995L4.44491 3.51704L3.73864 5.63147C3.68943 5.77866 3.79896 5.93066 3.95405 5.93066H5.45855C5.64576 5.93066 5.75256 6.14435 5.64031 6.29408Z"
                fill="currentColor"
            />
        </svg>
    );
};
