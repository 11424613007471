/** @jsxImportSource @emotion/react */
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import stopButtonStyles from '../stop-button/stop-button.styles';

import stopConfirmStyles from './stop-confirm.styles';

type PropTypes = {
    currency: string;
    cost: number;
    onStopConfirm: (stop: boolean) => void;
};
export const StopConfirm: FC<PropTypes> = ({ currency, cost, onStopConfirm }) => {
    const intl = useIntl();
    const [closing, setClosing] = useState(false);

    const handleConfirm = (confirm: boolean) => {
        setClosing(true);
        setTimeout(() => {
            onStopConfirm(confirm);
        }, 400);
    };

    return (
        <div
            css={[
                stopConfirmStyles.modal,
                closing ? stopConfirmStyles.fadeOut : stopConfirmStyles.fadeIn,
            ]}>
            <div css={stopConfirmStyles.stopWindow}>
                <h4 css={stopConfirmStyles.stopWindowTitle}>
                    {intl.formatMessage({ id: 'sureToStop' })}
                </h4>
                <p css={stopConfirmStyles.stopWindowDescription}>
                    {intl.formatMessage({ id: 'estimatedChargeCost' })} {cost} {currency}
                </p>
                <div css={stopConfirmStyles.stopWindowButtonContainer}>
                    <button
                        css={[
                            stopButtonStyles.powerStopButton,
                            stopConfirmStyles.stopWindowButtonNo,
                        ]}
                        onClick={() => handleConfirm(false)}>
                        {intl.formatMessage({ id: 'no' })}
                    </button>
                    <button
                        css={[
                            stopButtonStyles.powerStopButton,
                            stopConfirmStyles.stopWindowButtonYes,
                        ]}
                        onClick={() => handleConfirm(true)}>
                        {intl.formatMessage({ id: 'yes' })}
                    </button>
                </div>
            </div>
        </div>
    );
};
